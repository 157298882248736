import {
  AUTO_FILL,
  EMPTY_AUTO_FILL,
  ERROR_FETCH_AQAR,
  ERROR_FETCH_AQARAT,
  ERROR_FETCH_AQAR_ANNOUNCMENTS,
  ERROR_FETCH_AQAR_COOR,
  ERROR_FETCH_AQAR_DETAILS,
  ERROR_FETCH_AQAR_FILES,
  ERROR_FETCH_AQAR_HASER,
  ERROR_FETCH_AQAR_PRICES,
  ERROR_FETCH_AQAR_RECOARDS,
  ERROR_FETCH_LAYERS,
  ERROR_FETCH_PRICE_REVISION,
  FETCH_AQAR,
  FETCH_AQARAT,
  FETCH_AQAR_ANNOUNCMENTS,
  FETCH_AQAR_COOR,
  FETCH_AQAR_DETAILS,
  FETCH_AQAR_FILES,
  FETCH_AQAR_HASER,
  FETCH_AQAR_PRICES,
  FETCH_AQAR_RECOARDS,
  FETCH_LAYERS,
  FETCH_PRICE_REVISION,
  SEARCH_AQARAT,
} from './types';
import { removeIsLoading, setAlert, setIsLoading } from './alert';

import api from '../utils/api';
import { fetchArchiveRequests } from './archive';
import setAuthToken from '../utils/setAuthToken';

// FETCH_AQAR_RECOARDS,
// ERROR_FETCH_AQAR_RECOARDS,
export const fetchAqarCoord = (aqar_number) => async (dispatch) => {
  // console.log('aqar_number', aqar_number);
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  dispatch({
    type: ERROR_FETCH_AQAR_COOR,
  });
  try {
    const response = await api.get(`aqarat/${aqar_number}/fetchCoordanite`);
    dispatch({
      type: FETCH_AQAR_COOR,
      payload: response.data,
    });
  } catch (error) {
    dispatch(setAlert('حدث خطأ ما يرجى اعادة المحاولة', 'error', 'خطأ'));

    dispatch({
      type: ERROR_FETCH_AQAR_COOR,
    });
  }
};
export const updateAqarCoordnaite =
  (aqarNumber, formData) => async (dispatch) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    dispatch(setIsLoading());

    try {
      await api.post(`aqarat/${aqarNumber}/updateAqarCoordnaite`, formData);
      // console.log('fetchAqarat');
      dispatch(removeIsLoading());
      dispatch(fetchAqarCoord(aqarNumber));
      dispatch(
        setAlert('تم تحديث بيانات المالك بنجاح', 'success', 'تمت العملية بنجاح')
      );
    } catch (error) {
      dispatch(removeIsLoading());
      dispatch(setAlert('حدث خطأ ما يرجى اعادة المحاولة', 'error', 'خطأ'));
      dispatch({
        type: ERROR_FETCH_AQAR_COOR,
      });
    }
  };
export const annoucmentTathmeen = (aqar_number) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  dispatch(setIsLoading());
  try {
    const res = await api.post(`aqarat/${aqar_number}/annoucment`);
    dispatch(
      setAlert('تم تحديث بيانات المالك بنجاح', 'success', 'تمت العملية بنجاح')
    );
    dispatch(fetchAqaraAnnouncments(aqar_number));
    // const file = new Blob([res.data], { type: 'application/pdf' });
    // const fileURL = URL.createObjectURL(file);
    // window.open(fileURL);
    dispatch(removeIsLoading());
  } catch (error) {
    console.log(error);
    dispatch(removeIsLoading());
    dispatch(setAlert('حدث خطا اثناءالمعالجة يرجآ المحاولة لاحقاً', 'error'));
  }
};
export const updateAqarCoordnaiteStatus =
  (aqarNumber, staus, formData) => async (dispatch) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    dispatch(setIsLoading());

    try {
      await api.post(`aqarat/${aqarNumber}/updateAqarCoordnaiteStatus`, {
        formData,
        staus,
      });
      // console.log('fetchAqarat');
      dispatch(removeIsLoading());
      dispatch(fetchAqarCoord(aqarNumber));
      dispatch(
        setAlert('تم تحديث بيانات المالك بنجاح', 'success', 'تمت العملية بنجاح')
      );
    } catch (error) {
      dispatch(removeIsLoading());
      dispatch(setAlert('حدث خطأ ما يرجى اعادة المحاولة', 'error', 'خطأ'));
      dispatch({
        type: ERROR_FETCH_AQAR_COOR,
      });
    }
  };
export const deleteFile = (aqarNumber, fileKey) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  dispatch({ type: ERROR_FETCH_AQAR_FILES });
  dispatch(setIsLoading());
  try {
    const response = await api.post(`aqarat/${aqarNumber}/deleteFile`, {
      fileKey,
    });
    dispatch(setAlert('تم حذف الملف بنجاح', 'success', 'تمت العملية بنجاح'));
    dispatch(removeIsLoading());
    dispatch({ type: FETCH_AQAR_FILES, payload: response.data });
    dispatch(removeIsLoading());
  } catch (error) {
    dispatch(removeIsLoading());
    dispatch(setAlert('حدث خطأ ما يرجى اعادة المحاولة', 'error', 'خطأ'));
    console.log(error);
  }
};
export const renameFileinAws = (formData, aqarNumber) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  dispatch({ type: ERROR_FETCH_AQAR_FILES });
  dispatch(setIsLoading());
  try {
    const response = await api.post(
      `aqarat/${aqarNumber}/renameFile`,
      formData
    );
    dispatch(
      setAlert('تمت إعادة التسمية بنجاح', 'success', 'تمت العملية بنجاح')
    );
    dispatch(removeIsLoading());
    dispatch({ type: FETCH_AQAR_FILES, payload: response.data });
    // dispatch(getAqarFiles(aqarNumber));
  } catch (error) {
    dispatch(removeIsLoading());
    dispatch(setAlert('حدث خطأ ما يرجى اعادة المحاولة', 'error', 'خطأ'));
    console.log(error);
  }
};
export const addNewAqarNumber = (formData) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  dispatch(setIsLoading());
  try {
    await api.post(`aqarat/addNewAqarNumber`, formData);
    dispatch(
      setAlert(
        'تمت اضافة رقم العقار الجديد بنجاح',
        'success',
        'تمت العملية بنجاح'
      )
    );
    dispatch(removeIsLoading());
  } catch (error) {
    dispatch(removeIsLoading());
    dispatch(setAlert('حدث خطأ ما يرجى اعادة المحاولة', 'error', 'خطأ'));
    console.log(error);
  }
};
export const fetchAqarRecoards = (aqar_number) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  dispatch({
    type: ERROR_FETCH_AQAR_RECOARDS,
  });
  try {
    const response = await api.get(`aqarat/${aqar_number}/recoards`);
    dispatch({
      type: FETCH_AQAR_RECOARDS,
      payload: response.data,
    });
  } catch (error) {
    dispatch(setAlert('حدث خطأ ما يرجى اعادة المحاولة', 'error', 'خطأ'));

    dispatch({
      type: ERROR_FETCH_AQAR_RECOARDS,
    });
  }
};

export const updateAqarHaser = (aqar_number, formData) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  dispatch({
    type: ERROR_FETCH_AQAR_HASER,
  });
  try {
    const response = await api.post(`aqarat/${aqar_number}/haser`, formData);
    dispatch({
      type: FETCH_AQAR_HASER,
      payload: response.data,
    });
    dispatch(fetchAqarByAqarNumber(aqar_number));
    dispatch(
      setAlert('تم تحديث بيانات الحصر بنجاح', 'success', 'تمت العملية بنجاح')
    );
  } catch (error) {
    dispatch(setAlert('حدث خطأ ما يرجى اعادة المحاولة', 'error', 'خطأ'));
    dispatch({
      type: ERROR_FETCH_AQAR_HASER,
    });
  }
};
export const fetchHaser = (aqarNumber) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  dispatch({
    type: ERROR_FETCH_AQAR_HASER,
  });
  try {
    const response = await api.post(`aqarat/${aqarNumber}/fetchHaser`);
    dispatch({
      type: FETCH_AQAR_HASER,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_FETCH_AQAR_HASER,
    });
  }
};
export const fetchAqarDetails = (aqarNumber) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  try {
    const response = await api.get(`aqarat/${aqarNumber}/getAqarDetails`);
    dispatch({
      type: FETCH_AQAR_DETAILS,
      payload: response.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_FETCH_AQAR_DETAILS,
    });
  }
};
export const fetchAqaraAnnouncments = (aqarNumber) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  dispatch({
    type: ERROR_FETCH_AQAR_ANNOUNCMENTS,
  });
  try {
    const response = await api.get(`aqarat/${aqarNumber}/getAnnouncments`);
    dispatch({
      type: FETCH_AQAR_ANNOUNCMENTS,
      payload: response.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_FETCH_AQAR_ANNOUNCMENTS,
    });
  }
};
export const getPriceRevision = (aqar_number) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  try {
    const response = await api.get(`aqarat/${aqar_number}/priceRevision`);
    dispatch({
      type: FETCH_PRICE_REVISION,
      payload: response.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_FETCH_PRICE_REVISION,
    });
  }
};
export const updatePriceRevision =
  (aqar_number, formData) => async (dispatch) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    try {
      const response = await api.post(
        `aqarat/${aqar_number}/priceRevision`,
        formData
      );
      dispatch({
        type: FETCH_PRICE_REVISION,
      });
      dispatch(fetchAqarByAqarNumber(aqar_number));
      dispatch(
        setAlert('تم تحديث بيانات الحصر بنجاح', 'success', 'تمت العملية بنجاح')
      );
    } catch (error) {
      dispatch(setAlert('حدث خطأ ما يرجى اعادة المحاولة', 'error', 'خطأ'));

      dispatch({
        type: ERROR_FETCH_PRICE_REVISION,
      });
    }
  };
export const fetchAqarPrices = (aqarNumber) => async (dispatch) => {
  dispatch({
    type: ERROR_FETCH_AQAR_PRICES,
  });
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  try {
    const response = await api.get(`aqarat/${aqarNumber}/getPrices`);
    if (response.data.msg) {
      dispatch(setAlert(response.data.msg, 'error', 'خطأ'));
    } else {
      dispatch({
        type: FETCH_AQAR_PRICES,
        payload: response.data,
      });
    }
  } catch (error) {
    console.log(error);
    dispatch(setAlert('حدث خطأ ما يرجى اعادة المحاولة', 'error', 'خطأ'));
    dispatch({
      type: ERROR_FETCH_AQAR_PRICES,
    });
  }
};

export const fetchAqarat = (page) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  try {
    const response = await api.post(`aqarat?page=${page}`);
    // console.log('fetchAqarat');
    // console.log(response);
    dispatch({
      type: FETCH_AQARAT,
      payload: response.data,
    });
    dispatch({ type: ERROR_FETCH_AQAR_FILES });
    dispatch({ type: ERROR_FETCH_AQAR });
    dispatch({ type: ERROR_FETCH_LAYERS });
    dispatch({
      type: ERROR_FETCH_AQAR_PRICES,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_FETCH_AQARAT,
    });
  }
};
export const autoFillMalikInfo = (almalik_id_number) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  try {
    // console.log('almalik_id_number', { almalik_id_number });
    const response = await api.post('aqarat/auto/malikInfo', {
      almalik_id_number,
    });
    dispatch({ type: AUTO_FILL, payload: response.data });
  } catch (error) {
    console.log(error);
    dispatch({ type: EMPTY_AUTO_FILL });
  }
};
export const fetchAqarLayer = (aqarId) => async (dispatch) => {
  // console.log('fetchAqarLayer');
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  try {
    const res = await api.get(`aqarat/${aqarId}/getLayers`);
    // console.log(res.data);
    dispatch({
      type: FETCH_LAYERS,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_FETCH_LAYERS,
    });
  }
};
export const finalKrokiAproved = (aqarId, aproved) => async (dispatch) => {
  // console.log('finalKrokiAproved', aqarId);
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  try {
    const res = await api.post(`aqarat/${aqarId}/finalKrokiAproved`, {
      aproved,
    });
    // console.log(res.data);
    if (aproved) {
      dispatch(
        setAlert(
          'تم اعتماد بيانات الكروكي بنجاح',
          'success',
          'تمت العملية بنجاح'
        )
      );
    } else {
      dispatch(
        setAlert('تم رفض بيانات الكروكي', 'success', 'تمت العملية بنجاح')
      );
    }
  } catch (error) {
    console.log(error);
  }
};
export const getAqarFiles = (aqarId) => async (dispatch) => {
  // console.log('getAqarFiles');
  dispatch({ type: ERROR_FETCH_AQAR_FILES });
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  try {
    const response = await api.get(`aqarat/${aqarId}/files`);
    dispatch({ type: FETCH_AQAR_FILES, payload: response.data });
  } catch (error) {
    dispatch({ type: ERROR_FETCH_AQAR_FILES });
  }
};
export const uploadAqarFiles = (id, formData) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  dispatch(setIsLoading());
  // dispatch(setIsLoading());
  try {
    const response = await api.post(`aqarat/${id}/uploadFiles`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    dispatch({ type: FETCH_AQAR_FILES, payload: response.data });
    dispatch(removeIsLoading());
    dispatch(fetchArchiveRequests(id));
    dispatch(
      setAlert('تم حفظ المستندات بنجاح', 'success', 'تمت العملية بنجاح')
    );
    // dispatch(fetchReacordByNumber(recoardNumber));
  } catch (error) {
    console.log(error);
    // dispatch({ type: ERROR_FETCH_RECOARD });
    dispatch({ type: ERROR_FETCH_AQAR_FILES });
    dispatch(removeIsLoading());
    dispatch(setAlert('حدث خطا اثناءالمعالجة يرجآ المحاولة لاحقاً', 'error'));
  }
};
export const searchForAqar =
  (page, coulmName, sarch, companyName) => async (dispatch) => {
    // console.log('searchForAqar');
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    try {
      const response = await api.post(
        `/aqarat/search?page=${page}&coulmName=${coulmName}&search=${sarch}&companyName=${companyName}`
      );
      dispatch({
        type: ERROR_FETCH_AQAR_HASER,
      });
      dispatch({ type: ERROR_FETCH_AQAR });
      // console.log(response);
      dispatch({
        type: SEARCH_AQARAT,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: ERROR_FETCH_AQARAT,
      });
    }
  };

export const fetchAqarByAqarNumber = (aqar_number) => async (dispatch) => {
  dispatch({ type: EMPTY_AUTO_FILL });
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  dispatch({
    type: ERROR_FETCH_AQAR_HASER,
  });
  dispatch({
    type: ERROR_FETCH_AQAR_PRICES,
  });
  dispatch({ type: ERROR_FETCH_AQAR });

  //   try {
  api
    .post(`/aqarat/aqar`, { aqar_number: aqar_number })
    .then((response) => {
      // console.log('fetchAqarByAqarNumber-response');
      dispatch({
        type: FETCH_AQAR,
        payload: response.data,
      });
    })
    .catch((error) => {
      console.log(error);
      dispatch({ type: ERROR_FETCH_AQAR });
    });

  //   } catch (error) {

  //   }
};
export const updataeMalekInfo =
  (aqarNumber, formData, history) => async (dispatch) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    try {
      await api.post(`aqarat/${aqarNumber}/updataMalekInfo`, formData);
      // console.log('fetchAqarat');
      dispatch(fetchAqarByAqarNumber(aqarNumber));
      dispatch(
        setAlert('تم تحديث بيانات المالك بنجاح', 'success', 'تمت العملية بنجاح')
      );
    } catch (error) {
      dispatch(setAlert('حدث خطأ ما يرجى اعادة المحاولة', 'error', 'خطأ'));
      dispatch({
        type: ERROR_FETCH_AQAR,
      });
    }
  };
export const updateAlternitveLand =
  (aqar_number, formData) => async (dispatch) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    try {
      await api.post(`aqarat/${aqar_number}/updateAlternitiveLand`, formData);
      dispatch(fetchAqarByAqarNumber(aqar_number));
      dispatch(
        setAlert(
          'تم تحديث بيانات الارض البديلة بنجاح بنجاح',
          'success',
          'تمت العملية بنجاح'
        )
      );
    } catch (error) {
      dispatch(setAlert('حدث خطأ ما يرجى اعادة المحاولة', 'error', 'خطأ'));
      dispatch({
        type: ERROR_FETCH_AQAR,
      });
    }
  };
export const updateSakAlanqath =
  (aqarNumber, formData, history) => async (dispatch) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    try {
      await api.post(`aqarat/${aqarNumber}/updateSakAlanqath`, formData);
      // console.log('updateSakAlanqath');
      dispatch(fetchAqarByAqarNumber(aqarNumber));
      dispatch(
        setAlert('تم تحديث بيانات المالك بنجاح', 'success', 'تمت العملية بنجاح')
      );
    } catch (error) {
      dispatch(setAlert('حدث خطأ ما يرجى اعادة المحاولة', 'error', 'خطأ'));
      dispatch({
        type: ERROR_FETCH_AQAR,
      });
    }
  };
export const updateAqarInfor =
  (aqarNumber, formData, history) => async (dispatch) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    // console.log('updateAqarInfor');
    api
      .post(`aqarat/${aqarNumber}/updataAqaraInfo`, formData)
      .then((respons) => {
        dispatch(fetchAqarByAqarNumber(aqarNumber));
        dispatch(
          setAlert(
            'تم تحديث بيانات العقار بنجاح',
            'success',
            'تمت العملية بنجاح'
          )
        );
      })
      .catch((err) => {
        dispatch(setAlert('حدث خطأ ما يرجى اعادة المحاولة', 'error', 'خطأ'));
        dispatch({
          type: ERROR_FETCH_AQAR,
        });
      });
  };
export const updateOwnerOption = (aqarNumber, formData) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  try {
    await api.post(`aqarat/${aqarNumber}/updateOwnerOptions`, formData);
    // console.log('fetchAqarat');
    dispatch(fetchAqarByAqarNumber(aqarNumber));
    dispatch(
      setAlert('تم تحديث خيار المالك بنجاح', 'success', 'تمت العملية بنجاح')
    );
  } catch (error) {
    console.log(error);
    dispatch(setAlert('حدث خطأ ما يرجى اعادة المحاولة', 'error', 'خطأ'));
    dispatch({
      type: ERROR_FETCH_AQAR,
    });
  }
};
export const updateAqarFollowUp =
  (aqarNumber, formData, history) => (dispatch) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    api
      .post(`aqarat/${aqarNumber}/updateAqarFollowUp`, formData)
      .then((respons) => {
        dispatch(fetchAqarByAqarNumber(aqarNumber));
        dispatch(
          setAlert(
            'تم تحديث بيانات مراجعة العقار بنجاح',
            'success',
            'تمت العملية بنجاح'
          )
        );
      })
      .catch((err) => {
        // console.log(error.response.data);
        dispatch(setAlert('حدث خطأ ما يرجى اعادة المحاولة', 'error', 'خطأ'));
        dispatch({
          type: ERROR_FETCH_AQAR,
        });
      });
  };
