import { Grid, Typography } from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import Sidebar from '../Sidbar';
import Spinner from '../Spinner';
import { connect } from 'react-redux';
import { fetchAqarRecoards } from '../../../actions/aqarat';

// import { makeStyles } from '@material-ui/core/styles';

// const initialState = {
//   esem_almalik: '',
//   jawal_almalik: '',
//   jawal_alwakel: '',
//   jawal_mandob: '',
//   alenwan_alwatni: '',
//   alWakel_id_number: '',
//   almandob_id_number: '',
//   esam_almandob: '',
//   esam_alwakel: '',
//   almalik_id_number: '',
//   motnaza_alih: '',
//   ValidlegitimatePapers: '',
// };
const AqarRecoards = ({
  auth: { user },
  aqar: { loading, data, recoards },
  match,
  fetchAqarRecoards,
}) => {
  const { params } = match;
  const { id } = params;
  useEffect(() => {
    if (!recoards) fetchAqarRecoards(id);
    // const aqarData = { ...initialState };
    // for (const key in data) {
    //   if (key in aqarData) aqarData[key] = data[key];
    // }
    // setFormData(aqarData);
    // if (!loading && recoards) {
    //   const detailsData = { ...initalDatailsState };
    //   for (const key in details) {
    //     if (key in detailsData) detailsData[key] = details[key];
    //   }
    //   setDaitlesData(detailsData);
    // }
  }, [loading, fetchAqarRecoards, recoards, id]);
  // const [formData, setFormData] = useState({
  //   esem_almalik: '',
  //   jawal_almalik: '',
  //   jawal_alwakel: '',
  //   jawal_mandob: '',
  //   alenwan_alwatni: '',
  //   alWakel_id_number: '',
  //   almandob_id_number: '',
  //   esam_almandob: '',
  //   esam_alwakel: '',
  //   almalik_id_number: '',
  //   motnaza_alih: '',
  //   ValidlegitimatePapers: '',
  // });
  // const title = <Typography>لا يوجد معاملات مرتبطة برقم العقار</Typography>;
  const setupRecoards = (recoards) => {
    const inRecoard = recoards.filter((e) => {
      return e.InOut_ === 'In';
    });
    if (inRecoard.length >= 0) {
      return inRecoard.map((rec) => {
        return (
          <Grid item xs={10}>
            <Link to={`/${user.companyName}/inComing/${rec.TransactionNo}`}>
              <Typography>{rec.TransactionNo}</Typography>
            </Link>
          </Grid>
        );
      });
    } else {
      return (
        <Grid item xs={10}>
          <Typography>لا يوجد معاملات واردة برقم العقار</Typography>
        </Grid>
      );
    }
  };
  const setupOutRecoards = (recoards) => {
    const inRecoard = recoards.filter((e) => {
      return e.InOut_ === 'Out';
    });
    if (inRecoard.length >= 0) {
      return inRecoard.map((rec) => {
        return (
          <Grid item xs={10}>
            <Link to={`/${user.companyName}/outGoing/${rec.TransactionNo}`}>
              <Typography>{rec.TransactionNo}</Typography>
            </Link>
          </Grid>
        );
      });
    } else {
      return (
        <Grid item xs={10}>
          <Typography>لا يوجد معاملات صادرة برقم العقار</Typography>
        </Grid>
      );
    }
  };
  return (
    <Fragment>
      {recoards === null || loading ? (
        <Spinner />
      ) : (
        <Grid container spacing={4}>
          <Grid item sm={2}>
            <Sidebar proprtyId={id} />
          </Grid>
          <Grid item xs={10}>
            {/*  {recoards.length >= 0 ? ({recoards.map}):{title}} */}
            {recoards ? (
              <Grid item xs={10}>
                <Typography>معاملات واردة</Typography>
                {setupRecoards(recoards)}
                <Typography>معاملات صادرة</Typography>
                {setupOutRecoards(recoards)}
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  aqar: state.aqar,
  isLoading: state.isLoading,
  auth: state.auth,
});
export default connect(mapStateToProps, {
  fetchAqarRecoards,
})(AqarRecoards);
