import {
  ACCOUNT_DELETE,
  AUTH_ERROR,
  LOGIN_FAEL,
  LOGIN_SUCCES,
  LOGOUT,
  REGISTER_FAEL,
  REGISTER_SUCCES,
  USER_LOADED,
} from '../actions/types';
const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticted: null,
  loading: true,
  user: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case USER_LOADED:
      return { ...state, isAuthenticted: true, loading: false, user: payload };
    case REGISTER_SUCCES:
    case LOGIN_SUCCES:
      localStorage.setItem('token', payload.token);
      return {
        ...state,
        token: payload.token,
        isAuthenticted: true,
        loading: false,
      };
    case LOGIN_FAEL:
    case REGISTER_FAEL:

    case ACCOUNT_DELETE:
      // console.log(state);
      localStorage.removeItem('token');
      return {
        ...state,
        token: null,
        isAuthenticted: false,
        loading: false,
        user: null,
      };
    case AUTH_ERROR:
    case LOGOUT:
      localStorage.removeItem('token');
      return {
        ...state,
        token: null,
        isAuthenticted: false,
        loading: false,
        user: null,
      };
    default:
      return state;
  }
}
