import {
  ERROR_FETCH_ALL_CATEORIES,
  ERROR_FETCH_CATEORY,
  ERROR_FETCH_SUB_CATEORIES,
  ERROR_FETECH_CATEGORY_SUBS,
  FETCH_ALL_CATEORIES,
  FETCH_CATEORY,
  FETCH_SUB_CATEORIES,
  FETECH_CATEGORY_SUBS,
} from './types';
import { removeIsLoading, setAlert, setIsLoading } from './alert';

import api from '../utils/api';
import setAuthToken from '../utils/setAuthToken';

export const fetchCategorySubs = (catID) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  dispatch({ type: ERROR_FETECH_CATEGORY_SUBS });
  try {
    const response = await api.get(`subCategories/${catID}`);
    // dispatch(removeIsLoading());
    dispatch({
      type: FETECH_CATEGORY_SUBS,
      payload: response.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({ type: ERROR_FETECH_CATEGORY_SUBS });
    // dispatch(setAlert('حدث خطا اثناءالمعالجة يرجآ المحاولة لاحقاً', 'error'));
    // dispatch(removeIsLoading());
  }
};
export const fetchAllCategories = (page, history) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  try {
    const response = await api.get(`categories?page=${page}`);
    dispatch({
      type: FETCH_ALL_CATEORIES,
      payload: response.data,
    });

    // dispatch({ type: ERROR_FETCH_USER_BY_ID });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_FETCH_ALL_CATEORIES,
    });
  }
};
export const updateCategoryById =
  (catID, formData, history) => async (dispatch) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    dispatch(setIsLoading());
    dispatch({
      type: ERROR_FETCH_ALL_CATEORIES,
    });
    try {
      await api.post(`categories/${catID}`, formData);
      dispatch(removeIsLoading());
      dispatch(setAlert('تمت العملية بنجاح', 'success', 'تمت العملية بنجاح'));
      history.push(`/categroies/mangment`);
    } catch (error) {
      console.log(error);
      dispatch(setAlert('حدث خطا اثناءالمعالجة يرجآ المحاولة لاحقاً', 'error'));
      dispatch(removeIsLoading());
    }
  };
export const fetchCategoryById = (id) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  dispatch(setIsLoading());
  dispatch({
    type: ERROR_FETCH_ALL_CATEORIES,
  });
  try {
    const response = await api.get(`categories/${id}`);
    dispatch(removeIsLoading());
    // dispatch(setAlert('تمت العملية بنجاح', 'success', 'تمت العملية بنجاح'));
    // history.push(`/categroies/mangment`);
    // history.push(`/${companyName}/inComing/${Regestration_Number}`);
    dispatch({
      type: FETCH_CATEORY,
      payload: response.data,
    });
    // dispatch({ type: ERROR_FETCH_USER_BY_ID });
  } catch (error) {
    console.log(error);
    // dispatch(setAlert('حدث خطا اثناءالمعالجة يرجآ المحاولة لاحقاً', 'error'));
    dispatch(removeIsLoading());
    dispatch({
      type: ERROR_FETCH_CATEORY,
    });
  }
};
export const fetchSubCategories = (catID) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  dispatch({ type: ERROR_FETCH_SUB_CATEORIES });
  try {
    const response = await api.get(`subCategories/${catID}`);
    // dispatch(removeIsLoading());
    dispatch({
      type: FETCH_SUB_CATEORIES,
      payload: response.data,
    });
  } catch (error) {
    console.log(error);
    // dispatch(setAlert('حدث خطا اثناءالمعالجة يرجآ المحاولة لاحقاً', 'error'));
    // dispatch(removeIsLoading());
  }
};
export const updateSubCatgory = (newValue, catID) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  // dispatch({ type: ERROR_FETCH_SUB_CATEORIES });
  try {
    await api.post(`subCategories/${catID}`, newValue);
    dispatch(setAlert('تمت العملية بنجاح', 'success', 'تمت العملية بنجاح'));
    // dispatch(removeIsLoading());
    // dispatch({
    //   type: FETCH_SUB_CATEORIES,
    //   payload: response.data,
    // });
  } catch (error) {
    console.log(error);
    dispatch(setAlert('حدث خطا اثناءالمعالجة يرجآ المحاولة لاحقاً', 'error'));
    // dispatch(removeIsLoading());
  }
};
export const createSubCatgory = (newValue, catID) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  // dispatch({ type: ERROR_FETCH_SUB_CATEORIES });
  try {
    newValue.parent_id = catID;
    await api.post(`subCategories/`, newValue);
    dispatch(setAlert('تمت العملية بنجاح', 'success', 'تمت العملية بنجاح'));
    // dispatch(removeIsLoading());
    // dispatch({
    //   type: FETCH_SUB_CATEORIES,
    //   payload: response.data,
    // });
  } catch (error) {
    console.log(error);
    dispatch(setAlert('حدث خطا اثناءالمعالجة يرجآ المحاولة لاحقاً', 'error'));
    // dispatch(removeIsLoading());
  }
};
export const addNewCategory = (formData, history) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  dispatch(setIsLoading());
  dispatch({
    type: ERROR_FETCH_ALL_CATEORIES,
  });
  try {
    await api.post(`categories/`, formData);
    dispatch(removeIsLoading());
    dispatch(setAlert('تمت العملية بنجاح', 'success', 'تمت العملية بنجاح'));
    history.push(`/categroies/mangment`);
  } catch (error) {
    console.log(error);
    dispatch(setAlert('حدث خطا اثناءالمعالجة يرجآ المحاولة لاحقاً', 'error'));
    dispatch(removeIsLoading());
  }
};
