import { FETCH_ACCESS_RIGHT, ERROR_FETCH_ACCESS_RIGHT } from '../actions/types';
const initialState = {
  accessRight: { loading: true, data: [] },
};
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ACCESS_RIGHT:
      return {
        ...state,
        accessRight: { data: payload, loading: false },
      };
    case ERROR_FETCH_ACCESS_RIGHT:
      return { ...state };
    default:
      return state;
  }
}
