import React, { Fragment, useEffect } from 'react';

import AccessTime from '@material-ui/icons/AccessTime';
import Card from '../../../Card/Card';
import CardFooter from '../../../Card/CardFooter';
import CardHeader from '../../../Card/CardHeader';
import CardIcon from '../../../Card/CardIcon';
import Spinner from '../../Spinner';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styles from '../../../../assets/jss/material-dashboard-react/views/dashboardStyle';

const CardComponent = ({ color, icon, compoentTitle, data, chart }) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  function renderComponent() {
    if (!data) {
      return (
        <Card>
          <Spinner />
        </Card>
      );
    } else {
      return (
        <Card>
          <CardHeader color={color} stats icon>
            <CardIcon color={color}>{icon}</CardIcon>
            <Typography variant='h5' className={classes.cardCategory}>
              {compoentTitle}
            </Typography>
            <div style={{ display: 'flex' }}>
              <div style={{ width: '50%' }}>
                {data.array.map((it) => (
                  <Typography className={classes.cardCategory} key={it.key}>
                    {it.key} {it.value}{' '}
                    <small>
                      {'  '} {it.plour}{' '}
                    </small>
                  </Typography>
                ))}
              </div>
              {chart}
            </div>
          </CardHeader>
          <CardFooter stats>
            <div className={classes.stats}>
              <AccessTime />
              <Typography variant='caption'>
                آخر تحديث في : {data.date}
              </Typography>
            </div>
          </CardFooter>
        </Card>
      );
    }
  }
  return renderComponent();
};
export default CardComponent;
