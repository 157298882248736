import React, { useEffect } from 'react';
import { Tab, Tabs } from '@material-ui/core';

import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    height: 500,
  },
  toolbarMargin: {
    ...theme.mixins.toolbar,
    marginBottom: '3em',
  },
  tabs: {
    ...theme.typography.tab,
    borderRight: `1px solid ${theme.palette.divider}`,
    minWidth: 10,
    paddingLeft: 0,
    marginRight: '1px',
  },
}));

const VerticalTabs = ({
  proprtyId,
  auth: { loading, user },
  aqar: { data },
}) => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    // console.log(newValue);
    setValue(newValue);
  };
  useEffect(() => {
    if (
      window.location.pathname === `/${proprtyId}/personal_informations` &&
      value !== 0
    ) {
      setValue(0);
    } else if (
      window.location.pathname === `/${proprtyId}/proprty_details` &&
      value !== 1
    ) {
      setValue(1);
    } else if (
      window.location.pathname === `/${proprtyId}/follow_up` &&
      value !== 2
    ) {
      setValue(2);
    } else if (
      window.location.pathname === `/${proprtyId}/alhaser` &&
      value !== 2
    ) {
      setValue(3);
    }
  }, [value, proprtyId]);
  const classes = useStyles();
  const superAdmin = // الإدارة - الإدارة
    (
      <Tabs
        orientation='vertical'
        value={value}
        onChange={handleChange}
        aria-label='Vertical tabs example'
        className={classes.tabs}
        indicatorColor='primary'
      >
        <Tab
          label='المستخدمين'
          {...a11yProps(1)}
          component={Link}
          to={`/users`}
        />
        <Tab
          label='الادارات'
          {...a11yProps(1)}
          component={Link}
          to={`/${proprtyId}/alhaser`}
        />
      </Tabs>
    );
  const araddeAdmin = // الاراضي والممتلكات - مدخل البيانات
    (
      <Tabs
        orientation='vertical'
        value={value}
        onChange={handleChange}
        aria-label='Vertical tabs example'
        className={classes.tabs}
        indicatorColor='primary'
      >
        <Tab
          label='البيانات الشخصية'
          {...a11yProps(0)}
          component={Link}
          to={`/${proprtyId}/personal_informations`}
        />
        <Tab
          label='بيانات العقار'
          {...a11yProps(1)}
          component={Link}
          to={`/${proprtyId}/proprty_details`}
        />
        <Tab
          label='المتابعة'
          {...a11yProps(1)}
          component={Link}
          to={`/${proprtyId}/follow_up`}
        />
        <Tab
          label='الحصر'
          {...a11yProps(1)}
          component={Link}
          to={`/${proprtyId}/alhaser`}
        />
      </Tabs>
    );
  const aradeeAdminContent = // الاراضي والممتلكات - الإدارة
    (
      <Tabs
        orientation='vertical'
        value={value}
        onChange={handleChange}
        aria-label='Vertical tabs example'
        className={classes.tabs}
        indicatorColor='primary'
      >
        <Tab
          label='الاحصائيات'
          {...a11yProps(1)}
          component={Link}
          to={`/dashboard`}
        />
        <Tab
          label='العقارات'
          {...a11yProps(1)}
          component={Link}
          to={`/aqarat`}
        />
        <Tab
          label='التصنيفات'
          {...a11yProps(1)}
          component={Link}
          to={`/categroies/mangment`}
        />
        <Tab
          label='المستخدمين'
          {...a11yProps(1)}
          component={Link}
          to={`/department/users`}
        />
      </Tabs>
    );
  const aradeeHaserContent = // الاراضي والمملتلكات - الحصر
    (
      <Tabs
        orientation='vertical'
        value={value}
        onChange={handleChange}
        aria-label='Vertical tabs example'
        className={classes.tabs}
        indicatorColor='primary'
      >
        <Tab
          label='الحصر'
          {...a11yProps(1)}
          component={Link}
          to={`/${proprtyId}/alhaser`}
        />
      </Tabs>
    );
  // const aletsalat = ( // الاتصالات الإدارية
  //   <Tabs
  //     orientation='vertical'
  //     value={value}
  //     onChange={handleChange}
  //     aria-label='Vertical tabs example'
  //     className={classes.tabs}
  //     indicatorColor='primary'
  //   >
  //     <Tab
  //       label='الحصر'
  //       {...a11yProps(1)}
  //       component={Link}
  //       to={`/${proprtyId}/alhaser`}
  //     />
  //   </Tabs>
  // );
  // );
  const consaltList = // الاتصالات الإدارية
    (
      <Tabs
        orientation='vertical'
        value={value}
        onChange={handleChange}
        aria-label='Vertical tabs example'
        className={classes.tabs}
        indicatorColor='primary'
      >
        <Tab
          label='الارشيف'
          {...a11yProps(1)}
          component={Link}
          id='archives'
          to={`/${proprtyId}/archives`}
        />
      </Tabs>
    );
  const projectDep = // الاتصالات الإدارية
    (
      <Tabs
        orientation='vertical'
        value={value}
        onChange={handleChange}
        aria-label='Vertical tabs example'
        className={classes.tabs}
        indicatorColor='primary'
      >
        <Tab
          label='البيانات الشخصية'
          {...a11yProps(0)}
          component={Link}
          to={`/${proprtyId}/personal_informations`}
        />
        <Tab
          label='بيانات العقار'
          {...a11yProps(1)}
          component={Link}
          to={`/${proprtyId}/proprty_details`}
        />
        <Tab
          label='المتابعة'
          {...a11yProps(1)}
          component={Link}
          to={`/${proprtyId}/follow_up`}
        />
        <Tab
          label='الارشيف'
          {...a11yProps(1)}
          component={Link}
          id='archives'
          to={`/${proprtyId}/archives`}
        />
      </Tabs>
    );
  function araddeDataEntryF() {
    switch (user.department) {
      case '1': // الإدارة
        return superAdmin;
      case '2': // الاراضي والممتلكات
        if (user.roll.group_name === 'الادارة') {
          if (
            window.location.pathname ===
              `/${proprtyId}/personal_informations` ||
            window.location.pathname === `/${proprtyId}/proprty_details` ||
            window.location.pathname === `/${proprtyId}/follow_up` ||
            window.location.pathname === `/${proprtyId}/alhaser`
          ) {
            return araddeAdmin;
          } else {
            return aradeeAdminContent;
          }
        } else if (user.roll.group_name === 'ادخال البيانات') {
          return araddeDataEntry;
        } else if (user.roll.group_name === 'ادخال البيانات ٢') {
          return araddeDataEntryWithHaser;
        } else if (user.roll.group_name === 'الارشيف') {
          return araddeDataEntry;
        } else if (user.roll.group_name === 'الحصر') {
          return aradeeHaserContent;
        }
        break;
      case '3': // المشاريع
        if (user.roll.group_name === 'الاستشاريين') {
          return consaltList;
        } else if (user.roll.group_name === 'المهندسين') {
          return projectDep;
        }

      case '7':
        // إدارة الاتصالات الإدارية
        return projectDep;
    }
  }
  const araddeDataEntryWithHaser = (
    <Tabs
      orientation='vertical'
      value={value}
      onChange={handleChange}
      aria-label='Vertical tabs example'
      className={classes.tabs}
      indicatorColor='primary'
    >
      <Tab
        label='البيانات الشخصية'
        {...a11yProps(0)}
        component={Link}
        to={`/${proprtyId}/personal_informations`}
      />
      <Tab
        label='بيانات العقار'
        {...a11yProps(1)}
        component={Link}
        to={`/${proprtyId}/proprty_details`}
      />
      <Tab
        label='المتابعة'
        {...a11yProps(1)}
        component={Link}
        to={`/${proprtyId}/follow_up`}
      />
      <Tab
        label='التثمين'
        {...a11yProps(1)}
        component={Link}
        to={`/${proprtyId}/tathmeen`}
      />
      <Tab
        label='إعادة التثمين'
        {...a11yProps(1)}
        component={Link}
        to={`/${proprtyId}/priceRevision`}
      />
      <Tab
        label='الحصر'
        {...a11yProps(1)}
        component={Link}
        to={`/${proprtyId}/alhaser`}
      />
      {data && data.noa_almilkia === 'وثيقة' ? (
        // <Tab
        //   label='صك الانقاض'
        //   {...a11yProps(1)}
        //   component={Link}
        //   to={`/${proprtyId}/SakAlanqath`}
        // />
        <Tab
          label='نموذج اثبات الانقاض'
          {...a11yProps(1)}
          component={Link}
          to={`/${proprtyId}/coordinate`}
        />
      ) : null}
      {/* <Tab
        label='الارض البديلة'
        {...a11yProps(1)}
        component={Link}
        to={`/${proprtyId}/altLands`}
      /> */}
      <Tab
        label='الخطابات'
        {...a11yProps(1)}
        component={Link}
        to={`/${proprtyId}/aqarDocs`}
      />
      <Tab
        label='الارشيف'
        {...a11yProps(1)}
        component={Link}
        id='archives'
        to={`/${proprtyId}/archives`}
      />
      <Tab
        label='سجل المعاملات'
        {...a11yProps(1)}
        component={Link}
        to={`/${proprtyId}/recoards`}
      />
      {/* {data && data.consultant_kroki ? (
        <Tab
          label='مراجعة الكروكي المعتمد'
          {...a11yProps(1)}
          component={Link}
          to={`/${proprtyId}/aqarLayers`}
        />
      ) : null} */}
    </Tabs>
  );
  const araddeDataEntry = (
    <Tabs
      orientation='vertical'
      value={value}
      onChange={handleChange}
      aria-label='Vertical tabs example'
      className={classes.tabs}
      indicatorColor='primary'
    >
      <Tab
        label='البيانات الشخصية'
        {...a11yProps(0)}
        component={Link}
        to={`/${proprtyId}/personal_informations`}
      />
      <Tab
        label='بيانات العقار'
        {...a11yProps(1)}
        component={Link}
        to={`/${proprtyId}/proprty_details`}
      />
      <Tab
        label='المتابعة'
        {...a11yProps(1)}
        component={Link}
        to={`/${proprtyId}/follow_up`}
      />
      <Tab
        label='التثمين'
        {...a11yProps(1)}
        component={Link}
        to={`/${proprtyId}/tathmeen`}
      />
      <Tab
        label='إعادة التثمين'
        {...a11yProps(1)}
        component={Link}
        to={`/${proprtyId}/priceRevision`}
      />
      {data && data.noa_almilkia === 'وثيقة' ? (
        // <Tab
        //   label='صك الانقاض'
        //   {...a11yProps(1)}
        //   component={Link}
        //   to={`/${proprtyId}/SakAlanqath`}
        // />
        <Tab
          label='نموذج اثبات الانقاض'
          {...a11yProps(1)}
          component={Link}
          to={`/${proprtyId}/coordinate`}
        />
      ) : null}
      {/* <Tab
        label='الارض البديلة'
        {...a11yProps(1)}
        component={Link}
        to={`/${proprtyId}/altLands`}
      /> */}
      <Tab
        label='الخطابات'
        {...a11yProps(1)}
        component={Link}
        to={`/${proprtyId}/aqarDocs`}
      />
      <Tab
        label='الارشيف'
        {...a11yProps(1)}
        component={Link}
        id='archives'
        to={`/${proprtyId}/archives`}
      />
      <Tab
        label='سجل المعاملات'
        {...a11yProps(1)}
        component={Link}
        to={`/${proprtyId}/recoards`}
      />
      {/* {data && data.consultant_kroki ? (
        <Tab
          label='مراجعة الكروكي المعتمد'
          {...a11yProps(1)}
          component={Link}
          to={`/${proprtyId}/aqarLayers`}
        />
      ) : null} */}
    </Tabs>
  );
  return <div className={classes.root}>{araddeDataEntryF()}</div>;
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  aqar: state.aqar,
});
export default connect(mapStateToProps)(VerticalTabs);
