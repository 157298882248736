import {
  ERROR_FETCH_ALL_CATEORIES,
  FETCH_ALL_CATEORIES,
} from '../actions/types';

const initialState = {
  catLoading: true,
  categories: [],
  pageNumber: 1,
  pageSize: 0,
  totalElment: 0,
  total_pages: 0,
};
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ALL_CATEORIES:
      return {
        ...state,
        categories: payload.data,
        catLoading: false,
        pageNumber: payload.pageNumber,
        pageSize: payload.pageSize,
        totalElment: payload.totalElment,
        total_pages: payload.total_pages,
      };
    case ERROR_FETCH_ALL_CATEORIES:
      return {
        ...state,
        categories: null,
        catLoading: true,
        pageNumber: 1,
        pageSize: 0,
        totalElment: 0,
        total_pages: 0,
      };
    default:
      return state;
  }
}
