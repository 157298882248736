import AdminDashboard from './Admin/AdminDashboard';
// import Departments from "./Admin/Departments";
import AdministrativeComuncations from './AdministrativeCom/Administrative';
import { Grid } from '@material-ui/core';
import React from 'react';
// import Users from "./Admin/Users";
import Sidbar from '../ui/Sidbar';
import Spinner from '../ui/Spinner';
import Table1 from './Table/Table1';
import { connect } from 'react-redux';
const Home = ({ auth: { loading, user } }) => {
  function renerContent() {
    if (user) {
      switch (user.department) {
        case '1': // مكتب العضو المنتدب
          return (
            <Grid container>
              <Grid item xs={2}>
                <Sidbar />
              </Grid>
              <Grid item xs={10}>
                <Table1 />
              </Grid>
            </Grid>
          );
        case '2': // الاراضي والممتلكات
          if (user.roll.group_name === 'الادارة') {
            return (
              <Grid container>
                <Grid item xs={2}>
                  <Sidbar />
                </Grid>
                <Grid item xs={10}>
                  <AdminDashboard />
                </Grid>
              </Grid>
            );
          } else {
            return table;
          }
        case '3': // ادارة المشاريع
          if (user.roll.group_name === 'الادارة') {
            return (
              <Grid container>
                <Grid item xs={2}>
                  <Sidbar />
                </Grid>
                <Grid item xs={10}>
                  <Table1 />
                </Grid>
              </Grid>
            );
          } else {
            return table;
          }
        case '7':
          return <AdministrativeComuncations />;
        default:
          break;
      }
    }
  }
  // const rollOne = <Users />;
  const table = <Table1 />;
  // const aradee = <Fragment></Fragment>;
  // const depatemte = <Departments />;
  return (
    <Grid container>
      <Grid item sm={12}>
        {loading ? <Spinner /> : renerContent()}
      </Grid>
    </Grid>
  );
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps)(Home);
