import {
  AppBar,
  Button,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { logout } from '../../actions/auth';
import { makeStyles } from '@material-ui/styles';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';

// import logo from '../../assets/logo';
function ElevationScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });
  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

const useStyle = makeStyles((theme) => ({
  toolbarMargin: {
    ...theme.mixins.toolbar,
    marginBottom: '1em',
  },
  logo: {
    height: '7em',
    marginLeft: '1em',
  },
  tabContainer: {
    marginRight: 'auto',
  },
  logoContent: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  menu: {
    backgroundColor: theme.palette.common.arcBlue,
    color: 'white',
    borderRadius: '0px',
  },
  tab: {
    ...theme.typography.tab,
    minWidth: 10,
    marginRight: '25px',
    fontSize: '1em',
  },
  menuItem: {
    ...theme.typography.tab,
    opacity: 0.7,
    '&:hover': {
      opacity: 1,
      color: 'white',
    },
  },
}));
const Header = ({ auth: { isAuthenticted, loading }, logout }) => {
  const [value, setValue] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    if (window.location.pathname === '/' && value !== 0) {
      setValue(0);
    } else if (window.location.pathname === '/tow' && value !== 1) {
      setValue(1);
    } else if (window.location.pathname === '/three' && value !== 2) {
      setValue(2);
    } else if (window.location.pathname === '/foure' && value !== 3) {
      setValue(3);
    }
    switch (window.location.pathname) {
      case '/':
        if (value !== 0) {
          setValue(0);
        }
        break;
      case '/services':
        if (value !== 1) {
          setValue(1);
          setSelectedIndex(0);
        }
        break;
      case '/customSoftware':
        if (value !== 1) {
          setValue(1);
          setSelectedIndex(1);
        }
        break;
      case '/mobilApp':
        if (value !== 1) {
          setValue(1);
          setSelectedIndex(2);
        }
        break;
      default:
        break;
    }
  }, [value]);

  // const handleMuneItemClicked = (e, i) => {
  //   setAnchorEl(null);
  //   setOpen(false);
  //   setSelectedIndex(i);
  // };
  // const menuOptions = [
  //   { name: 'Services', link: '/services' },
  //   { name: 'Custom Software', link: '/customSoftware' },
  //   { name: 'mobilApp', link: '/mobilApp' },
  // ];
  const handelOnChange = (e, newValue) => {
    // console.log(newValue);
    setValue(newValue);
  };
  // const handleClicked = (e) => {
  //   setAnchorEl(e.currentTarget);
  //   setOpen(true);
  // };
  // const handleClose = (e) => {
  //   setAnchorEl(null);
  //   setOpen(false);
  // };
  const classes = useStyle();
  const handleLogout = () => {
    console.log('handleLogout');
    logout();
  };
  const authBtns = (
    <Tabs
      value={value}
      className={classes.tabContainer}
      onChange={handelOnChange}
      indicatorColor='primary'
    >
      <Tab label='الرئيسية' className={classes.tab} component={Link} to='/' />
      <Tab
        label='الاحصائيات'
        className={classes.tab}
        component={Link}
        to={`/dashboard`}
      />
      <Tab
        label='تسجيل خروج'
        className={classes.tab}
        component={Link}
        to='/'
        onClick={handleLogout}
      />
    </Tabs>
  );
  const gustBtns = (
    <Tabs
      value={value}
      className={classes.tabContainer}
      onChange={handelOnChange}
      indicatorColor='primary'
    >
      <Tab
        label='تسجيل دخول'
        className={classes.tab}
        component={Link}
        to='/login'
      />
      <Tab label='' className={classes.tab} component={Link} to='/login' />
    </Tabs>
  );
  // console.log('HEADER,', isAuthenticted);
  return (
    <Fragment>
      <ElevationScroll>
        <AppBar position='fixed'>
          <Toolbar disableGutters>
            <Button
              component={Link}
              to='/'
              disableRipple
              className={classes.logoContent}
            >
              {/* <img src={logo} alt='logo' className={classes.logo} /> */}
            </Button>
            <Typography variant='h5'>
              شركة وادي إبراهيم للتنمية والتطوير
            </Typography>
            {isAuthenticted ? authBtns : gustBtns}
            {/* <Tabs
              value={value}
              className={classes.tabContainer}
              onChange={handelOnChange}
              indicatorColor='primary'
            >
              <Tab
                label='الرئيسية'
                className={classes.tab}
                component={Link}
                to='/'
              /> */}
            {/* <Tab
                aria-owns={anchorEl ? 'simpl-menu' : undefined}
                label='Services'
                className={classes.tab}
                aria-haspopup={anchorEl ? 'true' : undefined}
                onMouseOver={(e) => handleClicked(e)}
              /> */}
            {/* <Tab label='df33fd' className={classes.tab} /> */}
            {/* </Tabs> */}
            {/* <Menu
              id='simpl-menu'
              anchorEl={anchorEl}
              open={open}
              elevation={0}
              classes={{ paper: classes.menu }}
              onClose={handleClose}
              MenuListProps={{ onMouseLeave: handleClose }}
            >
              {menuOptions.map((option, i) => (
                <MenuItem
                  component={Link}
                  key={option.name}
                  to={option.link}
                  classes={{ root: classes.menuItem }}
                  onClick={(e) => {
                    handleMuneItemClicked(e, i);
                    setValue(1);
                    handleClose();
                  }}
                  selected={i === selectedIndex && value === 1}
                >
                  {option.name}
                </MenuItem>
              ))}
            </Menu> */}
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <div className={classes.toolbarMargin} />
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { logout })(Header);
