import { Button, CircularProgress, Grid, Typography } from '@material-ui/core';
import React, { Fragment } from 'react';
import { accceptRequest, rejectRequest } from '../../../actions/archive';

import ArchiveImageCard from './ArchiveImageCard';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    paddingTop: '56.25%', // 16:9,
    marginTop: '30',
    aspectRatio: 135 / 76,
  },
});
const ArchiveRequestContanier = ({
  request,
  accceptRequest,
  isLoading,
  aqar_number,
  auth: { user },
  rejectRequest,
}) => {
  const acceptRequest = () => {
    const data = { status: 1 };
    accceptRequest(request.id, aqar_number, data);
  };
  const classes = useStyles();
  const rejectRequestHandle = () => {
    const data = { status: 2 };
    rejectRequest(request.id, aqar_number, data);
  };
  return (
    <Card style={{ paddingBottom: 10 }}>
      <CardContent>
        <Grid item sx={12}>
          <Typography>
            قام الموظف {request.user.name} بانشاء طلب رفع للارشيف رقم{' '}
            {request.id}
          </Typography>
          <Grid container spacing={4}>
            {request.fi
              ? request.fi.map((tile) => (
                  <Fragment key={tile.url}>
                    <ArchiveImageCard
                      url={tile.url}
                      name={tile.Key}
                      // opt={imgeNewName}
                      // changeFileName={reNameFileinAws}
                      // saveNewFileName={saveNewName}
                      // deleteFile={deleteFileFromAWS}
                      // printLetter={printLetter}
                    />
                  </Fragment>
                ))
              : null}
          </Grid>
        </Grid>
      </CardContent>
      {user.roll.group_name === 'الارشيف' ? (
        <CardActions>
          {isLoading.isLoading ? (
            <CircularProgress size={24} className={classes.buttonProgress} />
          ) : (
            <Button color='primary' onClick={acceptRequest} variant='contained'>
              قبول
            </Button>
          )}
          {isLoading.isLoading ? (
            <CircularProgress size={24} className={classes.buttonProgress} />
          ) : (
            <Button
              color='danger'
              onClick={rejectRequestHandle}
              variant='contained'
            >
              رفض
            </Button>
          )}
        </CardActions>
      ) : null}
    </Card>
  );
};
const mapStateToProps = (state) => ({
  isLoading: state.isLoading,
  auth: state.auth,
});
export default connect(mapStateToProps, { accceptRequest, rejectRequest })(
  ArchiveRequestContanier
);
