import { removeIsLoading, setAlert, setIsLoading } from './alert';
import api from '../utils/api';
import setAuthToken from '../utils/setAuthToken';

export const printDeleteLetter = (aqarNumber, name) => async (dispatch) => {
  console.log('deleteLetter');
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  dispatch(setIsLoading());
  try {
    const res = await api.get(`pdf/${aqarNumber}/${name}/deleteLetter`, {
      responseType: 'blob',
    });
    const file = new Blob([res.data], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
    dispatch(removeIsLoading());
  } catch (error) {
    console.log(error);
    dispatch(removeIsLoading());
    dispatch(setAlert('حدث خطا اثناءالمعالجة يرجآ المحاولة لاحقاً', 'error'));
  }
};
// Print Malik info
export const printHaserDoc = (aqarNumber) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  dispatch(setIsLoading());
  try {
    const res = await api.get(`pdf/${aqarNumber}/haserDoc`, {
      responseType: 'blob',
    });
    const file = new Blob([res.data], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
    dispatch(removeIsLoading());
  } catch (error) {
    console.log(error);
    dispatch(removeIsLoading());
    dispatch(setAlert('حدث خطا اثناءالمعالجة يرجآ المحاولة لاحقاً', 'error'));
  }
};
export const printTaslslAlSak = (aqarNumber) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  dispatch(setIsLoading());
  try {
    const res = await api.get(`pdf/${aqarNumber}/printTaslslAlSak`, {
      responseType: 'blob',
    });
    const file = new Blob([res.data], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
    dispatch(removeIsLoading());
  } catch (error) {
    console.log(error);
    dispatch(removeIsLoading());
    dispatch(setAlert('حدث خطا اثناءالمعالجة يرجآ المحاولة لاحقاً', 'error'));
  }
};
export const printAnqathLetter2 = (aqarNumber, preview) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  dispatch(setIsLoading());
  try {
    const res = await api.get(`pdf/${aqarNumber}/anqath_letter2`, {
      responseType: 'blob',
    });
    const file = new Blob([res.data], { type: 'application/pdf' });
    // const fileURL = URL.createObjectURL(file);
    // window.open(fileURL);
    if (preview) {
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    } else {
      let link = document.createElement('a');
      var title = 'HSH';
      link.download = `${title}-${aqarNumber}.pdf`;
      link.href = URL.createObjectURL(file);
      link.click();
      URL.revokeObjectURL(link.href);
    }
    // dispatch(removeIsLoading());
    dispatch(removeIsLoading());
  } catch (error) {
    console.log(error);
    dispatch(removeIsLoading());
    dispatch(setAlert('حدث خطا اثناءالمعالجة يرجآ المحاولة لاحقاً', 'error'));
  }
};
export const printPropertyCheck = (aqarNumber) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  dispatch(setIsLoading());
  try {
    const res = await api.get(`pdf/${aqarNumber}/printPropertyCheck`, {
      responseType: 'blob',
    });
    const file = new Blob([res.data], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
    dispatch(removeIsLoading());
  } catch (error) {
    console.log(error);
    dispatch(removeIsLoading());
    dispatch(setAlert('حدث خطا اثناءالمعالجة يرجآ المحاولة لاحقاً', 'error'));
  }
};
export const printReffDoc = (companyName, recoardId) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  dispatch(setIsLoading());
  try {
    console.log('printReffDoc');
    const res = await api.get(`pdf/${recoardId}/${companyName}/printReffDoc`, {
      responseType: 'blob',
    });
    const file = new Blob([res.data], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
    dispatch(removeIsLoading());
  } catch (error) {
    console.log(error);
    dispatch(removeIsLoading());
    dispatch(setAlert('حدث خطا اثناءالمعالجة يرجآ المحاولة لاحقاً', 'error'));
  }
};
export const printRecivefDoc = (companyName, recoardId) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  dispatch(setIsLoading());
  try {
    console.log('printReffDoc');
    const res = await api.get(
      `pdf/${recoardId}/${companyName}/printReciveDocment`,
      {
        responseType: 'blob',
      }
    );
    const file = new Blob([res.data], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
    dispatch(removeIsLoading());
  } catch (error) {
    console.log(error);
    dispatch(removeIsLoading());
    dispatch(setAlert('حدث خطا اثناءالمعالجة يرجآ المحاولة لاحقاً', 'error'));
  }
};
export const printOwnerOption = (aqarNumber) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  dispatch(setIsLoading());
  try {
    console.log('printOwnerOption');
    const res = await api.get(`pdf/${aqarNumber}/ownerOption`, {
      responseType: 'blob',
    });
    const file = new Blob([res.data], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
    dispatch(removeIsLoading());
  } catch (error) {
    console.log(error);
    dispatch(removeIsLoading());
    dispatch(setAlert('حدث خطا اثناءالمعالجة يرجآ المحاولة لاحقاً', 'error'));
  }
};

export const printTathmeenPDF = (aqarNumber) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  dispatch(setIsLoading());
  try {
    console.log('printReffDoc');
    const res = await api.get(`pdf/${aqarNumber}/tathmeen`, {
      responseType: 'blob',
    });
    const file = new Blob([res.data], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
    dispatch(removeIsLoading());
  } catch (error) {
    console.log(error);
    dispatch(removeIsLoading());
    dispatch(setAlert('حدث خطا اثناءالمعالجة يرجآ المحاولة لاحقاً', 'error'));
  }
};

export const printOutGoingNumber =
  (recoard_id, companyName) => async (dispatch) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    dispatch(setIsLoading());
    try {
      console.log('printOutGoingNumber');
      const res = await api.get(
        `pdf/${recoard_id}/${companyName}/printOutGoingNumber`,
        {
          responseType: 'blob',
        }
      );
      const file = new Blob([res.data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      // const file = new Blob([res.data]);
      // // const fileURL = URL.createObjectURL(file);
      // // window.open(fileURL);
      // let link = document.createElement('a');
      // link.download = `TFR-${recoard_id}.docx`;
      // link.href = URL.createObjectURL(file);
      // link.click();
      // URL.revokeObjectURL(link.href);
      dispatch(removeIsLoading());
    } catch (error) {
      console.log(error);
      dispatch(removeIsLoading());
      dispatch(setAlert('حدث خطا اثناءالمعالجة يرجآ المحاولة لاحقاً', 'error'));
    }
  };
export const printOutGoingReceipt =
  (recoard_id, companyName) => async (dispatch) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    dispatch(setIsLoading());
    try {
      const res = await api.get(
        `pdf/${recoard_id}/${companyName}/printOutGoingReceipt`,
        {
          responseType: 'blob',
        }
      );
      const file = new Blob([res.data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      dispatch(removeIsLoading());
    } catch (error) {
      console.log(error);
      dispatch(removeIsLoading());
      dispatch(setAlert('حدث خطا اثناءالمعالجة يرجآ المحاولة لاحقاً', 'error'));
    }
  };
export const printMalikInfo = (id) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  dispatch(setIsLoading());
  try {
    const res = await api.get(`pdf/${id}/printMalikInfo`, {
      responseType: 'blob',
    });
    const file = new Blob([res.data], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
    dispatch(removeIsLoading());
  } catch (error) {
    console.log(error);
    dispatch(removeIsLoading());
    dispatch(setAlert('حدث خطا اثناءالمعالجة يرجآ المحاولة لاحقاً', 'error'));
  }
};
export const printFull = (data, preview) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  dispatch(setIsLoading());
  try {
    console.log('printAccountDetails');
    const res = await api.post('pdf/printAccountDetails', data, {
      responseType: 'blob',
    });
    const file = new Blob([res.data], { type: 'application/pdf' });
    console.log('preview', preview);
    if (preview) {
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    } else {
      let link = document.createElement('a');
      link.download = `TFR-${data.aqar.aqar_number}.pdf`;
      link.href = URL.createObjectURL(file);
      link.click();
      URL.revokeObjectURL(link.href);
    }

    dispatch(removeIsLoading());
  } catch (error) {
    console.log(error);
    dispatch(removeIsLoading());
    dispatch(setAlert('حدث خطا اثناءالمعالجة يرجآ المحاولة لاحقاً', 'error'));
  }
};
export const downloadRCMCGISReport = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  dispatch(setIsLoading());
  const url = 'status/rcmc_gis_full_report';
  try {
    const res = await api.get(url, {
      responseType: 'blob',
    });
    const file = new Blob([res.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    let link = document.createElement('a');
    link.download = `Rcmc full report ${new Date()
      .toISOString()
      .slice(0, 10)}.xlsx`;
    link.href = URL.createObjectURL(file);
    link.click();
    URL.revokeObjectURL(link.href);
    dispatch(removeIsLoading());
  } catch (error) {
    console.log(error);
    dispatch(removeIsLoading());
    dispatch(setAlert('حدث خطا اثناءالمعالجة يرجآ المحاولة لاحقاً', 'error'));
  }
};
export const downloadRCMCFullReport = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  dispatch(setIsLoading());
  const url = 'status/rcmc_full_report';
  try {
    const res = await api.get(url, {
      responseType: 'blob',
    });
    const file = new Blob([res.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    let link = document.createElement('a');
    link.download = `Rcmc full report ${new Date()
      .toISOString()
      .slice(0, 10)}.xlsx`;
    link.href = URL.createObjectURL(file);
    link.click();
    URL.revokeObjectURL(link.href);
    dispatch(removeIsLoading());
  } catch (error) {
    console.log(error);
    dispatch(removeIsLoading());
    dispatch(setAlert('حدث خطا اثناءالمعالجة يرجآ المحاولة لاحقاً', 'error'));
  }
};
export const downloadFullReport = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  dispatch(setIsLoading());
  const url = 'status/fullReport';
  try {
    const res = await api.get(url, {
      responseType: 'blob',
    });
    const file = new Blob([res.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    let link = document.createElement('a');
    link.download = `Full Repoert ${new Date()
      .toISOString()
      .slice(0, 10)}.xlsx`;
    link.href = URL.createObjectURL(file);
    link.click();
    URL.revokeObjectURL(link.href);
    dispatch(removeIsLoading());
  } catch (error) {
    console.log(error);
    dispatch(removeIsLoading());
    dispatch(setAlert('حدث خطا اثناءالمعالجة يرجآ المحاولة لاحقاً', 'error'));
  }
};
export const rcmcRegestration = (preview, aqarNumber) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  dispatch(setIsLoading());
  try {
    const res = await api.get(`pdf/${aqarNumber}/rcmcRegestration`, {
      responseType: 'blob',
    });
    const file = new Blob([res.data], { type: 'application/pdf' });
    if (preview) {
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    } else {
      let link = document.createElement('a');
      var title = 'RCMC';
      link.download = `${title}-${aqarNumber}.pdf`;
      link.href = URL.createObjectURL(file);
      link.click();
      URL.revokeObjectURL(link.href);
    }
    dispatch(removeIsLoading());
  } catch (error) {
    console.log(error);
    dispatch(removeIsLoading());
    dispatch(setAlert('حدث خطا اثناءالمعالجة يرجآ المحاولة لاحقاً', 'error'));
  }
};
export const rcmcRegestration2 = (preview, aqarNumber) => async (dispatch) => {
  console.log('aqarNumber', aqarNumber);
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  dispatch(setIsLoading());
  try {
    const res = await api.get(`pdf/${aqarNumber}/rcmcRegestration2`, {
      responseType: 'blob',
    });
    const file = new Blob([res.data], { type: 'application/pdf' });
    if (preview) {
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    } else {
      let link = document.createElement('a');
      var title = 'RCMC';
      link.download = `${title}-${aqarNumber}.pdf`;
      link.href = URL.createObjectURL(file);
      link.click();
      URL.revokeObjectURL(link.href);
    }
    dispatch(removeIsLoading());
  } catch (error) {
    console.log(error);
    dispatch(removeIsLoading());
    dispatch(setAlert('حدث خطا اثناءالمعالجة يرجآ المحاولة لاحقاً', 'error'));
  }
};
export const downloadRCMCRegestrationReport = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  dispatch(setIsLoading());
  const url = 'status/rcmcRegestrationReport';
  try {
    const res = await api.get(url, {
      responseType: 'blob',
    });
    const file = new Blob([res.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    let link = document.createElement('a');
    link.download = ` تسجيل الملكية ${new Date()
      .toISOString()
      .slice(0, 10)}.xlsx`;
    link.href = URL.createObjectURL(file);
    link.click();
    URL.revokeObjectURL(link.href);
    dispatch(removeIsLoading());
  } catch (error) {
    console.log(error);
    dispatch(removeIsLoading());
    dispatch(setAlert('حدث خطا اثناءالمعالجة يرجآ المحاولة لاحقاً', 'error'));
  }
};
export const downloadHaserReport = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  dispatch(setIsLoading());
  const url = 'status/fullHaserReport';
  try {
    const res = await api.get(url, {
      responseType: 'blob',
    });
    const file = new Blob([res.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    let link = document.createElement('a');
    link.download = `Haser Repoert ${new Date()
      .toISOString()
      .slice(0, 10)}.xlsx`;
    link.href = URL.createObjectURL(file);
    link.click();
    URL.revokeObjectURL(link.href);
  } catch (error) {
    console.log(error);
    dispatch(removeIsLoading());
    dispatch(setAlert('حدث خطا اثناءالمعالجة يرجآ المحاولة لاحقاً', 'error'));
  }
};
export const downloadOutGoingFullRecoardsReport =
  (companyName) => async (dispatch) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    dispatch(setIsLoading());
    const url = `status/${companyName}/outGoingFullRecoardsReport`;
    try {
      const res = await api.get(url, {
        responseType: 'blob',
      });
      const file = new Blob([res.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      let link = document.createElement('a');
      link.download = `المعاملات الصادرة.xlsx`;
      link.href = URL.createObjectURL(file);
      link.click();
      URL.revokeObjectURL(link.href);
      dispatch(removeIsLoading());
    } catch (error) {
      console.log(error);
      dispatch(removeIsLoading());
      dispatch(setAlert('حدث خطا اثناءالمعالجة يرجآ المحاولة لاحقاً', 'error'));
    }
  };

export const downloadRecoardsFullReport = (companyName) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  dispatch(setIsLoading());
  const url = `status/${companyName}/fullRecoardsReport`;
  try {
    const res = await api.get(url, {
      responseType: 'blob',
    });
    const file = new Blob([res.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    let link = document.createElement('a');
    link.download = `المعاملات الواردة.xlsx`;
    link.href = URL.createObjectURL(file);
    link.click();
    URL.revokeObjectURL(link.href);
    dispatch(removeIsLoading());
  } catch (error) {
    console.log(error);
    dispatch(removeIsLoading());
    dispatch(setAlert('حدث خطا اثناءالمعالجة يرجآ المحاولة لاحقاً', 'error'));
  }
};
export const printAnqathLetter = (aqarNumber) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  dispatch(setIsLoading());
  try {
    const res = await api.get(`pdf/${aqarNumber}/anqath_letter`, {
      responseType: 'blob',
    });
    const file = new Blob([res.data], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
    dispatch(removeIsLoading());
  } catch (error) {
    console.log(error);
    dispatch(removeIsLoading());
    dispatch(setAlert('حدث خطا اثناءالمعالجة يرجآ المحاولة لاحقاً', 'error'));
  }
};
export const printServicesDocments =
  (preview, docId, aqar_number) => async (dispatch) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    dispatch(setIsLoading());
    try {
      var url = `pdf/${aqar_number}/secDoc`;
      if (docId === '0') {
        url = `pdf/${aqar_number}/nwcDoc`;
      } else if (docId === '5') {
        url = `pdf/${aqar_number}/stc`;
      }
      const res = await api.get(url, {
        responseType: 'blob',
      });
      const file = new Blob([res.data], { type: 'application/pdf' });
      console.log('preview', preview);
      if (preview) {
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      } else {
        let link = document.createElement('a');
        var title = '';
        switch (docId) {
          case '0':
            title = 'SRV';
            break;
          case '1':
            title = 'WAT';
            break;
          default:
            title = 'STC';
            break;
        }
        link.download = `${title}-${aqar_number}.pdf`;
        link.href = URL.createObjectURL(file);
        link.click();
        URL.revokeObjectURL(link.href);
      }

      dispatch(removeIsLoading());
    } catch (error) {
      console.log(error);
      dispatch(removeIsLoading());
      dispatch(setAlert('حدث خطا اثناءالمعالجة يرجآ المحاولة لاحقاً', 'error'));
    }
  };
export const printSaheftAlNaza = (data, preview) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  dispatch(setIsLoading());
  try {
    console.log('printSaheftAlNaza');
    const res = await api.post('pdf/printSaheftAlNaza', data, {
      responseType: 'blob',
    });
    const file = new Blob([res.data], { type: 'application/pdf' });
    console.log('preview', preview);
    if (preview) {
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    } else {
      let link = document.createElement('a');
      link.download = `VAP-${data.aqar_number}.pdf`;
      link.href = URL.createObjectURL(file);
      link.click();
      URL.revokeObjectURL(link.href);
    }
    dispatch(removeIsLoading());
  } catch (error) {
    console.log(error);
    dispatch(removeIsLoading());
    dispatch(setAlert('حدث خطا اثناءالمعالجة يرجآ المحاولة لاحقاً', 'error'));
  }
};
