// import { makeStyles } from "@material-ui/core/styles";
import { Alert, AlertTitle } from '@material-ui/lab';
import React, { Fragment, useRef } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     width: '100%',
//     '& > * + *': {
//       marginTop: theme.spacing(2),
//     },
//   },
// }));
// const classes = useStyles();
const DescriptionAlerts = ({ alerts }) => {
  const myRef = useRef(null);

  const executeScroll = () => {
    console.log(myRef);
    // window.scrollTo({ behavior: 'smooth', top: myRef.current.offsetTop })
    // myRef.current.scrollIntoView();
  };
  return (
    <Fragment>
      {alerts != null && alerts.length > 0 ? (
        <Fragment>
          {alerts.map((alert) => (
            <div ref={myRef} key={alert.id}>
              <Alert severity={alert.alertType}>
                <AlertTitle>{alert.alertTitle}</AlertTitle>
                {alert.msg}
              </Alert>
            </div>
          ))}
          {executeScroll()}
        </Fragment>
      ) : null}
    </Fragment>
  );
};
// alerts != null &&
// alerts.length > 0 &&

DescriptionAlerts.protoTypes = {
  alerta: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => {
  return { alerts: state.alert };
};
export default connect(mapStateToProps)(DescriptionAlerts);

// export default function DescriptionAlerts() {

//   return (
//     <div className={classes.root}>
//       <Alert severity="error">
//         <AlertTitle>Error</AlertTitle>
//         This is an error alert — <strong>check it out!</strong>
//       </Alert>
//       <Alert severity="warning">
//         <AlertTitle>Warning</AlertTitle>
//         This is a warning alert — <strong>check it out!</strong>
//       </Alert>
//       <Alert severity="info">
//         <AlertTitle>Info</AlertTitle>
//         This is an info alert — <strong>check it out!</strong>
//       </Alert>
//       <Alert severity="success">
//         <AlertTitle>Success</AlertTitle>
//         This is a success alert — <strong>check it out!</strong>
//       </Alert>
//     </div>
//   );
// }
