import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { connect } from 'react-redux';
import { searchForInComingRecoard } from '../../actions/inComing';
import { searchForRecoard } from '../../actions/administrativeCom';

const ComboBox = ({
  name,
  recoards,
  searchForRecoard,
  updateOutGoingLinked,
  title,
  defVal,
  tableName,
  disabled,
  companyName,
}) => {
  const [defulyVal, setDefVal] = useState([]);
  useEffect(() => {
    setDefVal(defVal);
    updateOutGoingLinked(defVal);
  }, [defVal]);
  return (
    <Autocomplete
      multiple
      id='combo-box-demo'
      value={defulyVal}
      defaultValue={defulyVal}
      options={recoards.map((option) => option)}
      style={{ width: 300 }}
      disabled={disabled}
      name={name}
      onChange={(event, newValue) => {
        if (newValue) {
          setDefVal(newValue);
          updateOutGoingLinked(newValue);
        }
      }}
      getOptionLabel={(option) => option.Regestration_Number}
      onInputChange={(event, newInputValue) => {
        if (tableName === 'in_coming') {
          searchForInComingRecoard(
            1,
            companyName,
            'in_coming',
            'asc',
            event.target.value
          );
        } else {
          searchForRecoard(
            1,
            companyName,
            tableName,
            'Regestration_Number',
            event.target.value
          );
        }
      }}
      renderOption={(option) => (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography noWrap variant='body1'>
                    رقم المعاملة
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography noWrap variant='body1'>
                    موضوع المعاملة
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography noWrap>{option.Regestration_Number}</Typography>
                </TableCell>
                <TableCell>
                  <Typography noWrap>{option.Subject}</Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          variant='standard'
          label={title}
          size='small'
        />
      )}
    />
  );
};
const mapStateToProps = (state) => ({
  recoards: state.recoards.data,
});
export default connect(mapStateToProps, { searchForRecoard })(ComboBox);
