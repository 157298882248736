import {
  Button,
  Card,
  CircularProgress,
  Grid,
  Menu,
  MenuItem,
  NativeSelect,
  TextField,
  Typography,
} from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { Fragment, useEffect, useState } from 'react';
import {
  autoFillMalikInfo,
  fetchAqarByAqarNumber,
  fetchAqarDetails,
  updataeMalekInfo,
} from '../../../actions/aqarat';

import AccessForbidden from './AccessForbidden';
import AlertDialog from '../AlertDialog';
import ContentHeader from '../../Layout/ContentHeader';
import HijriUtils from '@date-io/hijri';
import MuiAlert from '@material-ui/lab/Alert';
import Sidebar from '../Sidbar';
import Spinner from '../Spinner';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { printMalikInfo } from '../../../actions/printDocs';

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}
// const initalDatailsState = {
//   NationalAddress: '',
// };
const initialState = {
  esem_almalik: '',
  jawal_almalik: '',
  jawal_alwakel: '',
  jawal_mandob: '',
  alenwan_alwatni: '',
  alWakel_id_number: '',
  almandob_id_number: '',
  esam_almandob: '',
  esam_alwakel: '',
  almalik_id_number: '',
  motnaza_alih: '',
  tarek_alwkala: '',
  tarek_alwkala_start: '',
  ValidlegitimatePapers: '',
  NationalAddress: '',
  almalik_id_date: '',
  alWakel_id_date: '',
  alWakala_number: '',
  warith_id_number: '',
  esem_warith: '',
  alWarith_id_date: '',
  deh: '',
};
const PersonalInformations = ({
  match,
  auth: { user },
  aqar: { loading, data, details },
  fetchAqarByAqarNumber,
  printMalikInfo,
  updataeMalekInfo,
  isLoading,
  history,
  autoFillMalikInfo,
  autofill,
  fetchAqarDetails,
}) => {
  const { params } = match;
  const { id } = params;
  useEffect(() => {
    if (!data) fetchAqarByAqarNumber(id);
    if (!loading && data) {
      const aqarData = { ...initialState };
      for (const key in data) {
        if (key in aqarData) aqarData[key] = data[key];
      }
      setFormData(aqarData);
    }
  }, [loading, fetchAqarByAqarNumber, data, id]);
  const handleClose = (index) => {
    setAnchorEl(null);
    // console.log('formData', formData);
    const aqarData = { ...formData };
    const malikInfo = autofill.data[index];
    for (const key in malikInfo) {
      if (key in aqarData) aqarData[key] = malikInfo[key];
    }
    setFormData(aqarData);
    console.log('-----', formData);
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      minWidth: 275,
      textAlign: 'center',
      padding: '15px',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    contentTitle: {
      margin: 'auto',
    },
    buttonsContainer: {
      margin: '10px',
    },
    butns: {
      margin: '10px',
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    daitelsContainer: {
      textAlign: 'center',
    },
    buttonProgress: {
      // color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    motnaza: {
      color: 'red',
    },
    margin: {
      margin: theme.spacing(1),
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
  }));
  const classes = useStyles();
  const onSubmit = (e) => {
    e.preventDefault();
    console.log('SUCCESS');
    setEdit(!edit);
    // if (NationalAddress !== '') {
    //   formData.NationalAddress = NationalAddress;
    // }
    if (tarek_alwkala) {
      formData.tarek_alwkala = moment(tarek_alwkala).format(
        'YYYY-MM-DD HH:mm:ss'
      );
    }
    if (tarek_alwkala_start) {
      formData.tarek_alwkala_start = moment(tarek_alwkala_start).format(
        'YYYY-MM-DD HH:mm:ss'
      );
    }
    if (almalik_id_date) {
      formData.almalik_id_date = moment(almalik_id_date).format(
        'YYYY-MM-DD HH:mm:ss'
      );
    }
    if (alWakel_id_date) {
      formData.alWakel_id_date = moment(alWakel_id_date).format(
        'YYYY-MM-DD HH:mm:ss'
      );
    }
    if (alWarith_id_date) {
      formData.alWarith_id_date = moment(alWarith_id_date).format(
        'YYYY-MM-DD HH:mm:ss'
      );
    }

    updataeMalekInfo(data.aqar_number, formData, history);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const [formData, setFormData] = useState({
    esem_almalik: '',
    jawal_almalik: '',
    jawal_alwakel: '',
    jawal_mandob: '',
    alenwan_alwatni: '',
    alWakel_id_number: '',
    almandob_id_number: '',
    esam_almandob: '',
    esam_alwakel: '',
    almalik_id_number: '',
    motnaza_alih: '',
    tarek_alwkala: null,
    tarek_alwkala_start: null,
    ValidlegitimatePapers: '',
    NationalAddress: '',
    almalik_id_date: null,
    alWakel_id_date: null,
    alWakala_number: '',
    warith_id_number: '',
    esem_warith: '',
    alWarith_id_date: null,
    deh: '',
  });
  // const [daitlesData, setDaitlesData] = useState({
  //   NationalAddress: '',
  // });
  // const { NationalAddress } = daitlesData;
  const onlyNumbers = (e) => {
    if (e.target.value === '' || /^\d+$/.test(e.target.value)) {
      console.log('fg');
      if (e.target.name === 'almalik_id_number') {
        if (e.target.value.length === 9) {
          autoFillMalikInfo(e.target.value);
        }
      }
      console.log(e.target.name);
      setFormData({ ...formData, [e.target.name]: e.target.value });
    } else {
      return false;
    }
  };
  const onChange = (e) => {
    // if (e.target.name === 'NationalAddress') {
    //   setDaitlesData({ ...formData, [e.target.name]: e.target.value });
    // } else {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    // }
  };
  // const [showMenu, setShowMenu] = useState(false);
  const [edit, setEdit] = useState(true);
  const enableEdit = () => {
    setEdit(!edit);
  };
  const handlPrintMalikInfo = () => {
    formData.aqar_number = data.aqar_number;
    formData.aqar = data;
    printMalikInfo(formData);
    setEdit(!edit);
  };
  const {
    esem_almalik,
    jawal_almalik,
    jawal_alwakel,
    jawal_mandob,
    alenwan_alwatni,
    alWakel_id_number,
    almandob_id_number,
    esam_almandob,
    esam_alwakel,
    almalik_id_number,
    motnaza_alih,
    tarek_alwkala,
    tarek_alwkala_start,
    ValidlegitimatePapers,
    NationalAddress,
    almalik_id_date,
    alWakel_id_date,
    alWakala_number,
    warith_id_number,
    esem_warith,
    alWarith_id_date,
    deh,
  } = formData;
  const handleTarek_alwkala = (date) => {
    setFormData({ ...formData, tarek_alwkala: date });
  };
  const handleTarek_alwkala_start = (date) => {
    setFormData({ ...formData, tarek_alwkala_start: date });
  };
  const handleAlmalik_id_date = (date) => {
    setFormData({ ...formData, almalik_id_date: date });
  };
  const handleAlWakel_id_date = (date) => {
    setFormData({ ...formData, alWakel_id_date: date });
  };
  const handleAlWarith_id_date = (date) => {
    setFormData({ ...formData, alWarith_id_date: date });
  };
  const compaerTarekAlwakala = () => {
    const d1 = new Date();
    if (tarek_alwkala) {
      if (d1 >= new Date(tarek_alwkala)) {
        return true;
      }
    }
  };
  // const { isLoading } = isLoading;
  if (
    user.department &&
    (user.roll.group_name === 'ادخال البيانات' ||
      user.roll.group_name === 'الارشيف' ||
      user.roll.group_name === 'ادخال البيانات ٢' ||
      user.roll.group_name === 'المهندسين')
  ) {
    return (
      <Fragment>
        {data === null || loading ? (
          <Spinner />
        ) : (
          <Grid container spacing={4}>
            {data.molahthat ? (
              <AlertDialog
                name='Alert'
                molahthat={data.molahthat}
                isOpen={true}
              />
            ) : null}
            <Grid item xs={2}>
              <Sidebar proprtyId={data.aqar_number} />
            </Grid>
            <Grid item xs={10}>
              <Grid container className={classes.daitelsContainer}>
                <Grid item xs={12}>
                  <Typography variant='h4' display='block'>
                    {data.aqar_number}{' '}
                    {motnaza_alih ? (
                      <Alert severity='error'>متنازع عليه</Alert>
                    ) : null}
                    {compaerTarekAlwakala() ? (
                      <Alert severity='error'>يرجى مراجعة تاريخ الوكالة</Alert>
                    ) : null}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='body1'>بيانات المالك</Typography>
                </Grid>
                <Grid item xs={12} className={classes.buttonsContainer}>
                  {/* {isLoading.isLoading ? null : (
                  <Button
                    className={classes.butns}
                    disabled={edit}
                    // fullWidth
                    onClick={handlPrintMalikInfo}
                    variant="contained"
                    color="primary"
                  >
                    طباعة معلومات المالك
                  </Button>
                )} */}

                  <Button
                    className={classes.butns}
                    type='submit'
                    disabled={edit}
                    // fullWidth
                    variant='contained'
                    color='primary'
                  >
                    السجل
                  </Button>
                  {user.roll.group_name ? (
                    user.roll.group_name === 'المهندسين' ? null : (
                      <Button
                        className={classes.butns}
                        // fullWidth
                        variant='contained'
                        color='primary'
                        onClick={enableEdit}
                      >
                        تحرير
                      </Button>
                    )
                  ) : null}
                </Grid>
              </Grid>
              <Card className={classes.root}>
                <form onSubmit={(e) => onSubmit(e)}>
                  <Grid container>
                    <Grid item xs={2} className={classes.contentTitle}>
                      <Typography variant='body2'>اسم المالك</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <TextField
                        title='name'
                        fullWidth
                        margin='normal'
                        required
                        disabled={edit}
                        value={esem_almalik}
                        label='اسم المالك'
                        variant='outlined'
                        id='esem_almalik'
                        size='small'
                        onChange={(e) => onChange(e)}
                        name='esem_almalik'
                      />
                    </Grid>
                    <Grid item xs={2} className={classes.contentTitle}>
                      <Typography variant='body2'>رقم الهوية</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        margin='normal'
                        disabled={edit}
                        size='small'
                        label='رقم الهوية'
                        variant='outlined'
                        id=''
                        value={almalik_id_number}
                        name='almalik_id_number'
                        onChange={onlyNumbers}
                        inputProps={{
                          maxLength: 10,
                        }}
                      />
                    </Grid>
                    <Grid item xs={2} className={classes.contentTitle}>
                      <Typography variant='body2'>
                        تاريخ انتهاء الهوية
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <MuiPickersUtilsProvider
                        utils={HijriUtils}
                        locale='ar-SA'
                      >
                        <DatePicker
                          clearable
                          // className={useStyles.textField}
                          okLabel='موافق'
                          cancelLabel='الغاء'
                          fullWidth
                          disabled={edit}
                          clearLabel='مسح'
                          name='almalik_id_date'
                          labelFunc={(date) =>
                            date ? date.format('iYYYY/iMM/iDD') : ''
                          }
                          value={almalik_id_date}
                          onChange={handleAlmalik_id_date}
                          minDate='1937-03-14'
                          maxDate='2076-11-26'
                          // className={classes.tfSty}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={2} className={classes.contentTitle}>
                      <Typography variant='body2'>رقم الجوال</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <TextField
                        fullWidth
                        margin='normal'
                        disabled={edit}
                        label='رقم الجوال'
                        value={jawal_almalik}
                        size='small'
                        variant='outlined'
                        onChange={onlyNumbers}
                        helperText='يرجى كتابة رقم الجوال بدون صفر في البداية مثال: 54xxxxxxx'
                        inputProps={{
                          maxLength: 9,
                        }}
                        name='jawal_almalik'
                      />
                    </Grid>
                    {autofill.data && autofill.data.length !== 0 ? (
                      <Grid item xs={12}>
                        <Button
                          aria-controls='simple-menu'
                          aria-haspopup='true'
                          variant='contained'
                          color='primary'
                          onClick={handleClick}
                        >
                          يوجد بيانات تطابق المدخله
                        </Button>
                        <Menu
                          id='simple-menu'
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl)}
                          onClose={handleClose}
                        >
                          {autofill.data
                            ? autofill.data.map((item, index) => {
                                return (
                                  <MenuItem
                                    key={index}
                                    onClick={() => handleClose(index)}
                                  >
                                    {item.esem_almalik}
                                  </MenuItem>
                                );
                              })
                            : null}
                        </Menu>
                      </Grid>
                    ) : null}
                    <Grid item xs={12}>
                      <Typography variant='body1'>العنوان الوطني</Typography>
                    </Grid>
                    <Grid item xs={2} className={classes.contentTitle}>
                      <Typography variant='body2'>العنوان الوطني</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <TextField
                        fullWidth
                        margin='normal'
                        disabled={edit}
                        size='small'
                        label='العنوان الوطني'
                        value={NationalAddress}
                        name='NationalAddress'
                        onChange={(e) => onChange(e)}
                        variant='outlined'
                      />
                    </Grid>{' '}
                    {/* END OF NATIONAL ADDRESS ROW */}
                    <Grid item xs={12}>
                      <Typography variant='body1'>
                        بيانات الوكيل الشرعي
                      </Typography>
                    </Grid>
                    <Grid item xs={2} className={classes.contentTitle}>
                      <Typography variant='body2'>اسم الوكيل الشرعي</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <TextField
                        fullWidth
                        margin='normal'
                        disabled={edit}
                        size='small'
                        name='esam_alwakel'
                        label='اسم الوكيل الشرعي'
                        value={esam_alwakel}
                        onChange={onChange}
                        variant='outlined'
                      />
                    </Grid>
                    <Grid item xs={2} className={classes.contentTitle}>
                      <Typography variant='body2'>رقم هوية الوكيل</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        margin='normal'
                        disabled={edit}
                        size='small'
                        type='phone'
                        name='alWakel_id_number'
                        value={alWakel_id_number}
                        label='رقم هوية الوكيل'
                        onChange={onlyNumbers}
                        inputProps={{
                          maxLength: 10,
                        }}
                        variant='outlined'
                      />
                    </Grid>
                    <Grid item xs={2} className={classes.contentTitle}>
                      <Typography variant='body2'>
                        تاريخ انتهاء الهوية
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <MuiPickersUtilsProvider
                        utils={HijriUtils}
                        locale='ar-SA'
                      >
                        <DatePicker
                          clearable
                          // className={useStyles.textField}
                          okLabel='موافق'
                          cancelLabel='الغاء'
                          fullWidth
                          disabled={edit}
                          clearLabel='مسح'
                          name='alWakel_id_date'
                          labelFunc={(date) =>
                            date ? date.format('iYYYY/iMM/iDD') : ''
                          }
                          value={alWakel_id_date}
                          onChange={handleAlWakel_id_date}
                          minDate='1937-03-14'
                          maxDate='2076-11-26'
                          // className={classes.tfSty}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={2} className={classes.contentTitle}>
                      <Typography variant='body2'>رقم جوال الوكيل</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <TextField
                        fullWidth
                        margin='normal'
                        disabled={edit}
                        type='phone'
                        size='small'
                        value={jawal_alwakel}
                        label='رقم جوال الوكيل'
                        helperText='يرجى كتابة رقم الجوال بدون صفر في البداية مثال: 54xxxxxxx'
                        name='jawal_alwakel'
                        onChange={onlyNumbers}
                        inputProps={{
                          maxLength: 9,
                        }}
                        variant='outlined'
                      />
                    </Grid>
                    <Grid item xs={2} className={classes.contentTitle}>
                      <Typography variant='body2'>رقم الوكاله</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        fullWidth
                        disabled={edit}
                        margin='normal'
                        size='small'
                        name='alWakala_number'
                        value={alWakala_number}
                        label='رقم الوكاله'
                        onChange={onChange}
                        variant='outlined'
                      />
                    </Grid>
                    <Grid item xs={2} className={classes.contentTitle}>
                      <Typography variant='body2'>
                        تاريخ بداية الوكالة
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <MuiPickersUtilsProvider
                        utils={HijriUtils}
                        locale='ar-SA'
                      >
                        <DatePicker
                          clearable
                          // className={useStyles.textField}
                          okLabel='موافق'
                          cancelLabel='الغاء'
                          fullWidth
                          disabled={edit}
                          clearLabel='مسح'
                          name='tarek_alwkala_start'
                          labelFunc={(date) =>
                            date ? date.format('iYYYY/iMM/iDD') : ''
                          }
                          value={tarek_alwkala_start}
                          onChange={handleTarek_alwkala_start}
                          minDate='1937-03-14'
                          maxDate='2076-11-26'
                          // className={classes.tfSty}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={2} className={classes.contentTitle}>
                      <Typography variant='body2'>
                        تاريخ إنتهاء الوكالة
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <MuiPickersUtilsProvider
                        utils={HijriUtils}
                        locale='ar-SA'
                      >
                        <DatePicker
                          clearable
                          // className={useStyles.textField}
                          okLabel='موافق'
                          cancelLabel='الغاء'
                          fullWidth
                          disabled={edit}
                          clearLabel='مسح'
                          name='tarek_alwkala'
                          labelFunc={(date) =>
                            date ? date.format('iYYYY/iMM/iDD') : ''
                          }
                          value={tarek_alwkala}
                          onChange={handleTarek_alwkala}
                          minDate='1937-03-14'
                          maxDate='2076-11-26'
                          // className={classes.tfSty}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={2} className={classes.contentTitle}>
                      <Typography variant='body2'>
                        يوجد لدية وكالة سارية
                      </Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <NativeSelect
                        value={ValidlegitimatePapers}
                        onChange={onChange}
                        fullWidth
                        name='ValidlegitimatePapers'
                        inputProps={{
                          id: 'ValidlegitimatePapers-menu',
                        }}
                      >
                        {ValidlegitimatePapers ? (
                          <option value={ValidlegitimatePapers}>
                            {ValidlegitimatePapers}
                          </option>
                        ) : (
                          <option></option>
                        )}
                        <option value='يوجد'>يوجد</option>
                        <option value='لا يوجد'>لايوجد</option>
                      </NativeSelect>
                    </Grid>
                    {deh ? (
                      <>
                        <Grid item xs={12}>
                          <Typography variant='body1'>
                            بيانات الورثه{' '}
                          </Typography>
                        </Grid>
                        <Grid item xs={2} className={classes.contentTitle}>
                          <Typography variant='body2'>
                            اسم احد الورثة
                          </Typography>
                        </Grid>
                        <Grid item xs={10}>
                          <TextField
                            title='name'
                            fullWidth
                            margin='normal'
                            required
                            disabled={edit}
                            value={esem_warith}
                            label='اسم احد الورثة'
                            variant='outlined'
                            id='esem_warith'
                            size='small'
                            onChange={(e) => onChange(e)}
                            name='esem_warith'
                          />
                        </Grid>
                        <Grid item xs={2} className={classes.contentTitle}>
                          <Typography variant='body2'>رقم الهوية</Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            fullWidth
                            margin='normal'
                            disabled={edit}
                            size='small'
                            label='رقم الهوية'
                            variant='outlined'
                            id=''
                            value={warith_id_number}
                            name='warith_id_number'
                            onChange={onlyNumbers}
                            inputProps={{
                              maxLength: 10,
                            }}
                          />
                        </Grid>
                        <Grid item xs={2} className={classes.contentTitle}>
                          <Typography variant='body2'>
                            تاريخ انتهاء الهوية
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <MuiPickersUtilsProvider
                            utils={HijriUtils}
                            locale='ar-SA'
                          >
                            <DatePicker
                              clearable
                              // className={useStyles.textField}
                              okLabel='موافق'
                              cancelLabel='الغاء'
                              fullWidth
                              disabled={edit}
                              clearLabel='مسح'
                              name='alWarith_id_date'
                              labelFunc={(date) =>
                                date ? date.format('iYYYY/iMM/iDD') : ''
                              }
                              value={alWarith_id_date}
                              onChange={handleAlWarith_id_date}
                              minDate='1937-03-14'
                              maxDate='2076-11-26'
                              // className={classes.tfSty}
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                      </>
                    ) : null}
                    {/* <Grid item xs={12}>
                      <Typography variant='body1'>بيانات المندوب </Typography>
                    </Grid>
                    <Grid item xs={2} className={classes.contentTitle}>
                      <Typography variant='body2'>اسم المندوب</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <TextField
                        fullWidth
                        disabled={edit}
                        margin='normal'
                        size='small'
                        label='اسم المندوب'
                        name='esam_almandob'
                        value={esam_almandob}
                        onChange={onChange}
                        variant='outlined'
                      />
                    </Grid>
                    <Grid item xs={2} className={classes.contentTitle}>
                      <Typography variant='body2'>رقم هوية المندوب</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <TextField
                        fullWidth
                        disabled={edit}
                        margin='normal'
                        size='small'
                        name='almandob_id_number'
                        value={almandob_id_number}
                        label='رقم هوية المندوب'
                        onChange={onlyNumbers}
                        inputProps={{
                          maxLength: 10,
                        }}
                        variant='outlined'
                      />
                    </Grid>
                    <Grid item xs={2} className={classes.contentTitle}>
                      <Typography variant='body2'>رقم جوال المندوب</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <TextField
                        fullWidth
                        margin='normal'
                        disabled={edit}
                        name='jawal_mandob'
                        value={jawal_mandob}
                        onChange={onlyNumbers}
                        inputProps={{
                          maxLength: 9,
                        }}
                        label='رقم جوال المندوب'
                        variant='outlined'
                        helperText='يرجى كتابة رقم الجوال بدون صفر في البداية مثال: 54xxxxxxx'
                      />
                    </Grid> */}
                    <Button
                      type='submit'
                      fullWidth
                      disabled={edit}
                      variant='contained'
                      color='primary'
                    >
                      حفظ
                    </Button>
                  </Grid>
                </form>
                {/* END OF FORM */}
              </Card>
            </Grid>
          </Grid>
        )}
      </Fragment>
    );
  } else {
    return <AccessForbidden />;
  }
};
const mapStateToProps = (state) => ({
  aqar: state.aqar,
  isLoading: state.isLoading,
  autofill: state.autofill,
  auth: state.auth,
});
export default connect(mapStateToProps, {
  fetchAqarByAqarNumber,
  updataeMalekInfo,
  printMalikInfo,
  autoFillMalikInfo,
  fetchAqarDetails,
})(PersonalInformations);
