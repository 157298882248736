import React, { useEffect } from 'react';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';

import DescriptionAlerts from './ui/DescriptionAlerts';
import { Grid } from '@material-ui/core';
import Header from './ui/Header';
import { LOGOUT } from '../actions/types';
import { Provider } from 'react-redux';
// import { grey, blueGrey } from '@material-ui/core/colors';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from '../routes/Routes';
import { loadeUser } from '../actions/auth';
import setAuthToken from '../utils/setAuthToken';
import store from '../store';
import theme from './ui/Theme';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: 0,
    textAlign: 'center',
    // backgroundColor: blueGrey[200],
    color: theme.palette.text.secondary,
  },
  text: {
    ...theme.typography.button,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1),
    textAlign: 'center',
  },
}));

const App = () => {
  useEffect(() => {
    // setAuthToken(localStorage.token);
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    store.dispatch(loadeUser());
    // store.dispatch(loadUser());
    window.addEventListener('storage', () => {
      if (!localStorage.token) store.dispatch({ type: LOGOUT });
    });
  }, []);
  const classes = useStyles();
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Router>
          <div className={classes.flexGrow}>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <Header />
              </Grid>
              {/* <Sidebar /> */}
              <Grid item xs={12}>
                <DescriptionAlerts />
                <Routes />
              </Grid>
              <Grid item xs={12}>
                {/* <Paper className={classes.paper}>xs=12</Paper> */}
              </Grid>
            </Grid>
          </div>
        </Router>
      </ThemeProvider>
    </Provider>
  );
};
export default App;
