import {
  ERROR_FETCH_RECOARD_NUMBER,
  FETCH_RECOARD_NUMBER,
} from '../actions/types';

const initialState = {
  numLoading: true,
  newNumber: null,
};
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_RECOARD_NUMBER:
      return { ...state, newNumber: payload, numLoading: false };
    case ERROR_FETCH_RECOARD_NUMBER:
      return { ...state, newNumber: null, numLoading: true };
    default:
      return state;
  }
}
