import {
  ERROR_FETCH_LINKED_AQARAT,
  FETCH_LINKED_AQARAT,
} from '../actions/types';

const initialState = {
  linkedAqarat: [],
  loading: true,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  // console.log(type);
  // console.log('state', payload);
  switch (type) {
    case FETCH_LINKED_AQARAT:
      return {
        ...state,
        linkedAqarat: payload,
        loading: false,
      };
    case ERROR_FETCH_LINKED_AQARAT:
      return { ...state, linkedAqarat: [], loading: true };
    default:
      return state;
  }
}
