import {
  ERROR_FETECH_CATEGORY_SUBS,
  FETECH_CATEGORY_SUBS,
} from '../actions/types';

const initialState = {
  subs: [],
  subsLoading: true,
};
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FETECH_CATEGORY_SUBS:
      return { ...state, subs: payload, subsLoading: false };
    case ERROR_FETECH_CATEGORY_SUBS:
      return { ...state, subs: null, subsLoading: true };
    default:
      return state;
  }
}
