import {
  ERROR_FETCH_OUT_GOING_FIELS,
  ERROR_FETCH_RECOARD,
  FETCH_OUT_GOING_FIELS,
  FETCH_RECOARD,
} from '../actions/types';

const initialState = {
  loading: true,
  data: null,
  docments: null,
};
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_RECOARD:
      return { ...state, data: payload, loading: false };
    case FETCH_OUT_GOING_FIELS:
      return { ...state, docments: payload, loading: false };
    case ERROR_FETCH_OUT_GOING_FIELS:
      return { ...state, docments: null, loading: true };
    case ERROR_FETCH_RECOARD:
      return { ...state, data: null, loading: true };
    default:
      return state;
  }
}
