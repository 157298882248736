import {
  ERROR_FETCH_ACCESS_RIGHT,
  ERROR_FETCH_USERS,
  ERROR_FETCH_USER_BY_ID,
  FETCH_ACCESS_RIGHT,
  FETCH_USERS,
  FETCH_USER_BY_ID,
  SEARCH_USERS,
} from './types';

import api from '../utils/api';
import { setAlert } from './alert';
import setAuthToken from '../utils/setAuthToken';

export const fetchUsers = (page) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  try {
    const response = await api.get(`users?page=${page}`);
    console.log('fetchUsers');
    console.log(response);
    dispatch({
      type: FETCH_USERS,
      payload: response.data,
    });
    dispatch({ type: ERROR_FETCH_USER_BY_ID });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_FETCH_USERS,
    });
  }
};
export const searchForUsers = (page, coulmName, sarch) => async (dispatch) => {
  console.log('searchForAqar');
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  try {
    const response = await api.post(
      `users?page=${page}&coulmName=${coulmName}&search=${sarch}`
    );
    dispatch({ type: ERROR_FETCH_USER_BY_ID });

    // console.log(response);
    dispatch({
      type: SEARCH_USERS,
      payload: response.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_FETCH_USERS,
    });
  }
};
export const fetchAccessRigh = (department) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  try {
    const departmentId = { department: department };
    const response = await api.post(`users/get/accessright`, departmentId);
    dispatch({
      type: FETCH_ACCESS_RIGHT,
      payload: response.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_FETCH_ACCESS_RIGHT,
    });
  }
};
export const fetchUserById = (id) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  try {
    const response = await api.get(`users/${id}`);
    dispatch({
      type: FETCH_USER_BY_ID,
      payload: response.data,
    });
  } catch (error) {
    const errs = error.response.data.errors;
    console.log(errs);
    dispatch({ type: ERROR_FETCH_USER_BY_ID });
  }
};
export const createNewUser = (formData, history) => async (dispatch) => {
  console.log('createNewUser', formData);
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  try {
    await api.post('users/createNewUser', formData);
    const msg = `تم انشاء المستخدم بنجاح`;
    dispatch(setAlert(msg, 'success', 'تمت العملية بنجاح'));
    history.push('/department/users');
  } catch (error) {
    const errs = error.response.data.errors;
    if (errs) {
      console.log(errs);
      errs.forEach((err) => dispatch(setAlert(err.sqlMessage, 'error', 'خطأ')));
    }
  }
};
export const updateUser = (id, formData, history) => async (dispatch) => {
  console.log('update user');
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  try {
    const data = { ...formData, roll: formData.roll.id };
    await api.post(`users/${id}`, data);
    const msg = `تم تحديث بيانات المستخدم بنجاح`;
    dispatch(setAlert(msg, 'success', 'تمت العملية بنجاح'));
    // dispatch(fetchUsers(1));
    history.push('/department/users');
  } catch (error) {
    const errs = error.response.data.errors;
    if (errs) {
      console.log(errs);
      errs.forEach((err) => dispatch(setAlert(err.sqlMessage, 'error', 'خطأ')));
    }
  }
};
