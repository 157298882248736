import {  Button,
  Card,
  Checkbox,
  Grid,
  NativeSelect,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';
import {
  annoucmentTathmeen,
  fetchAqarByAqarNumber,
  fetchAqarPrices,
  fetchAqaraAnnouncments,
  updateOwnerOption,
} from '../../../actions/aqarat';
import { printOwnerOption, printTathmeenPDF } from '../../../actions/printDocs';

import AccessForbidden from './AccessForbidden';
import AlertDialog from '../AlertDialog';
import Sidebar from '../Sidbar';
import Spinner from '../Spinner';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

const announcementInialState = {
  AnnounceID: '',
  Aqar_Code: '',
  Notified: '',
  NotificationDate: '',
  Notifier: '',
};
const initialState = {
  esem_almalik: '',
  almalik_id_number: '',
  esam_alwakel: '',
  alWakel_id_number: '',
  esam_almandob: '',
  almandob_id_number: '',
  noa_almilkia: '',
  raqam_alsak: '',
  masdar_alsak: '',
  tarek_alsak: '',
  kera_almalik: '',
  tathmen_signature: '',
};
const pricesInialState = {
  LandPrice: '',
  ConcretePrice: '',
  ClassicPrice: '',
  HangerPrice: '',
  RoofWallPrice: '',
  FencePrice: '',
  UpdateRemarks: '',
};
const Tathmeen = ({
  match,
  auth: { user },
  aqar: { loading, data, announcment },
  fetchAqarPrices,
  aqarPrices: { aqarPrices },
  fetchAqarByAqarNumber,
  fetchAqaraAnnouncments,
  printTathmeenPDF,
  annoucmentTathmeen,
  printOwnerOption,
  updateOwnerOption,
}) => {
  const { params } = match;
  const { id } = params;
  const useStyles = makeStyles((theme) => ({
    root: {
      minWidth: 275,
      textAlign: 'center',
      padding: '15px',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    flexed: {
      display: 'flex',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    contentTitle: {
      margin: 'auto',
    },
    buttonsContainer: {
      margin: '10px',
    },
    butns: {
      margin: '10px',
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    daitelsContainer: {
      textAlign: 'center',
      marginLeft: '10px',
    },
    marginLef: {
      marginLeft: '10px',
    },
    buttonProgress: {
      // color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    margin: {
      margin: theme.spacing(1),
    },
    flexedWithMargin: {
      display: 'flex',
      marginTop: '20px',
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    paddingCard: {
      paddingLeft: '10px',
    },
  }));
  const [formData, setFormData] = useState({
    esem_almalik: '',
    almalik_id_number: '',
    esam_alwakel: '',
    alWakel_id_number: '',
    esam_almandob: '',
    almandob_id_number: '',
    noa_almilkia: '',
    raqam_alsak: '',
    masdar_alsak: '',
    tarek_alsak: '',
    kera_almalik: '',
    tathmen_signature: '',
  });
  const [pricesData, setPricesData] = useState({
    LandPrice: '',
    ConcretePrice: '',
    ClassicPrice: '',
    HangerPrice: '',
    RoofWallPrice: '',
    UpdateRemarks: '',
    FencePrice: '',
  });
  const [announcementData, setAnnouncement] = useState({
    AnnounceID: '',
    Aqar_Code: '',
    Notified: '',
    NotificationDate: '',
    Notifier: '',
  });
  const {
    LandPrice,
    ConcretePrice,
    ClassicPrice,
    HangerPrice,
    RoofWallPrice,
    UpdateRemarks,
    FencePrice,
  } = pricesData;
  const {
    esem_almalik,
    almalik_id_number,
    esam_alwakel,
    alWakel_id_number,
    esam_almandob,
    almandob_id_number,
    noa_almilkia,
    raqam_alsak,
    masdar_alsak,
    tarek_alsak,
    kera_almalik,
    tathmen_signature,
  } = formData;
  const { AnnounceID, Aqar_Code, Notified, NotificationDate, Notifier } =
    announcementData;
  useEffect(() => {
    if (!announcment) fetchAqaraAnnouncments(id);
    const announcData = { ...announcementInialState };
    for (const key in announcment) {
      if (key in announcData) announcData[key] = announcment[key];
    }
    setAnnouncement(announcData);
  }, [fetchAqaraAnnouncments, announcment]);
  useEffect(() => {
    if (!aqarPrices) fetchAqarPrices(id);
    const pricesData = { ...pricesInialState };
    for (const key in aqarPrices) {
      if (key in pricesData) pricesData[key] = aqarPrices[key];
    }
    setPricesData(pricesData);
  }, [fetchAqarPrices, aqarPrices]);
  useEffect(() => {
    if (!data) fetchAqarByAqarNumber(id);
    if (!loading && data) {
      const aqarData = { ...initialState };
      for (const key in data) {
        if (key in aqarData) aqarData[key] = data[key];
      }
      setFormData(aqarData);
    }
  }, [loading, fetchAqarByAqarNumber, data, id]);
  const onChange = (e) => {
    console.log(e.target.value);
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const companyName =
    user.companyName === 'jas' ? 'الجوار السامي' : 'وادي إبراهيم';
  const onCheckboxChange = (event) => {
    const name = event.target.name;
    setFormData({ ...formData, [name]: +event.target.checked });
  };
  const classes = useStyles();
  const onSubmit = () => {
    updateOwnerOption(id, formData);
  };
  const handleTathmeenClicked = () => {
    printTathmeenPDF(id);
  };
  const handleAnnouncmentTathmeenClicked = () => {
    annoucmentTathmeen(id);
  };
  const handleOwnerOptionClicked = () => {
    console.log('sdvsdvsdvsdv');
    printOwnerOption(id);
  };
  if (
    (user.department && user.roll.group_name === 'ادخال البيانات') ||
    user.roll.group_name === 'الارشيف' ||
    (user.department && user.roll.group_name === 'ادخال البيانات ٢')
  ) {
    return (
      <Fragment>
        {data === null || loading ? (
          <Spinner />
        ) : (
          <Grid container spacing={4}>
            {data.molahthat ? (
              <AlertDialog molahthat={data.molahthat} isOpen={true} />
            ) : null}
            <Grid item xs={2}>
              <Sidebar proprtyId={id} />
            </Grid>
            {loading ? (
              <Spinner />
            ) : (
              <Grid item xs={10}>
                <Card className={classes.paddingCard}>
                  <Grid container className={classes.daitelsContainer}>
                    <Grid item xs={12}>
                      <Typography variant='button' display='block'>
                        رقم العقار {data.aqar_number}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant='button' display='block'>
                        تسجيل تبليغ التثمين وخيار المالك
                      </Typography>
                    </Grid>
                    <Grid
                      container
                      className={classes.daitelsContainer}
                      spacing={2}
                    >
                      <Grid item xs={8}>
                        <Grid item xs={12} className={classes.flexed}>
                          <Grid item xs={2} className={classes.contentTitle}>
                            <Typography variant='body2'>اسم المالك</Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <TextField
                              disabled={true}
                              value={esem_almalik}
                              margin='normal'
                              fullWidth
                              variant='outlined'
                              id='esem_almalik'
                              size='small'
                              name='esem_almalik'
                            />
                          </Grid>
                          <Grid item xs={2} className={classes.contentTitle}>
                            <Typography variant='body2'>رقم الهوية</Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <TextField
                              disabled={true}
                              value={almalik_id_number}
                              margin='normal'
                              fullWidth
                              variant='outlined'
                              id='almalik_id_number'
                              size='small'
                              name='almalik_id_number'
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={12} className={classes.flexed}>
                          <Grid item xs={2} className={classes.contentTitle}>
                            <Typography variant='body2'>اسم الوكيل</Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <TextField
                              disabled={true}
                              value={esam_alwakel}
                              margin='normal'
                              fullWidth
                              variant='outlined'
                              id='esam_alwakel'
                              size='small'
                              name='esam_alwakel'
                            />
                          </Grid>
                          <Grid item xs={2} className={classes.contentTitle}>
                            <Typography variant='body2'>رقم الهوية</Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <TextField
                              disabled={true}
                              value={alWakel_id_number}
                              margin='normal'
                              fullWidth
                              variant='outlined'
                              id='alWakel_id_number'
                              size='small'
                              name='alWakel_id_number'
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={12} className={classes.flexed}>
                          <Grid item xs={2} className={classes.contentTitle}>
                            <Typography variant='body2'>اسم المندوب</Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <TextField
                              disabled={true}
                              value={esam_almandob}
                              margin='normal'
                              fullWidth
                              label='اسم المالك'
                              variant='outlined'
                              id='esam_almandob'
                              size='small'
                              name='esam_almandob'
                            />
                          </Grid>
                          <Grid item xs={2} className={classes.contentTitle}>
                            <Typography variant='body2'>رقم الهوية</Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <TextField
                              disabled={true}
                              value={almandob_id_number}
                              margin='normal'
                              fullWidth
                              variant='outlined'
                              id='almandob_id_number'
                              size='small'
                              name='almandob_id_number'
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={4}>
                        <Grid item xs={12} className={classes.flexed}>
                          <Grid item xs={4} className={classes.contentTitle}>
                            <Typography variant='body2'>
                              نوع الملكية{' '}
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <TextField
                              disabled={true}
                              value={noa_almilkia}
                              margin='normal'
                              fullWidth
                              variant='outlined'
                              id='noa_almilkia'
                              size='small'
                              name='noa_almilkia'
                            />
                          </Grid>
                        </Grid>

                        <Grid item xs={12} className={classes.flexed}>
                          <Grid item xs={4} className={classes.contentTitle}>
                            <Typography variant='body2'>رقم الصك</Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <TextField
                              disabled={true}
                              value={raqam_alsak}
                              margin='normal'
                              fullWidth
                              variant='outlined'
                              id='raqam_alsak'
                              size='small'
                              name='raqam_alsak'
                            />
                          </Grid>
                        </Grid>

                        <Grid item xs={12} className={classes.flexed}>
                          <Grid item xs={4} className={classes.contentTitle}>
                            <Typography variant='body2'>مصدر الصك</Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <TextField
                              disabled={true}
                              value={masdar_alsak}
                              margin='normal'
                              fullWidth
                              variant='outlined'
                              id='masdar_alsak'
                              size='small'
                              name='masdar_alsak'
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={12} className={classes.flexed}>
                          <Grid item xs={4} className={classes.contentTitle}>
                            <Typography variant='body2'>تاريخ الصك</Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <TextField
                              disabled={true}
                              value={tarek_alsak}
                              margin='normal'
                              fullWidth
                              variant='outlined'
                              id='tarek_alsak'
                              size='small'
                              name='tarek_alsak'
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={8}>
                      <Grid item xs={12} className={classes.contentTitle}>
                        <Typography variant='body2'> حالة التبليغ </Typography>
                      </Grid>
                      <Grid item xs={12} className={classes.flexed}>
                        <Grid item xs={2} className={classes.contentTitle}>
                          <Typography variant='body2'>تاريخ التبليغ</Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            disabled={true}
                            value={NotificationDate}
                            margin='normal'
                            fullWidth
                            variant='outlined'
                            id='NotificationDate'
                            size='small'
                            name='NotificationDate'
                          />
                        </Grid>
                        <Grid item xs={2} className={classes.contentTitle}>
                          <Typography variant='body2'>الحالة</Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            disabled={true}
                            value={Notified}
                            margin='normal'
                            fullWidth
                            variant='outlined'
                            id='Notified'
                            size='small'
                            name='Notified'
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} className={classes.flexed}>
                        <Grid item xs={2} className={classes.contentTitle}>
                          <Typography variant='body2'>المبلغ</Typography>
                        </Grid>
                        <Grid item xs={10}>
                          <TextField
                            disabled={true}
                            value={Notifier}
                            margin='normal'
                            fullWidth
                            variant='outlined'
                            id='Notifier'
                            size='small'
                            name='Notifier'
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} className={classes.flexed}>
                        <Grid item xs={2} className={classes.contentTitle}>
                          <Typography variant='body2'>اختيار المالك</Typography>
                        </Grid>
                        <Grid item xs={10}>
                          <NativeSelect
                            inputProps={{
                              id: 'kera_almalik-menu',
                            }}
                            fullWidth
                            value={kera_almalik}
                            name='kera_almalik'
                            // disabled={
                            //   (aqarPrices ? false : true) ||
                            //   data.kera_almalik !== ''
                            //     ? true
                            //     : false
                            // }
                            onChange={onChange}
                          >
                            {kera_almalik !== '' ? (
                              <option value={kera_almalik}>
                                {kera_almalik}
                              </option>
                            ) : (
                              <option value=''></option>
                            )}
                            <option value=' '></option>
                            <option
                              value={`عرض العقار للبيع لشركة ${companyName}`}
                            >
                              عرض العقار للبيع لشركة {companyName}
                            </option>
                            <option value={`المشاركة في شركة ${companyName}`}>
                              المشاركة في شركة {companyName}
                            </option>
                            <option value='البيع لطرف ثالث'>
                              البيع لطرف ثالث
                            </option>
                          </NativeSelect>
                        </Grid>
                      </Grid>
                      {/* <Grid item xs={12} style={{ display: 'flex' }}>
                        <Grid item xs={3} className={classes.contentTitle}>
                          <Typography variant='body2'>
                            التوقيع على التثمين الاجمالي
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Checkbox
                            color='primary'
                            checked={!!tathmen_signature}
                            name='tathmen_signature'
                            onChange={onCheckboxChange}
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                          />
                        </Grid>
                        <Grid item xs={6}></Grid>
                      </Grid> */}
                      <Grid item xs={12} className={classes.flexedWithMargin}>
                        <Grid item xs={3}></Grid>
                        <Grid item xs={3}>
                          {/* {data.kera_almalik !== '' ? ( */}
                          <Button
                            fullWidth
                            variant='contained'
                            // disabled={kera_almalik === '' ? true : false}
                            onClick={handleOwnerOptionClicked}
                            color='primary'
                          >
                            طباعة خيار المالك
                          </Button>
                          {/* ) : null} */}
                        </Grid>
                        <Grid item xs={3}></Grid>
                        <Grid item xs={3}>
                          {announcment ? (
                            announcment.AnnounceID ? (
                              <Button
                                fullWidth
                                variant='contained'
                                color='primary'
                                disabled={aqarPrices ? false : true}
                                onClick={handleTathmeenClicked}
                              >
                                طباعة تبليغ التثمين
                              </Button>
                            ) : null
                          ) : (
                            <>
                              <Button
                                fullWidth
                                variant='contained'
                                color='primary'
                                disabled={aqarPrices ? false : true}
                                onClick={handleTathmeenClicked}
                              >
                                طباعة تبليغ التثمين
                              </Button>
                              <Button
                                fullWidth
                                variant='contained'
                                color='primary'
                                style={{ marginTop: '10px' }}
                                disabled={aqarPrices ? false : true}
                                onClick={handleAnnouncmentTathmeenClicked}
                              >
                                تبليغ التثمين
                              </Button>
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={4}>
                      <Grid item xs={12} className={classes.contentTitle}>
                        <Typography variant='body2'>
                          اسعار مشتملات العقار{' '}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} className={classes.flexed}>
                        <Grid item xs={4} className={classes.contentTitle}>
                          <Typography variant='body2'>سعر م٢ الارض</Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <TextField
                            disabled={true}
                            value={LandPrice}
                            margin='normal'
                            fullWidth
                            variant='outlined'
                            id='LandPrice'
                            size='small'
                            name='LandPrice'
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} className={classes.flexed}>
                        <Grid item xs={4} className={classes.contentTitle}>
                          <Typography variant='body2'>سعر م٢ المسلح</Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <TextField
                            disabled={true}
                            value={ConcretePrice}
                            margin='normal'
                            fullWidth
                            variant='outlined'
                            id='ConcretePrice'
                            size='small'
                            name='ConcretePrice'
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} className={classes.flexed}>
                        <Grid item xs={4} className={classes.contentTitle}>
                          <Typography variant='body2'>سعر م٢ الشعبي</Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <TextField
                            disabled={true}
                            value={ClassicPrice}
                            margin='normal'
                            fullWidth
                            variant='outlined'
                            id='ClassicPrice'
                            size='small'
                            name='ClassicPrice'
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} className={classes.flexed}>
                        <Grid item xs={4} className={classes.contentTitle}>
                          <Typography variant='body2'>سعر م٢ الهنجر</Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <TextField
                            disabled={true}
                            value={HangerPrice}
                            margin='normal'
                            fullWidth
                            variant='outlined'
                            id='HangerPrice'
                            size='small'
                            name='HangerPrice'
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} className={classes.flexed}>
                        <Grid item xs={4} className={classes.contentTitle}>
                          <Typography variant='body2'>سعر م ط للسور</Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <TextField
                            disabled={true}
                            value={FencePrice}
                            margin='normal'
                            fullWidth
                            variant='outlined'
                            id='FencePrice'
                            size='small'
                            name='RoofWallPrice'
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} className={classes.flexed}>
                        <Grid item xs={4} className={classes.contentTitle}>
                          <Typography variant='body2'> الملاحظات </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <TextField
                            disabled={true}
                            id='UpdateRemarks'
                            value={UpdateRemarks}
                            margin='normal'
                            fullWidth
                            variant='outlined'
                            size='small'
                            name='UpdateRemarks'
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} className={classes.flexed}>
                      <Button
                        fullWidth
                        variant='contained'
                        color='primary'
                        // disabled={aqarPrices ? false : true}
                        onClick={onSubmit}
                      >
                        حفظ
                      </Button>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            )}
          </Grid>
        )}
      </Fragment>
    );
  } else {
    return <AccessForbidden />;
  }
};
const mapStateToProps = (state) => ({
  aqar: state.aqar,
  aqarPrices: state.aqarPrices,
  auth: state.auth,
});
export default connect(mapStateToProps, {
  fetchAqarByAqarNumber,
  fetchAqarPrices,
  fetchAqaraAnnouncments,
  printTathmeenPDF,
  printOwnerOption,
  updateOwnerOption,
  annoucmentTathmeen,
})(Tathmeen);
