import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';
import { fetchAqarByAqarNumber, fetchAqarLayer } from '../../../actions/aqarat';
import {
  printAnqathLetter2,
  printFull,
  printPropertyCheck,
  printSaheftAlNaza,
  printServicesDocments,
  printTaslslAlSak,
  rcmcRegestration,
  rcmcRegestration2,
} from '../../../actions/printDocs';

import Sidebar from '../Sidbar';
import Spinner from '../Spinner';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

const initialState = {
  esem_almalik: '',
};
const AqarDocs = ({
  aqarData: { loading, data },
  match,
  fetchAqarLayer,
  fetchAqarByAqarNumber,
  printFull,
  printSaheftAlNaza,
  isLoading,
  layers: { layers },
  printTaslslAlSak,
  printServicesDocments,
  printPropertyCheck,
  printAnqathLetter2,
  rcmcRegestration,
  rcmcRegestration2,
}) => {
  const { params } = match;
  const { id } = params;
  const [formData, setFormData] = useState({
    esem_almalik: '',
  });
  useEffect(() => {
    if (!data) fetchAqarByAqarNumber(id);
    fetchAqarLayer(id);
    if (!loading && data) {
      //   setFormData(aqarData);
      const aqarData = { ...initialState };
      for (const key in data) {
        if (key in aqarData) aqarData[key] = data[key];
      }
      setFormData(aqarData);
    }
  }, [loading, fetchAqarByAqarNumber, data, id]);

  const useStyles = makeStyles((theme) => ({
    root: {
      minWidth: 275,
      textAlign: 'center',
      padding: '15px',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    contentTitle: {
      margin: 'auto',
    },
    buttonsContainer: {
      margin: '10px',
    },
    butns: {
      margin: '10px',
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    daitelsContainer: {
      textAlign: 'center',
    },
    buttonProgress: {
      // color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    margin: {
      margin: theme.spacing(1),
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
  }));
  const classes = useStyles();
  const { esem_almalik } = formData;
  const rows = [
    { id: '0', enable: false, name: 'خطاب شركة المياة', isLoading },
    { id: '1', enable: false, name: 'خطاب شركة الكهرباء', isLoading },
    { id: '5', enable: false, name: 'خطاب شركة الاتصالات', isLoading },
    // {
    //   id: '2',
    //   enable:
    //     data && data.consultant_kroki === 1 ? false : true && layers.lenght > 0,
    //   name: 'الكشف المالي التفصيلي',
    // },
    { id: '3', enable: false, name: 'تبليغ التثمين', isLoading },
    { id: '6', enable: false, name: 'تسلسل الصك', isLoading },
    { id: '7', enable: false, name: 'فحص الملكية', isLoading },
    // {
    //   id: '4',
    //   enable:
    //     data && data.final_kroki_aproved === 0
    //       ? true
    //       : false && layers.lenght > 0,
    //   name: 'صحيفة نزع الملكية',
    // },
    {
      id: '12',
      enable:
        esem_almalik === 'شركة المملكة للأستثمار و التطوير ' ? true : false,
      name: 'نموذج اثبات تملك',
      isLoading,
    },
    { id: '13', enable: false, name: 'نموذج تسجيل ملكيات الهيئة', isLoading },
    {
      id: '14',
      enable: false,
      name: 'نموذج تسجيل ملكيات الهيئة - بدون كلكات',
      isLoading,
    },
  ];
  // const { isLoading } = isLoading;
  const printAqar = (docId, preview) => {
    var printData;
    const aqar = data;
    switch (docId) {
      case '0':
        // printData = { aqar, docName: "NK-107" };
        printServicesDocments(preview, docId, id);
        break;
      case '1':
        // printData = { aqar, docName: "NK-106" };
        printServicesDocments(preview, docId, id);
        break;
      case '5':
        // printData = { aqar, docName: "NK-108" };
        printServicesDocments(preview, docId, id);
        break;
      case '2':
        printData = { aqar, layers };
        console.log(preview);
        printFull(printData, preview);
        break;
      case '4':
        printSaheftAlNaza(aqar, preview);
        break;
      case '6':
        printTaslslAlSak(aqar.aqar_number, preview);
        break;
      case '7':
        printPropertyCheck(aqar.aqar_number, preview);
        break;
      case '12':
        printAnqathLetter2(aqar.aqar_number, preview);
        break;
      case '13':
        rcmcRegestration(preview, aqar.aqar_number);
        break;
      case '14':
        rcmcRegestration2(preview, aqar.aqar_number);
        break;
      default:
        break;
    }
  };
  console.log('isLoading', isLoading);
  return (
    <Fragment>
      {data === null || loading ? (
        <Spinner />
      ) : (
        <Grid container spacing={4}>
          <Grid item xs={2}>
            <Sidebar proprtyId={data.aqar_number} />
          </Grid>
          <Grid item xs={10}>
            <Grid container className={classes.daitelsContainer}>
              <Grid item xs={12}>
                <Typography variant='button' display='block'>
                  رقم العقار {data.aqar_number}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='body1'>بيانات المالك</Typography>
              </Grid>
              <Fragment>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align='center'>اسم الخطاب</TableCell>
                        <TableCell align='center'>ملاحظة</TableCell>
                        <TableCell align='center'></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => {
                        if (row.id === '2000') {
                          return (
                            <TableRow key={row.id}>
                              <TableCell align='center'>{row.name}</TableCell>
                              <TableCell align='center'></TableCell>
                              <TableCell align='center'>
                                <Button
                                  // disabled={row.enable}
                                  disabled={isLoading.isLoading}
                                  variant='contained'
                                  onClick={() => printAqar(row.id, true)}
                                  color='primary'
                                >
                                  طباعة باسم المالك
                                </Button>
                              </TableCell>
                              <TableCell align='center'>
                                <Button
                                  // disabled={row.enable}
                                  disabled={isLoading.isLoading}
                                  variant='contained'
                                  onClick={() => printAqar(row.id, false)}
                                  color='primary'
                                >
                                  طباعة باسم الوكيل
                                </Button>
                              </TableCell>
                            </TableRow>
                          );
                        } else {
                          return (
                            <TableRow key={row.id}>
                              <TableCell align='center'>{row.name}</TableCell>
                              <TableCell align='center'></TableCell>
                              <TableCell align='center'>
                                <Button
                                  disabled={isLoading.isLoading}
                                  variant='contained'
                                  // disabled={}
                                  onClick={() => printAqar(row.id, true)}
                                  color='primary'
                                >
                                  طباعة
                                </Button>
                              </TableCell>
                              <TableCell align='center'>
                                <Button
                                  disabled={isLoading.isLoading}
                                  variant='contained'
                                  // disabled={row.isLoading}
                                  onClick={() => printAqar(row.id, false)}
                                  color='primary'
                                >
                                  تحميل
                                </Button>
                              </TableCell>
                            </TableRow>
                          );
                        }
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Fragment>
              {/* // TABLE */}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  aqarData: state.aqar,
  isLoading: state.isLoading,
  layers: state.layers,
});
export default connect(mapStateToProps, {
  fetchAqarByAqarNumber,
  fetchAqarLayer,
  printFull,
  printSaheftAlNaza,
  printServicesDocments,
  printTaslslAlSak,
  printPropertyCheck,
  printAnqathLetter2,
  rcmcRegestration,
  rcmcRegestration2,
})(AqarDocs);
