import { Button, Card, Grid, TextField, Typography } from '@material-ui/core';import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { Fragment, useEffect, useState } from 'react';
import {
  fetchAqarByAqarNumber,
  fetchAqarDetails,
  updateAlternitveLand,
} from '../../../actions/aqarat';

import HijriUtils from '@date-io/hijri';
import Sidebar from '../Sidbar';
import Spinner from '../Spinner';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

const initalDatailsState = {
  AltLndNo: '',
  AltLndDate: null,
  Remarks: '',
  FirstParty: '',
  SecondParty: '',
  SalesDocNo: '',
  SalesDocDate: '',
  PlotNo: '',
};
const initialState = {
  motnaza_alih: '',
};
const AltLand = ({
  auth: { user },
  aqar: { loading, data, details },
  match,
  fetchAqarDetails,
  fetchAqarByAqarNumber,
  updateAlternitveLand,
}) => {
  const { params } = match;
  const { id } = params;
  const [formData, setFormData] = useState({
    motnaza_alih: '',
  });
  useEffect(() => {
    if (!details) fetchAqarDetails(id);
    if (!loading && details) {
      const detailsData = { ...initalDatailsState };
      for (const key in details) {
        if (key in detailsData) detailsData[key] = details[key];
      }
      setDaitlesData(detailsData);
    }
  }, [loading, fetchAqarDetails, details, id]);
  useEffect(() => {
    // console.log('personalInformation', data);
    if (!data) fetchAqarByAqarNumber(id);
    if (!loading && data) {
      const aqarData = { ...initialState };
      for (const key in data) {
        if (key in aqarData) aqarData[key] = data[key];
      }
      setFormData(aqarData);
    }
  }, [loading, fetchAqarByAqarNumber, data, id]);
  const useStyles = makeStyles((theme) => ({
    root: {
      minWidth: 275,
      textAlign: 'center',
      padding: '15px',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    contentTitle: {
      margin: 'auto',
    },
    buttonsContainer: {
      margin: '10px',
    },
    butns: {
      margin: '10px',
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    daitelsContainer: {
      textAlign: 'center',
    },
    buttonProgress: {
      // color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    motnaza: {
      color: 'red',
    },
    margin: {
      margin: theme.spacing(1),
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
  }));
  const classes = useStyles();

  const [daitlesData, setDaitlesData] = useState({
    NationalAddress: '',
    AltLndNo: '',
    AltLndDate: null,
    Remarks: '',
    FirstParty: '',
    SecondParty: '',
    SalesDocNo: '',
    SalesDocDate: '',
    PlotNo: '',
  });
  const {
    AltLndNo,
    AltLndDate,
    Remarks,
    FirstParty,
    SecondParty,
    SalesDocNo,
    SalesDocDate,
    PlotNo,
  } = daitlesData;
  const onSubmit = (e) => {
    e.preventDefault();
    updateAlternitveLand(id, daitlesData);
  };
  const onChange = (e) => {
    setDaitlesData({ ...daitlesData, [e.target.name]: e.target.value });
  };
  const handleTarekAlaqad = (date) => {
    setDaitlesData({ ...daitlesData, AltLndDate: date });
  };
  const handleDocDate = (date) => {
    console.log(Object.prototype.toString.call(date));
    setDaitlesData({ ...daitlesData, SalesDocDate: date });
  };
  console.log(Object.prototype.toString.call(AltLndDate));
  if (
    user.department &&
    (user.roll.group_name === 'ادخال البيانات' ||
      user.roll.group_name === 'ادخال البيانات ٢')
  ) {
    return (
      <Fragment>
        {data === null || loading ? (
          <Spinner />
        ) : (
          <Grid container spacing={4}>
            {/* {data.molahthat ? (
            <AlertDialog molahthat={data.molahthat} isOpen={true} />
          ) : null} */}

            <Grid item sm={2}>
              <Sidebar proprtyId={id} />
            </Grid>
            <Grid item sm={10}>
              <Card className={classes.root}>
                <form onSubmit={(e) => onSubmit(e)}>
                  <Grid container className={classes.contenier}>
                    <Grid item xs={12}>
                      <Typography variant='button' display='block'>
                        رقم العقار {id}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant='body1'>الارض البديلة</Typography>
                    </Grid>
                    <Grid item xs={2} className={classes.contentTitle}>
                      <Typography variant='body2'>رقم العقد</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        size='small'
                        margin='normal'
                        label='رقم العقد'
                        variant='outlined'
                        id='AltLndNo'
                        value={AltLndNo}
                        onChange={onChange}
                        name='AltLndNo'
                      />
                    </Grid>
                    <Grid item xs={2} className={classes.contentTitle}>
                      <Typography variant='body2'>تاريخ العقد</Typography>
                    </Grid>
                    <Grid item xs={4} className={classes.contentTitle}>
                      <MuiPickersUtilsProvider
                        utils={HijriUtils}
                        locale='ar-SA'
                      >
                        <DatePicker
                          clearable
                          okLabel='موافق'
                          cancelLabel='الغاء'
                          fullWidth
                          clearLabel='مسح'
                          name='AltLndDate'
                          labelFunc={(date) =>
                            date ? date.format('iYYYY/iMM/iDD') : ''
                          }
                          value={
                            Object.prototype.toString.call(AltLndDate) ===
                              '[object String]' ||
                            Object.prototype.toString.call(AltLndDate) ===
                              '[object Object]'
                              ? AltLndDate
                              : null
                          }
                          onChange={handleTarekAlaqad}
                          minDate='1937-03-14'
                          maxDate='2076-11-26'
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={2} className={classes.contentTitle}>
                      <Typography variant='body2'>الملاحظات والوصف</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <TextField
                        fullWidth
                        size='small'
                        margin='normal'
                        value={Remarks}
                        name='Remarks'
                        onChange={onChange}
                        label='الملاحظات والوصف'
                        variant='outlined'
                      />
                    </Grid>
                    <Grid item xs={2} className={classes.contentTitle}>
                      <Typography variant='body2'>اسم الطرف الاول</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <TextField
                        fullWidth
                        size='small'
                        margin='normal'
                        value={FirstParty}
                        name='FirstParty'
                        maxLength='10'
                        onChange={onChange}
                        label='اسم الطرف الاول'
                        variant='outlined'
                      />
                    </Grid>
                    <Grid item xs={2} className={classes.contentTitle}>
                      <Typography variant='body2'>اسم الطرف الثاني</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <TextField
                        fullWidth
                        size='small'
                        margin='normal'
                        value={SecondParty}
                        name='SecondParty'
                        onChange={onChange}
                        label='اسم الطرف الثاني'
                        variant='outlined'
                      />
                    </Grid>
                    <Grid item xs={2} className={classes.contentTitle}>
                      <Typography variant='body2'>
                        رقم وثيقة المبايعة
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        fullWidth
                        size='small'
                        margin='normal'
                        value={SalesDocNo}
                        name='SalesDocNo'
                        onChange={onChange}
                        label='رقم وثيقة المبايعة'
                        variant='outlined'
                      />
                    </Grid>
                    <Grid item xs={2} className={classes.contentTitle}>
                      <Typography variant='body2'>تاريخ الوثيقة</Typography>
                    </Grid>
                    <Grid item xs={2} className={classes.contentTitle}>
                      <MuiPickersUtilsProvider
                        utils={HijriUtils}
                        locale='ar-SA'
                      >
                        <DatePicker
                          clearable
                          okLabel='موافق'
                          cancelLabel='الغاء'
                          fullWidth
                          clearLabel='مسح'
                          name='SalesDocDate'
                          labelFunc={(date) =>
                            date ? date.format('iYYYY/iMM/iDD') : ''
                          }
                          value={
                            Object.prototype.toString.call(SalesDocDate) ===
                              '[object String]' ||
                            Object.prototype.toString.call(SalesDocDate) ===
                              '[object Object]'
                              ? SalesDocDate
                              : null
                          }
                          onChange={handleDocDate}
                          minDate='1937-03-14'
                          maxDate='2076-11-26'
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={2} className={classes.contentTitle}>
                      <Typography variant='body2'>رقم القطعة</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        fullWidth
                        size='small'
                        margin='normal'
                        value={PlotNo}
                        name='PlotNo'
                        onChange={onChange}
                        label='رقم القطعة'
                        variant='outlined'
                      />
                    </Grid>
                    <Button
                      type='submit'
                      fullWidth
                      variant='contained'
                      color='primary'
                    >
                      حفظ
                    </Button>
                  </Grid>
                </form>
              </Card>
            </Grid>
          </Grid>
        )}
      </Fragment>
    );
  } else {
    // return <AccessForbidden />;
    return <div>2DFvfdvfdvfvfvdfvdvdfv</div>;
  }
};
const mapStateToProps = (state) => ({
  aqar: state.aqar,
  isLoading: state.isLoading,
  autofill: state.autofill,
  auth: state.auth,
});
export default connect(mapStateToProps, {
  fetchAqarDetails,
  fetchAqarByAqarNumber,
  updateAlternitveLand,
})(AltLand);
