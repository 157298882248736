import { ERROR_FETCH_ARCHIVE_REQUESTS, FETCH_ARCHIVE_REQUESTS } from './types';
import { removeIsLoading, setAlert, setIsLoading } from './alert';

import api from '../utils/api';
import { getAqarFiles } from './aqarat';
import setAuthToken from '../utils/setAuthToken';

export const fetchArchiveRequests = (aqar_number) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  dispatch({
    type: ERROR_FETCH_ARCHIVE_REQUESTS,
  });
  try {
    const response = await api.get(
      `archive/${aqar_number}/fetchArchiveRequests`
    );
    dispatch({
      type: FETCH_ARCHIVE_REQUESTS,
      payload: response.data,
    });
  } catch (error) {
    dispatch(setAlert('حدث خطأ ما يرجى اعادة المحاولة', 'error', 'خطأ'));
    dispatch({
      type: ERROR_FETCH_ARCHIVE_REQUESTS,
    });
  }
};
export const accceptRequest = (id, aqar_number, data) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  dispatch(setIsLoading());
  try {
    const response = await api.post(`archive/${id}/acceptRequest`, data);
    dispatch({
      type: FETCH_ARCHIVE_REQUESTS,
      payload: response.data,
    });
    dispatch(removeIsLoading());
    dispatch(getAqarFiles(aqar_number));
    dispatch(fetchArchiveRequests(aqar_number));
    dispatch(
      setAlert('تم تحديث بيانات الطلب بنجاح', 'success', 'تمت العملية بنجاح')
    );
  } catch (error) {
    console.log('error', error);
    dispatch(setAlert('حدث خطأ ما يرجى اعادة المحاولة', 'error', 'خطأ'));
    dispatch(removeIsLoading());
    dispatch({
      type: ERROR_FETCH_ARCHIVE_REQUESTS,
    });
  }
};

export const rejectRequest = (id, aqar_number, data) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  dispatch(setIsLoading());
  try {
    const response = await api.post(`archive/${id}/rejectRequest`, data);
    dispatch({
      type: FETCH_ARCHIVE_REQUESTS,
      payload: response.data,
    });
    dispatch(removeIsLoading());
    dispatch(getAqarFiles(aqar_number));
    dispatch(fetchArchiveRequests(aqar_number));
    dispatch(
      setAlert('تم تحديث بيانات الطلب بنجاح', 'success', 'تمت العملية بنجاح')
    );
  } catch (error) {
    console.log('error', error);
    dispatch(setAlert('حدث خطأ ما يرجى اعادة المحاولة', 'error', 'خطأ'));
    dispatch(removeIsLoading());
    dispatch({
      type: ERROR_FETCH_ARCHIVE_REQUESTS,
    });
  }
};
