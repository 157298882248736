import { createMuiTheme } from "@material-ui/core/styles";
// import purple from '@material-ui/core/colors/purple';
// import green from '@material-ui/core/colors/green';
const arcBlue = "#005b96";
const arcOrange = "#FFBA60";
export default createMuiTheme({
  palette: {
    common: {
      arcBlue: `${arcBlue}`,
      arcOrange: `${arcOrange}`,
    },
    primary: {
      main: `${arcBlue}`,
    },
    secondary: {
      main: `${arcOrange}`,
    },
  },
  typography: {
    tab: {
      fontWeight: 700,
      fontSize: "1em",
      textTransform: "none",
    },
    fontFamily: "Cairo",
  },

  direction: "rtl",
});
