import {
  ERROR_FETCH_NEW_INCOMING_NUMBER,
  FETCH_NEW_INCOMING_NUMBER,
} from '../actions/types';

const initialState = {
  newNumLoading: true,
  newNum: null,
};
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_NEW_INCOMING_NUMBER:
      return { ...state, newNum: payload, newNumLoading: false };
    case ERROR_FETCH_NEW_INCOMING_NUMBER:
      return { ...state, newNum: null, newNumLoading: true };
    default:
      return state;
  }
}
