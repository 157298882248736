import { FETCH_AQAR_HASER, ERROR_FETCH_AQAR_HASER } from "../actions/types";

const initialState = {
  loading: true,
  haser: null,
};
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_AQAR_HASER:
      console.log("FETCH_Aqarat-by", payload);
      return { ...state, haser: payload, loading: false };
    case ERROR_FETCH_AQAR_HASER:
      return { ...state, haser: null, loading: true };
    default:
      return state;
  }
}
