import {
  ERROR_FETCH_LINKED_AQARAT,
  ERROR_FETCH_LINKED_RECOARDS,
  ERROR_FETCH_OUT_GOING_FIELS,
  ERROR_FETCH_RECOARD,
  ERROR_FETCH_RECOARDS,
  ERROR_FETCH_RECOARD_NUMBER,
  FETCH_LINKED_AQARAT,
  FETCH_LINKED_RECOARDS,
  FETCH_OUT_GOING_FIELS,
  FETCH_RECOARD,
  FETCH_RECOARD_NUMBER,
  FETCH_RECOARS,
  SEARCH_RECOAR,
} from './types';
import { removeIsLoading, setAlert, setIsLoading } from './alert';

import api from '../utils/api';
import setAuthToken from '../utils/setAuthToken';

export const fetchNewRecoardNumber = (companyName) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  dispatch(setIsLoading());
  try {
    const response = await api.get(`outGoing/${companyName}`, { companyName });
    dispatch({
      type: FETCH_RECOARD_NUMBER,
      payload: response.data,
    });
    dispatch(removeIsLoading());
  } catch (error) {
    console.log(error);
    dispatch(removeIsLoading());
    dispatch(setAlert('حدث خطا اثناءالمعالجة يرجآ المحاولة لاحقاً', 'error'));
  }
};
export const updateRecoard = (companyName, recoardNumber, data) => async (
  dispatch
) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  dispatch(setIsLoading());
  console.log('updateRecoard');
  try {
    const response = await api.post(
      `outGoing/update/${companyName}/${recoardNumber}`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    dispatch({
      type: FETCH_RECOARD,
      payload: response.data,
    });
    dispatch(removeIsLoading());
    dispatch(
      setAlert('تمت تحديث المعاملة بنجاح بنجاح', 'success', 'تمت العملية بنجاح')
    );
    dispatch(fetchReacordByNumber(recoardNumber));
  } catch (error) {
    console.log(error);
    dispatch({ type: ERROR_FETCH_RECOARD });
    dispatch({
      type: ERROR_FETCH_RECOARD_NUMBER,
    });
    dispatch(removeIsLoading());
    dispatch(setAlert('حدث خطا اثناءالمعالجة يرجآ المحاولة لاحقاً', 'error'));
  }
};
export const saveNewOutGoingRecoard = (
  companyName,
  Regestration_Number,
  data,
  history
) => async (dispatch) => {
  console.log('saveNewOutGoingRecoard');
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  console.log('saveNewOutGoingRecoard');
  dispatch(setIsLoading());
  try {
    const response = await api.post(
      `outGoing/newOutGoingRecoard/${companyName}/${Regestration_Number}`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    dispatch(removeIsLoading());
    dispatch(setAlert('تمت العملية بنجاح', 'success', 'تمت العملية بنجاح'));
    history.push(`/${companyName}/outGoing/${Regestration_Number}`);
    dispatch({
      type: ERROR_FETCH_RECOARD_NUMBER,
    });
    // dispatch(fetchReacordByNumber(recoardNumber));
  } catch (error) {
    console.log(error);
    dispatch({ type: ERROR_FETCH_RECOARD });
    dispatch(removeIsLoading());
    dispatch(setAlert('حدث خطا اثناءالمعالجة يرجآ المحاولة لاحقاً', 'error'));
  }
};
export const fetchAllRecoard = (page, companyName) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  console.log(companyName);
  dispatch({ type: ERROR_FETCH_RECOARD });
  dispatch({ type: ERROR_FETCH_LINKED_AQARAT });
  dispatch({ type: ERROR_FETCH_LINKED_RECOARDS });
  dispatch({
    type: ERROR_FETCH_OUT_GOING_FIELS,
  });
  dispatch({
    type: ERROR_FETCH_RECOARD_NUMBER,
  });
  try {
    const response = await api.post(`outGoing/allOutGoing?page=${page}`, {
      companyName,
    });
    dispatch({
      type: FETCH_RECOARS,
      payload: response.data,
    });
  } catch (error) {
    console.log(error.response);
    dispatch({
      type: ERROR_FETCH_RECOARDS,
    });
  }
};
export const fetchRecoardFiels = (recoardNumber, companyName) => async (
  dispatch
) => {
  console.log('fetchRecoardFiels');
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  try {
    const response = await api.get(
      `outGoing/fiels/${companyName}/${recoardNumber}`
    );
    dispatch({
      type: FETCH_OUT_GOING_FIELS,
      payload: response.data,
    });
  } catch (error) {
    console.log(error.response);
    dispatch({
      type: ERROR_FETCH_OUT_GOING_FIELS,
    });
  }
};
export const searchForRecoard = (
  page,
  companyName,
  tableName,
  coulmName,
  sarch
) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  // dispatch({ type: ERROR_FETCH_RECOARD });
  try {
    const response = await api.post(
      `outGoing/search?page=${page}&coulmName=${coulmName}&search=${sarch}`,
      { companyName, tableName }
    );
    dispatch({ type: ERROR_FETCH_RECOARDS });
    // console.log(response);
    dispatch({
      type: SEARCH_RECOAR,
      payload: response.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_FETCH_RECOARDS,
    });
  }
};
export const fetchLinkedAqarat = (recoardNumber, companyName) => async (
  dispatch
) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  dispatch({ type: ERROR_FETCH_LINKED_AQARAT });
  try {
    const response = await api.get(
      `/outGoing/aqarat/${companyName}/${recoardNumber}`
    );
    dispatch({ type: FETCH_LINKED_AQARAT, payload: response.data });
  } catch (error) {
    console.log(error);
    dispatch({ type: ERROR_FETCH_LINKED_AQARAT });
  }
};
export const fetchLinkedRecaords = (recoardNumber, companyName) => async (
  dispatch
) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  dispatch({ type: ERROR_FETCH_LINKED_RECOARDS });
  try {
    const response = await api.get(
      `/outGoing/recoards/${companyName}/${recoardNumber}?source=Out`
    );
    dispatch({ type: FETCH_LINKED_RECOARDS, payload: response.data });
  } catch (error) {
    console.log(error);
    dispatch({ type: ERROR_FETCH_LINKED_RECOARDS });
  }
};
export const fetchReacordByNumber = (recoardNumber, companyName) => async (
  dispatch
) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  dispatch({ type: ERROR_FETCH_RECOARDS });
  try {
    const response = await api.post(`/outGoing/${recoardNumber}`, {
      companyName,
    });
    dispatch({
      type: FETCH_RECOARD,
      payload: response.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({ type: ERROR_FETCH_RECOARD });
  }
};
