import { Route, Switch } from 'react-router-dom';

import AddNewAqar from '../components/ui/AddNewAqar';
import AddNewCategory from '../components/ui/Categories/AddNewCategory';
import AddNewDepartment from '../components/ui/Admin/AddNewDepartment';
import AddNewUser from '../components/ui/Admin/AddNewUser';
import AdminDashboard from '../components/ui/Admin/AdminDashboard';
import AdministrativeCom from '../components/ui/AdministrativeCom/AdministrativeCom';
import AlHasser from '../components/ui/SidebarContent/AlHasser';
import AllCategories from '../components/ui/Categories/AllCategories';
import AllInComing from '../components/ui/AdministrativeCom/AllInComing';
import AllOutGoing from '../components/ui/AdministrativeCom/AllOutGoing';
import AlteLand from '../components/ui/SidebarContent/AltLand';
import AqarDocs from '../components/ui/SidebarContent/AqarDocs';
import AqarRecoards from '../components/ui/SidebarContent/AqarRecoards';
import AqaraCoordinate from '../components/ui/SidebarContent/AqaraCoordinate';
import Archives from '../components/ui/SidebarContent/Archives';
import ConsulteDash from '../components/ui/SidebarContent/ConsulteDash';
import FollowUp from '../components/ui/SidebarContent/FollowUp';
import Home from '../components/ui/Home';
import Incoming from '../components/ui/AdministrativeCom/Incoming';
import OutGoing from '../components/ui/AdministrativeCom/OutGoing';
import PersonalInformations from '../components/ui/SidebarContent/PersonalInformations';
import PriceRevision from '../components/ui/SidebarContent/PriceRevision';
import PrivateRoute from './PrivateRoute';
import ProprtyDetails from '../components/ui/SidebarContent/ProprtyDetails';
import React from 'react';
import ResetPassword from '../components/ui/ResetPassword';
import SakAlanqath from '../components/ui/SidebarContent/SakAlAnqad';
import Signin from '../components/ui/Signin';
import Table1 from '../components/ui/Table/Table1';
import Tathmeen from '../components/ui/SidebarContent/Tathmeen';
import UpdatePassword from '../components/ui/UpdatePassword';
import Users from '../components/ui/Admin/Users';
import VerificationCode from '../components/ui/VerificationCode';

const Routes = () => {
  return (
    <Switch>
      <Route path='/login' component={Signin} />
      <Route path='/restPassword' component={ResetPassword} />
      <Route path='/verificationCode' component={VerificationCode} />
      <Route path='/:userId/UpdatePassword' component={UpdatePassword} />

      <PrivateRoute exact path='/' component={Home} />

      <PrivateRoute exact path='/aqarat' component={Table1} />
      <PrivateRoute exact path='/addNewAqar' component={AddNewAqar} />

      <PrivateRoute exact path='/dashboard' component={AdminDashboard} />

      {/* AQARAT ROUTES */}
      <PrivateRoute exact path='/:id/follow_up' component={FollowUp} />
      <PrivateRoute exact path='/:id/alhaser' component={AlHasser} />
      <PrivateRoute
        exact
        path='/:id/personal_informations'
        component={PersonalInformations}
      />
      <PrivateRoute exact path='/:id/tathmeen' component={Tathmeen} />
      <PrivateRoute exact path='/:id/archives' component={Archives} />
      <PrivateRoute exact path='/:id/SakAlanqath' component={SakAlanqath} />
      <PrivateRoute exact path='/:id/aqarDocs' component={AqarDocs} />
      <PrivateRoute exact path='/:id/altLands' component={AlteLand} />
      <PrivateRoute exact path='/:id/coordinate' component={AqaraCoordinate} />
      <PrivateRoute
        exact
        path='/:id/proprty_details'
        component={ProprtyDetails}
      />
      <PrivateRoute exact path='/:id/priceRevision' component={PriceRevision} />
      <PrivateRoute exact path='/:aqarId/aqarLayers' component={ConsulteDash} />
      {/* DEPARTMENT ROUTES */}
      <PrivateRoute
        exact
        path='/add_new_department'
        component={AddNewDepartment}
      />
      <PrivateRoute
        exact
        path='/:id/edit_department'
        component={AddNewDepartment}
      />
      {/* RECOARD ROUTES */}
      <PrivateRoute exact path='/:companyName' component={AdministrativeCom} />
      <PrivateRoute exact path='/:companyName/inComing' component={Incoming} />
      <PrivateRoute exact path='/:companyName/outGoing' component={OutGoing} />
      <PrivateRoute
        exact
        path='/:companyName/allOutGoing'
        component={AllOutGoing}
      />
      <PrivateRoute exact path='/:id/altLands' component={AlteLand} />
      <PrivateRoute
        exact
        path='/:companyName/allInComing'
        component={AllInComing}
      />
      <PrivateRoute exact path='/:id/recoards' component={AqarRecoards} />
      <PrivateRoute
        exact
        path='/:companyName/outGoing/:recoardId'
        component={OutGoing}
      />
      <PrivateRoute
        exact
        path='/:companyName/inComing/:recoardId'
        component={Incoming}
      />

      {/* USERS ROUTES */}
      <PrivateRoute
        exact
        path='/department/:userId/edit_user'
        component={AddNewUser}
      />
      <PrivateRoute
        exact
        path='/department/add_new_user'
        component={AddNewUser}
      />
      <PrivateRoute exact path='/department/users' component={Users} />
      <PrivateRoute
        exact
        path='/categroies/mangment'
        component={AllCategories}
      />
      <PrivateRoute
        exact
        path='/categroies/add_new_category'
        component={AddNewCategory}
      />
      <PrivateRoute
        exact
        path='/categroies/:catID'
        component={AddNewCategory}
      />
    </Switch>
  );
};
export default Routes;
