import {
  ERROR_FETCH_CATEORY,
  ERROR_FETCH_SUB_CATEORIES,
  FETCH_CATEORY,
  FETCH_SUB_CATEORIES,
} from '../actions/types';

const initialState = {
  loading: true,
  category: null,
  subCategories: [],
  subLoading: true,
};
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_CATEORY:
      return { ...state, category: payload, loading: false };
    //   case FETCH_OUT_GOING_FIELS:
    //     return { ...state, docments: payload, loading: false };
    //   case ERROR_FETCH_OUT_GOING_FIELS:
    //     return { ...state, docments: null, loading: true };
    case FETCH_SUB_CATEORIES:
      return { ...state, subCategories: payload, subLoading: false };
    case ERROR_FETCH_CATEORY:
      return { ...state, category: null, loading: true };
    case ERROR_FETCH_SUB_CATEORIES:
      return { ...state, subCategories: [], subLoading: true };
    default:
      return state;
  }
}
