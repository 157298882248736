import { AUTO_FILL, EMPTY_AUTO_FILL } from '../actions/types';
const initialState = {
  loading: true,
  data: null,
};
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case AUTO_FILL:
      return { ...state, data: payload, loading: false };
    case EMPTY_AUTO_FILL:
      return { ...state, data: null, loading: true };
    default:
      return state;
  }
}
