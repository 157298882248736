import { lighten, makeStyles } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import React from 'react';
// import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
// import PropTypes from 'prop-types';
import clsx from 'clsx';

// EnhancedTableHead.propTypes = {
//   classes: PropTypes.object.isRequired,
//   numSelected: PropTypes.number.isRequired,
//   onRequestSort: PropTypes.func.isRequired,
//   onSelectAllClick: PropTypes.func.isRequired,
//   order: PropTypes.oneOf(['asc', 'desc']).isRequired,
//   orderBy: PropTypes.string.isRequired,
//   rowCount: PropTypes.number.isRequired,
// };

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
  searchInput: {},
  searchIcon: {},
  divConteainer: {
    display: 'flex',
    backgroundColor: 'green',
    marginTop: '5px',
    marginBottom: '5px',
    paddingLeft: '20px',
    paddingRight: '20px ',
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;
  // const handleOnChangeSearchInput = (e) => {
  //   console.log('SDvdvd');
  //   props.setFilter(e.target.value);
  // };
  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color='inherit'
          variant='subtitle1'
          component='div'
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant='h6'
          id='tableTitle'
          component='div'
          align='center'
        >
          {props.name}
        </Typography>
      )}
      <Tooltip title='Filter list'>
        <div style={{ display: 'flex' }}>
          <TextField onChange={(e) => props.handleOnChangeSearchInput(e)} id='search' />
          <IconButton aria-label='filter list'>
            <SearchIcon />
          </IconButton>
        </div>
      </Tooltip>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  // numSelected: PropTypes.number.isRequired,
};

export default EnhancedTableToolbar;
