import { ERROR_FETCH_AQAR_COOR, FETCH_AQAR_COOR } from '../actions/types';

const initialState = {
  coorLoading: true,
  coorData: null,
};
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_AQAR_COOR:
      return { ...state, coorData: payload, loading: false };
    case ERROR_FETCH_AQAR_COOR:
      return {
        ...state,
        coorData: null,
        loading: true,
      };

    default:
      return state;
  }
}
