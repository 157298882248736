import { CircularProgress, Grid } from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import LocalPrintshopIcon from '@material-ui/icons/LocalPrintshopOutlined';
import NativeSelect from '@material-ui/core/NativeSelect';
import SaveIcon from '@material-ui/icons/Save';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    paddingTop: '56.25%', // 16:9,
    marginTop: '30',
    aspectRatio: 135 / 76,
  },
});
const ImageCard = ({
  url,
  name,
  changeFileName,
  opt,
  saveNewFileName,
  isLoading,
  deleteFile,
  printLetter,
  auth: { user },
}) => {
  const imgName = name.split('/');
  const splet = imgName[imgName.length - 1].split('-');
  const realName = `${splet[0]}-${splet[1]}-${splet[2]}`;
  const options = [
    { value: 'DE', key: 'الصك / الوثيقة' },
    { value: 'ID', key: 'الهوية' },
    { value: 'RCMCPRF', key: 'نموذج تسجيل مستندات الملكيات' },
    { value: 'MHSR', key: 'مسودة محضر حصر المشتملات' },
    { value: 'MVAL', key: 'محضر تثمين العقار' },
    { value: 'FM', key: 'بيانات المالك' },
    { value: 'BL', key: 'فاتورة الكهرباء' },
    { value: 'HSH', key: 'نموذج اثبات تملك انقاض' },
    { value: 'KR', key: 'كروكي مساحي' },
    { value: 'FKR', key: 'كروكي مساحي نهائي' },
    { value: 'OVER', key: 'مخالصة' },
    { value: 'MFKR', key: 'مسودة كروكي مساحي نهائي' },
    { value: 'MSH', key: 'مشهد إنقاض ( للوثيقة )' },
    { value: 'HSR', key: 'حصر المشتملات' },
    { value: 'VAL', key: 'تبليغ التثمين' },
    { value: 'OPT', key: 'خيارات الملاك' },
    { value: 'WK', key: 'الوكالات' },
    { value: 'COM', key: 'شكوى المالك' },
    { value: 'DFMW', key: 'إحالة داخلية وادي إبراهيم' },
    { value: 'DEDM', key: 'صك انقاض' },
    { value: 'TAAD', key: 'تعهد بمراجعة المحكمة' },
    { value: 'STC', key: 'اخلاء طرف من الاتصالات' },
    { value: 'WAT', key: 'اخلاء طرف من شركة المياه الوطنية' },
    { value: 'SRV', key: 'اخلاء طرف من شركة الكهرباء' },
    { value: 'TAH', key: 'تعهد بتصفية عدادات الكهرباء والمياه واخلاء العقار' },
    { value: 'NAD', key: 'العنوان الوطني للمالك' },
    { value: 'VAP', key: 'صحيفة نزع الملكية' },
    { value: 'CH', key: 'شيك' },
    { value: 'CNT', key: 'بيان محتويات المستندات' },
    { value: 'OTHER', key: 'أخرى' },
    {
      value: 'KDN',
      key: 'مذكرة تسجيل الصك في الأمانة ( كروكي الصك الصادر من الأمانة )',
    },
    { value: 'TFR', key: 'كشف تفصيلي مالي' },
    { value: 'CON', key: 'العقد' },
    { value: 'DEH', key: 'صك حصر الورثة ' },
  ];
  const [editName, setEditName] = useState(false);
  const cardClicked = () => {
    window.open(url, '_blank');
  };
  const deleteImage = () => {
    deleteFile(name);
  };
  const printLetterAction = () => {
    printLetter(realName);
  };
  const editImage = () => {
    setEditName(true);
    console.log('editImage');
  };

  const classes = useStyles();

  const onChange = (e) => {
    const selectedOption = options.filter((op) => op.value === e.target.value);
    changeFileName({
      key: selectedOption[0].key,
      value: selectedOption[0].value,
    });
    // console.log(formData);
    // console.log(e.target.value);
  };
  const saveImageNewName = () => {
    saveNewFileName(name);
    setEditName(!isLoading.isLoading);
  };
  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Card className={classes.root}>
        <CardMedia
          className={classes.media}
          image={url}
          title={name}
          onClick={cardClicked}
        />
        <CardContent>
          {editName ? (
            <NativeSelect
              onChange={onChange}
              fullWidth
              key='opt'
              value={opt ? opt.value : ''}
              name='opt'
              inputProps={{
                id: 'opt-menu',
              }}
            >
              {options.map((opt) => (
                <option key={opt.value} value={opt.value}>
                  {opt.key} - {opt.value}
                </option>
              ))}
            </NativeSelect>
          ) : (
            <Typography gutterBottom variant='h5' component='h2'>
              {realName}
            </Typography>
          )}

          {/* <Typography variant='body2' color='textSecondary' component='p'>
              Lizards are a widespread group of squamate reptiles, with over
              6,000 species, ranging across all continents except Antarctica
            </Typography> */}
        </CardContent>
        <CardActionArea>
          {editName ? (
            <Fragment>
              <IconButton aria-label='edit' onClick={saveImageNewName}>
                {isLoading.isLoading ? (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                ) : (
                  <SaveIcon />
                )}
              </IconButton>
            </Fragment>
          ) : (
            <Fragment>
              {isLoading.isLoading ? (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              ) : (
                <Fragment>
                  {user.roll.group_name ? (
                    user.roll.group_name === 'ادخال البيانات' ||
                    user.roll.group_name === 'ادخال البيانات ٢' ? (
                      <Fragment>
                        <IconButton aria-label='edit' onClick={editImage}>
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          aria-label='deleteLetter'
                          onClick={printLetterAction}
                        >
                          <LocalPrintshopIcon />
                        </IconButton>
                        {user.roll.group_name === 'ادخال البيانات ٢' ? (
                          <IconButton aria-label='delete' onClick={deleteImage}>
                            <DeleteIcon />
                          </IconButton>
                        ) : null}
                      </Fragment>
                    ) : null
                  ) : null}
                </Fragment>
              )}
            </Fragment>
          )}
        </CardActionArea>
      </Card>
    </Grid>
  );
};
const mapStateToProps = (state) => ({
  isLoading: state.isLoading,
  auth: state.auth,
});

export default connect(mapStateToProps)(ImageCard);
