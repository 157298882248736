import { Button, Paper } from '@material-ui/core';
import React, { useEffect } from 'react';
import { fetchAqarat, searchForAqar } from '../../../actions/aqarat';

import EnhancedTableToolbar from './MyToolbar';
import { Link } from 'react-router-dom';
import MyTableHead from './MyTableHead';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';

const Table1 = ({
  history,
  fetchAqarat,
  searchForAqar,
  auth: { user },
  aqarat: { data, pageNumber, pageSize, totalElment, total_pages, loading },
}) => {
  console.log('TABLE1');
  useEffect(() => {
    fetchAqarat(1);
    // console.log('SDCDC');
  }, [fetchAqarat]);
  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  console.log('TABLE');
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    table: {
      minWidth: 650,
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }));
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('aqar_number');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [filter, setFilter] = React.useState('');
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  // console.log();
  const handleClick = (event, row) => {
    switch (user.department) {
      case '2':
        // console.log(user.roll.group_name);
        if (user.roll.group_name === 'الحصر') {
          history.push(`/${row.aqar_number}/alhaser`);
        } else {
          history.push(`/${row.aqar_number}/personal_informations`);
        }
        break;
      case '3':
        if (user.roll.group_name === 'الاستشاريين') {
          history.push(`/${row.aqar_number}/archives`);
        }
        break;
      default:
        break;
    }
    const selectedIndex = selected.indexOf(row.name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row.name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  const handleChangePage = (event, newPage) => {
    // console.log('newPage', newPage, page);
    fetchAqarat(newPage + 1);
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // const emptyRows =
  //   rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const headCells = [
    {
      id: 'aqar_number',
      numeric: false,
      disablePadding: true,
      label: 'رقم العقار',
    },
    {
      id: 'raqam_alsak',
      numeric: false,
      disablePadding: true,
      label: 'رقم الصك',
    },

    {
      id: 'esem_almalik',
      numeric: true,
      disablePadding: false,
      label: 'اسم المالك',
    },
    {
      id: 'almalik_id_number',
      numeric: true,
      disablePadding: false,
      label: 'هوية المالك',
    },
    {
      id: 'jawal_almalik',
      numeric: true,
      disablePadding: false,
      label: 'جوال المالك',
    },
    {
      id: 'esam_alwakel',
      numeric: true,
      disablePadding: false,
      label: 'اسم الوكيل',
    },
    {
      id: 'jawal_alwakel',
      numeric: true,
      disablePadding: false,
      label: 'جوال الوكيل',
    },
    {
      id: 'alWakel_id_number',
      numeric: true,
      disablePadding: false,
      label: 'هوية الوكيل',
    },
    {
      id: 'esam_almandob',
      numeric: true,
      disablePadding: false,
      label: 'اسم المندوب',
    },
    {
      id: 'jawal_mandob',
      numeric: true,
      disablePadding: false,
      label: 'جوال المندوب',
    },
    {
      id: 'almandob_id_number',
      numeric: true,
      disablePadding: false,
      label: 'هوية المندوب',
    },
  ];
  const handleOnChangeSearchInput = (e) => {
    // console.log(orderBy);
    searchForAqar(1, orderBy, e.target.value);
    setFilter(e.target.value);
  };
  var linkPath = '';
  switch (user.roll.group_name) {
    case 'الحصر':
      linkPath = `/alhaser`;
      break;
    case 'الاستشاريين':
      linkPath = `/archives`;
      break;
    default:
      linkPath = `/personal_informations`;
  }
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          handleOnChangeSearchInput={handleOnChangeSearchInput}
          name={'بيانات العقارات'}
        />
        {/* {user.department === '2' && user.roll == 4 ? ( */}
        <Button
          variant='contained'
          color='primary'
          component={Link}
          to='/addNewAqar'
        >
          تسجيل رقم عقار جديد
        </Button>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label='simple table'>
            <MyTableHead
              headCells={headCells}
              onRequestSort={handleRequestSort}
              order={order}
              orderBy={orderBy}
              classes={classes}
            >
              <TableRow>
                <TableCell align='center'>Dessert (100g serving)</TableCell>
                <TableCell align='center'>Calories</TableCell>
                <TableCell align='center'>Fat&nbsp;(g)</TableCell>
                <TableCell align='center'>Carbs&nbsp;(g)</TableCell>
                <TableCell align='center'>Protein&nbsp;(g)</TableCell>
              </TableRow>
            </MyTableHead>
            <TableBody>
              {!loading
                ? stableSort(data, getComparator(order, orderBy)).map(
                    (row, index) => {
                      // console.log(page * pageSize, page * pageSize + pageSize);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, row)}
                          role='checkbox'
                          tabIndex={-1}
                          key={row.aqar_number}
                          component={Link}
                          id={row.aqar_number}
                          to={`/${row.aqar_number}${linkPath}`}
                        >
                          <TableCell
                            component='th'
                            id={labelId}
                            scope='row'
                            padding='none'
                            align='center'
                          >
                            {row.aqar_number}
                          </TableCell>
                          <TableCell align='center'>
                            {row.raqam_alsak}
                          </TableCell>
                          <TableCell align='center'>
                            {row.esem_almalik}
                          </TableCell>
                          <TableCell align='center'>
                            {row.almalik_id_number}
                          </TableCell>
                          <TableCell align='center'>
                            {row.jawal_almalik}
                          </TableCell>
                          <TableCell align='center'>
                            {row.esam_alwakel}
                          </TableCell>
                          <TableCell align='center'>
                            {row.jawal_alwakel}
                          </TableCell>
                          <TableCell align='center'>
                            {row.alWakel_id_number}
                          </TableCell>
                          <TableCell align='center'>
                            {row.esam_almandob}
                          </TableCell>
                          <TableCell align='center'>
                            {row.jawal_mandob}
                          </TableCell>
                          <TableCell align='center'>
                            {row.almandob_id_number}
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )
                : null}
              {/* {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )} */}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5]}
          component='div'
          count={totalElment}
          rowsPerPage={pageSize}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};
Table1.propTypes = {
  aqarat: PropTypes.object.isRequired,
  fetchAqarat: PropTypes.func.isRequired,
  searchForAqar: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  aqarat: state.aqarat,
  auth: state.auth,
});
export default withRouter(
  connect(mapStateToProps, { fetchAqarat, searchForAqar })(Table1)
);
