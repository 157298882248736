import { ERROR_FETCH_LAYERS, FETCH_LAYERS } from '../actions/types';

const initialState = {
  layers: [],
  layersloading: true,
};
export default function (state = initialState, action) {
  const { type, payload } = action;
  // console.log(type);
  // console.log('state', payload);
  switch (type) {
    case FETCH_LAYERS:
      return {
        ...state,
        layers: payload,
        layersloading: false,
      };
    case ERROR_FETCH_LAYERS:
      return { ...state, layers: [], layersloading: true };
    default:
      return state;
  }
}
