import { Button, Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import {
  fetchAllRecoard,
  searchForRecoard,
} from '../../../actions/administrativeCom';

import EnhancedTableToolbar from '../Table/MyToolbar';
import { Link } from 'react-router-dom';
import MyTableHead from '../Table/MyTableHead';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  addNewBtn: {
    marginTop: '20px',
    marginRight: '20px',
  },
  backBtn: {
    textAlign: 'end',
    paddingLeft: '20px',
  },
});
const AllOutGoing = ({
  history,
  match,
  fetchAllRecoard,
  searchForRecoard,
  recoards: { data, pageNumber, pageSize, totalElment, total_pages, loading },
}) => {
  useEffect(() => {
    console.log('useEffect');
    fetchAllRecoard(1, companyName);
    // console.log('SDCDC');
  }, [fetchAllRecoard]);
  const { params } = match;
  const { companyName } = params;
  const classes = useStyles();
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('Regestration_Number');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [filter, setFilter] = React.useState('');
  console.log(rowsPerPage, filter);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const handleChangePage = (event, newPage) => {
    console.log('newPage', newPage, page);
    fetchAllRecoard(newPage + 1, companyName);
    setPage(newPage);
  };
  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  const handleClick = (event, row) => {
    history.push(`/${companyName}/outGoing/${row.Regestration_Number}`);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleOnChangeSearchInput = (e) => {
    console.log(orderBy);
    searchForRecoard(1, companyName, 'out_going', orderBy, e.target.value);
    setFilter(e.target.value);
  };
  const headCells = [
    {
      id: 'Regestration_Number',
      numeric: false,
      disablePadding: true,
      label: 'رقم المعاملة',
    },
    {
      id: 'Subject',
      numeric: true,
      disablePadding: false,
      label: 'الموضوع',
    },
    {
      id: 'sent_to',
      numeric: true,
      disablePadding: false,
      label: 'مرسل إلى',
    },
    {
      id: 'Status',
      numeric: true,
      disablePadding: false,
      label: 'حالة المعاملة',
    },
  ];
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container>
          <Grid item xs={4}>
            <Button
              className={classes.addNewBtn}
              color='primary'
              variant='contained'
              component={Link}
              to={`/${companyName}/outGoing`}
            >
              معاملة جديدة
            </Button>
          </Grid>
          <Grid item xs={4}></Grid>

          <Grid item xs={4} className={classes.backBtn}>
            <Button
              className={classes.addNewBtn}
              variant='contained'
              color='secondary'
              component={Link}
              to='/'
            >
              رجوع
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <EnhancedTableToolbar
            handleOnChangeSearchInput={handleOnChangeSearchInput}
            name={'بيانات المعاملات الصادرة'}
          />
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label='simple table'>
              <MyTableHead
                headCells={headCells}
                onRequestSort={handleRequestSort}
                order={order}
                orderBy={orderBy}
                classes={classes}
              ></MyTableHead>
              <TableBody>
                {!loading
                  ? stableSort(data, getComparator(order, orderBy)).map(
                      (row, index) => {
                        console.log(
                          page * pageSize,
                          page * pageSize + pageSize
                        );
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow
                            hover
                            onClick={(event) => handleClick(event, row)}
                            role='checkbox'
                            tabIndex={-1}
                            component={Link}
                            to={`/${companyName}/outGoing/${row.Regestration_Number}`}
                            key={row.Regestration_Number}
                          >
                            <TableCell
                              component='th'
                              id={labelId}
                              scope='row'
                              padding='none'
                              align='center'
                            >
                              {row.Regestration_Number}
                            </TableCell>
                            <TableCell align='center'>{row.Subject}</TableCell>
                            <TableCell align='center'>{row.sent_to}</TableCell>
                            <TableCell align='center'>{row.Status}</TableCell>
                          </TableRow>
                        );
                      }
                    )
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5]}
            component='div'
            count={totalElment}
            rowsPerPage={pageSize}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Grid>
      </Paper>
    </div>
  );
};
const mapStateToProps = (state) => ({
  recoards: state.recoards,
  auth: state.auth,
});
export default connect(mapStateToProps, { fetchAllRecoard, searchForRecoard })(
  AllOutGoing
);
