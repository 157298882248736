import {
  Button,
  Card,
  Checkbox,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { Fragment, useEffect, useState } from 'react';
import {
  fetchAqarByAqarNumber,
  updateSakAlanqath,
} from '../../../actions/aqarat';

import AlertDialog from '../AlertDialog';
import HijriUtils from '@date-io/hijri';
import Sidebar from '../Sidbar';
import Spinner from '../Spinner';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

const initialState = {
  // mashhad
  raqam_ketab_alamanh: '',
  tark_ketab_alamanh: '',
  raqam_ketab_almahkma: '',
  tark_ketab_almahkma: '',
  tableg_almalek_beraqam_moamalt_almahma: '',
  tarik_tableg_beraqam_almoamla: '',
  estlam_sak_alanqath: '',
  tark_estlam_sak_alanqath: '',
  raqam_sak_alanqath: '',
  tark_sak_alanqath: '',
  molahthat_almotaba: '',
  mashad_alanqath: '',
};
const SakAlAnqad = ({
  match,
  aqar: { loading, data },
  history,
  updateSakAlanqath,
  fetchAqarByAqarNumber,
}) => {
  const { params } = match;
  const { id } = params;
  const [formData, setFormData] = useState({
    raqam_ketab_alamanh: '',
    tark_ketab_alamanh: null,
    raqam_ketab_almahkma: '',
    tark_ketab_almahkma: '',
    tableg_almalek_beraqam_moamalt_almahma: '',
    tarik_tableg_beraqam_almoamla: '',
    estlam_sak_alanqath: '',
    tark_estlam_sak_alanqath: '',
    raqam_sak_alanqath: '',
    tark_sak_alanqath: '',
    molahthat_almotaba: '',
    mashad_alanqath: '',
  });
  const {
    raqam_ketab_alamanh,
    tark_ketab_alamanh,
    raqam_ketab_almahkma,
    tark_ketab_almahkma,
    tableg_almalek_beraqam_moamalt_almahma,
    tarik_tableg_beraqam_almoamla,
    estlam_sak_alanqath,
    tark_estlam_sak_alanqath,
    raqam_sak_alanqath,
    tark_sak_alanqath,
    molahthat_almotaba,
    mashad_alanqath,
  } = formData;
  const useStyles = makeStyles({
    root: {
      minWidth: 275,
      textAlign: 'center',
      padding: '15px',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    contentTitle: {
      margin: 'auto',
    },
  });

  useEffect(() => {
    if (!data) fetchAqarByAqarNumber(id);
    if (!loading && data) {
      const aqarData = { ...initialState };
      for (const key in data) {
        if (key in aqarData) aqarData[key] = data[key];
      }
      setFormData(aqarData);
    }
  }, [loading, fetchAqarByAqarNumber, data, id]);
  const onSubmit = (e) => {
    e.preventDefault();
    updateSakAlanqath(id, formData, history);
    // updateAqarFollowUp(data.aqar_number, formData, history);
  };
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handelKetabDate = (date) => {
    setFormData({
      ...formData,
      tark_ketab_alamanh: moment(date).format('YYYY-MM-DD HH:mm:ss'),
    });
  };
  const handleTarekKetabAlmahkma = (date) => {
    setFormData({
      ...formData,
      tark_ketab_almahkma: moment(date).format('YYYY-MM-DD HH:mm:ss'),
    });
  };
  const handleTarekTablegAlmalik = (date) => {
    setFormData({
      ...formData,
      tarik_tableg_beraqam_almoamla: moment(date).format('YYYY-MM-DD HH:mm:ss'),
    });
  };
  const handleEstlamSakAlanqathDate = (date) => {
    setFormData({
      ...formData,
      tark_estlam_sak_alanqath: moment(date).format('YYYY-MM-DD HH:mm:ss'),
    });
  };
  const handleSakAlanqathDate = (date) => {
    setFormData({
      ...formData,
      tark_sak_alanqath: moment(date).format('YYYY-MM-DD HH:mm:ss'),
    });
  };
  const onCheckboxChange = (event) => {
    console.log(+event.target.checked);
    const name = event.target.name;
    setFormData({ ...formData, [name]: +event.target.checked });
  };
  const classes = useStyles();
  return (
    <Fragment>
      {data === null || loading ? (
        <Spinner />
      ) : (
        <Grid container spacing={4}>
          {data.molahthat ? (
            <AlertDialog molahthat={data.molahthat} isOpen={true} />
          ) : null}
          <Grid item xs={2}>
            <Sidebar proprtyId={id} />
          </Grid>
          <Grid item xs={10}>
            <Card className={classes.root}>
              <form onSubmit={(e) => onSubmit(e)}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant='button' display='block'>
                      رقم العقار {id}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='body1'>صك الأنقاض</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='body1'> مشهد العمدة</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant='body1'>
                      تم استلام مشهد من العمدة
                    </Typography>
                  </Grid>
                  <Grid item xs={1} className={classes.contentTitle}>
                    <Checkbox
                      checked={!!mashad_alanqath}
                      name='mashad_alanqath'
                      color='primary'
                      onChange={onCheckboxChange}
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  </Grid>
                  <Grid item xs={9}></Grid>
                  <Grid item xs={12}>
                    <Typography variant='body1'>
                      الخطاب الصادر للأمانة
                    </Typography>
                  </Grid>
                  <Grid item xs={2} className={classes.contentTitle}>
                    <Typography variant='body2'> رقم الخطاب للأمانة</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      margin='normal'
                      value={raqam_ketab_alamanh}
                      variant='outlined'
                      id='raqam_ketab_alamanh'
                      size='small'
                      onChange={(e) => onChange(e)}
                      name='raqam_ketab_alamanh'
                    />
                  </Grid>
                  <Grid item xs={2} className={classes.contentTitle}>
                    <Typography variant='body2'>تاريخه</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <MuiPickersUtilsProvider utils={HijriUtils} locale='ar-SA'>
                      <DatePicker
                        clearable
                        okLabel='موافق'
                        cancelLabel='الغاء'
                        fullWidth
                        clearLabel='مسح'
                        name='tark_ketab_alamanh'
                        labelFunc={(date) =>
                          date ? date.format('iYYYY/iMM/iDD') : ''
                        }
                        value={tark_ketab_alamanh}
                        onChange={handelKetabDate}
                        minDate='1937-03-14'
                        maxDate='2076-11-26'
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      variant='contained'
                      color='primary'
                      className={classes.butns}
                      // disabled={edit}
                      // onClick={handlPrintMalikInfo}
                    >
                      طباعة الخطاب للهيئة
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='body1'>
                      إحالة المعاملة من الأمانة إلي المحكمة
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant='body2'> رقم الخطاب للمحكمة</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      margin='normal'
                      value={raqam_ketab_almahkma}
                      variant='outlined'
                      id='raqam_ketab_almahkma'
                      size='small'
                      onChange={(e) => onChange(e)}
                      name='raqam_ketab_almahkma'
                    />
                  </Grid>
                  <Grid item xs={2} className={classes.contentTitle}>
                    <Typography variant='body2'>
                      تاريخ الخطاب للمحكمة
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <MuiPickersUtilsProvider utils={HijriUtils} locale='ar-SA'>
                      <DatePicker
                        clearable
                        okLabel='موافق'
                        cancelLabel='الغاء'
                        fullWidth
                        clearLabel='مسح'
                        name='tark_ketab_almahkma'
                        labelFunc={(date) =>
                          date ? date.format('iYYYY/iMM/iDD') : ''
                        }
                        value={tark_ketab_almahkma}
                        onChange={handleTarekKetabAlmahkma}
                        minDate='1937-03-14'
                        maxDate='2076-11-26'
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={2} className={classes.contentTitle}>
                    <Typography variant='body2'>تم تبليغ المالك</Typography>
                  </Grid>
                  <Grid item xs={1} className={classes.contentTitle}>
                    <Checkbox
                      checked={!!tableg_almalek_beraqam_moamalt_almahma}
                      name='tableg_almalek_beraqam_moamalt_almahma'
                      color='primary'
                      onChange={onCheckboxChange}
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  </Grid>
                  <Grid item xs={2} className={classes.contentTitle}>
                    <Button
                      variant='contained'
                      color='primary'
                      className={classes.butns}
                      // disabled={edit}
                      // onClick={handlPrintMalikInfo}
                    >
                      ارسال الرسالة
                    </Button>
                  </Grid>
                  <Grid item xs={2} className={classes.contentTitle}>
                    <Typography variant='body2'>تاريخ التبليغ</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <MuiPickersUtilsProvider utils={HijriUtils} locale='ar-SA'>
                      <DatePicker
                        clearable
                        okLabel='موافق'
                        cancelLabel='الغاء'
                        fullWidth
                        clearLabel='مسح'
                        name='tarik_tableg_beraqam_almoamla'
                        labelFunc={(date) =>
                          date ? date.format('iYYYY/iMM/iDD') : ''
                        }
                        value={tarik_tableg_beraqam_almoamla}
                        onChange={handleTarekTablegAlmalik}
                        minDate='1937-03-14'
                        maxDate='2076-11-26'
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={2} className={classes.contentTitle}>
                    <Button
                      variant='contained'
                      color='primary'
                      className={classes.butns}
                      // disabled={edit}
                      // onClick={handlPrintMalikInfo}
                    >
                      طباعة الخطاب للمحكمة
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='body1'>صك الانقاض</Typography>
                  </Grid>
                  <Grid item xs={2} className={classes.contentTitle}>
                    <Typography variant='body2'>استلام صك الانقاض</Typography>
                  </Grid>
                  <Grid item xs={4} className={classes.contentTitle}>
                    <Checkbox
                      checked={!!estlam_sak_alanqath}
                      name='estlam_sak_alanqath'
                      color='primary'
                      onChange={onCheckboxChange}
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  </Grid>
                  <Grid item xs={2} className={classes.contentTitle}>
                    <Typography variant='body2'>
                      تاريخ استلام صك الانقاض
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <MuiPickersUtilsProvider utils={HijriUtils} locale='ar-SA'>
                      <DatePicker
                        clearable
                        okLabel='موافق'
                        cancelLabel='الغاء'
                        fullWidth
                        clearLabel='مسح'
                        name='tark_estlam_sak_alanqath'
                        labelFunc={(date) =>
                          date ? date.format('iYYYY/iMM/iDD') : ''
                        }
                        value={tark_estlam_sak_alanqath}
                        onChange={handleEstlamSakAlanqathDate}
                        minDate='1937-03-14'
                        maxDate='2076-11-26'
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={2} className={classes.contentTitle}>
                    <Typography variant='body2'>رقم صك الانقاض</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      margin='normal'
                      //   disabled={edit}
                      value={raqam_sak_alanqath}
                      variant='outlined'
                      id='raqam_sak_alanqath'
                      size='small'
                      onChange={(e) => onChange(e)}
                      name='raqam_sak_alanqath'
                    />
                  </Grid>
                  <Grid item xs={2} className={classes.contentTitle}>
                    <Typography variant='body2'>تاريخه</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <MuiPickersUtilsProvider utils={HijriUtils} locale='ar-SA'>
                      <DatePicker
                        clearable
                        okLabel='موافق'
                        cancelLabel='الغاء'
                        fullWidth
                        clearLabel='مسح'
                        name='tark_sak_alanqath'
                        labelFunc={(date) =>
                          date ? date.format('iYYYY/iMM/iDD') : ''
                        }
                        value={tark_sak_alanqath}
                        onChange={handleSakAlanqathDate}
                        minDate='1937-03-14'
                        maxDate='2076-11-26'
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={2} className={classes.contentTitle}>
                    <Typography variant='body2'>ملاحظات للمتابعة</Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <TextField
                      fullWidth
                      margin='normal'
                      value={molahthat_almotaba}
                      variant='outlined'
                      id='molahthat_almotaba'
                      size='small'
                      onChange={(e) => onChange(e)}
                      name='molahthat_almotaba'
                    />
                  </Grid>
                  <Button
                    type='submit'
                    fullWidth
                    variant='contained'
                    color='primary'
                  >
                    حفظ
                  </Button>
                </Grid>
              </form>
            </Card>
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  aqar: state.aqar,
});
export default connect(mapStateToProps, {
  fetchAqarByAqarNumber,
  updateSakAlanqath,
})(SakAlAnqad);
