import { Button, Grid, NativeSelect, Typography } from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';

const initialState = {
  ID: '',
  in_to: '',
  type: '',
  person: '',
  to_do: '',
};

const ReffComponent = ({
  departments,
  setReff,
  disabled,
  inComing,
  updateObject,
  deleteReff,
}) => {
  const users = [
    { id: 902, name: 'يوسف طه فدعق' },
    { id: 903, name: 'اسامة زيد الجهني' },
    { id: 906, name: 'محمد منير كحيل' },
    { id: 910, name: 'نايف سنان العبدلي' },
    { id: 905, name: 'عبدالمجيد منسي العبدلي' },
    { id: 924, name: 'محمد زهير الشيبي' },
    { id: 925, name: 'عبدالرحمن وليد سلامة' },
    { id: 922, name: 'قحطان سالمين باجعيفر' },
    { id: 930, name: 'هنادي عبدالحفيظ الزبيدي' },
    { id: 921, name: 'رائد احمد شاكر' },
  ];
  function order(a, b) {
    return a.id < b.id ? -1 : a.id > b.id ? 1 : 0;
  }
  const sortedArray = users.sort(order);
  const departmetOptions = departments.map((ar) => (
    <option key={ar.id} value={ar.name}>
      {ar.name}
    </option>
  ));

  const usersOptions = sortedArray.map((ar) => (
    <option key={ar.id} value={ar.name}>
      {ar.name}
    </option>
  ));
  const [refObject, setReffObject] = useState({
    ID: '',
    in_to: '',
    type: '',
    person: '',
    to_do: '',
  });
  useEffect(() => {
    if (inComing && departments.length > 0) {
      const recoardData = { ...initialState };
      for (const key in inComing) {
        if (key in recoardData) recoardData[key] = inComing[key];

        // } else {
        //   recoardData["id"] = inComing[""];
        // }
      }
      setReffObject(recoardData);
    }
    if (refObject.in_to !== '') {
      updateObject(refObject);
    }
  }, [inComing, departments]);

  const { in_to, type, person, to_do, ID } = refObject;
  const onChange = (e, ID) => {
    updateObject(refObject.ID, e.target.name, e.target.value);
  };
  return (
    <Fragment>
      <Grid item xs={12} style={{ display: 'flex' }}>
        {/* الاحالة */}
        <Grid item xs={3}>
          <Typography variant='body2'>محالة إلى</Typography>
          <NativeSelect
            style={{
              margin: 'auto',
              paddingTop: '5px',
              paddingBottom: '5px',
            }}
            onChange={onChange}
            fullWidth
            required
            disabled={disabled}
            value={inComing && departments.length > 0 ? in_to : ''}
            name='in_to'
            inputProps={{
              name: 'in_to',
              id: 'in_to',
            }}
          >
            <option></option>
            {departmetOptions}
          </NativeSelect>
        </Grid>
        <Grid item xs={1}>
          <Typography variant='body2'>النوع</Typography>
          <NativeSelect
            style={{
              margin: 'auto',
              paddingTop: '5px',
              paddingBottom: '5px',
            }}
            onChange={onChange}
            fullWidth
            required
            disabled={disabled}
            value={inComing && departments.length > 0 ? type : ''}
            name='type'
            inputProps={{
              name: 'type',
              id: 'type',
            }}
          >
            <option></option>
            <option value='أصل'>أصل</option>
            <option value='صورة'>صورة</option>
          </NativeSelect>
        </Grid>
        <Grid item xs={4}>
          <Typography variant='body2'>الموظف</Typography>
          <NativeSelect
            style={{
              margin: 'auto',
              paddingTop: '5px',
              paddingBottom: '5px',
            }}
            onChange={onChange}
            fullWidth
            // required
            value={inComing && departments.length > 0 ? person : ''}
            name='person'
            disabled={disabled}
            inputProps={{
              name: 'person',
              id: 'person',
            }}
          >
            <option></option>
            {usersOptions}
          </NativeSelect>
        </Grid>
        <Grid item xs={3}>
          <Typography variant='body2'>الإجراء</Typography>
          <NativeSelect
            style={{
              margin: 'auto',
              paddingTop: '5px',
              paddingBottom: '5px',
            }}
            onChange={onChange}
            fullWidth
            required
            disabled={disabled}
            value={inComing && departments.length > 0 ? to_do : ''}
            name='to_do'
            inputProps={{
              name: 'to_do',
              id: 'to_do',
            }}
          >
            <option value=''></option>
            <option value='للإفادة'>للإفادة</option>
            <option value='الإحاطة'>الإحاطة</option>
            <option value='للحفظ'>للحفظ</option>
            <option value='للإكمال الازم'>للإكمال الازم</option>
            <option value='صورة للإيميل لمكتبنا'>صورة للإيميل لمكتبنا</option>
            <option value='للمفاهمة'>للمفاهمة</option>
            <option value='للمتابعة'>للمتابعة</option>
            <option value='للتنسيق'>للتنسيق</option>
            <option value='للإعتماد والتنفيذ'>للإعتماد والتنفيذ</option>
            <option value='للدراسة وإبداء الرأي'>للدراسة وإبداء الرأي</option>
            <option value='للكل'>للكل</option>
          </NativeSelect>
        </Grid>
        <Grid item xs={1}>
          {inComing ? (
            <Button disabled={ID === '0302'} onClick={() => deleteReff(ID)}>
              حذف
            </Button>
          ) : null}
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default ReffComponent;
