import {
  Button,
  Card,
  CircularProgress,
  Grid,
  Menu,
  MenuItem,
  NativeSelect,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@material-ui/core';
import { Link, Redirect } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import {
  SDA,
  SDAB,
  SDAR,
  SDARR,
  SDD,
  SDET,
  SDF,
  SDH,
  SDP,
  SDSA,
  SDT,
} from '../../constant/GlobelConsts';

import MuiAlert from '@material-ui/lab/Alert';
import { connect } from 'react-redux';
import { logout } from '../../actions/auth';
import { useParams } from 'react-router-dom';

// const logo = require('../../static/images/logo.png');

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}
function MuiAlertt(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}
const initialState = {
  motnaza_alih: '',
  tarek_alwkala: '',
  Merged: '',
  MergedTo: '',
};
const ContentHeader = ({
  children,
  auth: { loading, user },
  aqar: { data },
  logout,
  title,
}) => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    console.log('newValue', newValue);
    setValue(newValue);
  };
  const { id } = useParams();
  useEffect(() => {
    if (
      window.location.pathname === `/${id}/personal_informations` &&
      value !== 0
    ) {
      setValue(0);
    } else if (
      window.location.pathname === `/${id}/proprty_details` &&
      value !== 1
    ) {
      setValue(1);
    } else if (window.location.pathname === `/${id}/aqarDocs` && value !== 2) {
      setValue(2);
    } else if (window.location.pathname === `/${id}/archives` && value !== 3) {
      setValue(3);
    } else if (window.location.pathname === `/${id}/follow_up` && value !== 4) {
      setValue(4);
    } else if (window.location.pathname === `/${id}/merge` && value !== 5) {
      setValue(5);
    } else if (window.location.pathname === `/${id}/recoards` && value !== 6) {
      setValue(6);
    }
    //  else if (window.location.pathname === `/${id}/follow_up` && value !== 2) {
    //   setValue(2);
    // } else if (window.location.pathname === `/${id}/alhaser` && value !== 2) {
    //   setValue(3);
    // }
  }, [value, id]);
  useEffect(() => {
    {
      const aqarData = { ...initialState };
      for (const key in data) {
        if (key in aqarData) aqarData[key] = data[key];
      }
      setFormData(aqarData);
    }
  }, []);
  const [formData, setFormData] = useState({
    motnaza_alih: '',
    tarek_alwkala: null,
    Merged: '',
    MergedTo: '',
  });
  const { motnaza_alih, tarek_alwkala } = formData;
  function createData(label, to) {
    return { label, to };
  }
  const dataEntry = [
    createData(SDP, `/${id}/personal_informations`),
    createData(SDA, `/${id}/proprty_details`),
    createData(SDF, `/${id}/follow_up`),
    createData(SDT, `/${id}/tathmeen`),
    createData(SDET, `/${id}/priceRevision`),
    createData(SDH, `/${id}/alhaser`),
    data && data.noa_almilkia === 'وثيقة'
      ? createData(SDSA, `/${id}/SakAlanqath`)
      : null,
    createData(SDAB, `/${id}/altLands`),
    createData(SDD, `/${id}/aqarDocs`),
    createData(SDAR, `/${id}/archives`),
    createData(SDARR, `/${id}/recoards`),
  ];
  const compaerTarekAlwakala = () => {
    const d1 = new Date();
    if (tarek_alwkala) {
      if (d1 >= new Date(tarek_alwkala)) {
        return true;
      }
    }
  };
  const dataEntryTabs = ( // الاراضي والممتلكات - مدخل البيانات
    <Tabs
      orientation='vertical'
      // value={value}
      onChange={handleChange}
      aria-label='Vertical tabs example'
      // className={classes.tabs}
      indicatorColor='primary'
    >
      {dataEntry.map((item) => (
        <Tab
          label={item.label}
          key={item.to}
          {...a11yProps(0)}
          component={Link}
          to={item.to}
        />
      ))}
    </Tabs>
  );
  return <div>ContentHeader</div>;
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  aqar: state.aqar,
});
export default connect(mapStateToProps, { logout })(ContentHeader);
