import { Button, Typography } from '@material-ui/core';
import React, { Fragment, useEffect } from 'react';
import {
  downloadFullReport,
  downloadHaserReport,
  downloadRCMCFullReport,
  downloadRCMCGISReport,
  downloadRCMCRegestrationReport,
} from '../../../../actions/printDocs';

import AccessTime from '@material-ui/icons/Info';
import Card from '../../../Card/Card';
import CardFooter from '../../../Card/CardFooter';
import CardHeader from '../../../Card/CardHeader';
import CardIcon from '../../../Card/CardIcon';
import Spinner from '../../Spinner';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import styles from '../../../../assets/jss/material-dashboard-react/views/dashboardStyle';

const ButtonCardCompoent = ({
  color,
  icon,
  compoentTitle,
  data,
  isLoading: { isLoading },
  downloadFullReport,
  downloadHaserReport,
  downloadRCMCFullReport,
  downloadRCMCGISReport,
  downloadRCMCRegestrationReport,
  description,
  reportNumber,
}) => {
  const useStyles = makeStyles(styles);
  const downloadFile = () => {
    switch (reportNumber) {
      case 1:
        downloadFullReport();
        break;
      case 2:
        // console.log('downloadHaserReport');
        downloadHaserReport();
        break;
      case 3:
        // downloadFullReport();
        downloadRCMCFullReport();
        break;
      case 4:
        downloadRCMCGISReport();
        break;
      case 5:
        downloadRCMCRegestrationReport();
        break;
    }
    // switch reportNumber {
    //   case 2 :
    //     console.log("2")
    // }
    // if reportNumber === 2 {
    //   downloadHaserReport();
    // } else {
    //   downloadFullReport();
    // }
  };
  const classes = useStyles();
  function renderComponent() {
    return (
      <Card>
        <CardHeader color={color} stats icon>
          <CardIcon color={color}>{icon}</CardIcon>
          <Typography variant='h5' className={classes.cardCategory}>
            {compoentTitle}
          </Typography>
          <div style={{ display: 'flex', paddingTop: 20 }}>
            {isLoading ? (
              <>
                <Spinner />
              </>
            ) : (
              <Button
                variant='contained'
                onClick={downloadFile}
                color='primary'
                fullWidth
                disabled={isLoading}
              >
                تحميل
              </Button>
            )}
          </div>
        </CardHeader>
        <CardFooter stats>
          <div className={classes.stats}>
            <AccessTime />
            <Typography variant='caption'>{description}</Typography>
          </div>
        </CardFooter>
      </Card>
    );
  }
  return renderComponent();
};
const mapStateToProps = (state) => ({
  isLoading: state.isLoading,
});
export default connect(mapStateToProps, {
  downloadFullReport,
  downloadHaserReport,
  downloadRCMCFullReport,
  downloadRCMCGISReport,
  downloadRCMCRegestrationReport,
})(ButtonCardCompoent);
