import AccessTime from '@material-ui/icons/AccessTime';
import { Bar } from 'react-chartjs-2';
import Card from '../../../Card/Card';
import CardBody from '../../../Card/CardBody';
import CardFooter from '../../../Card/CardFooter';
import CardHeader from '../../../Card/CardHeader';
import React from 'react';
import Spinner from '../../Spinner';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styles from '../../../../assets/jss/material-dashboard-react/views/dashboardStyle';
require('./RoundedBars.js');

const BarChart = ({ data, color, labels, title }) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  function renderComponent() {
    if (!data) {
      return (
        <Card>
          <CardHeader>
            <Spinner />
          </CardHeader>
        </Card>
      );
    } else {
      return (
        <Card chart>
          {/* color={color} */}
          <CardHeader>
            <Bar
              data={{
                labels: labels,
                datasets: data.datasets,
              }}
              options={{
                // maintainAspectRatio: false,
                cornerRadius: 8,
                scales: {
                  xAxes: [
                    {
                      barPercentage: 0.3,
                    },
                  ],
                  yAxes: [
                    {
                      ticks: {
                        beginAtZero: true,
                      },
                    },
                  ],
                },
              }}
            />
          </CardHeader>
          <CardBody>
            <h4 className={classes.cardTitle}>{title}</h4>
            <p className={classes.cardCategory}></p>
          </CardBody>
          <CardFooter chart>
            <div className={classes.stats}>
              <AccessTime />
              <Typography variant='caption'>
                آخر تحديث في : {data.date}
              </Typography>
            </div>
          </CardFooter>
        </Card>
      );
    }
  }
  return renderComponent();
};
export default BarChart;
