import { ERROR_FETCH_AQAR_PRICES, FETCH_AQAR_PRICES } from '../actions/types';
const initialState = {
  loading: true,
  aqarPrices: null,
};
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_AQAR_PRICES:
      return { ...state, aqarPrices: payload, loading: false };
    case ERROR_FETCH_AQAR_PRICES:
      return { ...state, aqarPrices: null, loading: true };
    default:
      return state;
  }
}
