import { Doughnut } from 'react-chartjs-2';
import React from 'react';
const DounatChart = ({ data }) => {
  const labels = data.array.map((it) => it.key);
  const values = data.array.map((it) => it.value);
  const colors = data.array.map((it) => it.color);
  var total = 0;
  values.forEach((t) => {
    total += t;
  });
  return (
    <div style={{ width: '50%' }}>
      <Doughnut
        data={{
          maintainAspectRatio: false,
          responsive: false,
          labels: labels,
          datasets: [
            {
              data: values,
              backgroundColor: colors,
              //   hoverBackgroundColor: chartColors,
            },
          ],
        }}
        options={{
          legend: {
            display: false,
            position: 'right',
          },
          elements: {
            arc: {
              borderWidth: 1,
            },
          },
          tooltips: {
            callbacks: {
              label: function (tooltipItem, data) {
                var label = data.labels[tooltipItem.index] || '';
                var value = data.datasets[0].data[tooltipItem.index] || '';
                if (label) {
                  label += ': ';
                }
                label += `${((value * 100) / total).toFixed(0)} %`;
                return label;
              },
            },
          },
        }}
      />
    </div>
  );
};
export default DounatChart;
