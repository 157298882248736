import {  Button,
  CircularProgress,
  Grid,
  NativeSelect,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import React, { useEffect, useState } from 'react';
import {
  fetIncomingReffs,
  fetchLinkedAqarat,
  fetchLinkedRecaords,
  fetchNewIncomingNumber,
  fetchReacordByNumber,
  fetchRecoardFiels,
  saveNewInComingRecoard,
  updateInComing,
} from '../../../actions/inComing';
import { printRecivefDoc, printReffDoc } from '../../../actions/printDocs';

import ComboBox2 from '../../Card/ComboBox2';
import ComboBox4 from '../../Card/ComboBox4';
import DateFnsUtils from '@date-io/date-fns';
import ReffComponent from './ReffCompoenent';
import UploadedFileReview from './UploadedFileReview';
import { connect } from 'react-redux';
import { fetchDepartment } from '../../../actions/department';
import { makeStyles } from '@material-ui/core/styles';
import { v4 as uuidV4 } from 'uuid';

const initialState = {
  Regestration_Number: '',
  Regestration_Number_au: '',
  Type: 'وارد',
  Subject: '',
  Status: '',
  Date_G: null,
  Came_from: '',
  Attatchments: '',
  out_to: '',
  to_do: '',
  note: '',
  person: '',
  linkedToAqar: [],
  linkedToOutGoing: [],
  out_number_from: '',
  routed_Date: null,
  tayseer_number: '',
};
const iniialReffState = {
  reffs: [],
};
const Incoming = ({
  departments,
  printReffDoc,
  printRecivefDoc,
  isLoading,
  fetchDepartment,
  fetchNewIncomingNumber,
  fetchLinkedAqarat,
  fetchLinkedRecaords,
  fetchRecoardFiels,
  updateInComing,
  newNum,
  auth: { user },
  newNumLoading,
  fetchReacordByNumber,
  fetIncomingReffs,
  inComing: { data, loading, recReff, docments },
  linkedAqarat: { linkedAqarat },
  linkedRecoards: { linkedRecoards },
  saveNewInComingRecoard,
  match,
  history,
}) => {
  const { params } = match;
  const { recoardId, companyName } = params;
  const [enableEditeing, setEnableEditeing] = useState(true);
  useEffect(() => {
    if (user) {
      switch (user.department) {
        case '7':
          setEnableEditeing(false);
          return;
        default:
          break;
      }
    }
  }, [setEnableEditeing]);
  useEffect(() => {
    if (departments.length === 0) fetchDepartment(1);
  }, [departments]);
  // useEffect(() => {
  //   if (recoardId) fetchLinkedAqarat(recoardId, companyName);
  //   if (linkedAqarat) {
  //     setFormData({ ...formData, linkedToAqar: linkedAqarat });
  //   }
  // }, [fetchLinkedAqarat, newNumLoading]);
  // useEffect(() => {
  //   if (recoardId) fetchLinkedRecaords(recoardId, companyName);
  //   if (linkedRecoards >= 0) {
  //     console.log('linkedRecoards', linkedRecoards);
  //     setFormData({ ...formData, linkedToOutGoing: linkedRecoards });
  //   }
  // }, [fetchLinkedRecaords, newNumLoading]);

  useEffect(() => {
    if (recoardId) {
      fetIncomingReffs(recoardId, companyName);
      var reffsData = { ...iniialReffState };
      if (recReff) {
        reffsData = recReff;
        setRecReff(reffsData);
      }
    }
  }, [loading, data]);
  useEffect(() => {
    console.log('fetchRecoardFiels');
    if (!docments) fetchRecoardFiels(recoardId, companyName);
  }, [fetchRecoardFiels, docments, recoardId]);

  useEffect(() => {
    if (!recoardId) {
      fetchNewIncomingNumber(companyName);
      if (newNum && !newNumLoading) {
        const recoardData = { ...initialState };
        recoardData['Regestration_Number_au'] =
          newNum['Regestration_Number_au'];
        recoardData['Regestration_Number'] = newNum['Regestration_Number'];
        setFormData(recoardData);
      }
    }
  }, [newNumLoading]);
  useEffect(() => {
    if (recoardId) {
      fetchReacordByNumber(recoardId, companyName);
      const recoardData = { ...initialState };
      if (data) {
        for (const key in data) {
          if (key in recoardData) recoardData[key] = data[key];
        }
        setFormData(recoardData);
      }
    }
  }, [loading]);
  const useStyles = makeStyles((theme) => ({
    root: {
      textAlign: 'center',
      padding: '20px',
      paddingLeft: '100px',
      paddingRight: '100px',
    },
    paper: {
      margin: '10px',
      padding: '10px 10px 20px',
    },
    table: {
      minWidth: 275,
      textAlign: 'center',
      padding: '15px',
      borderWidth: 1,
      borderStyle: 'solid',
      color: 'white',
    },
    contentTitle: {
      margin: 'auto',
      textAlign: 'center',
      //   backgroundColor: 'black',
    },
    buttonProgress: {
      // color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    container: {
      padding: '10px',
    },
    containerItem: {
      display: 'flex',
      textAlign: 'center',
      padding: '5px',
    },
    btnsContainer: {
      paddingTop: '20px',
    },
  }));
  const [reffsState, setRecReff] = useState([]);
  const [formData, setFormData] = useState({
    Regestration_Number: '',
    Regestration_Number_au: '',
    Type: 'وارد',
    Subject: '',
    Status: '',
    Date_G: '',
    Came_from: '',
    out_to: '',
    note: '',
    Attatchments: '',
    person: '',
    to_do: '',
    linkedToAqar: [],
    linkedToOutGoing: [],
    out_number_from: '',
    routed_Date: '',
    tayseer_number: '',
  });
  const classes = useStyles();
  const statusArray = [
    <option key={'مفتوح'} value={'مفتوح'}>
      مفتوح
    </option>,
    <option key={'مغلق'} value={'مغلق'}>
      مغلق
    </option>,
    <option key={'منتهي'} value={'منتهي'}>
      منتهي
    </option>,
    <option key={'معلق'} value={'معلق'}>
      معلق
    </option>,
  ];
  const [files, setFiles] = useState([]);
  const {
    Regestration_Number,
    Type,
    Subject,
    Status,
    Date_G,
    note,
    Attatchments,
    Came_from,
    Regestration_Number_au,
    to_do,
    linkedToAqar,
    linkedToOutGoing,
    out_number_from,
    routed_Date,
    tayseer_number,
  } = formData;
  // const { reffs } = reffsState;
  const seletcFile = ({ target }) => {
    const fils = target.files;
    var result = Object.keys(fils).map((key) => [fils[key]]);
    const ff = result.map((file) => ({ name: 'LTR-', value: file[0] }));
    setFiles(ff);
  };
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const updateFileName = (file) => {
    setFiles([
      ...files.filter((fi) => fi.value.name !== file.value.name),
      file,
    ]);
  };

  const insertNewDepartmentReff = () => {
    const id = uuidV4();
    setRecReff((curentFormData) => [...curentFormData, { ID: id }]);
  };
  const updateReffObject = (id, name, value) => {
    const refObject = reffsState.find((x) => x.ID === id);
    const changedItem = { ...refObject, [name]: value };
    let array = [];
    reffsState.forEach((it) => {
      if (it.ID === changedItem.ID) {
        array.push(changedItem);
      } else {
        array.push(it);
      }
    });
    if (name === 'in_to' && value === '') {
    }
    setRecReff([...array]);
  };
  const deleteReff = (id) => {
    if (reffsState.length > 0) {
      const filterDepReff = reffsState.filter((item) => item.id !== id);
      setFormData((curFormData) => ({
        ...curFormData,
        reffs: filterDepReff,
      }));
      // setReff(filterReff);
    }
  };
  const saveHandler = () => {
    var bodyFormData = new FormData();

    const mapArray = formData.linkedToOutGoing.map((it) => {
      return it.Regestration_Number;
    });
    bodyFormData.append('linkedToOutGoing', JSON.stringify(mapArray));
    const mapAqarArray = formData.linkedToAqar.map((it) => {
      return it.aqar_number;
    });
    bodyFormData.append('linkedToAqar', JSON.stringify(mapAqarArray));
    bodyFormData.append(`departmentReff`, JSON.stringify(reffsState));
    if (!Status) {
      formData.Status = 'مفتوح';
    }
    if (!Date_G) {
      formData.Date_G = selectedDate;
    }
    if (!routed_Date) {
      formData.routed_Date = cameFromDate;
    }
    // reffsState.forEach((fi, index) => {
    //   bodyFormData.append(`departmentReff`, JSON.stringify(fi));
    // });
    files.forEach((fi, index) => {
      bodyFormData.append(
        `${companyName}-${Regestration_Number}-${fi.name}0${index}`,
        fi.value
      );
    });

    bodyFormData.append('Regestration_Number', formData.Regestration_Number);
    bodyFormData.append('Type', formData.Type);
    bodyFormData.append('Subject', formData.Subject);
    bodyFormData.append('Status', formData.Status);
    bodyFormData.append('out_number_from', formData.out_number_from);
    bodyFormData.append('tayseer_number', formData.tayseer_number);
    bodyFormData.append(
      'Regestration_Number_au',
      formData.Regestration_Number_au
    );
    bodyFormData.append(
      'Date_G',
      require('moment')(Date_G).format('YYYY-MM-DD HH:mm:ss')
    );
    bodyFormData.append(
      'routed_Date',
      require('moment')(routed_Date).format('YYYY-MM-DD HH:mm:ss')
    );
    bodyFormData.append('Came_from', formData.Came_from);
    // bodyFormData.append("out_to", formData.out_to);
    bodyFormData.append('note', formData.note);
    bodyFormData.append(
      'Attatchments',
      formData.Attatchments ? formData.Attatchments : 'بدون'
    );
    // bodyFormData.append('to_do', formData.to_do);
    bodyFormData.append('companyName', companyName);
    if (recoardId) {
      console.log('updateInComing');
      updateInComing(companyName, Regestration_Number, bodyFormData);
    } else {
      saveNewInComingRecoard(
        companyName,
        Regestration_Number,
        bodyFormData,
        history
      );
    }
  };
  const updateOutGoingLinked = (newValue) => {
    setFormData({ ...formData, linkedToAqar: newValue });
  };
  const handleOutGoingDateChange = (date) => {
    setCameFromDate(date);
    setFormData({ ...formData, routed_Date: date });
  };
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [cameFromDate, setCameFromDate] = useState(new Date());
  const handleDateChange = (date) => {
    setSelectedDate(date);
    setFormData({ ...formData, Date_G: date });
  };
  const updateInComingLinkedToOutGoing = (newValue) => {
    setFormData({ ...formData, linkedToOutGoing: newValue });
  };
  const printReffDocHandler = () => {
    printReffDoc(companyName, Regestration_Number);
  };
  const printRecivefDocHandler = () => {
    printRecivefDoc(companyName, Regestration_Number);
  };
  return (
    <MuiPickersUtilsProvider
      utils={DateFnsUtils}
      style={{ display: 'contents' }}
    >
      <Grid container className={classes.root}>
        <form style={{ display: 'contents' }}>
          <Grid item xs={12}>
            <Typography variant='h5'>بيانات المعاملة الواردة </Typography>
          </Grid>
          <Grid item xs={2} className={classes.contentTitle}>
            <Typography variant='body2'>الرقم التسلسلي</Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              name='Regestration_Number_au'
              margin='normal'
              value={Regestration_Number_au}
              disabled={true}
              // label="رقم القيد"
              variant='outlined'
              id='Regestration_Number_au'
              size='small'
            />
          </Grid>
          <Grid item xs={2} className={classes.contentTitle}>
            <Typography variant='body2'>رقم القيد</Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              key='Regestration_Number'
              name='Regestration_Number'
              fullWidth
              margin='normal'
              value={Regestration_Number}
              disabled={true}
              // label="رقم القيد"
              variant='outlined'
              id='Regestration_Number'
              size='small'
            />
          </Grid>
          <Grid item xs={2} className={classes.contentTitle}>
            <Typography variant='body2'>الموضوع</Typography>
          </Grid>
          <Grid item xs={10}>
            <TextField
              fullWidth
              name='Subject'
              title='الموضوع'
              disabled={enableEditeing}
              margin='normal'
              value={Subject}
              onChange={onChange}
              // label="رقم القيد"
              variant='outlined'
              id='Subject'
              size='small'
            />
          </Grid>
          <Grid item xs={2} className={classes.contentTitle}>
            <Typography variant='body2'>النوع</Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              title='name'
              fullWidth
              name='Type'
              disabled={enableEditeing}
              value={Type}
              margin='normal'
              // value={type}
              disabled={true}
              // label="رقم القيد"
              variant='outlined'
              id='Type'
              size='small'
            />
          </Grid>
          <Grid item xs={2} className={classes.contentTitle}>
            <Typography variant='body2'>تاريخ المعاملة</Typography>
          </Grid>
          <Grid item xs={4}>
            <KeyboardDatePicker
              disableToolbar
              style={{
                margin: 'auto',
                paddingTop: '5px',
                paddingBottom: '5px',
              }}
              disabled={enableEditeing}
              variant='inline'
              format='MM/dd/yyyy'
              margin='normal'
              title='تاريخ المعاملة'
              id='date-picker-inline'
              label='تاريخ المعاملة'
              value={Date_G ? Date_G : new Date()}
              name='Date_G'
              fullWidth
              onChange={handleDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </Grid>
          <Grid item xs={2} className={classes.contentTitle}>
            <Typography variant='body2'>الحالة</Typography>
          </Grid>
          <Grid item xs={4}>
            <NativeSelect
              style={{
                margin: 'auto',
                paddingTop: '5px',
                paddingBottom: '5px',
              }}
              disabled={enableEditeing}
              onChange={onChange}
              fullWidth
              required
              value={Status ? Status : ''}
              name='Status'
              key='Status'
              title='حالة المعاملة'
              inputProps={{
                id: 'Status',
              }}
            >
              {/* {Status ? <option value={Status}>{Status}</option> : null} */}
              {statusArray}
            </NativeSelect>
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={2} className={classes.contentTitle}>
            <Typography variant='body2'>ربط برقم عقار</Typography>
          </Grid>
          <Grid item xs={4} style={{ paddingTop: '20px' }}>
            <ComboBox4
              name={'linkedToOutGoing'}
              title={'ربط برقم عقار'}
              value={linkedToAqar}
              defVal={linkedToAqar}
              disabled={enableEditeing}
              companyName={companyName}
              updateOutGoingLinked={updateOutGoingLinked}
            />
          </Grid>
          <Grid item xs={2} className={classes.contentTitle}>
            <Typography variant='body2'>ربط برقم صادر</Typography>
          </Grid>
          <Grid item xs={4} style={{ paddingTop: '20px' }}>
            <ComboBox2
              name={'linkedToAqar'}
              value={linkedToOutGoing}
              defVal={linkedToOutGoing}
              disabled={enableEditeing}
              companyName={companyName}
              tableName={'out_going'}
              updateOutGoingLinked={updateInComingLinkedToOutGoing}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h5'>بيانات الجهة المصدرة </Typography>
          </Grid>
          <Grid item xs={2} className={classes.contentTitle}>
            <Typography variant='body2'>رقم الصادر</Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              margin='normal'
              value={out_number_from}
              name='out_number_from'
              onChange={onChange}
              disabled={enableEditeing}
              // label="رقم القيد"
              variant='outlined'
              id='out_number_from'
              size='small'
            />
          </Grid>
          <Grid item xs={2} className={classes.contentTitle}>
            <Typography variant='body2'>تاريخ المعاملة</Typography>
          </Grid>
          <Grid item xs={4}>
            <KeyboardDatePicker
              disableToolbar
              style={{
                margin: 'auto',
                paddingTop: '5px',
                paddingBottom: '5px',
              }}
              disabled={enableEditeing}
              variant='inline'
              format='MM/dd/yyyy'
              margin='normal'
              title='تاريخ المعاملة'
              id='date-picker-inline'
              label='تاريخ المعاملة'
              value={routed_Date ? routed_Date : new Date()}
              name='routed_Date'
              fullWidth
              onChange={handleOutGoingDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </Grid>
          <Grid item xs={2} className={classes.contentTitle}>
            <Typography variant='body2'>الجهة المصدرة</Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              title=''
              fullWidth
              disabled={enableEditeing}
              margin='normal'
              name='Came_from'
              value={Came_from}
              // disabled={true}
              onChange={onChange}
              // label="رقم القيد"
              variant='outlined'
              id='name'
              size='small'
            />
          </Grid>
          <Grid item xs={2} className={classes.contentTitle}>
            <Typography variant='body2'>المرفقات</Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              margin='normal'
              disabled={enableEditeing}
              value={Attatchments}
              name='Attatchments'
              onChange={onChange}
              variant='outlined'
              id='name'
              size='small'
            />
          </Grid>
          <Grid item xs={2} className={classes.contentTitle}>
            <Typography variant='body2'>رقم تيسير</Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              title='tayseer_number'
              name='tayseer_number'
              fullWidth
              disabled={enableEditeing}
              onChange={onChange}
              value={tayseer_number}
              margin='normal'
              // label="رقم القيد"
              variant='outlined'
              id='tayseer_number'
              size='small'
            />
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={2} className={classes.contentTitle}>
            <Typography variant='body2'>ملاحظات</Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              margin='normal'
              value={note}
              disabled={enableEditeing}
              name='note'
              onChange={onChange}
              variant='outlined'
              id='name'
              size='small'
            />
          </Grid>
          <Grid item xs={2} className={classes.contentTitle}>
            <Typography variant='body2'>التوجية</Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              title='name'
              fullWidth
              disabled={enableEditeing}
              margin='normal'
              value={to_do}
              // label="رقم القيد"
              onChange={onChange}
              name='to_do'
              variant='outlined'
              id='name'
              size='small'
            />
          </Grid>
          {/* الإحالة */}
          <Grid item xs={10}>
            <Typography variant='h5'>الإحالة</Typography>
          </Grid>
          <Grid item xs={2}>
            <Button
              variant='outlined'
              disabled={enableEditeing}
              onClick={insertNewDepartmentReff}
            >
              اضافة إدارة{' '}
            </Button>
          </Grid>
          {reffsState ? (
            <Grid container>
              {reffsState.map((re) => {
                return (
                  <ReffComponent
                    departments={departments}
                    id={re.in_to}
                    key={re.id}
                    disabled={enableEditeing}
                    inComing={re}
                    setReff={insertNewDepartmentReff}
                    // reff={reff}
                    updateObject={updateReffObject}
                    deleteReff={deleteReff}
                  />
                );
              })}
            </Grid>
          ) : null}
        </form>
        <Grid container>
          {files
            ? files.map((fi, index) => (
                <UploadedFileReview
                  key={index}
                  file={fi}
                  updateFileName={updateFileName}
                />
              ))
            : null}
        </Grid>
        <Grid container>
          {docments
            ? docments.map((doc) => (
                <Grid item xs={12}>
                  <object
                    width='100%'
                    height='400'
                    type='application/pdf'
                    data={doc.url}
                  >
                    <p>
                      Insert your error message here, if the PDF cannot be
                      displayed.
                    </p>
                  </object>
                </Grid>
              ))
            : null}
        </Grid>
        {enableEditeing ? null : (
          <Grid container className={classes.btnsContainer}>
            <Grid item xs={4}>
              <Button
                className={classes.button}
                variant='contained'
                color='primary'
                type='sumit'
                // disabled={enablePrintBtns}
                disabled={isLoading.isLoading}
                onClick={saveHandler}
              >
                {isLoading.isLoading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
                حفظ
              </Button>
            </Grid>
            <Grid item xs={2} className={classes.contentTitle}>
              <input
                // accept='pdf'
                className={classes.input}
                style={{ display: 'none' }}
                id='raised-button-file'
                onChange={seletcFile}
                multiple
                type='file'
              />
              <label htmlFor='raised-button-file'>
                <Button
                  variant='contained'
                  color='primary'
                  component='span'
                  className={classes.button}
                >
                  رفع ملفات
                </Button>
              </label>
            </Grid>
            <Grid item xs={2}>
              <Button
                className={classes.button}
                variant='contained'
                color='primary'
                disabled={isLoading.isLoading}
                // disabled={recoardId ? false : true}
                onClick={printRecivefDocHandler}
              >
                {isLoading.isLoading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
                طباعة نموذج التسليم
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                className={classes.button}
                variant='contained'
                color='primary'
                disabled={isLoading.isLoading}
                // disabled={recoardId ? false : true}
                onClick={printReffDocHandler}
              >
                {isLoading.isLoading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
                طباعة نموذج احالة
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    </MuiPickersUtilsProvider>
  );
};
const mapStateToProps = (state) => ({
  departments: state.departments.data,
  inComing: state.inComing,
  linkedAqarat: state.linkedAqart,
  linkedRecoards: state.linkedRecoards,
  newNum: state.newInComingNumber.newNum,
  newNumLoading: state.newInComingNumber.newNumLoading,
  isLoading: state.isLoading,
  auth: state.auth,
});
export default connect(mapStateToProps, {
  fetchDepartment,
  fetchNewIncomingNumber,
  saveNewInComingRecoard,
  updateInComing,
  printReffDoc,
  printRecivefDoc,
  fetchReacordByNumber,
  fetchLinkedAqarat,
  fetchLinkedRecaords,
  fetIncomingReffs,
  fetchRecoardFiels,
})(Incoming);
