export const SDP = 'البيانات الشخصية';
export const SDA = 'بيانات العقار';
export const SDF = 'المتابعة';
export const SDT = 'التثمين';
export const SDET = 'إعادة التثمين';
export const SDH = 'الحصر';
export const SDSA = 'صك الانقاض';
export const SDAB = 'الارض البديلة';
export const SDD = 'الخطابات';
export const SDAR = 'الارشيف';
export const SDARR = 'سجل المعاملات';

export const SideBar_OwnerInfo = 'البيانات الشخصية';
export const SideBar_AqarInfo = 'بيانات العقار';
export const SideBar_FollowUp = 'المتابعة';
export const SideBar_Alhaser = 'الحصر';
export const SideBar_Altathmen = 'التثمين';
export const SideBar_SakkAlAnqath = 'صك الانقاض';
export const SideBar_AlarthAlbdela = 'الارض البديلة';
export const SideBar_Alketabat = 'الخطابات';
export const SideBar_Elarchive = 'الارشيف';
export const SideBar_SjelAlmoamlat = 'سجل المعاملات';
export const SideBar_Kroki = 'مراجعة الكروكي المعتمد';
export const SidBar_Aqarat = 'العقارات';
export const SidBar_Categories = 'التصنيفات';
export const SidBar_Users = 'المستخدمين';
export const SidBar_Departments = 'الإدارات';
export const SidBar_megreAqar = 'دمج';
export const AppBar_Home = 'الرئيسية';
export const AppBar_Status = 'الاحصائيات';
export const SidBar_RejesterCoordanite = 'ادخال الاحداثيات';
export const AppBar_Logout = 'تسجيل خروج';
export const SidBar_Coord = 'احداثيات العقار';
