import {
  ERROR_FETCH_AQARAT_COUNT,
  ERROR_FETCH_HASER_STATUS,
  ERROR_FETCH_KROKI_STATUS,
  ERROR_FETCH_LOGS,
  ERROR_FETCH_NAKASAH_NOA_ALMILKIA,
  FETCH_AQARAT_COUNT,
  FETCH_HASER_STATUS,
  FETCH_KROKI_STATUS,
  FETCH_LOGS,
  FETCH_NAKASAH_NOA_ALMILKIA,
} from './types';

import api from '../utils/api';
import setAuthToken from '../utils/setAuthToken';

export const fetchLogs = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  try {
    const res = await api.get('status/getTheLastFiveOpr');
    dispatch({ type: FETCH_LOGS, payload: res.data });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_FETCH_LOGS,
    });
  }
};
export const fetchAqaratCount = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  try {
    const res = await api.get('status/aqaratCount');
    dispatch({ type: FETCH_AQARAT_COUNT, payload: res.data });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_FETCH_AQARAT_COUNT,
    });
  }
};

export const fetchHaserStauts = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  try {
    const res = await api.get('status/haserStatus');
    dispatch({ type: FETCH_HASER_STATUS, payload: res.data });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_FETCH_HASER_STATUS,
    });
  }
};

export const fetchNoaAlmilkia = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  try {
    const res = await api.get('status/noa_almilkia');
    dispatch({ type: FETCH_NAKASAH_NOA_ALMILKIA, payload: res.data });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_FETCH_NAKASAH_NOA_ALMILKIA,
    });
  }
};
export const fetchKrokiStatus = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  try {
    const res = await api.get('status/krokiStatus');
    dispatch({ type: FETCH_KROKI_STATUS, payload: res.data });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_FETCH_KROKI_STATUS,
    });
  }
};
