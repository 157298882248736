import {
  Button,
  Grid,
  NativeSelect,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';
import {
  createNewUser,
  fetchAccessRigh,
  fetchUserById,
  updateUser,
} from '../../../actions/users';

import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchDepartment } from '../../../actions/department';
import { makeStyles } from '@material-ui/core/styles';

const initialState = {
  name: '',
  email: '',
  phone_number: '',
  employee_id: '',
  // roll: "",
  roll: { group_name: '', id: '' },
  department: '',
  companyName: '',
  user_name: '',
};
const AddNewUser = ({
  fetchAccessRigh,
  updateUser,
  createNewUser,
  accessRight: { data, loading },
  departments,
  history,
  fetchUserById,
  fetchDepartment,
  user,
  match,
}) => {
  const { params } = match;
  const { userId } = params;
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    table: {
      minWidth: 650,
    },
    paper: {
      width: '100%',
      padding: '20px',
      marginBottom: theme.spacing(2),
    },
    title: {
      flex: '1 1 100%',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    contentTitle: {
      margin: 'auto',
      textAlign: 'center',
      //   backgroundColor: 'black',
    },
    buttonContainer: {
      textAlign: 'center',
    },
    button: {
      width: '150px',
    },
    flexDisplay: {
      display: 'flex',
      margin: 'auto',
    },
    textfield: {
      //   padding: '20px',
    },
  }));
  const classes = useStyles();
  const onSubmit = (e) => {
    e.preventDefault();
    console.log('SUCCESS');

    if (userId) {
      updateUser(userId, formData, history);
    } else {
      createNewUser(formData, history);
    }
    // updataeMalekInfo(data.aqar_number, formData, history);
  };
  useEffect(() => {
    if (departments.length === 0) {
      fetchDepartment(1);
    }
  }, [departments]);
  useEffect(() => {
    if (userId) {
      fetchUserById(userId);
      const userData = { ...initialState };
      for (const key in user.user) {
        console.log('d');
        if (key in userData) userData[key] = user.user[key];
      }
      setFormData(userData);
    }
  }, [user.loading]);
  useEffect(() => {
    if (user.user) {
      if (user.user.department) fetchAccessRigh(user.user.department);
    }
  }, [user.loading]);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone_number: '',
    employee_id: '',
    roll: { group_name: '', id: '' },
    department: '',
    companyName: '',
    user_name: '',
  });
  const {
    name,
    email,
    phone_number,
    employee_id,
    roll: { group_name, id },
    department,
    companyName,
    user_name,
  } = formData;
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    if (department || data.length == 0) {
      if (e.target.name === 'department') {
        fetchAccessRigh(e.target.value);
      }

      if (e.target.name === 'roll') {
        setFormData({
          ...formData,
          ['roll']: { id: e.target.value, department: department },
        });
      }
    }
  };
  const accessRightOptions = data.map((ar) => (
    <option key={ar.id} value={ar.id}>
      {ar.group_name}
    </option>
  ));
  const departmetOptions = departments.map((ar) => (
    <option key={ar.id} value={ar.id}>
      {ar.name}
    </option>
  ));
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container>
          <form style={{ display: 'contents' }} onSubmit={(e) => onSubmit(e)}>
            <Grid item xs={12}>
              <Typography
                className={classes.title}
                variant='h6'
                id='tableTitle'
                component='div'
                align='center'
              >
                {id ? 'تعديل بيانات المستخدم' : 'اضافة مستخدم جديد'}
              </Typography>
            </Grid>
            <Grid item xs={6} className={classes.flexDisplay}>
              <Grid item xs={3} className={classes.contentTitle}>
                <Typography variant='body2'>اسم الموظف</Typography>
              </Grid>
              <Grid item xs={9} className={classes.textfield}>
                <TextField
                  // title='name'
                  fullWidth
                  margin='normal'
                  required
                  value={name ? name : ''}
                  label='اسم الموظف'
                  variant='outlined'
                  size='small'
                  onChange={onChange}
                  name='name'
                />
              </Grid>
            </Grid>
            <Grid item xs={6} className={classes.flexDisplay}>
              <Grid item xs={3} className={classes.contentTitle}>
                <Typography variant='body2'>الرقم الوظيفي</Typography>
              </Grid>
              <Grid item xs={9} className={classes.textfield}>
                <TextField
                  title='name'
                  fullWidth
                  margin='normal'
                  required
                  value={employee_id ? employee_id : ''}
                  label='الرقم الوظيفي'
                  variant='outlined'
                  size='small'
                  onChange={onChange}
                  name='employee_id'
                />
              </Grid>
            </Grid>
            <Grid item xs={4} className={classes.flexDisplay}>
              <Grid item xs={3} className={classes.contentTitle}>
                <Typography variant='body2'>الادارة</Typography>
              </Grid>
              <Grid item xs={9} className={classes.textfield}>
                <NativeSelect
                  style={{ margin: 'auto' }}
                  onChange={onChange}
                  fullWidth
                  required
                  value={department ? department : ''}
                  name='department'
                  inputProps={{
                    id: 'department',
                  }}
                >
                  <option></option>
                  {departmetOptions}
                </NativeSelect>
              </Grid>
            </Grid>
            {department === '7' ? (
              <Grid item xs={8}></Grid>
            ) : (
              <Fragment>
                <Grid item xs={4} className={classes.flexDisplay}>
                  <Grid item xs={3} className={classes.contentTitle}>
                    <Typography variant='body2'>الصلاحيات</Typography>
                  </Grid>
                  <Grid item xs={9} className={classes.textfield}>
                    <NativeSelect
                      style={{ margin: 'auto' }}
                      onChange={onChange}
                      fullWidth
                      required
                      disabled={department === '' ? true : false}
                      value={id ? id : ''}
                      name='roll'
                      inputProps={{
                        id: 'roll',
                      }}
                    >
                      <option></option>
                      {accessRightOptions}
                    </NativeSelect>
                  </Grid>
                </Grid>
                <Grid item xs={4} className={classes.flexDisplay}>
                  <Grid item xs={3} className={classes.contentTitle}>
                    <Typography variant='body2'>المشروع</Typography>
                  </Grid>
                  <Grid item xs={9} className={classes.textfield}>
                    <NativeSelect
                      style={{ margin: 'auto' }}
                      onChange={onChange}
                      fullWidth
                      required
                      disabled={group_name === '' ? true : false}
                      value={companyName ? companyName : ''}
                      name='companyName'
                      inputProps={{
                        id: 'project',
                      }}
                    >
                      <option></option>
                      <option value='jas'>الكدوة</option>
                      <option value='widco'>النكاسة</option>
                    </NativeSelect>
                  </Grid>
                </Grid>
              </Fragment>
            )}

            <Grid item xs={12} className={classes.flexDisplay}>
              <Grid item xs={2} className={classes.contentTitle}>
                <Typography variant='body2'>رقم الجوال</Typography>
              </Grid>
              <Grid item xs={10} className={classes.textfield}>
                <TextField
                  title='name'
                  fullWidth
                  margin='normal'
                  required
                  value={phone_number ? phone_number : ''}
                  label='رقم الجوال'
                  variant='outlined'
                  size='small'
                  onChange={onChange}
                  name='phone_number'
                />
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.flexDisplay}>
              <Grid item xs={2} className={classes.contentTitle}>
                <Typography variant='body2'>البريد الالكتروني</Typography>
              </Grid>
              <Grid item xs={4} className={classes.textfield}>
                <TextField
                  title='name'
                  fullWidth
                  margin='normal'
                  required
                  value={email ? email : ''}
                  label='البريد الالكتروني'
                  variant='outlined'
                  size='small'
                  onChange={onChange}
                  name='email'
                />
              </Grid>
              <Grid item xs={2} className={classes.contentTitle}>
                <Typography variant='body2'>البريد الالكتروني</Typography>
              </Grid>
              <Grid item xs={4} className={classes.textfield}>
                <TextField
                  fullWidth
                  margin='normal'
                  required
                  value={user_name ? user_name : ''}
                  label='اسم المستخدم'
                  variant='outlined'
                  size='small'
                  onChange={onChange}
                  name='user_name'
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={6} className={classes.buttonContainer}>
                <Button
                  className={classes.button}
                  variant='contained'
                  type='submit'
                  color='primary'
                >
                  حفظ
                </Button>
              </Grid>
              <Grid item xs={6} className={classes.buttonContainer}>
                <Button
                  component={Link}
                  to='/'
                  className={classes.button}
                  variant='contained'
                  color='secondary'
                >
                  رجوع
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Paper>
    </div>
  );
};
const mapStateToProps = (state) => ({
  accessRight: state.constant.accessRight,
  user: state.user,
  departments: state.departments.data,
});
export default connect(mapStateToProps, {
  fetchAccessRigh,
  createNewUser,
  fetchUserById,
  fetchDepartment,
  updateUser,
})(AddNewUser);
