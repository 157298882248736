import React, { Fragment } from 'react';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { Grid } from '@material-ui/core';
import NativeSelect from '@material-ui/core/NativeSelect';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    paddingTop: '56.25%', // 16:9,
    marginTop: '30',
    aspectRatio: 135 / 76,
  },
});

const UploadedImagePreview = ({ file, onNameChange, key }) => {
  const cardClicked = () => {
    console.log('cardClicked', file);
  };
  const classes = useStyles();

  const options = [
    { value: 'DE', key: 'الصك / الوثيقة' },
    { value: 'ID', key: 'الهوية' },
    { value: 'RCMCPRF', key: 'نموذج تسجيل مستندات الملكيات' },
    { value: 'MHSR', key: 'مسودة محضر حصر المشتملات' },
    { value: 'MVAL', key: 'محضر تثمين العقار' },
    { value: 'FM', key: 'بيانات المالك' },
    { value: 'BL', key: 'فاتورة الكهرباء' },
    { value: 'HSH', key: 'نموذج اثبات تملك انقاض' },
    { value: 'KR', key: 'كروكي مساحي' },
    { value: 'FKR', key: 'كروكي مساحي نهائي' },
    { value: 'OVER', key: 'مخالصة' },
    { value: 'MFKR', key: 'مسودة كروكي مساحي نهائي' },
    { value: 'MSH', key: 'مشهد إنقاض ( للوثيقة )' },
    { value: 'HSR', key: 'حصر المشتملات' },
    { value: 'VAL', key: 'تبليغ التثمين' },
    { value: 'OPT', key: 'خيارات الملاك' },
    { value: 'WK', key: 'الوكالات' },
    { value: 'COM', key: 'شكوى المالك' },
    { value: 'DFMW', key: 'إحالة داخلية وادي إبراهيم' },
    { value: 'DEDM', key: 'صك انقاض' },
    { value: 'TAAD', key: 'تعهد بمراجعة المحكمة' },
    { value: 'STC', key: 'اخلاء طرف من الاتصالات' },
    { value: 'WAT', key: 'اخلاء طرف من شركة المياه الوطنية' },
    { value: 'SRV', key: 'اخلاء طرف من شركة الكهرباء' },
    { value: 'TAH', key: 'تعهد بتصفية عدادات الكهرباء والمياه واخلاء العقار' },
    { value: 'NAD', key: 'العنوان الوطني للمالك' },
    { value: 'VAP', key: 'صحيفة نزع الملكية' },
    { value: 'CH', key: 'شيك' },
    { value: 'CNT', key: 'بيان محتويات المستندات' },
    { value: 'OTHER', key: 'أخرى' },
    {
      value: 'KDN',
      key: 'مذكرة تسجيل الصك في الأمانة ( كروكي الصك الصادر من الأمانة )',
    },
    { value: 'TFR', key: 'كشف تفصيلي مالي' },
    { value: 'CON', key: 'العقد' },
    { value: 'DEH', key: 'صك حصر الورثة ' },
  ];
  const onChange = (e) => {
    const fi = { name: e.target.value, value: file.value };
    // setFiles([...filterdArray, fi]);
    onNameChange(fi);
    // //setFileName({ ...fileName, [e.target.name]: e.target.value });
  };
  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Card className={classes.root} onClick={cardClicked}>
        <CardActionArea>
          <CardMedia
            name='img'
            className={classes.media}
            image={URL.createObjectURL(file.value)}
            title={file.name}
          />
          <CardContent>
            <NativeSelect
              onChange={onChange}
              fullWidth
              key={file.name}
              value={file.name}
              name='fileRename'
              inputProps={{
                id: 'fileRename-menu',
              }}
            >
              {options.map((opt) => (
                <option key={opt.value} value={opt.value}>
                  {opt.key} - {opt.value}
                </option>
              ))}
            </NativeSelect>
            {/* <Typography variant='body2' color='textSecondary' component='p'>
              Lizards are a widespread group of squamate reptiles, with over
              6,000 species, ranging across all continents except Antarctica
            </Typography> */}
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};
export default UploadedImagePreview;
