import { Button, Grid, Paper, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import {
  createNewDepartment,
  fetchDepartmentById,
  updateDepartment,
} from '../../../actions/department';

import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
// import department from '../../../reducers/department';

const initialState = {
  name: '',
  des: '',
};
const AddNewDepartment = ({
  createNewDepartment,
  fetchDepartmentById,
  updateDepartment,
  history,
  department,
  match,
}) => {
  const { params } = match;
  const { id } = params;
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      padding: '20px',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    title: {
      flex: '1 1 100%',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    contentTitle: {
      margin: 'auto',
      textAlign: 'center',
      //   backgroundColor: 'black',
    },
    buttonContainer: {
      textAlign: 'center',
    },
    button: {
      width: '150px',
    },
    flexDisplay: {
      display: 'flex',
      margin: 'auto',
    },
    textfield: {
      //   padding: '20px',
    },
  }));
  const classes = useStyles();
  const onSubmit = (e) => {
    e.preventDefault();
    console.log('SUCCESS');
    if (id) {
      updateDepartment(id, formData, history);
    } else {
      console.log('fdvdfvfdv');
      createNewDepartment(formData, history);
    }
  };
  useEffect(() => {
    if (id) {
      console.log('fetch user data', id);
      fetchDepartmentById(id);
      const userData = { ...initialState };
      for (const key in department.department) {
        if (key in userData) userData[key] = department.department[key];
      }
      console.log('userData');
      setFormData(userData);
    }
  }, [id, department.loading, department.department]);
  const [formData, setFormData] = useState({
    name: '',
    des: '',
  });
  const { name, des } = formData;
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    // console.log(formData);
  };
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container>
          <form style={{ display: 'contents' }} onSubmit={(e) => onSubmit(e)}>
            <Grid item xs={12}>
              <Typography
                className={classes.title}
                variant='h6'
                id='tableTitle'
                component='div'
                align='center'
              >
                {id ? 'تعديل معلومات الإدارة ' : 'اضافة إدارة جديدة'}
              </Typography>
            </Grid>
            <Grid item xs={6} className={classes.flexDisplay}>
              <Grid item xs={3} className={classes.contentTitle}>
                <Typography variant='body2'>اسم الموظف</Typography>
              </Grid>
              <Grid item xs={9} className={classes.textfield}>
                <TextField
                  // title='name'
                  fullWidth
                  margin='normal'
                  required
                  value={name ? name : ''}
                  label='اسم الإدارة'
                  variant='outlined'
                  size='small'
                  onChange={onChange}
                  name='name'
                />
              </Grid>
            </Grid>
            <Grid item xs={6} className={classes.flexDisplay}>
              <Grid item xs={3} className={classes.contentTitle}>
                <Typography variant='body2'>اسم الموظف</Typography>
              </Grid>
              <Grid item xs={9} className={classes.textfield}>
                <TextField
                  title='name'
                  fullWidth
                  margin='normal'
                  required
                  value={des ? des : ''}
                  label='وصف الإدارة'
                  variant='outlined'
                  size='small'
                  onChange={onChange}
                  name='des'
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={6} className={classes.buttonContainer}>
                <Button
                  className={classes.button}
                  variant='contained'
                  type='submit'
                  color='primary'
                >
                  حفظ
                </Button>
              </Grid>
              <Grid item xs={6} className={classes.buttonContainer}>
                <Button
                  component={Link}
                  to='/'
                  className={classes.button}
                  variant='contained'
                  color='secondary'
                >
                  رجوع
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Paper>
    </div>
  );
};
const mapStateToProps = (state) => ({
  department: state.department,
});
export default connect(mapStateToProps, {
  createNewDepartment,
  fetchDepartmentById,
  updateDepartment,
})(AddNewDepartment);
// export default AddNewDepartment;
