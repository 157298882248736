import administrativeCom from './administrativeCom';
import alert from './alert';
import allInComing from './allInComing';
import aqar from './aqar';
import aqarPrices from './aqarPrices';
import aqarat from './aqarat';
import archive from './archive';
import auth from './auth';
import autofill from './autofill';
import categories from './categories';
import category from './category';
import { combineReducers } from 'redux';
import constant from './constant';
import coordnaite from './coordnaite';
import department from './department';
import departments from './departments';
import haser from './haser';
import inComing from './inComing';
import isLoading from './isLoading';
import layers from './layers';
import linkedAqart from './linkedAqarat';
import linkedRecoards from './linkedRecoards';
import newInComingNumber from './newInComingNumber';
import recoard from './recoard';
import recoards from './recoards';
import selectProprty from './selectProprty';
import status from './status';
import subs from './subs';
import user from './user';
import users from './users';
export default combineReducers({
  alert,
  auth,
  selectProprty,
  aqarat,
  aqar,
  users,
  constant,
  user,
  departments,
  department,
  isLoading,
  layers,
  status,
  autofill,
  administrativeCom,
  recoards,
  recoard,
  inComing,
  allInComing,
  linkedAqart,
  linkedRecoards,
  newInComingNumber,
  aqarPrices,
  haser,
  categories,
  category,
  subs,
  coordnaite,
  archive,
  //   profile,
});
