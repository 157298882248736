import {  Button,
  CircularProgress,
  Grid,
  Menu,
  MenuItem,
  NativeSelect,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import React, { useEffect, useState } from 'react';
import {
  fetchLinkedAqarat,
  fetchLinkedRecaords,
  fetchNewRecoardNumber,
  fetchReacordByNumber,
  fetchRecoardFiels,
  saveNewOutGoingRecoard,
  updateRecoard,
} from '../../../actions/administrativeCom';
import {
  printOutGoingNumber,
  printOutGoingReceipt,
} from '../../../actions/printDocs';

import ComboBox from '../../Card/ComboBox';
import ComboBox3 from '../../Card/ComboBox3';
import DateFnsUtils from '@date-io/date-fns';
import { Link } from 'react-router-dom';
import UploadedFileReview from './UploadedFileReview';
import autofill from '../../../reducers/autofill';
import { connect } from 'react-redux';
import { fetchDepartment } from '../../../actions/department';
import { makeStyles } from '@material-ui/core/styles';

const initialState = {
  Regestration_Number_au: '',
  Regestration_Number: '',
  Subject: '',
  Status: '',
  sec: '',
  Date_G: '',
  Attatchments: '',
  Type: 'صادر',
  Data_entry: '',
  note: '',
  sent_to: '',
  CreatedBy: '',
  CreatedOn: '',
  ModifiedBy: '',
  ModifiedOn: '',
  linkedToAqar: [],
  linkedToInComing: [],
  tayseer_number: '',
};
const OutGoing = ({
  match,
  fetchDepartment,
  departments,
  isLoading,
  // fetchLinkedAqarat,
  printOutGoingNumber,
  saveNewOutGoingRecoard,
  // fetchLinkedRecaords,
  fetchNewRecoardNumber,
  fetchReacordByNumber,
  newRecoardNumber,
  newRecoardNumberLoading,
  fetchRecoardFiels,
  history,
  auth: { user },
  printOutGoingReceipt,
  recoard: { data, loading, docments },
  updateRecoard,
}) => {
  const { params } = match;
  const { recoardId, companyName } = params;
  const [formData, setFormData] = useState({
    Regestration_Number_au: '',
    Regestration_Number: '',
    Subject: '',
    Status: '',
    sec: '',
    Date_G: '',
    Attatchments: '',
    Type: '',
    Data_entry: '',
    note: '',
    sent_to: '',
    CreatedBy: '',
    CreatedOn: '',
    ModifiedBy: '',
    ModifiedOn: '',
    linkedToAqar: [],
    linkedToInComing: [],
    tayseer_number: '',
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClose = (index) => {
    setAnchorEl(null);
    // console.log('formData', index);
    const aqarData = { ...formData };
    console.log(aqarData);
    const malikInfo = autoFill[index];
    for (const key in malikInfo) {
      if (key in aqarData) aqarData[key] = malikInfo[key];
    }
    setFormData(aqarData);
    console.log('-----', formData);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const [enableEditeing, setEnableEditeing] = useState(true);
  useEffect(() => {
    if (user) {
      switch (user.department) {
        case '7':
          setEnableEditeing(false);
          return;
        default:
          break;
      }
    }
  }, [setEnableEditeing]);
  // useEffect(() => {
  //   if (recoardId) fetchLinkedAqarat(recoardId, companyName);
  // }, [fetchLinkedAqarat, recoardId]);
  // useEffect(() => {
  //   if (recoardId) fetchLinkedRecaords(recoardId, companyName);
  // }, [fetchLinkedRecaords, recoardId]);
  useEffect(() => {
    if (!docments && recoardId) fetchRecoardFiels(recoardId, companyName);
  }, [fetchRecoardFiels, docments, recoardId]);
  useEffect(() => {
    if (departments.length === 0) fetchDepartment(1);
  }, [departments]);
  useEffect(() => {
    if (!recoardId) {
      fetchNewRecoardNumber(companyName);
      if (newRecoardNumber && !newRecoardNumberLoading) {
        const recoardData = { ...initialState };
        recoardData['Regestration_Number_au'] =
          newRecoardNumber['Regestration_Number_au'];
        recoardData['Regestration_Number'] =
          newRecoardNumber['Regestration_Number'];
        setFormData(recoardData);
      }
    }
  }, [newRecoardNumberLoading]);
  useEffect(() => {
    if (recoardId) {
      fetchReacordByNumber(recoardId, companyName);
      // fetchLinkedAqarat(recoardId, companyName);
      if (!loading && data) {
        //console.log(' (!loading && data) ');
        const recoardData = { ...initialState };
        for (const key in data) {
          if (key in recoardData) recoardData[key] = data[key];
        }
        setFormData(recoardData);
      }
    } else {
      // console.log('newRecoardNumber', newRecoardNumber);
      // fetchNewRecoardNumber(companyName);
      // const recoardData = { ...initialState };
      // for (const key in newRecoardNumber) {
      //   if (key in recoardData) recoardData[key] = newRecoardNumber[key];
      // }
      // setFormData(recoardData);
    }
    // if (departments.length === 0) {
    //   fetchDepartment(1);
    // }
  }, [loading]);
  const [autoFill, setAutoFill] = useState([]);
  const afterSelectedAqar = (aqar) => {
    if (aqar[0]) {
      const aa = aqar[0];
      setAutoFill([
        ...autoFill,
        {
          ...aa,
          title: 'طلب معرفة بيانات الصك',
          Subject: `طلب معرفة بيانات وتسلسل الصك للعقار رقم ${aa.aqar_number} باسم مالك العقار /${aa.esem_almalik}`,
          sent_to: 'الهيئة الملكية لمدينة مكة المكرمة والمشاعر المقدسة',
          Attatchments: '٢ مرفق',
          sec: 2,
        },
        {
          ...aa,
          title: 'طلب فحص ملكية',
          Subject: `طلب فحص ملكية للعقار رقم ${aa.aqar_number} باسم مالك العقار /${aa.esem_almalik}`,
          sent_to: 'الهيئة الملكية لمدينة مكة المكرمة والمشاعر المقدسة',
          Attatchments: '٢ مرفق',
          sec: 2,
        },
        {
          ...aa,
          title: 'كهرباء ومياه',
          Subject: `  بشأن استكمال اجراءات  عقار رقم (${aa.aqar_number}) باسم مالك العقار /${aa.esem_almalik}`,
          sent_to: `مالك العقار /${aa.esem_almalik}`,
          Attatchments: 'لا يوجد',
          sec: 2,
        },
        // { ...aa, title: 'طلب استفسار عن ص2ك', Subject: 'fhsssl3948' },
      ]);

      // setFormData({ ...formData, Subject: 'طلب استفسار عن صك رقم ' });
    }
  };
  const [selectedDate, setSelectedDate] = useState(new Date());
  const departmetOptions = departments.map((ar) => (
    <option key={ar.id} value={ar.id}>
      {ar.name}
    </option>
  ));

  const useStyles = makeStyles((theme) => ({
    root: {
      textAlign: 'center',
      padding: '20px',
      paddingLeft: '100px',
      paddingRight: '100px',
    },
    paper: {
      margin: '10px',
      padding: '10px 10px 20px',
    },
    table: {
      minWidth: 275,
      textAlign: 'center',
      padding: '15px',
      borderWidth: 1,
      borderStyle: 'solid',
      color: 'white',
    },
    contentTitle: {
      margin: 'auto',
      textAlign: 'center',
      //   backgroundColor: 'black',
    },
    buttonProgress: {
      // color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    container: {
      padding: '10px',
    },
    containerItem: {
      display: 'flex',
      textAlign: 'center',
      padding: '5px',
    },
  }));
  const classes = useStyles();
  const handleDateChange = (date) => {
    setSelectedDate(date);
    setFormData({ ...formData, Date_G: date });
  };
  const setUpOutGoingNum = () => {
    printOutGoingNumber(recoardId, companyName);
  };
  const [enablePrintBtns, setEnablePrintBtns] = useState(true);
  const updateOutGoingLinked = (newValue) => {
    setFormData({ ...formData, linkedToAqar: newValue });
  };
  const updateOutGoingLinkedToInComing = (newValue) => {
    setFormData({ ...formData, linkedToInComing: newValue });
  };
  const saveNewRecoard = () => {
    var bodyFormData = new FormData();
    if (!Date_G) {
      formData.Date_G = selectedDate;
    }
    if (!Status) {
      formData.Status = 'مفتوح';
    }
    const mapArray = linkedToInComing.map((it) => {
      return it.Regestration_Number;
    });
    bodyFormData.append('linkedToInComing', JSON.stringify(mapArray));
    const mapAqarArray = linkedToAqar.map((it) => {
      return it.aqar_number;
    });
    bodyFormData.append('linkedToAqar', JSON.stringify(mapAqarArray));
    files.forEach((fi, index) => {
      bodyFormData.append(
        `${companyName}-${Regestration_Number}-${fi.name}0${index}`,
        fi.value
      );
    });
    bodyFormData.append('tayseer_number', formData.tayseer_number);
    bodyFormData.append('Regestration_Number', formData.Regestration_Number);
    bodyFormData.append('Type', formData.Type);
    bodyFormData.append('Subject', formData.Subject);
    bodyFormData.append('Status', formData.Status);
    bodyFormData.append(
      'Date_G',
      require('moment')(formData.Date_G).format('YYYY-MM-DD HH:mm:ss')
    );
    bodyFormData.append(
      'Regestration_Number_au',
      formData.Regestration_Number_au
    );
    bodyFormData.append('sent_to', formData.sent_to);
    bodyFormData.append('note', formData.note);
    bodyFormData.append(
      'Attatchments',
      formData.Attatchments ? formData.Attatchments : 'بدون'
    );
    bodyFormData.append('sec', formData.sec);
    bodyFormData.append('companyName', companyName);
    // formData.files = formD;
    if (recoardId) {
      updateRecoard(companyName, Regestration_Number, bodyFormData);
    } else {
      //console.log('saveNewOutGoingRecoard');
      saveNewOutGoingRecoard(
        companyName,
        Regestration_Number,
        bodyFormData,
        history
      );
    }
    setEnablePrintBtns(isLoading.isLoading);
  };
  const printRecivedForm = () => {
    printOutGoingReceipt(recoardId, companyName);
  };
  const viewDocment = (url) => {
    // let blob = await fetch(url).then(r => r.blob());
    // const fileURL = URL.createObjectURL(blob);
    // window.open(fileURL);
    // window.open(URL.createObjectURL(url));
  };
  const {
    Regestration_Number_au,
    Regestration_Number,
    Subject,
    Status,
    sec,
    Date_G,
    Attatchments,
    Type,
    note,
    sent_to,
    tayseer_number,
    linkedToAqar,
    linkedToInComing,
  } = formData;
  const statusArray = [
    <option key={'مفتوح'} value={'مفتوح'}>
      مفتوح
    </option>,
    <option key={'مغلق'} value={'مغلق'}>
      مغلق
    </option>,
    <option key={'منتهي'} value={'منتهي'}>
      منتهي
    </option>,
    <option key={'معلق'} value={'معلق'}>
      معلق
    </option>,
  ];
  const [files, setFiles] = useState([]);

  const seletcFile = ({ target }) => {
    const fils = target.files;
    var result = Object.keys(fils).map((key) => [fils[key]]);
    const ff = result.map((file) => ({ name: 'LTR-', value: file[0] }));
    setFiles(ff);
  };
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const updateFileName = (file) => {
    setFiles([
      ...files.filter((fi) => fi.value.name !== file.value.name),
      file,
    ]);
  };
  return (
    <MuiPickersUtilsProvider
      utils={DateFnsUtils}
      style={{ display: 'contents' }}
    >
      <Grid container className={classes.root}>
        <form style={{ display: 'contents' }}>
          <Grid item xs={12}>
            <Typography variant='h5'>المعاملات الصادرة</Typography>
          </Grid>
          <Grid item xs={12} style={{ display: 'contents' }}>
            <Grid item xs={2} className={classes.contentTitle}>
              <Typography variant='body2'>الرقم التسلسلي</Typography>
            </Grid>
            <Grid item xs={4}>
              <TextField
                title='name'
                fullWidth
                margin='normal'
                value={Regestration_Number_au}
                disabled={true}
                // label="رقم القيد"
                variant='outlined'
                id='name'
                size='small'
              />
            </Grid>
            <Grid item xs={2} className={classes.contentTitle}>
              <Typography variant='body2'>رقم القيد</Typography>
            </Grid>
            <Grid item xs={4}>
              <TextField
                key='Regestration_Number'
                title='name'
                name='Regestration_Number'
                fullWidth
                margin='normal'
                value={Regestration_Number}
                disabled={true}
                // label="رقم القيد"
                variant='outlined'
                id='Regestration_Number'
                size='small'
              />
            </Grid>
            <Grid item xs={2} className={classes.contentTitle}>
              <Typography variant='body2'>الموضوع</Typography>
            </Grid>
            <Grid item xs={10}>
              <TextField
                title='Subject'
                fullWidth
                name='Subject'
                margin='normal'
                value={Subject}
                disabled={enableEditeing}
                onChange={onChange}
                // label="رقم القيد"
                variant='outlined'
                id='Subject'
                size='small'
              />
            </Grid>
            {autoFill.length !== 0 ? (
              <Grid item xs={12} style={{ paddingTop: '10px' }}>
                <Button
                  aria-controls='simple-menu'
                  aria-haspopup='true'
                  variant='contained'
                  color='primary'
                  onClick={handleClick}
                >
                  اختيار نوع المعاملة
                </Button>
                <Menu
                  id='simple-menu'
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  {autoFill.map((item, indx) => (
                    <MenuItem
                      key={indx}
                      onClick={() => {
                        handleClose(indx);
                      }}
                    >
                      {item.title}
                    </MenuItem>
                  ))}
                </Menu>
              </Grid>
            ) : null}
            <Grid item xs={2} className={classes.contentTitle}>
              <Typography variant='body2'>النوع</Typography>
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                name='Type'
                margin='normal'
                value={Type}
                disabled={true}
                variant='outlined'
                id='Type'
                size='small'
              />
            </Grid>
            <Grid item xs={2} className={classes.contentTitle}>
              <Typography variant='body2'>تاريخ المعاملة</Typography>
            </Grid>
            <Grid item xs={4}>
              <KeyboardDatePicker
                disableToolbar
                style={{
                  margin: 'auto',
                  paddingTop: '5px',
                  paddingBottom: '5px',
                }}
                variant='inline'
                format='MM/dd/yyyy'
                disabled={enableEditeing}
                margin='normal'
                title='تاريخ المعاملة'
                id='date-picker-inline'
                label='تاريخ المعاملة'
                value={Date_G ? Date_G : new Date()}
                name='Date_G'
                fullWidth
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </Grid>
            <Grid item xs={2} className={classes.contentTitle}>
              <Typography variant='body2'>الإدارة</Typography>
            </Grid>
            <Grid item xs={4}>
              <NativeSelect
                style={{
                  margin: 'auto',
                  paddingTop: '5px',
                  paddingBottom: '5px',
                }}
                disabled={enableEditeing}
                onChange={onChange}
                fullWidth
                required
                value={sec ? sec : ''}
                name='sec'
                inputProps={{
                  name: 'sec',
                  id: 'sec',
                }}
              >
                <option></option>
                {departmetOptions}
              </NativeSelect>
            </Grid>
            <Grid item xs={2} className={classes.contentTitle}>
              <Typography variant='body2'>الحالة</Typography>
            </Grid>
            <Grid item xs={4}>
              <NativeSelect
                style={{
                  margin: 'auto',
                  paddingTop: '5px',
                  paddingBottom: '5px',
                }}
                disabled={enableEditeing}
                onChange={onChange}
                fullWidth
                required
                value={Status ? Status : ''}
                name='Status'
                key='Status'
                title='حالة المعاملة'
                inputProps={{
                  id: 'Status',
                }}
              >
                {Status ? <option value={Status}>{Status}</option> : null}
                {statusArray}
              </NativeSelect>
            </Grid>
            <Grid item xs={2} className={classes.contentTitle}>
              <Typography variant='body2'>المرفقات</Typography>
            </Grid>
            <Grid item xs={4}>
              <TextField
                title='المرفقات'
                fullWidth
                disabled={enableEditeing}
                required
                value={Attatchments}
                onChange={onChange}
                name='Attatchments'
                margin='normal'
                // label="رقم القيد"
                variant='outlined'
                id='Attatchments'
                size='small'
              />
            </Grid>
            <Grid item xs={2} className={classes.contentTitle}>
              <Typography variant='body2'>ملاحظات</Typography>
            </Grid>
            <Grid item xs={4}>
              <TextField
                title='note'
                fullWidth
                margin='normal'
                disabled={enableEditeing}
                onChange={onChange}
                value={note}
                name='note'
                key='note'
                // label="رقم القيد"
                variant='outlined'
                id='note'
                size='small'
              />
            </Grid>
            <Grid item xs={2} className={classes.contentTitle}>
              <Typography variant='body2'>مرسل إلى</Typography>
            </Grid>
            <Grid item xs={4}>
              <TextField
                title='sent_to'
                name='sent_to'
                fullWidth
                disabled={enableEditeing}
                onChange={onChange}
                value={sent_to}
                margin='normal'
                // label="رقم القيد"
                variant='outlined'
                id='sent_to'
                size='small'
              />
            </Grid>
            <Grid item xs={2} className={classes.contentTitle}>
              <Typography variant='body2'>رقم تيسير</Typography>
            </Grid>
            <Grid item xs={4}>
              <TextField
                title='tayseer_number'
                name='tayseer_number'
                fullWidth
                disabled={enableEditeing}
                onChange={onChange}
                value={tayseer_number}
                margin='normal'
                // label="رقم القيد"
                variant='outlined'
                id='tayseer_number'
                size='small'
              />
            </Grid>
            <Grid item xs={2} className={classes.contentTitle}>
              <Typography variant='body2'>ربط برقم عقار</Typography>
            </Grid>
            <Grid item xs={4}>
              {/* {linkedToAqar && linkedToAqar !== "" ? (
                <TextField
                  // title="linkedToAqar"
                  name="linkedToAqar"
                  fullWidth
                  onChange={onChange}
                  value={linkedToAqar}
                  margin="normal"
                  // label="رقم القيد"
                  variant="outlined"
                  id="linkedToAqar"
                  size="small"
                />
              ) : ( */}
              <ComboBox
                name={'linkedToAqar'}
                value={linkedToAqar}
                defVal={linkedToAqar}
                disabled={enableEditeing}
                companyName={companyName}
                updateOutGoingLinked={updateOutGoingLinked}
                afterSelectedAqar={afterSelectedAqar}
              />
              {/* // )} */}
            </Grid>
            <Grid item xs={2} className={classes.contentTitle}>
              <Typography variant='body2'>ربط برقم وارد</Typography>
            </Grid>
            <Grid item xs={4}>
              <ComboBox3
                name={'linkedToInComing'}
                value={linkedToInComing}
                disabled={enableEditeing}
                defVal={linkedToInComing}
                companyName={companyName}
                tableName={'in_coming'}
                updateOutGoingLinked={updateOutGoingLinkedToInComing}
              />
            </Grid>
            <Grid container>
              {files
                ? files.map((fi, index) => (
                    <UploadedFileReview
                      key={index}
                      file={fi}
                      updateFileName={updateFileName}
                    />
                  ))
                : null}
            </Grid>
            <Grid container>
              {docments
                ? docments.map((doc) => (
                    <Grid item xs={12}>
                      <object
                        width='100%'
                        height='400'
                        type='application/pdf'
                        data={doc.url}
                      >
                        <p>
                          Insert your error message here, if the PDF cannot be
                          displayed.
                        </p>
                      </object>
                    </Grid>
                  ))
                : null}
            </Grid>
            {enableEditeing ? null : (
              <Grid container style={{ paddingTop: '20px' }}>
                <Grid item xs={2} className={classes.contentTitle}>
                  <input
                    // accept='pdf'
                    className={classes.input}
                    style={{ display: 'none' }}
                    id='raised-button-file'
                    onChange={seletcFile}
                    multiple
                    type='file'
                  />
                  <label htmlFor='raised-button-file'>
                    <Button
                      variant='contained'
                      color='primary'
                      component='span'
                      className={classes.button}
                    >
                      رفع ملفات
                    </Button>
                  </label>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    className={classes.button}
                    variant='contained'
                    color='primary'
                    onClick={saveNewRecoard}
                    disabled={isLoading.isLoading}
                  >
                    {isLoading.isLoading && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                    حفظ
                  </Button>
                </Grid>
                <Grid item xs={3}>
                  <Button
                    className={classes.button}
                    variant='contained'
                    color='primary'
                    onClick={printRecivedForm}
                    disabled={isLoading.isLoading}
                    // disabled={enablePrintBtns}
                  >
                    {isLoading.isLoading && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                    طباعة نموذج التسليم
                  </Button>
                </Grid>
                <Grid item xs={3}>
                  <Button
                    className={classes.button}
                    variant='contained'
                    color='primary'
                    disabled={isLoading.isLoading}
                    // disabled={enablePrintBtns}
                    onClick={setUpOutGoingNum}
                  >
                    {isLoading.isLoading && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                    طباعة الرقم على الخطاب
                  </Button>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    className={classes.button}
                    variant='contained'
                    color='secondary'
                    component={Link}
                    to={`/${companyName}/allOutGoing`}
                  >
                    رجوع
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </form>
      </Grid>
    </MuiPickersUtilsProvider>
  );
};
const mapStateToProps = (state) => ({
  departments: state.departments.data,
  isLoading: state.isLoading,
  newRecoardNumber: state.administrativeCom.newNumber,
  newRecoardNumberLoading: state.administrativeCom.numLoading,
  recoard: state.recoard,
  linkedAqarat: state.linkedAqart,
  linkedRecoards: state.linkedRecoards,
  auth: state.auth,
});
export default connect(mapStateToProps, {
  fetchDepartment,
  printOutGoingNumber,
  fetchNewRecoardNumber,
  updateRecoard,
  saveNewOutGoingRecoard,
  fetchReacordByNumber,
  printOutGoingReceipt,
  fetchLinkedAqarat,
  fetchLinkedRecaords,
  fetchRecoardFiels,
})(OutGoing);
