import React, { Fragment, useEffect } from 'react';
import {
  fetchHaserStauts,
  fetchKrokiStatus,
  fetchLogs,
  fetchNoaAlmilkia,
} from '../../../actions/admin';

import AccessTime from '@material-ui/icons/AccessTime';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import AssessmentIcon from '@material-ui/icons/Assessment';
import BarChart from './dashboardComponents/BarChart';
import ButtonCardCompoent from './dashboardComponents/ButtonCardCompoent';
import Card from '../../Card/Card';
import CardBody from '../../Card/CardBody';
import CardComponent from '../Admin/dashboardComponents/CardComponent';
import CardFooter from '../../Card/CardFooter';
import CardHeader from '../../Card/CardHeader';
import CardIcon from '../../Card/CardIcon';
import Chartist from 'chartist';
import ChartistGraph from 'react-chartist';
import DounatChart from './dashboardComponents/DounatChart';
import GetApp from '@material-ui/icons/GetApp';
import GridContainer from '../../GridContainer';
import GridItem from '../../GridItem';
import Spinner from '../Spinner';
import Table from '../../Table/Table';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import styles from '../../../assets/jss/material-dashboard-react/views/dashboardStyle';

var delays = 80,
  durations = 500;
var delays2 = 80,
  durations2 = 500;
const AdminDashboard = ({
  fetchLogs,
  auth: { user },
  fetchNoaAlmilkia,
  fetchHaserStauts,
  fetchKrokiStatus,
  logs,
  noaAlmilkia,
  haserStatus,
  krokiStatus,
}) => {
  const useStyles = makeStyles(styles);
  useEffect(() => {
    fetchKrokiStatus();
    fetchNoaAlmilkia();
    fetchHaserStauts();
  }, [fetchNoaAlmilkia, fetchLogs]);
  const classes = useStyles();
  console.log('logs', logs.logs);
  const assessmentIcon = <AssessmentIcon />;
  const getApp = <GetApp />;
  return (
    <GridContainer>
      <GridItem xs={12} sm={6} md={6}>
        <CardComponent
          color='info'
          icon={assessmentIcon}
          data={noaAlmilkia.data}
          chart={<DounatChart data={noaAlmilkia.data} />}
          compoentTitle='اعداد العقارات'
        />
      </GridItem>
      {user.roll.department === 3 && user.roll.id === 9 ? (
        <>
          <GridItem xs={12} sm={3} md={3}>
            <ButtonCardCompoent
              color='success'
              icon={getApp}
              reportNumber={3}
              // data={haserStatus.data}
              compoentTitle='تقرير الهيئة الشامل'
              description='التقرير الشامل الاول'
            />
          </GridItem>

          <GridItem xs={12} sm={3} md={3}>
            <ButtonCardCompoent
              color='success'
              icon={getApp}
              reportNumber={4}
              // data={haserStatus.data}
              compoentTitle='تقرير الهيئة٢  '
              description='جدول اكسل   العقارات'
            />
          </GridItem>
        </>
      ) : null}
      {user.roll.department == 2 && user.roll.id === 0 ? (
        <>
          <GridItem xs={12} sm={3} md={3}>
            <ButtonCardCompoent
              color='success'
              icon={getApp}
              // data={haserStatus.data}
              reportNumber={1}
              compoentTitle='التقرير التفصيلي'
              description='جدول اكسل بجميع معلومات العقارات'
            />
          </GridItem>
          <GridItem xs={12} sm={3} md={3}>
            <ButtonCardCompoent
              color='success'
              icon={getApp}
              reportNumber={2}
              // data={haserStatus.data}
              compoentTitle='تقرير الحصر'
              description='تقرير حصر مشتملات جميع العقارات'
            />
          </GridItem>
          <GridItem xs={12} sm={3} md={3}>
            <ButtonCardCompoent
              color='success'
              icon={getApp}
              reportNumber={5}
              compoentTitle='تقرير تسجيل الملكيات'
              description='جدول تسجيل الملكيات في الهيئة'
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <BarChart
              data={haserStatus.data}
              color='info'
              labels={[
                'تم التوقيع',
                'بانتظار إعتماد لجنة الحصر',
                'تحت الاجراء',
                'لم يتم التوقيع',
              ]}
              title='تقرير محاضر لجنة حصر المشتملات'
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <BarChart
              data={krokiStatus.data}
              color='danger'
              labels={[
                'استلام الكروكي',
                'مراجعة الكروكي',
                'تم توقيع المالك ',
                'كروكيات جاهزة',
              ]}
              title='تقرير الكروكيات النهائية'
            />
          </GridItem>
        </>
      ) : null}
    </GridContainer>
  );
};
const mapStateToProps = (state) => ({
  logs: state.status.logs,
  aqaratCount: state.status.aqaratCount.totalCount,
  noaAlmilkia: state.status.noaAlmilkia,
  haserStatus: state.status.haserStatus,
  krokiStatus: state.status.krokiStatus,
  auth: state.auth,
});
export default connect(mapStateToProps, {
  fetchLogs,
  fetchHaserStauts,
  fetchNoaAlmilkia,
  fetchKrokiStatus,
})(AdminDashboard);
