import {
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Grid,
  Menu,
  MenuItem,
  NativeSelect,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';
import { getPriceRevision, updatePriceRevision } from '../../../actions/aqarat';

import AlertDialog from '../AlertDialog';
import MuiAlert from '@material-ui/lab/Alert';
import Sidebar from '../Sidbar';
import Spinner from '../Spinner';
import { connect } from 'react-redux';
import { fetchAqarByAqarNumber } from '../../../actions/aqarat';
import { makeStyles } from '@material-ui/core/styles';

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}
const initialState = {
  motnaza_alih: '',
};
const pricesInialState = {
  ReviseRequest: '',
  ReviseReason: '',
  Remarks: '',
  CommitteeInformed: false,
  RevisionComplete: false,
  CourtCaseOpened: false,
  CourtCaseClosed: false,
  ClosedReason: '',
  LandpriceREV: false,
  ConcretepriceREV: false,
  ClassicpriceREV: false,
  HangerpriceREV: false,
  FencepriceREV: false,
};
const PriceRevision = ({
  match,
  aqar: { loading, data, priceRevision },
  getPriceRevision,
  updatePriceRevision,
  fetchAqarByAqarNumber,
}) => {
  const { params } = match;
  const { id } = params;
  const [formData, setFormData] = useState({
    motnaza_alih: '',
  });
  const [pricesData, setPricesData] = useState({
    ReviseRequest: '',
    ReviseReason: '',
    Remarks: '',
    CommitteeInformed: false,
    RevisionComplete: false,
    CourtCaseOpened: false,
    CourtCaseClosed: false,
    ClosedReason: '',
    LandpriceREV: false,
    ConcretepriceREV: false,
    ClassicpriceREV: false,
    HangerpriceREV: false,
    FencepriceREV: false,
  });
  const {
    ReviseRequest,
    ReviseReason,
    Remarks,
    CommitteeInformed,
    RevisionComplete,
    CourtCaseOpened,
    CourtCaseClosed,
    ClosedReason,
    LandpriceREV,
    ConcretepriceREV,
    ClassicpriceREV,
    HangerpriceREV,
    FencepriceREV,
  } = pricesData;
  const { motnaza_alih } = formData;
  useEffect(() => {
    if (!data) fetchAqarByAqarNumber(id);
    console.log('useEffect');
    if (!loading && data) {
      const aqarData = { ...initialState };
      for (const key in data) {
        if (key in aqarData) aqarData[key] = data[key];
      }
      setFormData(aqarData);
      console.log('aqarData', aqarData);
    }
  }, [loading, fetchAqarByAqarNumber, data, id]);
  useEffect(() => {
    if (!priceRevision) getPriceRevision(id);
    const announcData = { ...pricesInialState };
    for (const key in priceRevision) {
      if (key in announcData) announcData[key] = priceRevision[key];
    }
    setPricesData(announcData);
  }, [getPriceRevision, priceRevision]);

  const useStyles = makeStyles({
    root: {
      minWidth: 275,
      textAlign: 'center',
      padding: '15px',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    contentTitle: {
      margin: 'auto',
    },
    contenier: {
      padding: '5px',
    },
  });
  const classes = useStyles();
  const onSubmit = (e) => {
    e.preventDefault();
    updatePriceRevision(id, pricesData);
  };
  const onCheckboxChange = (event) => {
    console.log(+event.target.checked);
    const name = event.target.name;
    console.log(name);
    setPricesData({ ...pricesData, [name]: +event.target.checked });
  };
  const onChange = (e) => {
    const name = e.target.name;
    setPricesData({ ...pricesData, [name]: e.target.value });
  };
  return (
    <Fragment>
      {data === null || loading ? (
        <Spinner />
      ) : (
        <Grid container spacing={4}>
          {/* {data.molahthat ? (
              <AlertDialog molahthat={data.molahthat} isOpen={true} />
            ) : null} */}
          <Grid item sm={2}>
            <Sidebar proprtyId={id} />
          </Grid>
          <Grid item sm={10}>
            <Card className={classes.root}>
              <form onSubmit={(e) => onSubmit(e)}>
                <Grid container className={classes.contenier}>
                  <Grid item xs={12}>
                    <Typography variant='button' display='block'>
                      رقم العقار {data.aqar_number}{' '}
                      {motnaza_alih ? (
                        <Alert severity='error'>متنازع عليه</Alert>
                      ) : null}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='body1'>إعادة التثمين</Typography>
                  </Grid>
                  <Grid item xs={2} className={classes.contentTitle}>
                    <Typography variant='body2'>إعادة تثمين</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Checkbox
                      checked={!!ReviseRequest}
                      color='primary'
                      onChange={onCheckboxChange}
                      name='ReviseRequest'
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  </Grid>
                  <Grid item xs={2} className={classes.contentTitle}>
                    <Typography variant='body2'>
                      رفع الطلب إلي اللجنة
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Checkbox
                      checked={!!CommitteeInformed}
                      color='primary'
                      onChange={onCheckboxChange}
                      name='CommitteeInformed'
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  </Grid>
                  <Grid item xs={2} className={classes.contentTitle}>
                    <Typography variant='body2'>سبب إعادة التثمين</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <NativeSelect
                      value={ReviseReason}
                      onChange={onChange}
                      fullWidth
                      name='ReviseReason'
                      inputProps={{
                        id: 'ReviseReason',
                      }}
                    >
                      {ReviseReason ? (
                        <option value={ReviseReason}>{ReviseReason}</option>
                      ) : (
                        <option></option>
                      )}
                      <option value='دمج العقارات'>دمج العقارات</option>
                      <option value='فصل العقارات'>فصل العقارات</option>
                      <option value='تداخل المساحات'>تداخل المساحات</option>
                      <option value='عقار جديد'>عقار جديد</option>
                      <option value='مشتملات'>مشتملات</option>
                      <option value='اخر'>اخر</option>
                    </NativeSelect>
                  </Grid>
                  <Grid item xs={1} className={classes.contentTitle}>
                    <Typography variant='body2'>سعر الارض</Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Checkbox
                      checked={!!LandpriceREV}
                      color='primary'
                      onChange={onCheckboxChange}
                      name='LandpriceREV'
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  </Grid>
                  <Grid item xs={1} className={classes.contentTitle}>
                    <Typography variant='body2'>سعر المسلح</Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Checkbox
                      checked={!!ConcretepriceREV}
                      color='primary'
                      onChange={onCheckboxChange}
                      name='ConcretepriceREV'
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  </Grid>
                  <Grid item xs={1} className={classes.contentTitle}>
                    <Typography variant='body2'>سعر الشعبي</Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Checkbox
                      checked={!!ClassicpriceREV}
                      color='primary'
                      onChange={onCheckboxChange}
                      name='ClassicpriceREV'
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  </Grid>
                  <Grid item xs={1} className={classes.contentTitle}>
                    <Typography variant='body2'>سعر الهنجر</Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Checkbox
                      checked={!!HangerpriceREV}
                      color='primary'
                      onChange={onCheckboxChange}
                      name='HangerpriceREV'
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  </Grid>
                  <Grid item xs={1} className={classes.contentTitle}>
                    <Typography variant='body2'>سعر السور</Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Checkbox
                      checked={!!FencepriceREV}
                      color='primary'
                      onChange={onCheckboxChange}
                      name='FencepriceREV'
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  </Grid>
                  <Grid item xs={2} className={classes.contentTitle}></Grid>
                  <Grid item xs={2} className={classes.contentTitle}>
                    <Typography variant='body2'>الملاحظات</Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <TextField
                      fullWidth
                      size='small'
                      margin='normal'
                      label='ملاحظات إعادة التثمين'
                      variant='outlined'
                      value={Remarks}
                      name='Remarks'
                      onChange={onChange}
                    />
                  </Grid>
                  <Grid item xs={2} className={classes.contentTitle}>
                    <Typography variant='body2'>
                      انتهاء إعادة التثمين
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Checkbox
                      checked={!!RevisionComplete}
                      color='primary'
                      onChange={onCheckboxChange}
                      name='RevisionComplete'
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  </Grid>
                  <Grid item xs={2} className={classes.contentTitle}>
                    <Typography variant='body2'>
                      طلب تظلم على التثمين
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Checkbox
                      checked={!!CourtCaseOpened}
                      color='primary'
                      onChange={onCheckboxChange}
                      name='CourtCaseOpened'
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  </Grid>
                  <Grid item xs={2} className={classes.contentTitle}>
                    <Typography variant='body2'>
                      طلب إلغاء التظلم على التثمين
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Checkbox
                      checked={!!CourtCaseClosed}
                      color='primary'
                      onChange={onCheckboxChange}
                      name='CourtCaseClosed'
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  </Grid>
                  <Grid item xs={2} className={classes.contentTitle}>
                    <Typography variant='body2'>سبب إلغاء التظلم</Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <TextField
                      fullWidth
                      size='small'
                      margin='normal'
                      label='سبب إلغاء التظلم'
                      variant='outlined'
                      name='ClosedReason'
                      value={ClosedReason}
                      onChange={onChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type='submit'
                      fullWidth
                      variant='contained'
                      color='primary'
                    >
                      حفظ
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Card>
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  aqar: state.aqar,
  auth: state.auth,
});
export default connect(mapStateToProps, {
  getPriceRevision,
  updatePriceRevision,
  fetchAqarByAqarNumber,
})(PriceRevision);
