import {
  FETCH_INCOMING_RECOARDS,
  ERROR_FETCH_INCOMING_RECOARDS,
  SEARCH_INCOMING_RECOARDS,
} from "../actions/types";

const initialState = {
  data: [],
  pageNumber: 1,
  pageSize: 0,
  totalElment: 0,
  total_pages: 0,
  loading: true,
};
export default function (state = initialState, action) {
  const { type, payload } = action;
  // console.log('state', payload);
  switch (type) {
    case FETCH_INCOMING_RECOARDS:
    case SEARCH_INCOMING_RECOARDS:
      return {
        ...state,
        data: payload.data,
        pageNumber: payload.pageNumber,
        pageSize: payload.pageSize,
        totalElment: payload.totalElment,
        total_pages: payload.total_pages,
        loading: false,
      };
    case ERROR_FETCH_INCOMING_RECOARDS:
      return { ...state };
    default:
      return state;
  }
}
