import { Link, withRouter } from 'react-router-dom';
import React, { useEffect } from 'react';

import Button from '@material-ui/core/Button';
import EnhancedTableToolbar from '../Table/MyToolbar';
import { Grid } from '@material-ui/core';
import MyTableHead from '../Table/MyTableHead';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import Sidbar from '../../ui/Sidbar';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import categories from '../../../reducers/categories';
import { connect } from 'react-redux';
import { fetchAllCategories } from '../../../actions/categories';
import { makeStyles } from '@material-ui/core/styles';

const AllCategories = ({
  fetchAllCategories,
  categories: { categories, pageSize, totalElment, total_pages, catLoading },
  history,
}) => {
  useEffect(() => {
    fetchAllCategories(1);
    // console.log('SDCDC');
  }, [fetchAllCategories]);
  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    table: {
      minWidth: 650,
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }));
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [filter, setFilter] = React.useState('');
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const handleClick = (event, row) => {
    const catID = row.id;
    // history.push(`/${row.id}/edit_user`);
    history.push({ pathname: `/categroies/${catID}` });
    const selectedIndex = selected.indexOf(row.name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row.name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  const handleChangePage = (event, newPage) => {
    console.log('newPage', newPage, page);
    fetchAllCategories(newPage + 1);
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const headCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'اسم التصنيف',
    },
    {
      id: 'description',
      numeric: true,
      disablePadding: false,
      label: 'الوصف',
    },
    {
      id: 'createdAt',
      numeric: false,
      disablePadding: false,
      label: 'تاريخ الانشاء',
    },
  ];
  const handleOnChangeSearchInput = (e) => {
    // console.log(orderBy);
    // searchForUsers(1, orderBy, e.target.value);
    setFilter(e.target.value);
  };
  console.log('dsvsdvsdvsdv USERSSSSS');
  return (
    <Grid container className={classes.root}>
      <Grid item xs={2}>
        <Sidbar />
      </Grid>
      <Grid item xs={10}>
        <Paper className={classes.paper}>
          <EnhancedTableToolbar
            handleOnChangeSearchInput={handleOnChangeSearchInput}
            name={'التصنيفات الرئيسية'}
          />
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label='simple table'>
              <MyTableHead
                headCells={headCells}
                onRequestSort={handleRequestSort}
                order={order}
                orderBy={orderBy}
                classes={classes}
              ></MyTableHead>
              <TableBody>
                {!catLoading
                  ? stableSort(categories, getComparator(order, orderBy)).map(
                      (row, index) => {
                        console.log(
                          page * pageSize,
                          page * pageSize + pageSize
                        );
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow
                            hover
                            onClick={(event) => handleClick(event, row)}
                            role='checkbox'
                            tabIndex={-1}
                            key={row.id}
                          >
                            <TableCell
                              component='th'
                              id={labelId}
                              scope='row'
                              padding='none'
                              align='center'
                            >
                              {row.name}
                            </TableCell>
                            <TableCell
                              component='th'
                              id={labelId}
                              scope='row'
                              padding='none'
                              align='center'
                            >
                              {row.description}
                            </TableCell>
                            <TableCell
                              component='th'
                              id={labelId}
                              scope='row'
                              padding='none'
                              align='center'
                            >
                              {row.createdAt}
                            </TableCell>
                          </TableRow>
                        );
                      }
                    )
                  : null}
                {/* {emptyRows > 0 && (
                      <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )} */}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5]}
            component='div'
            count={totalElment}
            rowsPerPage={pageSize}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
        <Button
          component={Link}
          variant='contained'
          color='primary'
          to='/categroies/add_new_category'
        >
          اضافة تصنيف جديد
        </Button>
      </Grid>
    </Grid>
  );
};
const mapStateToProps = (state) => ({
  categories: state.categories,
});
export default connect(mapStateToProps, { fetchAllCategories })(AllCategories);
