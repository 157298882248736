import {
  Box,
  Button,
  Collapse,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';
import {
  fetchAqarByAqarNumber,
  fetchAqarLayer,
  finalKrokiAproved,
} from '../../../actions/aqarat';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Sidebar from '../Sidbar';
import Spinner from '../Spinner';
import _ from 'lodash';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { printFull } from '../../../actions/printDocs';

const initialState = {
  esem_almalik: '',
  aqar_number: '',
  noa_almilkia: '',
  eadat_altathmen: '',
  eadat_altathmen_resone: '',
};
const ConsulteDash = ({
  match,
  aqar,
  printFull,
  finalKrokiAproved,
  loading,
  fetchAqarByAqarNumber,
  fetchAqarLayer,
  layers: { layers, layersloading },
}) => {
  const { params } = match;
  const { aqarId } = params;
  const [formData, setFormData] = useState({
    esem_almalik: '',
    aqar_number: '',
    noa_almilkia: '',
    eadat_altathmen: '',
    eadat_altathmen_resone: '',
  });
  useEffect(() => {
    if (!aqar) fetchAqarByAqarNumber(aqarId);
    fetchAqarLayer(aqarId);
    if (!loading && aqar) {
      const aqarData = { ...initialState };
      for (const key in aqar) {
        if (key in aqarData) aqarData[key] = aqar[key];
      }
      setFormData(aqarData);
    }
  }, [loading, aqar, aqarId, fetchAqarLayer]);

  const useStyles = makeStyles({
    root: {
      minWidth: 275,
      textAlign: 'center',
      padding: '15px',
    },
    paper: {
      margin: '10px',
      padding: '10px 10px 20px',
    },
    table: {
      minWidth: 275,
      textAlign: 'center',
      padding: '15px',
      borderWidth: 1,
      borderStyle: 'solid',
      color: 'white',
    },
    contentTitle: {
      margin: 'auto',
      textAlign: 'center',
      //   backgroundColor: 'black',
    },
    container: {
      padding: '10px',
    },
    containerItem: {
      display: 'flex',
    },
  });
  const classes = useStyles();

  const {
    aqar_number,
    esem_almalik,
    noa_almilkia,
    eadat_altathmen,
    eadat_altathmen_resone,
  } = formData;
  var newOutput = _(layers)
    .groupBy('layer_values')
    .map(function (v, k) {
      return JSON.parse(k);
    })
    .value();
  // var constr = _(newOutput)
  //   .groupBy('ConstructionType')
  //   .map(function (v, k) {
  //     return k;
  //   })
  //   .value();
  // function sum(input) {
  //   if (toString.call(input) !== "[object Array]") return false;
  //   var total = 0;
  //   for (var i = 0; i < input.length; i++) {
  //     if (isNaN(input[i])) {
  //       continue;
  //     }
  //     total += Number(input[i]);
  //   }
  //   return total;
  // }
  const [open, setOpen] = React.useState(true);
  var moslah = 0;
  var shadi = 0;
  var treeNo = 0;
  var palmNo = 0;
  var kazan = 0;
  var columnsNo = 0;
  function getTheTotal(row) {
    const layerValue = JSON.parse(row.layer_values);
    if (row.layer_name === 'lands') {
      treeNo += layerValue.TreeNo;
      columnsNo += layerValue.ColumnsNo;
      palmNo += layerValue.PalmNo;
      kazan += layerValue.TanksArea;
    } else if (row.layer_name === '') {
    } else if (row.layer_name === '') {
    } else {
      switch (layerValue.ConstructionType) {
        case 'مسلح':
          if (row.layer_name === 'SETRA' || row.layer_name === 'Sour') {
          } else {
            moslah += layerValue.AreaAsKroki;
          }
          // console.log("constr ==", row.layer_name, layerValue.AreaAsKroki);

          break;
        case 'شعبي':
          shadi += layerValue.AreaAsKroki;
          break;
        default:
          break;
      }
    }
  }
  function returnArabicLayerName(layerEnName) {
    switch (layerEnName) {
      case 'Ground':
        return 'الدور الأرضي';
      case 'lands':
        return 'حد العقار';
      case 'Mabeet':
        return 'مبيتات';
      case 'RepeatedFloor':
        return 'الأدوار المتكررة';
      case 'Roof':
        return 'الروف';
      case 'RoofChange':
        return 'الروف المتغير';
      case 'SETRA':
        return 'سترة';
      case 'Sour':
        return 'سور';
      case 'GroundRoom':
        return 'ملحق أرضي';
      case 'DeedBoundary_1':
        return 'حد الصك';
      case 'Mezaneen':
        return 'ميزانين';
      case 'RepeatedFloor':
        return 'الادوار المتكررة';
      case 'RepeatedChange':
        return 'الدور الأخير';
      // case "Basement":
      default:
        return 'البدروم';
    }
  }
  const aprovedFinalKroki = (aproved) => {
    console.log('aprovedFinalKroki', formData);
    printFull({ aqar, layers }, true);
    // console.log(layers);
    // finalKrokiAproved(aqar_number, aproved);
  };
  function setupRows(row) {
    const layerValue = JSON.parse(row.layer_values);
    getTheTotal(row);
    if (row.layer_name === 'lands') {
      return (
        <Fragment key={row.id}>
          <TableRow key={row.id}>
            <TableCell>
              <IconButton
                aria-label='expand row'
                size='small'
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
            <TableCell align='center' component='th'>
              {returnArabicLayerName(row.layer_name)}
            </TableCell>
            <TableCell align='center' component='th'>
              {layerValue.ConstructionType}
            </TableCell>
            <TableCell align='center' component='th'>
              {layerValue.Notes}
            </TableCell>
            <TableCell align='center' component='th'>
              {layerValue.AreaAskroki ?? layerValue.AreaAsKroki ?? ''}
            </TableCell>
            <TableCell align='center' component='th'>
              {layerValue.Height}
            </TableCell>
            <TableCell align='center' component='th'>
              {layerValue.Length}
            </TableCell>
          </TableRow>
          <TableRow key={10}>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
              <Collapse in={open} timeout='auto' unmountOnExit>
                <Box margin={1}>
                  <Typography
                    variant='h6'
                    gutterBottom
                    component='div'
                    style={{ textAlign: 'right' }}
                  >
                    معلومات طبقة اللاند
                  </Typography>
                  <Table size='small' aria-label='purchases'>
                    <TableHead>
                      <TableRow key={40}>
                        <TableCell align='center' colSpan={3}>
                          عدد الاعمدة
                        </TableCell>
                        <TableCell align='center'>
                          عدد الاشجار الكبيرة
                        </TableCell>
                        <TableCell align='center'> عدد النخل</TableCell>
                        <TableCell align='center'>مساحة الخزان</TableCell>
                        <TableCell align='center' colSpan={3}>
                          {' '}
                          ملاحظات الخزان
                        </TableCell>
                        <TableCell align='center'> </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow key={0}>
                        <TableCell align='center' colSpan={3}>
                          {layerValue.ColumnsNo}
                        </TableCell>
                        <TableCell align='center'>
                          {layerValue.TreeNo}
                        </TableCell>
                        <TableCell align='center'>
                          {layerValue.PalmNo}
                        </TableCell>
                        <TableCell align='center'>
                          {layerValue.TanksArea}
                        </TableCell>
                        <TableCell align='center' colSpan={3}>
                          {layerValue.Tanknotes}
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </Fragment>
      );
    } else {
      return (
        <TableRow key={row.id}>
          <TableCell></TableCell>
          <TableCell align='center' component='th'>
            {returnArabicLayerName(row.layer_name)}
          </TableCell>
          <TableCell align='center' component='th'>
            {layerValue.ConstructionType}
          </TableCell>
          <TableCell align='center' component='th'>
            {layerValue.Notes}
          </TableCell>
          {row.layer_name === 'DeedBoundary_1' ? (
            <TableCell align='center' component='th'>
              {layerValue.CompensationArea}
            </TableCell>
          ) : (
            <TableCell align='center' component='th'>
              {layerValue.AreaAskroki ?? layerValue.AreaAsKroki ?? ''}
            </TableCell>
          )}
          {/* <TableCell align="center" component="th"> 
            {layerValue.AreaAskroki ?? layerValue.AreaAsKroki ?? ""}
          </TableCell> */}
          <TableCell align='center' component='th'>
            {layerValue.Height}
          </TableCell>
          <TableCell align='center' component='th'>
            {layerValue.Length}
          </TableCell>
        </TableRow>
      );
    }
  }
  return (
    <Grid container spacing={4}>
      {/* {aqar.molahthat ? (
            <AlertDialog molahthat={aqar.molahthat} isOpen={true} />
          ) : null} */}
      <Grid item xs={2}>
        <Sidebar proprtyId={aqarId} />
      </Grid>
      <Grid item xs={10}>
        <Paper className={classes.paper}>
          <Grid container className={classes.container}>
            <Grid item xs={10} className={classes.containerItem}>
              <Grid item xs={3} className={classes.contentTitle}>
                <Typography
                  //   className={classes.title}
                  variant='body2'
                  id='tableTitle'
                  component='div'
                  align='center'
                >
                  اسم المالك
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  value={esem_almalik}
                  disabled
                  fullWidth
                  name='esem_almalik'
                  variant='outlined'
                  size='small'
                />
              </Grid>
              <Grid item xs={3} className={classes.contentTitle}>
                <Typography
                  //   className={classes.title}
                  variant='body2'
                  id='tableTitle'
                  component='div'
                  align='center'
                >
                  رقم العقار
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  value={aqar_number}
                  name='aqar_number'
                  disabled
                  fullWidth
                  variant='outlined'
                  size='small'
                />
              </Grid>
              <Grid item xs={3} className={classes.contentTitle}>
                <Typography
                  //   className={classes.title}
                  variant='body2'
                  id='tableTitle'
                  component='div'
                  align='center'
                >
                  نوع الملكية
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  value={noa_almilkia}
                  disabled
                  name='noa_almilkia'
                  fullWidth
                  variant='outlined'
                  size='small'
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container className={classes.paper}>
            {!layersloading ? (
              <Fragment>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell align='center'>اسم الطبقة</TableCell>
                        <TableCell align='center'>نوع البناء</TableCell>
                        <TableCell align='center'>الملاحظات</TableCell>
                        <TableCell align='center'>المساحة</TableCell>
                        <TableCell align='center'>الطول</TableCell>
                        <TableCell align='center'>الارتفاع</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>{layers.map((row) => setupRows(row))}</TableBody>
                  </Table>
                </TableContainer>
              </Fragment>
            ) : (
              <Fragment>
                <Spinner />
              </Fragment>
            )}
          </Grid>
          <Grid container className={classes.paper}>
            {layers.length >= 0 ? (
              <Fragment>
                <Grid item xs={3}>
                  <Button
                    fullWidth
                    disabled={!!eadat_altathmen}
                    variant='contained'
                    onClick={() => aprovedFinalKroki(true)}
                    color='primary'
                  >
                    طباعة الكشف المالي التفصيلي
                  </Button>
                </Grid>
                <Grid item xs={3}></Grid>
                <Grid item xs={3}>
                  {!!eadat_altathmen ? (
                    <Typography>
                      تم رفع طلب إعادة تثمين للعقار بسبب:{' '}
                      {eadat_altathmen_resone}
                    </Typography>
                  ) : null}
                </Grid>
                {/* <Grid item xs={3}>
                  <Button
                    fullWidth
                    variant='contained'
                    onClick={() => aprovedFinalKroki(false)}
                    color='danger'
                  >
                    رفض
                  </Button>
                </Grid> */}
              </Fragment>
            ) : null}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};
const mapStateToProps = (state) => ({
  aqar: state.aqar.data,
  loading: state.aqar.loading,
  layers: state.layers,
});
export default connect(mapStateToProps, {
  fetchAqarByAqarNumber,
  fetchAqarLayer,
  finalKrokiAproved,
  printFull,
})(ConsulteDash);
