import React, { useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropType from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import { fetchLinkedAqarat } from '../../actions/administrativeCom';
import { searchForAqar } from '../../actions/aqarat';

const ComboBox = ({
  name,
  aqarat,
  searchForAqar,
  updateOutGoingLinked,
  defVal,
  disabled,
  title,
  companyName,
  afterSelectedAqar,
}) => {
  const [defulyVal, setDefVal] = useState([]);
  useEffect(() => {
    setDefVal(defVal);
    updateOutGoingLinked(defVal);
  }, [defVal]);
  return (
    <Autocomplete
      multiple
      id='combo-box-demo-1'
      value={defulyVal}
      defaultValue={defulyVal}
      options={aqarat ? aqarat.map((option) => option) : []}
      getOptionLabel={(option) => option.aqar_number}
      style={{ width: 300 }}
      name={name}
      disabled={disabled}
      onChange={(event, newValue) => {
        if (newValue) {
          setDefVal(newValue);
          afterSelectedAqar(newValue);
          updateOutGoingLinked(newValue);
        }
      }}
      onInputChange={(event, newInputValue) => {
        // console.log(newInputValue);
        // updateOutGoingLinked(newInputValue);
        searchForAqar(1, 'aqar_number', event.target.value, companyName);
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            disabled={disabled}
            fullWidth
            label={title}
            variant='outlined'
            size='small'
          />
        );
      }}
      f
    />
  );
};
ComboBox.prototype = {
  linkedAqarat: PropType.array.isRequired,
};
const mapStateToProps = (state) => ({
  aqarat: state.aqarat.data,
});
export default connect(mapStateToProps, { searchForAqar })(ComboBox);
