import { Button, TextField, Typography, styled } from '@material-ui/core';

import React from 'react';

const DescrptionComponnet = ({
  onChange,
  id,
  type,
  descrption,
  details,
  note,
  deleteRow,
  edit,
}) => {
  // const onChange = (e) => {

  //   // setFormData({ ...formData, [e.target.name]: e.target.value });
  // };
  const stackGap = 5;
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {/* <Stack direction='row' alignItems={'center'} gap={stackGap}> */}
      {!edit ? (
        <Button
          variant='contained'
          color='primary'
          onClick={() => deleteRow(id)}
        >
          -
        </Button>
      ) : null}
      <StyledHeading variant='body1'>
        <b>نوع الانقاض</b>
      </StyledHeading>
      <TextField
        title='name'
        fullWidth
        margin='normal'
        value={type}
        label='نوع الانقاض'
        variant='outlined'
        id='type'
        disabled={edit}
        size='small'
        onChange={(e) => onChange(e, id)}
        name='type'
      />
      <StyledHeading variant='body1'>
        <b>وصف الانقاض</b>
      </StyledHeading>
      <TextField
        fullWidth
        margin='normal'
        // disabled={edit}
        size='small'
        disabled={edit}
        label='وصف الانقاض'
        variant='outlined'
        id=''
        value={descrption}
        name='descrption'
        onChange={(e) => onChange(e, id)}
        inputProps={{
          maxLength: 10,
        }}
      />
      <StyledHeading variant='body1'>
        <b>تفاصيل الانقاض</b>
      </StyledHeading>
      <TextField
        fullWidth
        margin='normal'
        // disabled={edit}
        size='small'
        disabled={edit}
        label='تفاصيل الانقاض'
        variant='outlined'
        id=''
        value={details}
        name='details'
        onChange={(e) => onChange(e, id)}
        inputProps={{
          maxLength: 10,
        }}
      />
      <StyledHeading variant='body1'>
        <b>ملاحظات</b>
      </StyledHeading>
      <TextField
        fullWidth
        margin='normal'
        // disabled={edit}
        size='small'
        disabled={edit}
        label='ملاحظات'
        variant='outlined'
        id=''
        value={note}
        name='note'
        onChange={(e) => onChange(e, id)}
        inputProps={{
          maxLength: 10,
        }}
      />
      {/* </Stack> */}
    </div>
  );
};
export default DescrptionComponnet;
const StyledHeading = styled(Typography)({
  minWidth: 120,
  textAlign: 'center',
});
