import { Button, Grid, NativeSelect, Typography } from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const AdministrativeComuncations = ({ history }) => {
  const useStyles = makeStyles({
    root: {
      textAlign: 'center',
      padding: '20px',
    },
    card: {
      paddingLeft: '100px',
      paddingRight: '100px',
    },
    paper: {
      margin: '10px',
      padding: '10px 10px 20px',
    },
    nextBtn: {
      justifyContent: 'center',
      paddingTop: '20px',
      display: 'flex',
    },
    table: {
      minWidth: 275,
      textAlign: 'center',
      padding: '15px',
      borderWidth: 1,
      borderStyle: 'solid',
      color: 'white',
    },
    contentTitle: {
      margin: 'auto',
      textAlign: 'center',
      //   backgroundColor: 'black',
    },
    container: {
      padding: '10px',
    },
    containerItem: {
      display: 'flex',
      textAlign: 'center',
      padding: '5px',
    },
  });
  const [formData, setFormData] = useState({
    companyName: '',
  });
  const { companyName } = formData;
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const classes = useStyles();
  const companies = [
    { companyCode: 'widco', companyName: 'وادي ابراهيم' },
    { companyCode: 'jas', companyName: 'الجوار السامي' },
    // { companyCode: 'jwar-awal', companyName: 'الجوار الاول' },
    // { companyCode: 'alhoukail', companyName: 'مؤسسة الحقيل' },
  ];
  const companiesOptions = companies.map((com) => (
    <option key={com.companyCode} value={com.companyCode}>
      {com.companyName}
    </option>
  ));
  let content = (
    <Grid item xs={12} className={classes.containerItem}>
      <Grid item xs={4}>
        <Button
          component={Link}
          to='/allOutGoing'
          fullWidth
          variant='contained'
          color='primary'
        >
          الصادر
        </Button>
      </Grid>
      <Grid item xs={4}></Grid>
      <Grid item xs={4}>
        <Button component={Link} to='/inComing' fullWidth variant='contained'>
          الوارد
        </Button>
      </Grid>
    </Grid>
  );
  const nextHandler = () => {
    console.log(formData.companyName);
    // const {companyName} = formda
    history.push(`/company/${companyName}`);
  };
  let content2 = (
    <Grid item xs={12} className={classes.card}>
      <Grid item xs={12} className={classes.contentTitle}>
        <Typography variant='body2'>اختر الشركة</Typography>
      </Grid>
      <Grid item xs={12}>
        <NativeSelect
          style={{
            margin: 'auto',
            paddingTop: '5px',
            paddingBottom: '5px',
          }}
          onChange={onChange}
          fullWidth
          required
          value={companyName ? companyName : ''}
          name='companyName'
          key='Status'
          inputProps={{
            id: 'Status',
          }}
        >
          <option></option>
          {companiesOptions}
        </NativeSelect>
      </Grid>
      <Grid item xs={12} className={classes.nextBtn}>
        <Button
          variant='contained'
          color='primary'
          component='span'
          // onClick={nextHandler}
          component={Link}
          to={`/${companyName}/`}
          disabled={companyName === '' ? true : false}
        >
          التالي
        </Button>
      </Grid>
    </Grid>
  );

  return (
    <Grid container>
      <Grid item xs={12} className={classes.root}>
        <Typography variant='h4'>الإتصالات الإدارية</Typography>
      </Grid>
      {content2}
    </Grid>
  );
};
export default AdministrativeComuncations;
