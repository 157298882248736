import {
  Button,
  Card,
  Checkbox,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';
import {
  fetchAqarByAqarNumber,
  fetchHaser,
  updateAqarHaser,
} from '../../../actions/aqarat';

import AccessForbidden from './AccessForbidden';
import AlertDialog from '../AlertDialog';
import Sidebar from '../Sidbar';
// import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import Spinner from '../Spinner';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { printHaserDoc } from '../../../actions/printDocs';

const initialState = {
  aqar_number: '',
  noa_almilkia: '',
  esem_almalik: '',
  raqam_alsak: '',
  masdar_alsak: '',
  tarek_alsak: '',
  almalik_id_number: '',
  esam_alwakel: '',
  alWakel_id_number: '',
};
const haserInitialState = {
  Buld_No: '',
  BuildingNo: '',
  Aqar_Type: '',
  HasrRemarks: '',
  Tree_No: '',
  Palm_No: '',
  Land_Area: '',
  Buld_Dscr: '',
  Land_L: '',
  Grnd_Flr_No: '',
  Grnd_Constr_type: '',
  Bsmnt_No: '',
  Park_No: '',
  Mznin_No: '',
  Tank_No: '',
  Grd_Area: '',
  Fence_Len: '',
  Fence_Type: '',
  Rpted_Cons: '',
  Floor_No: '',
  Rpted_No: '',
  Rep_Area: '',
  LF_ConTyp: '',
  Last_Floor_No: '',
  LastFloor_Area: '',
  Roof_No: '',
  RoofCh_Area: '',
  Roof_Constr_type: '',
  Roof_Area: '',
  RoofCh_No: '',
  RoofCh_Const_type: '',
  Mbit_Constr_type: '',
  Mbitat_No: '',
  Mbitat_Area: '',
  RoofWall_Type: '',
  RoofWall_Area: '',
  MultiBuilding: '',
  LayerDup: '',
  VisitRequest: '',
  HasrComplete: '',
  HasrSigned: '',
  Pillar_No: '',
  Mzn_Cns: '',
  Bsm_Cns: '',
  GrRm_No: '',
  GrRm_Cns: '',
  Pool_No: '',
};
const AlHasser = ({
  match,
  auth: { user },
  aqar: { loading, data },
  fetchAqarByAqarNumber,
  haser: { haser },
  fetchHaser,
  history,
  isLoading,
  updateAqarHaser,
  printHaserDoc,
}) => {
  const { params } = match;
  const { id } = params;
  const [haserData, setHaserData] = useState({
    Buld_No: '',
    BuildingNo: '',
    Aqar_Type: '',
    HasrRemarks: '',
    Tree_No: '',
    Palm_No: '',
    Land_Area: '',
    Buld_Dscr: '',
    Land_L: '',
    Grnd_Flr_No: '',
    Grnd_Constr_type: '',
    Bsmnt_No: '',
    Park_No: '',
    Mznin_No: '',
    Tank_No: '',
    Grd_Area: '',
    Fence_Len: '',
    Fence_Type: '',
    Rpted_Cons: '',
    Floor_No: '',
    Rpted_No: '',
    Rep_Area: '',
    LF_ConTyp: '',
    Last_Floor_No: '',
    LastFloor_Area: '',
    Roof_No: '',
    RoofCh_Area: '',
    Roof_Constr_type: '',
    Roof_Area: '',
    RoofCh_No: '',
    RoofCh_Const_type: '',
    Mbit_Constr_type: '',
    Mbitat_No: '',
    Mbitat_Area: '',
    RoofWall_Type: '',
    RoofWall_Area: '',
    MultiBuilding: '',
    LayerDup: '',
    VisitRequest: '',
    // VisitResoan: '',
    HasrComplete: '',
    HasrSigned: '',
    Pillar_No: '',
    Mzn_Cns: '',
    Bsm_Cns: '',
    GrRm_No: '',
    GrRm_Cns: '',
    Pool_No: '',
  });
  const [formData, setFormData] = useState({
    aqar_number: '',
    noa_almilkia: '',
    esem_almalik: '',
    raqam_alsak: '',
    masdar_alsak: '',
    tarek_alsak: '',
    almalik_id_number: '',
    esam_alwakel: '',
    alWakel_id_number: '',
  });
  useEffect(() => {
    if (!haser) fetchHaser(id);
    if (haser) {
      const hsData = { ...haserInitialState };
      for (const key in haser) {
        if (key in hsData) hsData[key] = haser[key];
      }
      setHaserData(hsData);
    }
  }, [haser, fetchHaser]);
  useEffect(() => {
    if (!data) fetchAqarByAqarNumber(id);
    if (!loading && data) {
      const aqarData = { ...initialState };
      for (const key in data) {
        if (key in aqarData) aqarData[key] = data[key];
      }
      setFormData(aqarData);
      // console.log("aqarData", aqarData);
    }
  }, [loading, fetchAqarByAqarNumber, data, id]);
  const useStyles = makeStyles({
    root: {
      minWidth: 275,
      textAlign: 'center',
      padding: '15px',
    },
    table: {
      minWidth: 275,
      textAlign: 'center',
      padding: '15px',
      borderWidth: 1,
      borderColor: '#565656',
      borderStyle: 'solid',
      color: 'white',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    contentTitle: {
      margin: 'auto',
    },
    darkCell: {
      backgroundColor: '#ccc',
      // borderWidth: 1,
      // borderColor: '#565656',
      // borderStyle: 'solid',
    },
    titleCell: {
      backgroundColor: '#ccc',
      color: 'whit',
      width: '100%',
    },
  });
  const classes = useStyles();
  const onSubmit = (e) => {
    e.preventDefault();
  };

  const {
    aqar_number,
    noa_almilkia,
    esem_almalik,
    raqam_alsak,
    masdar_alsak,
    tarek_alsak,
    almalik_id_number,
    esam_alwakel,
    alWakel_id_number,
  } = formData;
  const {
    Aqar_Type,
    HasrRemarks,
    Tree_No,
    Palm_No,
    Land_L,
    Grnd_Flr_No,
    Grnd_Constr_type,
    Bsmnt_No,
    Park_No,
    Mznin_No,
    Tank_No,
    Fence_Len,
    Fence_Type,
    Rpted_Cons,
    Rpted_No,
    LF_ConTyp,
    Last_Floor_No,
    Roof_No,
    Roof_Constr_type,
    RoofCh_No,
    RoofCh_Const_type,
    Mbit_Constr_type,
    Mbitat_No,
    RoofWall_Type,
    RoofWall_Area,
    HasrComplete,
    HasrSigned,
    Pillar_No,
    Mzn_Cns,
    Bsm_Cns,
    GrRm_No,
    GrRm_Cns,
    Pool_No,
  } = haserData;

  const [enable, setEnable] = useState(true);

  const onChange = (e) => {
    setHaserData({ ...haserData, [e.target.name]: e.target.value });
  };
  const onCheckboxChange = (event) => {
    const name = event.target.name;
    setHaserData({ ...haserData, [name]: +event.target.checked });
  };
  const enableEdit = () => {
    setEnable(!enable);
  };
  const printHaser = () => {
    printHaserDoc(id);
  };
  const handleSave = () => {
    updateAqarHaser(id, haserData);
  };
  // const componentRef = useRef();
  // if (user.department === '2' && user.roll.group_name === 'الحصر') {
  return (
    <Fragment>
      {data === null || loading ? (
        <Spinner />
      ) : (
        <Grid container spacing={4}>
          {data.molahthat ? (
            <AlertDialog molahthat={data.molahthat} isOpen={true} />
          ) : null}
          <Grid item xs={2}>
            <Sidebar proprtyId={id} />
          </Grid>
          {loading ? (
            <Spinner />
          ) : (
            <Grid item xs={10}>
              <Card className={classes.root}>
                <form onSubmit={(e) => onSubmit(e)}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant='button' display='block'>
                        رقم العقار {id}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant='body1'>محضر الحصر</Typography>
                    </Grid>
                    <Grid item xs={5} className={classes.contentTitle}>
                      <Typography variant='body2'>حالة الحصر</Typography>
                    </Grid>
                    <Grid item xs={2} className={classes.contentTitle}>
                      {/* <Typography variant='body2'>حالة الحصر</Typography> */}
                      <Button
                        onClick={printHaser}
                        fullWidth
                        variant='contained'
                        color='primary'
                        disabled={isLoading.isLoading}
                      >
                        طباعة
                      </Button>
                    </Grid>
                    <Grid item xs={2} className={classes.contentTitle}></Grid>
                    <Grid item xs={2} className={classes.contentTitle}>
                      {user.id === 47 || user.id === 75 || user.id === 35 ? (
                        <Button
                          onClick={enableEdit}
                          fullWidth
                          variant='contained'
                          color='primary'
                        >
                          تعديل
                        </Button>
                      ) : null}
                    </Grid>
                    <Grid item xs={2} className={classes.contentTitle}>
                      <Typography variant='body2'>نوع المليكة</Typography>
                    </Grid>
                    <Grid item xs={4} className={classes.contentTitle}>
                      <TextField
                        fullWidth
                        value={noa_almilkia}
                        margin='normal'
                        size='small'
                        disabled
                        variant='outlined'
                        id=''
                        onChange={onChange}
                        name='mlahzat_aletsal'
                      />
                    </Grid>
                    <Grid item xs={2} className={classes.contentTitle}>
                      <Typography variant='body2'>رقم العقار</Typography>
                    </Grid>
                    <Grid item xs={4} className={classes.contentTitle}>
                      <TextField
                        fullWidth
                        value={aqar_number}
                        margin='normal'
                        size='small'
                        disabled
                        variant='outlined'
                        id=''
                        // value={mlahzat_aletsal}
                        onChange={onChange}
                        name='mlahzat_aletsal'
                      />
                    </Grid>
                    <Grid item xs={2} className={classes.contentTitle}>
                      <Typography variant='body2'>اسم المالك</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        value={esem_almalik}
                        margin='normal'
                        size='small'
                        variant='outlined'
                        id=''
                        disabled
                        // value={mlahzat_aletsal}
                        onChange={onChange}
                        name='mlahzat_aletsal'
                      />
                    </Grid>
                    <Grid item xs={2} className={classes.contentTitle}>
                      <Typography variant='body2'>رقم هوية المالك</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        id='almalik_id_number'
                        fullWidth
                        margin='normal'
                        size='small'
                        variant='outlined'
                        disabled
                        value={almalik_id_number}
                        onChange={onChange}
                        name='almalik_id_number'
                      />
                    </Grid>
                    <Grid item xs={2} className={classes.contentTitle}>
                      <Typography variant='body2'>اسم الوكيل</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        margin='normal'
                        size='small'
                        id='esam_alwakel'
                        variant='outlined'
                        disabled
                        value={esam_alwakel}
                        onChange={onChange}
                        name='esam_alwakel'
                      />
                    </Grid>
                    <Grid item xs={2} className={classes.contentTitle}>
                      <Typography variant='body2'>رقم هوية الوكيل</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        margin='normal'
                        size='small'
                        variant='outlined'
                        id='alWakel_id_number'
                        disabled
                        value={alWakel_id_number}
                        onChange={onChange}
                        name='alWakel_id_number'
                      />
                    </Grid>
                    <Grid item xs={2} className={classes.contentTitle}>
                      <Typography variant='body2'>رقم الصك/الوثيقة</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        fullWidth
                        margin='normal'
                        size='small'
                        variant='outlined'
                        id=''
                        disabled
                        value={raqam_alsak}
                        onChange={onChange}
                        name='mlahzat_aletsal'
                      />
                    </Grid>
                    <Grid item xs={2} className={classes.contentTitle}>
                      <Typography variant='body2'> تاريخه/تاريخها</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        fullWidth
                        value={tarek_alsak}
                        margin='normal'
                        size='small'
                        variant='outlined'
                        id=''
                        disabled
                        // value={mlahzat_aletsal}
                        onChange={onChange}
                        name='mlahzat_aletsal'
                      />
                    </Grid>
                    <Grid item xs={2} className={classes.contentTitle}>
                      <Typography variant='body2'>مصدره/مصدرها</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        fullWidth
                        value={masdar_alsak}
                        margin='normal'
                        size='small'
                        variant='outlined'
                        id=''
                        disabled
                        // value={mlahzat_aletsal}
                        onChange={onChange}
                        name='mlahzat_aletsal'
                      />
                    </Grid>
                    <TableContainer>
                      <Table className={classes.table}>
                        <TableBody>
                          <TableRow className={classes.titleCell}>
                            <TableCell
                              className={classes.titleCell}
                              align='center'
                              colSpan={9}
                            >
                              مشتملات العقار
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              className={classes.darkCell}
                              align='center'
                              colSpan={1}
                            >
                              نوع العقار
                            </TableCell>
                            <TableCell colSpan={3} align='center'>
                              <TextField
                                size='small'
                                disabled={enable}
                                value={Aqar_Type}
                                onChange={onChange}
                                name='Aqar_Type'
                                id='Aqar_Type'
                              />
                            </TableCell>
                            <TableCell
                              align='center'
                              className={classes.darkCell}
                            >
                              عدد
                            </TableCell>
                            <TableCell
                              align='center'
                              className={classes.darkCell}
                            >
                              نوع الانشاء
                            </TableCell>
                            <TableCell
                              align='center'
                              className={classes.darkCell}
                            >
                              {' '}
                            </TableCell>
                            <TableCell
                              align='center'
                              className={classes.darkCell}
                            >
                              عدد
                            </TableCell>
                            <TableCell
                              align='center'
                              className={classes.darkCell}
                            >
                              نوع
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              className={classes.darkCell}
                              align='center'
                              colSpan={1}
                            ></TableCell>
                            <TableCell
                              colSpan={1}
                              align='center'
                              className={classes.darkCell}
                            >
                              عدد
                            </TableCell>
                            <TableCell
                              colSpan={1}
                              align='center'
                              className={classes.darkCell}
                            >
                              نوع الانشاء
                            </TableCell>
                            <TableCell
                              colSpan={1}
                              align='center'
                              className={classes.darkCell}
                            >
                              متكرر
                            </TableCell>
                            <TableCell colSpan={1} align='center'>
                              <TextField
                                size='small'
                                disabled={enable}
                                value={Rpted_No}
                                onChange={onChange}
                                name='Rpted_No'
                                id='Rpted_No'
                              />
                            </TableCell>
                            <TableCell colSpan={1} align='center'>
                              <TextField
                                size='small'
                                disabled={enable}
                                onChange={onChange}
                                value={Rpted_Cons}
                                name='Rpted_Cons'
                                id='Rpted_Cons'
                              />
                            </TableCell>
                            <TableCell
                              colSpan={1}
                              align='center'
                              className={classes.darkCell}
                            >
                              الاعمدة
                            </TableCell>
                            <TableCell colSpan={1} align='center'>
                              <TextField
                                size='small'
                                disabled={enable}
                                onChange={onChange}
                                value={Pillar_No}
                                name='Pillar_No'
                                id='Pillar_No'
                              />
                            </TableCell>
                            <TableCell colSpan={1} align='center'>
                              {/* <TextField size="small" disabled={enable} /> */}
                            </TableCell>
                          </TableRow>
                          {/* BEDROOM */}
                          <TableRow>
                            <TableCell
                              className={classes.darkCell}
                              align='center'
                              colSpan={1}
                            >
                              بدروم
                            </TableCell>
                            <TableCell colSpan={1} align='center'>
                              <TextField
                                size='small'
                                disabled={enable}
                                onChange={onChange}
                                value={Bsmnt_No}
                                name='Bsmnt_No'
                                id='Bsmnt_No'
                              />
                            </TableCell>
                            <TableCell colSpan={1} align='center'>
                              <TextField
                                size='small'
                                disabled={enable}
                                onChange={onChange}
                                value={Bsm_Cns}
                                name='Bsm_Cns'
                                id='Bsm_Cns'
                              />
                            </TableCell>
                            <TableCell
                              colSpan={1}
                              align='center'
                              className={classes.darkCell}
                            >
                              متكرر متغير
                            </TableCell>
                            <TableCell colSpan={1} align='center'>
                              <TextField
                                size='small'
                                disabled={enable}
                                onChange={onChange}
                                value={Last_Floor_No}
                                name='Last_Floor_No'
                                id='Last_Floor_No'
                              />
                            </TableCell>
                            <TableCell colSpan={1} align='center'>
                              <TextField
                                size='small'
                                disabled={enable}
                                onChange={onChange}
                                value={LF_ConTyp}
                                name='LF_ConTyp'
                                id='LF_ConTyp'
                              />
                            </TableCell>
                            <TableCell
                              colSpan={1}
                              align='center'
                              className={classes.darkCell}
                            >
                              شجر
                            </TableCell>
                            <TableCell colSpan={1} align='center'>
                              <TextField
                                size='small'
                                disabled={enable}
                                onChange={onChange}
                                value={Tree_No}
                                name='Tree_No'
                                id='Tree_No'
                              />
                            </TableCell>
                            <TableCell colSpan={1} align='center'>
                              {/* <TextField size="small" disabled={enable} /> */}
                            </TableCell>
                          </TableRow>
                          {/* ARDEE */}
                          <TableRow>
                            <TableCell
                              className={classes.darkCell}
                              align='center'
                              colSpan={1}
                            >
                              أرضي
                            </TableCell>
                            <TableCell colSpan={1} align='center'>
                              <TextField
                                size='small'
                                disabled={enable}
                                onChange={onChange}
                                value={Grnd_Flr_No}
                                name='Grnd_Flr_No'
                                id='Grnd_Flr_No'
                              />
                            </TableCell>
                            <TableCell colSpan={1} align='center'>
                              <TextField
                                size='small'
                                disabled={enable}
                                onChange={onChange}
                                value={Grnd_Constr_type}
                                name='Grnd_Constr_type'
                                id='Grnd_Constr_type'
                              />
                            </TableCell>
                            <TableCell
                              colSpan={1}
                              align='center'
                              className={classes.darkCell}
                            >
                              السقف
                            </TableCell>
                            <TableCell colSpan={1} align='center'>
                              <TextField
                                size='small'
                                disabled={enable}
                                onChange={onChange}
                                value={Roof_No}
                                name='Roof_No'
                                id='Roof_No'
                              />
                            </TableCell>
                            <TableCell colSpan={1} align='center'>
                              <TextField
                                size='small'
                                disabled={enable}
                                onChange={onChange}
                                value={Roof_Constr_type}
                                name='Roof_Constr_type'
                                id='Roof_Constr_type'
                              />
                            </TableCell>
                            <TableCell
                              colSpan={1}
                              align='center'
                              className={classes.darkCell}
                            >
                              نخل
                            </TableCell>
                            <TableCell colSpan={1} align='center'>
                              <TextField
                                size='small'
                                disabled={enable}
                                onChange={onChange}
                                value={Palm_No}
                                name='Palm_No'
                                id='Palm_No'
                              />
                            </TableCell>
                            <TableCell colSpan={1} align='center'>
                              {/* <TextField size="small" disabled={enable} /> */}
                            </TableCell>
                          </TableRow>
                          {/* ميزانين */}
                          <TableRow>
                            <TableCell
                              className={classes.darkCell}
                              align='center'
                              colSpan={1}
                            >
                              ملحق أرضي
                            </TableCell>
                            <TableCell colSpan={1} align='center'>
                              <TextField
                                size='small'
                                disabled={enable}
                                onChange={onChange}
                                value={GrRm_No}
                                name='GrRm_No'
                                id='GrRm_No'
                              />
                            </TableCell>
                            <TableCell colSpan={1} align='center'>
                              <TextField
                                size='small'
                                disabled={enable}
                                onChange={onChange}
                                value={GrRm_Cns}
                                name='GrRm_Cns'
                                id='GrRm_Cns'
                              />
                            </TableCell>
                            <TableCell
                              colSpan={1}
                              align='center'
                              className={classes.darkCell}
                            >
                              السقف المتغير
                            </TableCell>
                            <TableCell colSpan={1} align='center'>
                              <TextField
                                size='small'
                                disabled={enable}
                                onChange={onChange}
                                value={RoofCh_No}
                                name='RoofCh_No'
                                id='RoofCh_No'
                              />
                            </TableCell>
                            <TableCell colSpan={1} align='center'>
                              <TextField
                                size='small'
                                disabled={enable}
                                onChange={onChange}
                                value={RoofCh_Const_type}
                                name='RoofCh_Const_type'
                                id='RoofCh_Const_type'
                              />
                            </TableCell>
                            <TableCell
                              colSpan={1}
                              align='center'
                              className={classes.darkCell}
                            >
                              تسوية
                            </TableCell>
                            <TableCell colSpan={1} align='center'>
                              <TextField
                                size='small'
                                disabled={enable}
                                onChange={onChange}
                                value={Land_L}
                                name='Land_L'
                                id='Land_L'
                              />
                            </TableCell>
                            <TableCell colSpan={1} align='center'>
                              {/* <TextField size="small" disabled={enable} /> */}
                            </TableCell>
                          </TableRow>
                          {/* جراج */}
                          <TableRow>
                            <TableCell
                              className={classes.darkCell}
                              align='center'
                              colSpan={1}
                            >
                              ميزانين
                            </TableCell>
                            <TableCell colSpan={1} align='center'>
                              <TextField
                                size='small'
                                disabled={enable}
                                onChange={onChange}
                                value={Mznin_No}
                                name='Mznin_No'
                                id='Mznin_No'
                              />
                            </TableCell>
                            <TableCell colSpan={1} align='center'>
                              <TextField
                                size='small'
                                disabled={enable}
                                onChange={onChange}
                                value={Mzn_Cns}
                                name='Mzn_Cns'
                                id='Mzn_Cns'
                              />
                            </TableCell>
                            <TableCell
                              colSpan={1}
                              align='center'
                              className={classes.darkCell}
                            >
                              سترة
                            </TableCell>
                            <TableCell colSpan={1} align='center'>
                              <TextField
                                size='small'
                                disabled={enable}
                                onChange={onChange}
                                value={RoofWall_Area}
                                name='RoofWall_Area'
                                id='RoofWall_Area'
                              />
                            </TableCell>
                            <TableCell colSpan={1} align='center'>
                              <TextField
                                size='small'
                                disabled={enable}
                                onChange={onChange}
                                value={RoofWall_Type}
                                name='RoofWall_Type'
                                id='RoofWall_Type'
                              />
                            </TableCell>
                            <TableCell
                              className={classes.darkCell}
                              align='center'
                              colSpan={1}
                            >
                              جراج
                            </TableCell>
                            <TableCell colSpan={1} align='center'>
                              <TextField
                                size='small'
                                disabled={enable}
                                onChange={onChange}
                                value={Park_No}
                                name='Park_No'
                                id='Park_No'
                              />
                            </TableCell>
                            <TableCell colSpan={1} align='center'>
                              {/* <TextField size="small" disabled={enable} /> */}
                            </TableCell>
                          </TableRow>
                          {/* خزان */}
                          <TableRow>
                            <TableCell
                              colSpan={1}
                              align='center'
                              className={classes.darkCell}
                            >
                              الأسوار
                            </TableCell>
                            <TableCell colSpan={1} align='center'>
                              <TextField
                                size='small'
                                disabled={enable}
                                onChange={onChange}
                                value={Fence_Len}
                                name='Fence_Len'
                                id='Fence_Len'
                              />
                            </TableCell>
                            <TableCell colSpan={1} align='center'>
                              <TextField
                                size='small'
                                disabled={enable}
                                onChange={onChange}
                                value={Fence_Type}
                                name='Fence_Type'
                                id='Fence_Type'
                              />
                            </TableCell>
                            <TableCell
                              colSpan={1}
                              align='center'
                              className={classes.darkCell}
                            >
                              المبيتات
                            </TableCell>
                            <TableCell colSpan={1} align='center'>
                              <TextField
                                size='small'
                                disabled={enable}
                                onChange={onChange}
                                value={Mbitat_No}
                                name='Mbitat_No'
                                id='Mbitat_No'
                              />
                            </TableCell>
                            <TableCell colSpan={1} align='center'>
                              <TextField
                                size='small'
                                disabled={enable}
                                onChange={onChange}
                                value={Mbit_Constr_type}
                                name='Mbit_Constr_type'
                                id='Mbit_Constr_type'
                              />
                            </TableCell>
                            <TableCell
                              className={classes.darkCell}
                              align='center'
                              colSpan={1}
                            >
                              خزان
                            </TableCell>
                            <TableCell colSpan={1} align='center'>
                              <TextField
                                size='small'
                                disabled={enable}
                                onChange={onChange}
                                value={Tank_No}
                                name='Tank_No'
                                id='Tank_No'
                              />
                            </TableCell>
                            <TableCell colSpan={1} align='center'>
                              {/* <TextField size="small" disabled={enable} /> */}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              colSpan={1}
                              align='center'
                              className={classes.darkCell}
                            >
                              اخرى
                            </TableCell>
                            <TableCell colSpan={1} align='center'>
                              <TextField
                                size='small'
                                disabled={enable}
                                onChange={onChange}
                                value={Tank_No}
                                name='Tank_No'
                                id='Tank_No'
                              />
                              {/* <TextField size="small" disabled={enable} /> */}
                            </TableCell>
                            <TableCell
                              colSpan={4}
                              className={classes.darkCell}
                              align='center'
                            >
                              {/* <TextField size="small" disabled={enable} /> */}
                            </TableCell>
                            <TableCell
                              className={classes.darkCell}
                              align='center'
                              colSpan={1}
                            >
                              مسبح
                            </TableCell>
                            <TableCell colSpan={1} align='center'>
                              <TextField
                                size='small'
                                disabled={enable}
                                onChange={onChange}
                                value={Pool_No}
                                name='Pool_No'
                                id='Pool_No'
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              colSpan={2}
                              align='center'
                              className={classes.darkCell}
                            >
                              تم الانتهاء من الحصر
                            </TableCell>
                            <TableCell colSpan={2} align='center'>
                              <Checkbox
                                color='primary'
                                onChange={onCheckboxChange}
                                disabled={enable}
                                name='HasrComplete'
                                checked={!!HasrComplete}
                                inputProps={{
                                  'aria-label': 'secondary checkbox',
                                }}
                              />
                            </TableCell>
                            <TableCell
                              colSpan={3}
                              align='center'
                              className={classes.darkCell}
                            >
                              تم توقيع الحصر من قبل المالك
                            </TableCell>
                            <TableCell colSpan={2} align='center'>
                              <Checkbox
                                color='primary'
                                disabled={enable}
                                checked={!!HasrSigned}
                                name='HasrSigned'
                                onChange={onCheckboxChange}
                                inputProps={{
                                  'aria-label': 'secondary checkbox',
                                }}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              colSpan={2}
                              align='center'
                              className={classes.darkCell}
                            >
                              ملاحظات الحصر
                            </TableCell>
                            <TableCell colSpan={7} align='center'>
                              <TextField
                                multiline
                                rows={4}
                                onChange={onChange}
                                value={HasrRemarks}
                                name='HasrRemarks'
                                id='HasrRemarks'
                                disabled={enable}
                                fullWidth
                              />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Button
                      type='submit'
                      fullWidth
                      disabled={enable}
                      variant='contained'
                      onClick={handleSave}
                      color='primary'
                    >
                      حفظ
                    </Button>
                  </Grid>
                </form>
                {/* END OF FORM */}
              </Card>
            </Grid>
          )}
        </Grid>
      )}
    </Fragment>
  );
  // } else {
  //   return <AccessForbidden />;
  // }
};
const mapStateToProps = (state) => ({
  aqar: state.aqar,
  haser: state.haser,
  auth: state.auth,
  isLoading: state.isLoading,
});
export default connect(mapStateToProps, {
  fetchAqarByAqarNumber,
  fetchHaser,
  updateAqarHaser,
  printHaserDoc,
})(AlHasser);
