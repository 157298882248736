import {
  FETCH_DEPARTMENT_BY_ID,
  ERROR_FETCH_DEPARTMENT_BY_ID,
} from '../actions/types';

const initialState = {
  department: {},
  loading: true,
};
export default function (state = initialState, action) {
  const { type, payload } = action;
  // console.log('state', payload);
  switch (type) {
    case FETCH_DEPARTMENT_BY_ID:
      return {
        ...state,
        department: payload,
        loading: false,
      };
    case ERROR_FETCH_DEPARTMENT_BY_ID:
      return { ...state, department: null, loading: true };
    default:
      return state;
  }
}
