import {
  SET_ALERT,
  REMOVE_ALERT,
  SET_IS_LOADING,
  REMOVE_IS_LOADING,
} from "./types";
import { v4 as uuidV4 } from "uuid";
export const setAlert = (
  msg,
  alertType,
  alertTitle = "خطأ",
  timeout = 5000
) => (dispatch) => {
  const id = uuidV4();
  dispatch({
    type: SET_ALERT,
    payload: { msg, alertType, id, alertTitle },
  });
  setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), timeout);
};

export const setIsLoading = () => (dispatch) => {
  dispatch({ type: SET_IS_LOADING });
};
export const removeIsLoading = () => (dispatch) => {
  dispatch({ type: REMOVE_IS_LOADING });
};
