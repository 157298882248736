import {
  ERROR_FETCH_INCOMING,
  ERROR_FETCH_INCOMING_REFFS,
  ERROR_FETCH_IN_COMING_FIELS,
  FETCH_INCOMING,
  FETCH_INCOMING_REFFS,
  FETCH_IN_COMING_FIELS,
} from '../actions/types';

const initialState = {
  loading: true,
  data: null,
  recReff: null,
  docments: null,
};
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_INCOMING:
      return { ...state, data: payload, loading: false };
    case FETCH_INCOMING_REFFS:
      return { ...state, recReff: payload, loading: false };
    case ERROR_FETCH_INCOMING_REFFS:
      return { ...state, recReff: null, loading: true };
    case FETCH_IN_COMING_FIELS:
      return { ...state, docments: payload, loading: true };
    case ERROR_FETCH_IN_COMING_FIELS:
      return { ...state, docments: null, loading: true };

    case ERROR_FETCH_INCOMING:
      return { ...state, data: null, loading: true };
    default:
      return state;
  }
}
