import {
  Button,
  Card,
  Checkbox,
  Grid,
  NativeSelect,
  TextField,
  Typography,
} from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { Fragment, useEffect, useState } from 'react';
import {
  fetchAqarByAqarNumber,
  updateAqarFollowUp,
} from '../../../actions/aqarat';

import AccessForbidden from './AccessForbidden';
import AlertDialog from '../AlertDialog';
import HijriUtils from '@date-io/hijri';
import MuiAlert from '@material-ui/lab/Alert';
import Sidebar from '../Sidbar';
import Spinner from '../Spinner';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { printHaserDoc } from '../../../actions/printDocs';

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}
const initialState = {
  tathmen_signature: '',
  noa_almilkia: '',
  kroki_signature: '',
  consultant_signature: '',
  company_signature: '',
  consultant_kroki: '',
  moragat_mshtmlat_alaqar: '',
  mlahzat_aletsal: '',
  halet_tawqee_almalik: '',
  halet_alhaser: '',
  etsal_belmalik: '',
  molahthat_alqanonih: '',
  motnaza_alih: '',
  momtanee: '',
  demolished: '',
  compensated: '',
  removal_services: '',
  DscLetterReceived: '',
  tarek_alwkala: '',
  ElecteicityDscLetterReceived: '',
  WaterDscLetterReceived: '',
  DeedSequenceLetterSend: '',
  DeedSequenceLetterNote: '',
  DeedSequenceLetterMatched: '',
  DeedCheckLetterReceived: '',
  owner_kroki_rejection: '',
  owner_kroki_rejection_note: '',
  print_haser: '',
  REDFLetterReceived: '',
  ADFLetterReceived: '',
  cadCheckBox: '',
  gisCheckBox: '',
  deh: '',
  dehDate: '',
  dehNumber: '',
  halet_alhaser_note: '',
  // AnqathLetter: '',
};

const FollowUp = ({
  match,
  aqar: { loading, data },
  fetchAqarByAqarNumber,
  updateAqarFollowUp,
  printHaserDoc,
  history,
  auth: { user },
}) => {
  const { params } = match;
  const { id } = params;
  const useStyles = makeStyles({
    root: {
      minWidth: 275,
      textAlign: 'center',
      padding: '15px',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    contentTitle: {
      margin: 'auto',
    },
    demolishedStyle: {
      backgroundColor: 'blue',
    },
    compensatedStyle: {
      backgroundColor: 'orange',
    },
    compensatedAnddemolished: {
      backgroundColor: 'green',
    },
  });
  const classes = useStyles();
  const onSubmit = (e) => {
    e.preventDefault();
    if (dehDate) {
      formData.dehDate = moment(dehDate).format('YYYY-MM-DD HH:mm:ss');
    }
    updateAqarFollowUp(data.aqar_number, formData, history);
  };
  const [formData, setFormData] = useState({
    tathmen_signature: '',
    noa_almilkia: '',
    kroki_signature: '',
    consultant_signature: '',
    company_signature: '',
    consultant_kroki: '',
    moragat_mshtmlat_alaqar: '',
    mlahzat_aletsal: '',
    halet_tawqee_almalik: '',
    halet_alhaser: '',
    etsal_belmalik: '',
    molahthat_alqanonih: '',
    motnaza_alih: '',
    momtanee: '',
    demolished: '',
    compensated: '',
    removal_services: '',
    DscLetterReceived: '',
    tarek_alwkala: '',
    ElecteicityDscLetterReceived: '',
    WaterDscLetterReceived: '',
    DeedSequenceLetterSend: '',
    DeedSequenceLetterNote: '',
    DeedSequenceLetterMatched: '',
    DeedCheckLetterReceived: '',
    owner_kroki_rejection: '',
    owner_kroki_rejection_note: '',
    print_haser: '',
    REDFLetterReceived: '',
    ADFLetterReceived: '',
    cadCheckBox: '',
    gisCheckBox: '',
    deh: '',
    dehDate: null,
    dehNumber: '',
    halet_alhaser_note: '',
    // AnqathLetter: '',
  });
  const {
    tathmen_signature,
    kroki_signature,
    consultant_signature,
    company_signature,
    consultant_kroki,
    moragat_mshtmlat_alaqar,
    halet_alhaser,
    molahthat_alqanonih,
    motnaza_alih,
    momtanee,
    demolished,
    compensated,
    removal_services,
    tarek_alwkala,
    ElecteicityDscLetterReceived,
    WaterDscLetterReceived,
    DeedSequenceLetterSend,
    DeedSequenceLetterNote,
    DeedSequenceLetterMatched,
    DeedCheckLetterReceived,
    owner_kroki_rejection,
    owner_kroki_rejection_note,
    print_haser,
    REDFLetterReceived,
    ADFLetterReceived,
    cadCheckBox,
    gisCheckBox,
    deh,
    dehDate,
    dehNumber,
    halet_alhaser_note,
  } = formData;
  useEffect(() => {
    if (!data) fetchAqarByAqarNumber(id);
    console.log('useEffect');
    if (!loading && data) {
      const aqarData = { ...initialState };
      for (const key in data) {
        if (key in aqarData) aqarData[key] = data[key];
      }
      setFormData(aqarData);
    }
  }, [loading, fetchAqarByAqarNumber, data, id]);
  const onChange = (e) => {
    console.log(e.target.value);
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const genratePDF = () => {
    printHaserDoc(id);
  };
  var aqarClass = '';
  // console.log(
  //   'user.employee_id != 905 ||',
  //   user.employee_id != 905 ||
  //     user.employee_id != '55547' ||
  //     user.employee_id != 906
  // );
  const compaerTarekAlwakala = () => {
    const d1 = new Date();
    if (tarek_alwkala) {
      if (d1 >= new Date(tarek_alwkala)) {
        return true;
      }
    }
  };

  // if (demolished && compensated) {
  //   aqarClass = classes.compensatedAnddemolished;
  // }
  // if (demolished && !compensated) {
  //   aqarClass = classes.demolishedStyle;
  // }
  // if (compensated && !demolished) {
  //   aqarClass = classes.compensatedStyle;
  // }
  const onCheckboxChange = (event) => {
    const name = event.target.name;
    setFormData({ ...formData, [name]: +event.target.checked });
  };
  const [edit, setEdit] = useState(true);
  const enableEdit = () => {
    setEdit(!edit);
  };
  const handleDateChange = (date) => {
    setFormData({ ...formData, dehDate: date });
  };
  if (
    user.department &&
    (user.roll.group_name === 'ادخال البيانات' ||
      user.roll.group_name === 'ادخال البيانات ٢' ||
      user.roll.group_name === 'الارشيف' ||
      user.roll.group_name === 'المهندسين')
  ) {
    return (
      <Fragment>
        {data === null || loading ? (
          <Spinner />
        ) : (
          <Grid container spacing={4}>
            {data.molahthat ? (
              <AlertDialog molahthat={data.molahthat} isOpen={true} />
            ) : null}
            <Grid item xs={2}>
              <Sidebar proprtyId={id} />
            </Grid>
            {loading ? (
              <Spinner />
            ) : (
              <Grid item xs={10}>
                <Card className={classes.root}>
                  <form onSubmit={(e) => onSubmit(e)}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography
                          variant='h4'
                          display='block'
                          className={aqarClass}
                        >
                          {data.aqar_number}{' '}
                          {motnaza_alih ? (
                            <Alert severity='error'>متنازع عليه</Alert>
                          ) : null}
                          {compaerTarekAlwakala() ? (
                            <Alert severity='error'>
                              يرجى مراجعة تاريخ الوكالة
                            </Alert>
                          ) : null}
                        </Typography>
                      </Grid>
                      {user.roll.group_name === 'ادخال البيانات' ||
                      user.roll.group_name === 'ادخال البيانات ٢' ||
                      user.roll.group_name === 'المهندسين' ? (
                        <Fragment>
                          <Grid item xs={12}>
                            <Typography variant='body1'>حالة العقار</Typography>
                            {user.roll.group_name ? (
                              user.roll.group_name === 'المهندسين' ? null : (
                                <Button
                                  className={classes.butns}
                                  // fullWidth
                                  variant='contained'
                                  color='primary'
                                  onClick={enableEdit}
                                >
                                  تحرير
                                </Button>
                              )
                            ) : null}
                          </Grid>
                          <Grid item xs={2} className={classes.contentTitle}>
                            <Typography variant='body2'>تمت الإزالة</Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Checkbox
                              checked={!!demolished}
                              name='demolished'
                              color='primary'
                              disabled={edit}
                              onChange={onCheckboxChange}
                              inputProps={{
                                'aria-label': 'secondary checkbox',
                              }}
                            />
                          </Grid>
                          <Grid item xs={2} className={classes.contentTitle}>
                            <Typography variant='body2'>تم التعويض</Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Checkbox
                              checked={!!compensated}
                              name='compensated'
                              color='primary'
                              disabled={edit}
                              onChange={onCheckboxChange}
                              inputProps={{
                                'aria-label': 'secondary checkbox',
                              }}
                            />
                          </Grid>
                          <Grid item xs={2} className={classes.contentTitle}>
                            <Typography variant='body2'>
                              تم فصل الخدمات
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Checkbox
                              checked={!!removal_services}
                              name='removal_services'
                              color='primary'
                              disabled={edit}
                              onChange={onCheckboxChange}
                              inputProps={{
                                'aria-label': 'secondary checkbox',
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant='body1'>ممتنع</Typography>
                          </Grid>
                          <Grid item xs={2} className={classes.contentTitle}>
                            <Typography variant='body2'>ممتنع</Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Checkbox
                              checked={!!momtanee}
                              name='momtanee'
                              disabled={edit}
                              color='primary'
                              onChange={onCheckboxChange}
                              inputProps={{
                                'aria-label': 'secondary checkbox',
                              }}
                            />
                          </Grid>
                          <Grid item xs={8}></Grid>
                          <Grid item xs={12}>
                            <Typography variant='body1'>
                              خطابات التصفية
                            </Typography>
                          </Grid>
                          <Grid item xs={2} className={classes.contentTitle}>
                            <Typography variant='body2'>الكهرباء</Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Checkbox
                              checked={!!ElecteicityDscLetterReceived}
                              name='ElecteicityDscLetterReceived'
                              color='primary'
                              disabled={edit}
                              onChange={onCheckboxChange}
                              inputProps={{
                                'aria-label': 'secondary checkbox',
                              }}
                            />
                          </Grid>
                          <Grid item xs={2} className={classes.contentTitle}>
                            <Typography variant='body2'>المياة</Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Checkbox
                              checked={!!WaterDscLetterReceived}
                              name='WaterDscLetterReceived'
                              color='primary'
                              disabled={edit}
                              onChange={onCheckboxChange}
                              inputProps={{
                                'aria-label': 'secondary checkbox',
                              }}
                            />
                          </Grid>
                          <Grid item xs={4}></Grid>
                          <Grid item xs={2} className={classes.contentTitle}>
                            <Typography variant='body2'>
                              صندوق التنمية العقارية
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Checkbox
                              checked={!!REDFLetterReceived}
                              name='REDFLetterReceived'
                              color='primary'
                              disabled={edit}
                              onChange={onCheckboxChange}
                              inputProps={{
                                'aria-label': 'secondary checkbox',
                              }}
                            />
                          </Grid>
                          <Grid item xs={2} className={classes.contentTitle}>
                            <Typography variant='body2'>
                              صندوق التنمية الزراعية
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Checkbox
                              checked={!!ADFLetterReceived}
                              name='ADFLetterReceived'
                              color='primary'
                              disabled={edit}
                              onChange={onCheckboxChange}
                              inputProps={{
                                'aria-label': 'secondary checkbox',
                              }}
                            />
                          </Grid>
                          <Grid item xs={4}></Grid>
                          <Grid item xs={8}></Grid>
                          <Grid item xs={8}></Grid>
                          <Grid item xs={12}>
                            <Typography variant='body1'>
                              خطابات الامانة
                            </Typography>
                          </Grid>
                          <Grid item xs={2} className={classes.contentTitle}>
                            <Typography variant='body2'>
                              مرسل تسلسل الصك
                            </Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <Checkbox
                              checked={!!DeedSequenceLetterSend}
                              name='DeedSequenceLetterSend'
                              color='primary'
                              disabled={edit}
                              onChange={onCheckboxChange}
                              inputProps={{
                                'aria-label': 'secondary checkbox',
                              }}
                            />
                          </Grid>
                          <Grid item xs={2} className={classes.contentTitle}>
                            <Typography variant='body2'>
                              مطابقة تسلسل الصك
                            </Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <Checkbox
                              checked={!!DeedSequenceLetterMatched}
                              name='DeedSequenceLetterMatched'
                              color='primary'
                              disabled={edit}
                              onChange={onCheckboxChange}
                              inputProps={{
                                'aria-label': 'secondary checkbox',
                              }}
                            />
                          </Grid>
                          <Grid item xs={2} className={classes.contentTitle}>
                            <Typography variant='body2'>آخرى</Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <TextField
                              fullWidth
                              margin='normal'
                              size='small'
                              disabled={edit}
                              label='ملاحظات في حال عدم المطابقة'
                              variant='outlined'
                              id=''
                              value={DeedSequenceLetterNote}
                              onChange={onChange}
                              name='DeedSequenceLetterNote'
                            />
                          </Grid>
                          <Grid item xs={2} className={classes.contentTitle}>
                            <Typography variant='body2'>فحص الملكية</Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Checkbox
                              checked={!!DeedCheckLetterReceived}
                              name='DeedCheckLetterReceived'
                              color='primary'
                              disabled={edit}
                              onChange={onCheckboxChange}
                              inputProps={{
                                'aria-label': 'secondary checkbox',
                              }}
                            />
                          </Grid>
                          <Grid item xs={8}></Grid>
                          <Grid item xs={12}>
                            <Typography variant='body1'>
                              الملفات الهندسية للعقار
                            </Typography>
                          </Grid>
                          <Grid item xs={2} className={classes.contentTitle}>
                            <Typography variant='body2'>الكاد</Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Checkbox
                              checked={!!cadCheckBox}
                              name='cadCheckBox'
                              color='primary'
                              disabled={edit}
                              onChange={onCheckboxChange}
                              inputProps={{
                                'aria-label': 'secondary checkbox',
                              }}
                            />
                          </Grid>
                          <Grid item xs={2} className={classes.contentTitle}>
                            <Typography variant='body2'>GIS</Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Checkbox
                              checked={!!gisCheckBox}
                              name='gisCheckBox'
                              color='primary'
                              disabled={edit}
                              onChange={onCheckboxChange}
                              inputProps={{
                                'aria-label': 'secondary checkbox',
                              }}
                            />
                          </Grid>
                          <Grid item xs={4}></Grid>
                          <Grid item xs={12}>
                            <Typography variant='body1'>
                              صك حصر الورثة
                            </Typography>
                          </Grid>
                          <Grid item xs={2} className={classes.contentTitle}>
                            <Typography variant='body2'>
                              صك حصر الورثة
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Checkbox
                              checked={!!deh}
                              name='deh'
                              color='primary'
                              disabled={edit}
                              onChange={onCheckboxChange}
                              inputProps={{
                                'aria-label': 'secondary checkbox',
                              }}
                            />
                          </Grid>
                          <Grid item xs={8}></Grid>
                          {deh ? (
                            <>
                              <Grid
                                item
                                xs={2}
                                className={classes.contentTitle}
                              >
                                <Typography variant='body2'>
                                  رقم صك حصر الورثة
                                </Typography>
                              </Grid>
                              <Grid item xs={4}>
                                <TextField
                                  fullWidth
                                  margin='normal'
                                  size='small'
                                  disabled={edit}
                                  label='رقم صك حصر الورثة'
                                  variant='outlined'
                                  id=''
                                  value={dehNumber}
                                  onChange={onChange}
                                  name='dehNumber'
                                />
                              </Grid>
                              <Grid
                                item
                                xs={2}
                                className={classes.contentTitle}
                              >
                                <Typography variant='body2'>
                                  تاريخ صك حصر الورثة
                                </Typography>
                              </Grid>
                              <Grid item xs={4}>
                                <MuiPickersUtilsProvider
                                  utils={HijriUtils}
                                  locale='ar-SA'
                                >
                                  <DatePicker
                                    clearable
                                    // className={useStyles.textField}
                                    okLabel='موافق'
                                    cancelLabel='الغاء'
                                    fullWidth
                                    disabled={edit}
                                    clearLabel='مسح'
                                    name='dehDate'
                                    labelFunc={(date) =>
                                      date ? date.format('iYYYY/iMM/iDD') : ''
                                    }
                                    value={dehDate}
                                    onChange={handleDateChange}
                                    minDate='1937-03-14'
                                    maxDate='2076-11-26'
                                    // className={classes.tfSty}
                                  />
                                </MuiPickersUtilsProvider>
                              </Grid>
                            </>
                          ) : null}
                          {/* {noa_almilkia === 'وثيقة' ? (
                            <>
                              <Grid item xs={12}>
                                <Typography variant='body1'>
                                  نموذج اثبات تملك الانقاض
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={2}
                                className={classes.contentTitle}
                              >
                                <Typography variant='body2'>
                                  نموذج اثبات الانقاض
                                </Typography>
                              </Grid>
                              <Grid item xs={2}>
                                <Checkbox
                                  checked={!!AnqathLetter}
                                  name='AnqathLetter'
                                  color='primary'
                                  disabled={edit}
                                  onChange={onCheckboxChange}
                                  inputProps={{
                                    'aria-label': 'secondary checkbox',
                                  }}
                                />
                              </Grid>
                              <Grid item xs={8}></Grid>
                            </>
                          ) : null} */}

                          <Grid item xs={12}>
                            <Typography variant='body1'>متنازع عليه</Typography>
                          </Grid>
                          <Grid item xs={2} className={classes.contentTitle}>
                            <Typography variant='body2'>متنازع عليه</Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Checkbox
                              checked={!!motnaza_alih}
                              name='motnaza_alih'
                              color='primary'
                              disabled={edit}
                              onChange={onCheckboxChange}
                              inputProps={{
                                'aria-label': 'secondary checkbox',
                              }}
                            />
                          </Grid>
                          <Grid item xs={2} className={classes.contentTitle}>
                            <Typography variant='body2'>
                              الملاحظات القانونية
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              fullWidth
                              margin='normal'
                              size='small'
                              disabled={edit}
                              label='الملاحظات القانونية'
                              variant='outlined'
                              id=''
                              value={molahthat_alqanonih}
                              onChange={onChange}
                              name='molahthat_alqanonih'
                            />
                          </Grid>
                        </Fragment>
                      ) : null}

                      <Grid item xs={12}>
                        <Typography variant='body1'>محضر الحصر</Typography>
                      </Grid>
                      <Grid item xs={2} className={classes.contentTitle}>
                        <Typography variant='body2'>حالة الحصر</Typography>
                      </Grid>
                      <Grid item xs={4} className={classes.contentTitle}>
                        <NativeSelect
                          inputProps={{
                            id: 'halet_alhaser-menu',
                          }}
                          fullWidth
                          disabled={edit}
                          value={halet_alhaser}
                          name='halet_alhaser'
                          onChange={onChange}
                        >
                          {halet_alhaser === '' ? (
                            <option value={halet_alhaser}></option>
                          ) : (
                            <option value={halet_alhaser}>
                              {halet_alhaser}
                            </option>
                          )}
                          <option value='معتمد من المالك ولجنة الحصر'>
                            معتمد من المالك ولجنة الحصر
                          </option>
                          <option value='معتمد من المالك'>
                            معتمد من المالك
                          </option>
                          <option value='معتمد من لجنة الحصر'>
                            معتمد من لجنة الحصر
                          </option>
                          <option value='أخرى'>اخرى</option>
                        </NativeSelect>
                      </Grid>
                      <Grid item xs={1}></Grid>
                      <Grid item xs={5} className={classes.contentTitle}>
                        <Button
                          fullWidth
                          variant='contained'
                          color='primary'
                          disabled={!!motnaza_alih || !print_haser}
                          onClick={genratePDF}
                        >
                          طباعة
                        </Button>
                      </Grid>
                      {halet_alhaser === 'أخرى' ? (
                        <>
                          <Grid item xs={2} className={classes.contentTitle}>
                            <Typography variant='body2'>
                              ملاحظة حالة الحصر
                            </Typography>
                          </Grid>
                          <Grid item xs={10}>
                            <TextField
                              title='name'
                              fullWidth
                              disabled={edit}
                              margin='normal'
                              size='small'
                              label='ملاحظة حالة الحصر'
                              variant='outlined'
                              id=''
                              value={halet_alhaser_note}
                              onChange={onChange}
                              name='halet_alhaser_note'
                            />
                          </Grid>
                        </>
                      ) : null}
                      {/* <Grid item xs={2} className={classes.contentTitle}>
                        <Typography variant='body2'>ملاحظات الاتصال</Typography>
                      </Grid>
                      <Grid item xs={10}>
                        <TextField
                          title='name'
                          fullWidth
                          disabled={edit}
                          margin='normal'
                          size='small'
                          label='ملاحظات الاتصال'
                          variant='outlined'
                          id=''
                          value={mlahzat_aletsal}
                          onChange={onChange}
                          name='mlahzat_aletsal'
                        />
                      </Grid> */}
                      <Grid item xs={12}>
                        <Typography variant='body1'>
                          حالة الكروكي المساحي النهائي
                        </Typography>
                      </Grid>
                      <Grid item xs={3} className={classes.contentTitle}>
                        <Typography variant='body2'>
                          استلام الكروكي من الاستشاري
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Checkbox
                          checked={!!consultant_kroki}
                          name='consultant_kroki'
                          color='primary'
                          disabled={
                            user.employee_id == 905 ||
                            user.employee_id == 901 ||
                            user.employee_id == 906
                              ? false
                              : true
                          }
                          onChange={onCheckboxChange}
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                      </Grid>
                      <Grid item xs={3} className={classes.contentTitle}>
                        <Typography variant='body2'>
                          توقيع المالك على الكروكي
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Checkbox
                          checked={!!kroki_signature}
                          name='kroki_signature'
                          color='primary'
                          // disabled={edit}
                          disabled={
                            user.employee_id == 905 ||
                            user.employee_id == 901 ||
                            user.employee_id == 906
                              ? false
                              : true
                          }
                          onChange={onCheckboxChange}
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                      </Grid>
                      <Grid item xs={3} className={classes.contentTitle}>
                        <Typography variant='body2'>
                          مراجعة مشتملات العقار
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Checkbox
                          checked={!!moragat_mshtmlat_alaqar}
                          name='moragat_mshtmlat_alaqar'
                          color='primary'
                          // disabled={edit}
                          disabled={
                            user.employee_id == 905 ||
                            user.employee_id == 901 ||
                            user.employee_id == 906
                              ? false
                              : true
                          }
                          onChange={onCheckboxChange}
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                      </Grid>
                      <Grid item xs={3} className={classes.contentTitle}>
                        <Typography variant='body2'>توقيع الاستشاري</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Checkbox
                          checked={!!consultant_signature}
                          name='consultant_signature'
                          color='primary'
                          // disabled={edit}
                          disabled={
                            user.employee_id == 905 ||
                            user.employee_id == 901 ||
                            user.employee_id == 906
                              ? false
                              : true
                          }
                          onChange={onCheckboxChange}
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                      </Grid>
                      <Grid item xs={3} className={classes.contentTitle}>
                        <Typography variant='body2'>
                          توقيع شركة وادي إبراهيم
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Checkbox
                          checked={!!company_signature}
                          name='company_signature'
                          color='primary'
                          // disabled={edit}
                          disabled={
                            user.employee_id == 905 ||
                            user.employee_id == 901 ||
                            user.employee_id == 906
                              ? false
                              : true
                          }
                          onChange={onCheckboxChange}
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                      </Grid>
                      <Grid item xs={3} className={classes.contentTitle}>
                        <Typography variant='body2'>
                          إعتراض المالك على الكروكي
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Checkbox
                          checked={!!owner_kroki_rejection}
                          name='owner_kroki_rejection'
                          color='primary'
                          // disabled={edit}
                          disabled={
                            user.employee_id == 905 ||
                            user.employee_id == 901 ||
                            user.employee_id == 906
                              ? false
                              : true
                          }
                          onChange={onCheckboxChange}
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                      </Grid>
                      <Grid item xs={12} style={{ display: 'flex' }}>
                        <Grid item xs={3} className={classes.contentTitle}>
                          <Typography variant='body2'>
                            التوقيع على التثمين الاجمالي
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Checkbox
                            color='primary'
                            checked={!!tathmen_signature}
                            name='tathmen_signature'
                            disabled={edit}
                            onChange={onCheckboxChange}
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                          />
                        </Grid>
                        <Grid item xs={6}></Grid>
                      </Grid>
                      {owner_kroki_rejection ? (
                        <>
                          <Grid item xs={2} className={classes.contentTitle}>
                            <Typography variant='body2'>
                              سبب اعتراض المالك
                            </Typography>
                          </Grid>
                          <Grid item xs={10}>
                            <TextField
                              fullWidth
                              margin='normal'
                              size='small'
                              disabled={edit}
                              label='سبب اعتراض المالك'
                              variant='outlined'
                              id=''
                              value={owner_kroki_rejection_note}
                              onChange={onChange}
                              name='owner_kroki_rejection_note'
                            />
                          </Grid>
                        </>
                      ) : null}
                      <Button
                        type='submit'
                        fullWidth
                        disabled={edit}
                        variant='contained'
                        color='primary'
                      >
                        حفظ
                      </Button>
                    </Grid>
                  </form>
                  {/* END OF FORM */}
                </Card>
              </Grid>
            )}
          </Grid>
        )}
      </Fragment>
    );
  } else {
    return <AccessForbidden />;
  }
};
const mapStateToProps = (state) => ({
  aqar: state.aqar,
  auth: state.auth,
});
export default connect(mapStateToProps, {
  fetchAqarByAqarNumber,
  updateAqarFollowUp,
  printHaserDoc,
})(FollowUp);
