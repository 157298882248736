import { Button, Grid, Typography } from '@material-ui/core';

import Container from '@material-ui/core/Container';
import { Link } from 'react-router-dom';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const AccessForbidden = () => {
  const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }));
  const classes = useStyles();
  return (
    <Container component='main' maxWidth='xs'>
      <Grid item xs={12}>
        <Typography component='h1' variant='h5'>
          خطأ ٤٠٣
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography>ليس لديك الصلاحيات لدخول هذه الصفحة</Typography>
      </Grid>
      <Button
        component={Link}
        to='/'
        fullWidth
        variant='contained'
        color='primary'
        className={classes.submit}
      >
        العودة الى الصغحة الرذيسية
      </Button>
    </Container>
  );
};
export default AccessForbidden;
