import {
  ERROR_FETCH_INCOMING,
  ERROR_FETCH_INCOMING_RECOARDS,
  ERROR_FETCH_INCOMING_REFFS,
  ERROR_FETCH_IN_COMING_FIELS,
  ERROR_FETCH_LINKED_AQARAT,
  ERROR_FETCH_LINKED_RECOARDS,
  ERROR_FETCH_NEW_INCOMING_NUMBER,
  FETCH_INCOMING,
  FETCH_INCOMING_RECOARDS,
  FETCH_INCOMING_REFFS,
  FETCH_IN_COMING_FIELS,
  FETCH_LINKED_AQARAT,
  FETCH_LINKED_RECOARDS,
  FETCH_NEW_INCOMING_NUMBER,
  SEARCH_INCOMING_RECOARDS,
} from './types';
import { removeIsLoading, setAlert, setIsLoading } from './alert';

import api from '../utils/api';
import setAuthToken from '../utils/setAuthToken';

export const fetchRecoardFiels = (recoardNumber, companyName) => async (
  dispatch
) => {
  console.log('fetchRecoardFiels');
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  try {
    const response = await api.get(
      `inComing/fiels/${companyName}/${recoardNumber}`
    );
    dispatch({
      type: FETCH_IN_COMING_FIELS,
      payload: response.data,
    });
  } catch (error) {
    console.log(error.response);
    dispatch({
      type: ERROR_FETCH_IN_COMING_FIELS,
    });
  }
};
export const fetIncomingReffs = (recNumber, companyName) => async (
  dispatch
) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  dispatch(setIsLoading());
  try {
    const response = await api.get(
      `inComing/getReffs/${companyName}/${recNumber}`
    );
    dispatch({
      type: FETCH_INCOMING_REFFS,
      payload: response.data,
    });
    dispatch(removeIsLoading());
  } catch (error) {
    dispatch({ type: ERROR_FETCH_INCOMING_REFFS });
    dispatch(setAlert('حدث خطا اثناءالمعالجة يرجآ المحاولة لاحقاً', 'error'));
  }
};
export const fetchNewIncomingNumber = (companyName) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  // dispatch({ type: ERROR_FETCH_NEW_INCOMING_NUMBER });
  try {
    const response = await api.get(`inComing/${companyName}`, { companyName });
    dispatch({
      type: FETCH_NEW_INCOMING_NUMBER,
      payload: response.data,
    });
  } catch (error) {
    dispatch({ type: ERROR_FETCH_NEW_INCOMING_NUMBER });
    dispatch(setAlert('حدث خطا اثناءالمعالجة يرجآ المحاولة لاحقاً', 'error'));
  }
};
export const saveNewInComingRecoard = (
  companyName,
  Regestration_Number,
  data,
  history
) => async (dispatch) => {
  console.log('saveNewInComingRecoard');
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  dispatch(setIsLoading());
  try {
    const response = await api.post(
      `inComing/newInGoingRecoard/${companyName}/${Regestration_Number}`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    dispatch({
      type: FETCH_INCOMING,
      payload: response.data,
    });
    dispatch(removeIsLoading());
    dispatch(setAlert('تمت العملية بنجاح', 'success', 'تمت العملية بنجاح'));
    history.push(`/${companyName}/inComing/${Regestration_Number}`);
    // history.push({
    //   pathname: `/${data.get('companyName')}/inComing/${data.get(
    //     'Regestration_Number'
    //   )}`,
    //   state: { recoardId: data.get('Regestration_Number') },
    // });
    dispatch({ type: ERROR_FETCH_NEW_INCOMING_NUMBER });
    // dispatch(fetchReacordByNumber(recoardNumber));
  } catch (error) {
    console.log(error.data);
    dispatch(removeIsLoading());
    dispatch(setAlert('حدث خطا اثناءالمعالجة يرجآ المحاولة لاحقاً', 'error'));
    dispatch({ type: ERROR_FETCH_INCOMING });
  }
};
export const fetchAllIncomingRecoards = (page, companyName) => async (
  dispatch
) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  dispatch({ type: ERROR_FETCH_LINKED_RECOARDS });
  dispatch({ type: ERROR_FETCH_LINKED_AQARAT });
  dispatch({ type: ERROR_FETCH_INCOMING });
  dispatch({ type: ERROR_FETCH_NEW_INCOMING_NUMBER });
  dispatch({ type: ERROR_FETCH_INCOMING_REFFS });
  dispatch({
    type: ERROR_FETCH_IN_COMING_FIELS,
  });
  try {
    const response = await api.post(`inComing/allInComing?page=${page}`, {
      companyName,
    });
    dispatch({
      type: FETCH_INCOMING_RECOARDS,
      payload: response.data,
    });
  } catch (error) {
    console.log(error.response);
    dispatch({
      type: ERROR_FETCH_INCOMING_RECOARDS,
    });
  }
};
export const searchForInComingRecoard = (
  page,
  companyName,
  tableName,
  coulmName,
  sarch
) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  // dispatch({ type: ERROR_FETCH_RECOARD });
  try {
    const response = await api.post(
      `inComing/search?page=${page}&coulmName=${coulmName}&search=${sarch}`,
      { companyName, tableName }
    );
    dispatch({ type: ERROR_FETCH_INCOMING_RECOARDS });
    // console.log(response);
    dispatch({
      type: SEARCH_INCOMING_RECOARDS,
      payload: response.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_FETCH_INCOMING_RECOARDS,
    });
  }
};
export const fetchReacordByNumber = (recoardNumber, companyName) => async (
  dispatch
) => {
  // dispatch({ type: EMPTY_AUTO_FILL });
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  console.log('fetchReacordByNumber');
  try {
    const response = await api.post(`/inComing/${recoardNumber}`, {
      companyName,
    });
    dispatch({
      type: FETCH_INCOMING,
      payload: response.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({ type: ERROR_FETCH_INCOMING });
  }
};
export const updateInComing = (companyName, recoardNumber, data) => async (
  dispatch
) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  dispatch(setIsLoading());
  console.log('updateInComing--',data);
  try {
    const response = await api.post(
      `inComing/update/${companyName}/${recoardNumber}`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    dispatch({
      type: FETCH_INCOMING,
      payload: response.data,
    });
    dispatch(removeIsLoading());
    dispatch(
      setAlert('تمت تحديث المعاملة بنجاح بنجاح', 'success', 'تمت العملية بنجاح')
    );
    dispatch({ type: ERROR_FETCH_NEW_INCOMING_NUMBER });
    dispatch(fetchReacordByNumber(recoardNumber));
  } catch (error) {
    console.log(error);
    dispatch({ type: ERROR_FETCH_INCOMING });
    dispatch(removeIsLoading());
    dispatch(setAlert('حدث خطا اثناءالمعالجة يرجآ المحاولة لاحقاً', 'error'));
  }
};
export const fetchLinkedAqarat = (recoardNumber, companyName) => async (
  dispatch
) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  dispatch({ type: ERROR_FETCH_LINKED_AQARAT });
  try {
    const response = await api.get(
      `/inComing/aqarat/${companyName}/${recoardNumber}`
    );
    dispatch({ type: FETCH_LINKED_AQARAT, payload: response.data });
  } catch (error) {
    console.log(error);
    dispatch({ type: ERROR_FETCH_LINKED_AQARAT });
  }
};
export const fetchLinkedRecaords = (recoardNumber, companyName) => async (
  dispatch
) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  dispatch({ type: ERROR_FETCH_LINKED_RECOARDS });
  try {
    const response = await api.get(
      `/incoming/recoards/${recoardNumber}/${companyName}?source=IN`
    );
    dispatch({ type: FETCH_LINKED_RECOARDS, payload: response.data });
  } catch (error) {
    console.log(error);
    dispatch({ type: ERROR_FETCH_LINKED_RECOARDS });
  }
};
