import React, { useState } from 'react';
import { Redirect, withRouter } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { updatePassword } from '../../actions/auth';

function Copyright() {
  return (
    <Typography variant='body2' color='textSecondary' align='center'>
      {'Copyright © '}
      <Link color='inherit' href='https://material-ui.com/'>
        Widco.com.sa
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const UpdatePassword = ({
  match,
  isAuthenticated,
  history,
  updatePassword,
}) => {
  const [formData, setFormData] = useState({
    password: '',
    password1: '',
  });
  const { params } = match;
  const { userId } = params;
  const { password, password1 } = formData;
  const [error, setError] = useState(null);
  const [enable, setEnable] = useState(true);
  const classes = useStyles();
  const onSubmit = (e) => {
    e.preventDefault();
    if (password === password1) {
      formData.userId = userId;
      updatePassword(formData, history);
    } else {
      setError({ message: 'يرجي التاكد من مطابقة كلمة المرور' });
    }

    // loginUser({ email, password }, history);
  };
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    if (password.length >= 6) {
      setEnable(false);
    } else {
      setEnable(true);
    }
  };
  if (isAuthenticated) {
    console.log('isAuthendddddddticated', isAuthenticated);
    return <Redirect to='/' />;
  }
  return (
    <Container component='main' maxWidth='xs'>
      {/* <CssBaseline /> */}
      <div className={classes.paper}>
        <Typography component='h1' variant='h5'>
          تحديث كلمة المرور
        </Typography>
        <form className={classes.form} noValidate onSubmit={(e) => onSubmit(e)}>
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            type='password'
            id='password'
            onChange={(e) => onChange(e)}
            label='كلمة المرور'
            name='password'
            value={password}
            // autoFocus
          />
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            type='password'
            id='password1'
            value={password1}
            onChange={(e) => onChange(e)}
            label='تاكيد كلمة المرور'
            name='password1'
            // autoComplete='password1'
            // autoFocus
          />
          {error ? <Typography color='red'>{error.message}</Typography> : null}
          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            disabled={enable}
            className={classes.submit}
            // onClick={handleLogin}
          >
            حفظ
          </Button>
          {/* <Grid container>
            <Grid item xs>
              <Link href='#' variant='body2'>
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href='#' variant='body2'>
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid> */}
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};
const mapStateToProps = (state) => {
  return { isAuthenticated: state.auth.isAuthenticted };
};
UpdatePassword.propTypes = {
  //   loginUser: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

export default withRouter(
  connect(mapStateToProps, { updatePassword })(UpdatePassword)
);
