import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
const Spinner = () => {
  const useStyles = makeStyles((theme) => ({
    root: {
      height: '100px',
      width: '100px',
      margin: 'auto',
      display: 'flex',
      '& > * + *': {
        marginLeft: theme.spacing(2),
      },
    },
    spinner: {
      width: '100px',
      margin: 'auto',
      height: '100px',
    },
  }));
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CircularProgress className={classes.spinner} />
      {/* <CircularProgress color='secondary' /> */}
    </div>
  );
};
export default Spinner;
