import {
  ERROR_FETCH_AQAR,
  ERROR_FETCH_AQAR_ANNOUNCMENTS,
  ERROR_FETCH_AQAR_DETAILS,
  ERROR_FETCH_AQAR_FILES,
  ERROR_FETCH_AQAR_RECOARDS,
  ERROR_FETCH_PRICE_REVISION,
  FETCH_AQAR,
  FETCH_AQAR_ANNOUNCMENTS,
  FETCH_AQAR_DETAILS,
  FETCH_AQAR_FILES,
  FETCH_AQAR_RECOARDS,
  FETCH_PRICE_REVISION,
} from '../actions/types';

const initialState = {
  loading: true,
  data: null,
  announcment: null,
  details: null,
  priceRevision: null,
  files: null,
  recoards: null,
};
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_AQAR:
      return { ...state, data: payload, loading: false };
    case ERROR_FETCH_AQAR:
      return {
        ...state,
        data: null,
        details: null,
        announcment: null,
        priceRevision: null,
        recoards: null,
        loading: true,
      };
    case FETCH_AQAR_RECOARDS:
      return { ...state, recoards: payload, loading: false };
    case FETCH_AQAR_ANNOUNCMENTS:
      return { ...state, announcment: payload, loading: false };
    case FETCH_PRICE_REVISION:
      return { ...state, priceRevision: payload, loading: false };
    case FETCH_AQAR_FILES:
      return { ...state, files: payload, loading: false };
    case ERROR_FETCH_AQAR_ANNOUNCMENTS:
      return { ...state, announcment: null, loading: true };
    case FETCH_AQAR_DETAILS:
      return { ...state, details: payload, loading: false };
    case ERROR_FETCH_AQAR_DETAILS:
      return { ...state, details: null, loading: true };
    case ERROR_FETCH_AQAR_RECOARDS:
      return { ...state, recoards: null, loading: true };
    case ERROR_FETCH_AQAR_FILES:
      return { ...state, files: null, loading: true };
    case ERROR_FETCH_PRICE_REVISION:
      return { ...state, priceRevision: null, loading: true };
    default:
      return state;
  }
}
