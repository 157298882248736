import {
  AUTH_ERROR,
  ERROR_FETCH_AQARAT,
  LOGIN_FAEL,
  LOGIN_SUCCES,
  LOGOUT,
  USER_LOADED,
} from './types';

import api from '../utils/api';
import { setAlert } from './alert';
import setAuthToken from '../utils/setAuthToken';

// Load User
export const updatePassword = (password, history) => async (dispatch) => {
  try {
    const res = await api.post('users/updatepassword', password);
    dispatch(setAlert('تم تحديث كلمة المرور بنجاح', 'success'));
    history.push('/login');
  } catch (error) {
    console.log('error', error);
    dispatch(setAlert('حدث خطأ ما يرجي المحاولة لاحقاً', 'error'));
  }
};
export const loadeUser = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
    api
      .post('users/auth')
      .then((response) => {
        dispatch({
          type: USER_LOADED,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log('error', error);
        dispatch({
          type: AUTH_ERROR,
        });
      });
  } else {
    dispatch(logout());
  }
};
// Login user
export const resetPassword = (email, history) => async (dispatch) => {
  try {
    await api.post('users/password-reset', email);
    // dispatch(setAlert('تم ارسال البريد بنجاح', 'success'));
    history.push('/verificationCode');
  } catch (error) {
    console.log('error', error);
    dispatch(setAlert('لايوجد مستخدم مرتبط بهذا البريد الالكتروني', 'error'));
  }
};
export const verificationCode = (code, history) => async (dispatch) => {
  // console.log(code);
  try {
    const res = await api.post('users/verificationCode', { code });
    // console.log(res.data);
    // dispatch(setAlert('تم ارسال البريد بنجاح', 'success'));
    history.push(`${res.data}/UpdatePassword`);
  } catch (error) {
    console.log('error', error);
    dispatch(setAlert('رمز التحقق غير صحيح', 'error'));
  }
};
export const loginUser = ({ user_name, password, history }) => async (
  dispatch
) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify({ user_name, password });
  console.log(body);
  try {
    const res = await api.post('users/login', body, config);
    dispatch({
      type: LOGIN_SUCCES,
      payload: res.data,
    });
    dispatch(loadeUser());
  } catch (error) {
    const errs = error.response.data.errors;
    if (errs) {
      console.log(errs);
      errs.forEach((err) => dispatch(setAlert(err.msg, 'error')));
    }
    dispatch({
      type: LOGIN_FAEL,
    });
  }
};
// Logout user
export const logout = () => async (dispatch) => {
  dispatch({ type: LOGOUT });
  dispatch({ type: ERROR_FETCH_AQARAT });
};
