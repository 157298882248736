import api from '../utils/api';
import { setAlert } from './alert';
import {
  ERROR_FETCH_DEPARTMENTS,
  FETCH_DEPARTMENTS,
  SEARCH_DEPARTMENTS,
  FETCH_DEPARTMENT_BY_ID,
  ERROR_FETCH_DEPARTMENT_BY_ID,
} from './types';
import setAuthToken from '../utils/setAuthToken';
export const fetchDepartment = (page) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  try {
    const response = await api.get(`department?page=${page}`);
    console.log('fetchDepartment');
    dispatch({
      type: FETCH_DEPARTMENTS,
      payload: response.data,
    });
    dispatch({ type: ERROR_FETCH_DEPARTMENT_BY_ID });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_FETCH_DEPARTMENTS,
    });
  }
};
export const searchForDepartment = (page, coulmName, sarch) => async (
  dispatch
) => {
  console.log('searchForDepartment');
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  try {
    const response = await api.post(
      `department?page=${page}&coulmName=${coulmName}&search=${sarch}`
    );
    dispatch({ type: ERROR_FETCH_DEPARTMENT_BY_ID });

    // console.log(response);
    dispatch({
      type: SEARCH_DEPARTMENTS,
      payload: response.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_FETCH_DEPARTMENTS,
    });
  }
};
export const createNewDepartment = (formData, history) => async (dispatch) => {
  console.log('createNewDepartment');
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  try {
    await api.post('department/createNewDepartment', formData);
    const msg = `تم انشاء الإدارة بنجاح`;
    dispatch(setAlert(msg, 'success', 'تمت العملية بنجاح'));
    history.push('/');
  } catch (error) {
    const errs = error.response.data.errors;
    if (errs) {
      console.log(errs);
      errs.forEach((err) => dispatch(setAlert(err.sqlMessage, 'error', 'خطأ')));
    }
  }
};
export const updateDepartment = (id, formData, history) => async (dispatch) => {
  console.log('updateDepartment');
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  try {
    await api.post(`department/${id}`, formData);
    const msg = `تم تحديث ملعومات الإدارة بنجاح`;
    dispatch(setAlert(msg, 'success', 'تمت العملية بنجاح'));
    history.push('/');
  } catch (error) {
    const errs = error.response.data.errors;
    if (errs) {
      console.log(errs);
      errs.forEach((err) => dispatch(setAlert(err.sqlMessage, 'error', 'خطأ')));
    }
  }
};
export const fetchDepartmentById = (id) => async (dispatch) => {
  console.log('fetchDepartmentById');
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  try {
    const response = await api.get(`department/${id}`);
    dispatch({
      type: FETCH_DEPARTMENT_BY_ID,
      payload: response.data,
    });
  } catch (error) {
    const errs = error.response.data.errors;
    console.log(errs);
    dispatch({ type: ERROR_FETCH_DEPARTMENT_BY_ID });
  }
};
