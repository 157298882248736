import { Button, Card, Grid, Typography } from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';
import {
  fetchAqarByAqarNumber,
  fetchAqarCoord,
  updateAqarCoordnaite,
  updateAqarCoordnaiteStatus,
} from '../../../actions/aqarat';

import AccessForbidden from './AccessForbidden';
import AlertDialog from '../AlertDialog';
import CoordinateComponnet from '../CoordinateComponnet';
import DescrptionComponnet from '../DescrptionComponnet';
import MuiAlert from '@material-ui/lab/Alert';
import Sidebar from '../Sidbar';
import Spinner from '../Spinner';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { printAnqathLetter } from '../../../actions/printDocs';
import { v4 } from 'uuid';

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}
const initialState = {
  id: v4(),
  aqar_number: '',
  coordnaite: [{ id: '', northern: '', eastern: '' }],
  descraption: [{ id: '', type: '', descrption: '', details: '', note: '' }],
  status: '',
};
const AqaraCoordinate = ({
  auth: { user },
  match,
  isLoading,
  coordnaite: { coorData, coorLoading },
  aqar: { loading, data, details },
  fetchAqarCoord,
  fetchAqarByAqarNumber,
  updateAqarCoordnaite,
  printAnqathLetter,
}) => {
  const { params } = match;
  const { id } = params;
  const [formData, setFormData] = useState({
    id: '',
    aqar_number: '',
    coordnaite: [{ id: v4(), northern: '', eastern: '' }],
    descraption: [
      { id: v4(), type: '', descrption: '', details: '', note: '' },
    ],
    status: '',
  });
  useEffect(() => {
    // console.log('personalInformation', data);
    if (!data) fetchAqarByAqarNumber(id);
    if (!loading && data) {
      const aqarData = { ...initialState };
      for (const key in data) {
        if (key in aqarData) aqarData[key] = data[key];
      }
      //   setFormData(aqarData);
    }
  }, [loading, fetchAqarByAqarNumber, data, id]);
  // useEffect(() => {
  //   if (!coorData) fetchAqarCoord(id);
  //   if (!loading && coorData) {
  //     const aqarData = { ...initialState };
  //     for (const key in coorData) {
  //       if (key in aqarData) aqarData[key] = coorData[key];
  //     }
  //     setFormData(aqarData);
  //   }
  // }, [loading, fetchAqarCoord, coorData, id]);
  const [edit, setEdit] = useState(true);
  const enableEdit = () => {
    setEdit(!edit);
  };
  const useStyles = makeStyles((theme) => ({
    root: {
      minWidth: 275,
      textAlign: 'center',
      padding: '15px',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    contentTitle: {
      margin: 'auto',
    },
    buttonsContainer: {
      margin: '10px',
    },
    butns: {
      margin: '10px',
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    daitelsContainer: {
      textAlign: 'center',
    },
    buttonProgress: {
      // color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    motnaza: {
      color: 'red',
    },
    margin: {
      margin: theme.spacing(1),
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
  }));
  const classes = useStyles();
  useEffect(() => {
    if (!coorData) fetchAqarCoord(id);
    if (!loading && coorData) {
      const aqarData = { ...initialState };
      for (const key in coorData) {
        if (key in aqarData) aqarData[key] = coorData[key];
      }
      setFormData(aqarData);
    }
  }, [loading, fetchAqarCoord, coorData, id]);
  const onSubmit = (e) => {
    e.preventDefault();
    if (user.roll.group_name === 'مراجعة') {
      if (coorData) {
        updateAqarCoordnaiteStatus(id, 1, formData);
      }
    } else {
      updateAqarCoordnaite(id, formData);
    }
  };
  const rejectCoord = () => {
    if (coorData) {
      updateAqarCoordnaiteStatus(id, 2, formData);
    }
  };
  const onChange = (e, id) => {
    e.persist();
    setFormData((prevState) => {
      const { name, value } = e.target;
      const newState = prevState.coordnaite.map((obj) => {
        if (obj.id === id) {
          return { ...obj, [name]: value };
        }
        return obj;
      });
      return { ...prevState, coordnaite: newState };
    });
  };
  const onChangeDescrption = (e, id) => {
    e.persist();
    setFormData((prevState) => {
      const { name, value } = e.target;
      const newState = prevState.descraption.map((obj) => {
        if (obj.id === id) {
          return { ...obj, [name]: value };
        }
        return obj;
      });
      return { ...prevState, descraption: newState };
    });
  };
  const printAnqath = () => {
    printAnqathLetter(id);
  };
  const addNewRow = () => {
    setFormData({
      ...formData,
      coordnaite: [
        ...formData.coordnaite,
        { id: v4(), northern: '', eastern: '' },
      ],
    });
  };
  const addNewDesRow = () => {
    setFormData({
      ...formData,
      descraption: [
        ...formData.descraption,
        { id: v4(), type: '', descrption: '', details: '', note: '' },
      ],
    });
  };
  const deleteRow = (id) => {
    setFormData((prevState) => {
      const cor = prevState.coordnaite.filter((data) => {
        return data.id != id;
      });
      return { ...prevState, coordnaite: cor };
    });
  };
  const deleteDescRow = (id) => {
    setFormData((prevState) => {
      const cor = prevState.descraption.filter((data) => {
        return data.id != id;
      });
      return { ...prevState, descraption: cor };
    });
  };
  const { coordnaite, descraption, status } = formData;
  if (
    user.department &&
    (user.roll.group_name === 'ادخال البيانات' ||
      user.roll.group_name === 'ادخال البيانات ٢')
  ) {
    return (
      <Fragment>
        {data === null || loading ? (
          <Spinner />
        ) : (
          <Grid container spacing={4}>
            {data.molahthat ? (
              <AlertDialog molahthat={data.molahthat} isOpen={true} />
            ) : null}
            <Grid item xs={2}>
              <Sidebar proprtyId={data.aqar_number} />
            </Grid>
            <Grid item xs={10}>
              <Grid container className={classes.daitelsContainer}>
                <Grid item xs={12}>
                  <Typography variant='h4' display='block'>
                    {data.aqar_number}
                    {data.motnaza_alih ? (
                      <Alert severity='error'>متنازع عليه</Alert>
                    ) : null}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='body1'>
                    نموذج اثبات تملك الانقاض
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={addNewRow}
                  >
                    + إضافة احداثيات عقار
                  </Button>
                </Grid>
                <Grid item xs={12} className={classes.buttonsContainer}>
                  {coordnaite.map((da) => {
                    return (
                      <CoordinateComponnet
                        key={da.id}
                        id={da.id}
                        northern={da.northern}
                        eastern={da.eastern}
                        onChange={onChange}
                        setFormData={setFormData}
                        deleteRow={deleteRow}
                        edit={user.roll.group_name === 'مراجعة'}
                      />
                    );
                  })}
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={addNewDesRow}
                  >
                    + اضافة بيانات الانقاض
                  </Button>
                </Grid>
                <Grid item xs={12} className={classes.buttonsContainer}>
                  {descraption.map((da) => {
                    return (
                      <DescrptionComponnet
                        key={da.id}
                        id={da.id}
                        type={da.type}
                        descrption={da.descrption}
                        details={da.details}
                        note={da.note}
                        onChange={onChangeDescrption}
                        setFormData={setFormData}
                        deleteRow={deleteDescRow}
                        edit={user.roll.group_name === 'مراجعة'}
                      />
                    );
                  })}
                </Grid>
              </Grid>
              <Card className={classes.root}>
                <form onSubmit={(e) => onSubmit(e)}>
                  <Grid container>
                    <Button
                      type='submit'
                      fullWidth
                      //   disabled={edit}
                      variant='contained'
                      color='primary'
                    >
                      حفظ
                    </Button>
                  </Grid>
                </form>
                <Button
                  fullWidth
                  onClick={printAnqath}
                  style={{ marginTop: '15px' }}
                  // disabled={edit}
                  variant='contained'
                  color='primary'
                  //   loading={isLoading ? isLoading.isLoading : false}
                >
                  طباعة نموذج اثبات تملك الانقاض
                </Button>
                {/* END OF FORM */}
              </Card>
            </Grid>
          </Grid>
        )}
      </Fragment>
    );
  } else {
    return <AccessForbidden />;
  }
};
const mapStateToProps = (state) => ({
  aqar: state.aqar,
  isLoading: state.isLoading,
  autofill: state.autofill,
  auth: state.auth,
  coordnaite: state.coordnaite,
});
export default connect(mapStateToProps, {
  fetchAqarByAqarNumber,
  updateAqarCoordnaite,
  printAnqathLetter,
  fetchAqarCoord,
  updateAqarCoordnaiteStatus,
})(AqaraCoordinate);
