import { Button, CircularProgress, Grid, Typography } from '@material-ui/core';
import React, { Fragment } from 'react';
import {
  downloadOutGoingFullRecoardsReport,
  downloadRecoardsFullReport,
} from '../../../actions/printDocs';

import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

const AdministrativeComuncations = ({
  match,
  isLoading,
  downloadRecoardsFullReport,
  downloadOutGoingFullRecoardsReport,
}) => {
  const { params } = match;
  const { companyName } = params;
  const useStyles = makeStyles({
    root: {
      textAlign: 'center',
      padding: '20px',
    },
    card: {
      paddingLeft: '100px',
      paddingRight: '100px',
    },
    paper: {
      margin: '10px',
      padding: '10px 10px 20px',
    },
    nextBtn: {
      justifyContent: 'center',
      paddingTop: '20px',
      display: 'flex',
    },
    table: {
      minWidth: 275,
      textAlign: 'center',
      padding: '15px',
      borderWidth: 1,
      borderStyle: 'solid',
      color: 'white',
    },
    contentTitle: {
      margin: 'auto',
      textAlign: 'center',
      //   backgroundColor: 'black',
    },
    container: {
      padding: '10px',
    },
    containerItem: {
      display: 'flex',
      textAlign: 'center',
      padding: '5px',
    },
  });
  const classes = useStyles();
  const getTheRecoardsReport = () => {
    downloadRecoardsFullReport(companyName);
  };
  const handleDownloadOutGoingFullRecoardsReport = () => {
    downloadOutGoingFullRecoardsReport(companyName);
  };
  let content = (
    <Fragment>
      <Grid item xs={12} className={classes.containerItem}>
        <Grid item xs={4}>
          <Button
            component={Link}
            //   to='/:companyName/allOutGoing'
            to={`/${companyName}/allOutGoing`}
            fullWidth
            variant='contained'
            color='primary'
          >
            الصادر
          </Button>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
          <Button
            component={Link}
            to={`/${companyName}/allInComing`}
            fullWidth
            variant='contained'
          >
            الوارد
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Button
          fullWidth
          disabled={isLoading.isLoading}
          onClick={handleDownloadOutGoingFullRecoardsReport}
          variant='contained'
        >
          {isLoading.isLoading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
          تصدير تقرير المعاملات الصادرة
        </Button>
      </Grid>
      <Grid item xs={4}></Grid>
      <Grid item xs={4}>
        <Button
          fullWidth
          disabled={isLoading.isLoading}
          onClick={getTheRecoardsReport}
          variant='contained'
        >
          {isLoading.isLoading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
          تصدير تقرير المعاملات الواردة
        </Button>
      </Grid>
    </Fragment>
  );

  return (
    <Grid container>
      <Grid item xs={12} className={classes.root}>
        <Typography variant='h4'>الإتصالات الإدارية</Typography>
      </Grid>
      {content}
    </Grid>
  );
};
const mapStateToProps = (state) => ({
  isLoading: state.isLoading,
});
export default connect(mapStateToProps, {
  downloadRecoardsFullReport,
  downloadOutGoingFullRecoardsReport,
})(AdministrativeComuncations);
