import { Button, CircularProgress } from '@material-ui/core';
import React, { useState } from 'react';

import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { addNewAqarNumber } from '../../actions/aqarat';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
const AddNewAqar = ({ addNewAqarNumber, isLoading }) => {
  const [formData, setFormData] = useState({
    aqar_number: '',
    BaseRef: '',
  });
  const { aqar_number, BaseRef } = formData;
  const classes = useStyles();
  const onSubmit = (e) => {
    e.preventDefault();
    addNewAqarNumber(formData);
  };
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  return (
    <Container component='main' maxWidth='xs'>
      {/* <CssBaseline /> */}
      <div className={classes.paper}>
        <Typography component='h1' variant='h5'>
          تسجيل رقم عقار جديد
        </Typography>
        <form className={classes.form} noValidate onSubmit={(e) => onSubmit(e)}>
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            onChange={(e) => onChange(e)}
            label='رقم العقار الجديد'
            name='aqar_number'
            autoFocus
          />
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            name='BaseRef'
            label='سبب التسجيل'
            onChange={(e) => onChange(e)}
            autoComplete='current-password'
          />
          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            disabled={isLoading.isLoading}
            className={classes.submit}
            // onClick={handleLogin}
          >
            {isLoading.isLoading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
            تسجيل
          </Button>
          {/* <Grid container>
          <Grid item xs>
            <Link href='#' variant='body2'>
              Forgot password?
            </Link>
          </Grid>
          <Grid item>
            <Link href='#' variant='body2'>
              {"Don't have an account? Sign Up"}
            </Link>
          </Grid>
        </Grid> */}
        </form>
      </div>
    </Container>
  );
};
const mapStateToProps = (state) => {
  return { isLoading: state.isLoading };
};
export default connect(mapStateToProps, { addNewAqarNumber })(AddNewAqar);
