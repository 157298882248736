import { Button, TextField, Typography, styled } from '@material-ui/core';

import React from 'react';

const CoordinateComponnet = ({
  onChange,
  id,
  northern,
  eastern,
  deleteRow,
  edit,
}) => {
  // const onChange = (e) => {

  //   // setFormData({ ...formData, [e.target.name]: e.target.value });
  // };
  const stackGap = 5;
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {/* <Stack direction='row' alignItems={'center'} gap={stackGap}> */}
      {!edit ? (
        <Button
          variant='contained'
          color='primary'
          onClick={() => deleteRow(id)}
        >
          -
        </Button>
      ) : null}
      <StyledHeading variant='body1'>
        <b>شماليات</b>
      </StyledHeading>
      <TextField
        title='name'
        fullWidth
        margin='normal'
        value={northern}
        label='شماليات'
        variant='outlined'
        id='northern'
        disabled={edit}
        size='small'
        onChange={(e) => onChange(e, id)}
        name='northern'
      />
      <StyledHeading variant='body1'>
        <b>شرقيات</b>
      </StyledHeading>
      <TextField
        fullWidth
        margin='normal'
        // disabled={edit}
        size='small'
        disabled={edit}
        label='شرقيات'
        variant='outlined'
        id=''
        value={eastern}
        name='eastern'
        onChange={(e) => onChange(e, id)}
        inputProps={{
          maxLength: 10,
        }}
      />
      {/* </Stack> */}
    </div>
  );
};
export default CoordinateComponnet;
const StyledHeading = styled(Typography)({
  minWidth: 120,
  textAlign: 'center',
});
