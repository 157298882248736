import {
  Button,
  CircularProgress,
  Grid,
  NativeSelect,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';
import {
  addNewCategory,
  createSubCatgory,
  fetchCategoryById,
  fetchSubCategories,
  updateCategoryById,
  updateSubCatgory,
} from '../../../actions/categories';

import MaterialTable from 'material-table';
import Sidbar from '../../ui/Sidbar';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

const initialState = {
  name: '',
  description: '',
};
const subInitialState = [];
const AddNewCategory = ({
  addNewCategory,
  isLoading,
  history,
  updateSubCatgory,
  match,
  fetchSubCategories,
  category: { category, loading, subLoading, subCategories },
  fetchCategoryById,
  updateCategoryById,
  createSubCatgory,
}) => {
  const { params } = match;
  const { catID } = params;
  useEffect(() => {
    if (catID) fetchSubCategories(catID);
    console.log(subCategories);
    // if (subCategories.length >= 0) {
    setData([...subCategories]);
    // }
  }, [category, loading]);
  useEffect(() => {
    if (catID) fetchCategoryById(catID);
    // if (catID) fetchSubCategories(catID);
    if (subCategories.length >= 0) {
      setData([...subCategories]);
    }
    const recoardData = { ...initialState };
    if (category) {
      for (const key in category) {
        if (key in recoardData) recoardData[key] = category[key];
      }
      setFormData(recoardData);
    }
  }, [loading]);
  const [data, setData] = useState([]);
  const useStyles = makeStyles((theme) => ({
    root: {
      minWidth: 275,
      textAlign: 'center',
      padding: '15px',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    contentTitle: {
      margin: 'auto',
    },
    buttonsContainer: {
      margin: '10px',
    },
    butns: {
      margin: '10px',
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    daitelsContainer: {
      textAlign: 'center',
    },
    buttonProgress: {
      // color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    motnaza: {
      color: 'red',
    },
    margin: {
      margin: theme.spacing(1),
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
  }));
  const classes = useStyles();
  const [columns, setColumns] = useState([
    { title: 'الاسم', field: 'name', align: 'right' },
    {
      title: 'الوصف',
      field: 'description',
      align: 'right',
    },
  ]);
  const [formData, setFormData] = useState({
    name: '',
    description: '',
  });
  const { name, description } = formData;
  const onSubmit = (e) => {
    e.preventDefault();
    if (catID) {
      updateCategoryById(catID, formData, history);
    } else {
      addNewCategory(formData, history);
    }
  };
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  return (
    <Fragment>
      <Grid container className={classes.root}>
        <Grid item xs={2}>
          <Sidbar />
        </Grid>
        <Grid item sm={10}>
          <form style={{ display: 'contents' }} onSubmit={(e) => onSubmit(e)}>
            <Grid item xs={2} className={classes.contentTitle}>
              <Typography variant='body2'>اسم التصنيف</Typography>
            </Grid>
            <Grid item xs={10}>
              <TextField
                fullWidth
                name='name'
                onChange={onChange}
                value={name}
                margin='normal'
                required
                variant='outlined'
                size='small'
              />
            </Grid>
            <Grid item xs={2} className={classes.contentTitle}>
              <Typography variant='body2'>وصف التصنيف</Typography>
            </Grid>
            <Grid item xs={10}>
              <TextField
                fullWidth
                name='description'
                onChange={onChange}
                value={description}
                margin='normal'
                required
                variant='outlined'
                size='small'
              />
            </Grid>
            <Grid item xs={10}>
              <Button
                type='submit'
                fullWidth
                variant='contained'
                color='primary'
              >
                حفظ
              </Button>
            </Grid>
          </form>
          {catID ? (
            //   <Fragment>
            <MaterialTable
              options={{
                search: false,
                actionsColumnIndex: 2,
              }}
              title='التصنيفات الفرعية'
              columns={columns}
              data={data}
              editable={{
                onRowAdd: (newData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      setData([...data, newData]);
                      // console.log(newData);
                      createSubCatgory(newData, catID);
                      resolve();
                    }, 1000);
                  }),
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      const dataUpdate = [...data];
                      const index = oldData.tableData.id;
                      dataUpdate[index] = newData;
                      setData([...dataUpdate]);
                      updateSubCatgory(newData, catID);
                      resolve();
                    }, 1000);
                  }),
                onRowDelete: (oldData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      const dataDelete = [...data];
                      const index = oldData.tableData.id;
                      dataDelete.splice(index, 1);
                      setData([...dataDelete]);

                      resolve();
                    }, 1000);
                  }),
              }}
            />
          ) : //   </Fragment>
          null}
        </Grid>
      </Grid>
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  isLoading: state.isLoading,
  category: state.category,
});
export default connect(mapStateToProps, {
  addNewCategory,
  fetchCategoryById,
  updateCategoryById,
  fetchSubCategories,
  updateSubCatgory,
  createSubCatgory,
})(AddNewCategory);
