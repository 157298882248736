import { Grid, NativeSelect, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';

const UploadedFileReview = ({ file, updateFileName }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      textAlign: 'center',
      padding: '20px',
      paddingLeft: '100px',
      paddingRight: '100px',
    },
    paper: {
      margin: '10px',
      padding: '10px 10px 20px',
    },
    table: {
      minWidth: 275,
      textAlign: 'center',
      padding: '15px',
      borderWidth: 1,
      borderStyle: 'solid',
      color: 'white',
    },
    contentTitle: {
      margin: 'auto',
      textAlign: 'center',
      //   backgroundColor: 'black',
    },
    buttonProgress: {
      // color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    container: {
      padding: '10px',
    },
    containerItem: {
      display: 'flex',
      textAlign: 'center',
      padding: '5px',
    },
    margenAuto: {
      margin: 'auto',
    },
  }));
  useEffect(() => {}, []);
  const classes = useStyles();
  //   const [file, setFile] = useState(null);
  const onChange = (e) => {
    const fi = { name: e.target.value, value: file.value };
    // setFiles([...filterdArray, fi]);
    updateFileName(fi);
    // //setFileName({ ...fileName, [e.target.name]: e.target.value });
  };
  const objOptions = [
    { value: 'LTR-', str: 'خطاب' },
    { value: 'RPT-', str: 'محضر' },
    { value: 'CNT-', str: 'عقد' },
    { value: 'BIL-', str: 'فاتورة' },
    { value: 'OFR-', str: 'عرض' },
    { value: 'CV-', str: 'سيرة ذاتية' },
    { value: 'REF-', str: 'إحالة' },
    { value: 'DLV-', str: 'نموذج إستلام' },
    { value: 'MAP-', str: 'خريطة' },
    { value: 'OTH-', str: 'اخرى' },
    { value: 'ATTC-', str: 'مرفق' },
  ];
  const options = objOptions.map((ob, index) => (
    <option key={index} value={ob.value}>
      {ob.str}
    </option>
  ));
  const validImageTypes = ['gif', 'jpeg', 'png', 'pdf'];
  function getFileExtension(filename) {
    return filename.split('.').pop();
  }
  const fileExt = getFileExtension(file.value.name);
  return (
    <Grid item xs={12} className={classes.containerItem}>
      <Grid item xs={3}>
        {validImageTypes.includes(fileExt) ? (
          <object
            width='200px'
            height='200px'
            type='application/pdf'
            data={URL.createObjectURL(file.value)}
          >
            <p>لايمكن استعراض الملف</p>
          </object>
        ) : null}
      </Grid>
      <Grid item xs={5} className={classes.margenAuto}>
        <Typography>{file.value.name}</Typography>
      </Grid>
      <Grid item xs={4} className={classes.margenAuto}>
        <NativeSelect
          style={{
            margin: 'auto',
            paddingTop: '5px',
            paddingBottom: '5px',
          }}
          onChange={onChange}
          fullWidth
          required
          value={file.name}
          name='fileName'
          inputProps={{
            id: 'fileName',
          }}
        >
          {options}
        </NativeSelect>
      </Grid>
    </Grid>
  );
};
export default UploadedFileReview;
