import { Link, Redirect } from 'react-router-dom';
import React, { useState } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { verificationCode } from '../../actions/auth';

function Copyright() {
  return (
    <Typography variant='body2' color='textSecondary' align='center'>
      {'Copyright © '}
      <Link color='inherit' href='https://material-ui.com/'>
        Widco.com.sa
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const VerificationCode = ({ verificationCode, isAuthenticated, history }) => {
  const [formData, setFormData] = useState({
    code: '',
  });
  const { code } = formData;
  const classes = useStyles();
  const onSubmit = (e) => {
    e.preventDefault();
    console.log('SUCCESS');
    verificationCode(code, history);
    // loginUser({ email, password }, history);
  };
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  if (isAuthenticated) {
    console.log('isAuthendddddddticated', isAuthenticated);
    return <Redirect to='/' />;
  }
  return (
    <Container component='main' maxWidth='xs'>
      {/* <CssBaseline /> */}
      <div className={classes.paper}>
        <Typography component='h1' variant='h5'>
          التحقق
        </Typography>
        <Typography variant='body1'>
          تم ارسال رمز التحقق من خلال البريد الإلكتروني يرجى التحقق منه
        </Typography>
        <form className={classes.form} noValidate onSubmit={(e) => onSubmit(e)}>
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            id='code'
            onChange={(e) => onChange(e)}
            label='رمز التحقق'
            name='code'
            autoFocus
          />
          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            className={classes.submit}
            // onClick={handleLogin}
          >
            تحقق
          </Button>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};
const mapStateToProps = (state) => {
  return { isAuthenticated: state.auth.isAuthenticted };
};
VerificationCode.propTypes = {
  // loginUser: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};
export default connect(mapStateToProps, { verificationCode })(VerificationCode);
