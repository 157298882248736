import {
  ERROR_FETCH_AQARAT_COUNT,
  ERROR_FETCH_HASER_STATUS,
  ERROR_FETCH_KROKI_STATUS,
  ERROR_FETCH_LOGS,
  ERROR_FETCH_NAKASAH_NOA_ALMILKIA,
  FETCH_AQARAT_COUNT,
  FETCH_HASER_STATUS,
  FETCH_KROKI_STATUS,
  FETCH_LOGS,
  FETCH_NAKASAH_NOA_ALMILKIA,
} from '../actions/types';
const initialState = {
  logs: {
    logs: [],
    loading: true,
  },
  aqaratCount: {
    totalCount: {},
    loading: true,
  },
  noaAlmilkia: {
    data: null,
    loading: true,
  },
  haserStatus: {
    data: null,
    loading: true,
  },
  krokiStatus: {
    data: null,
    loading: true,
  },
};
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_NAKASAH_NOA_ALMILKIA:
      return {
        ...state,
        noaAlmilkia: {
          data: payload,
          loading: true,
        },
      };
    case FETCH_LOGS:
      return { ...state, logs: { logs: payload, loading: false } };
    case FETCH_AQARAT_COUNT:
      return { ...state, aqaratCount: { totalCount: payload, loading: false } };
    case ERROR_FETCH_LOGS:
      return { ...state, logs: { logs: [], loading: true } };
    case FETCH_HASER_STATUS:
      return {
        ...state,
        haserStatus: { data: payload, loading: true },
      };
    case FETCH_KROKI_STATUS:
      return {
        ...state,
        krokiStatus: { data: payload, loading: true },
      };
    case ERROR_FETCH_KROKI_STATUS:
      return {
        ...state,
        krokiStatus: { data: null, loading: true },
      };
    case ERROR_FETCH_AQARAT_COUNT:
      return { ...state, aqaratCount: { totalCount: {}, loading: true } };
    case ERROR_FETCH_NAKASAH_NOA_ALMILKIA:
      return {
        ...state,
        noaAlmilkia: { data: null, loading: true },
      };
    case ERROR_FETCH_HASER_STATUS:
      return { ...state, haserStatus: { data: null, loading: true } };
    default:
      return state;
  }
}
