import { Button, CircularProgress, Grid } from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';
import {
  deleteFile,
  fetchAqarByAqarNumber,
  getAqarFiles,
  renameFileinAws,
  uploadAqarFiles,
} from '../../../actions/aqarat';

import ArchiveRequestContainer from './ArchiveRequestContanier';
import ImageCard from './ImageCard';
// import ImageList from '@material-ui/core/ImageList';
// import ImageListItem from '@material-ui/core/ImageListItem';
import Sidebar from '../Sidbar';
import Spinner from '../Spinner';
import UploadedImagePreview from './UploadedImagePreview';
import { connect } from 'react-redux';
import { fetchArchiveRequests } from '../../../actions/archive';
import { makeStyles } from '@material-ui/core/styles';
import { printDeleteLetter } from '../../../actions/printDocs';

const Archives = ({
  match,
  auth: { user },
  getAqarFiles,
  uploadAqarFiles,
  deleteFile,
  isLoading,
  renameFileinAws,
  fetchAqarByAqarNumber,
  fetchArchiveRequests,
  printDeleteLetter,
  aqar: { loading, data, files },
  archive,
}) => {
  const { params } = match;
  const { id } = params;
  useEffect(() => {
    if (!files && !isLoading) {
      fetchArchiveRequests(id);
      getAqarFiles(id);
    }
  }, [loading, getAqarFiles, files, id]);
  useEffect(() => {
    if (!data) fetchAqarByAqarNumber(id);
  }, [loading, fetchAqarByAqarNumber, data, id]);
  // useEffect(() => {
  //    fetchArchiveRequests(id);
  // }, [archive.length]);
  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
    },
    gridList: {
      //width: 900,
      height: 'auto',
    },
    icon: {
      color: 'rgba(255, 255, 255, 0.54)',
    },
    image: {
      '&:hover': {
        opacity: 0.25,
      },
    },
  }));
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [imgeNewName, setImageNewName] = useState({});
  const seletcFile = ({ target }) => {
    const fils = target.files;
    var result = Object.keys(fils).map((key) => [fils[key]]);
    const ff = result.map((file) => ({ name: 'DE-', value: file[0] }));
    setSelectedFiles(ff);
  };
  const classes = useStyles();
  function isImage(file) {
    return file['type'].split('/')[0] == 'image';
  }
  const renameFile = (file) => {
    setSelectedFiles([
      ...selectedFiles.filter((fi) => fi.value.name !== file.value.name),
      file,
    ]);
  };
  const saveNewName = (url) => {
    renameFileinAws({ imgeNewName, url }, id);
  };
  const reNameFileinAws = (name) => {
    setImageNewName(name);
  };
  const deleteFileFromAWS = (url) => {
    deleteFile(id, url);
  };
  const printLetter = (name) => {
    printDeleteLetter(id, name);
  };
  const sendFiles = () => {
    var bodyFormData = new FormData();
    selectedFiles.forEach((fi, index) => {
      bodyFormData.append(`${id}-${fi.name}-0${index}`, fi.value);
    });
    setSelectedFiles([]);
    uploadAqarFiles(id, bodyFormData);
  };
  return (
    <Fragment>
      {data === null || loading ? (
        <Spinner />
      ) : (
        <Grid container spacing={4}>
          <Grid item sm={2}>
            <Sidebar proprtyId={id} />
          </Grid>
          {!files ? (
            <Spinner />
          ) : (
            <Grid item xs={10}>
              <Grid item xs={12} style={{ marginBottom: 30 }}>
                {archive.requests.length > 0
                  ? archive.requests.map((ar) => (
                      <ArchiveRequestContainer request={ar} aqar_number={id} />
                    ))
                  : null}
                <input
                  // accept='pdf'
                  className={classes.input}
                  style={{ display: 'none' }}
                  id='raised-button-file'
                  onChange={seletcFile}
                  multiple
                  type='file'
                />
                <label htmlFor='raised-button-file'>
                  {user.roll.id === 4 || user.roll.id === 0 ? (
                    <Button
                      variant='contained'
                      color='primary'
                      component='span'
                      disabled={isLoading}
                      className={classes.button}
                      id='uplaod'
                    >
                      {isLoading.isLoading ? (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      ) : null}
                      رفع ملفات
                    </Button>
                  ) : null}
                </label>
              </Grid>
              {selectedFiles.length > 0 ? (
                <Grid item xs={12}>
                  <Button
                    variant='contained'
                    color='primary'
                    component='span'
                    onClick={sendFiles}
                    id='send'
                    className={classes.button}
                  >
                    ارسال
                  </Button>
                </Grid>
              ) : null}
              <Grid item xs={12}>
                <Grid container spacing={4}>
                  {selectedFiles.map((tile, index) => (
                    <Fragment key={index}>
                      <UploadedImagePreview
                        file={tile}
                        key={index.name}
                        onNameChange={renameFile}
                      />
                    </Fragment>
                  ))}
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                {files
                  ? files.map((tile) => (
                      <Fragment key={tile.url}>
                        <ImageCard
                          url={tile.url}
                          name={tile.Key}
                          opt={imgeNewName}
                          changeFileName={reNameFileinAws}
                          saveNewFileName={saveNewName}
                          deleteFile={deleteFileFromAWS}
                          printLetter={printLetter}
                        />
                      </Fragment>
                    ))
                  : null}
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  aqar: state.aqar,
  auth: state.auth,
  isLoading: state.isLoading.isLoading,
  archive: state.archive,
});
export default connect(mapStateToProps, {
  getAqarFiles,
  uploadAqarFiles,
  fetchAqarByAqarNumber,
  renameFileinAws,
  deleteFile,
  printDeleteLetter,
  fetchArchiveRequests,
})(Archives);
