import { SET_IS_LOADING, REMOVE_IS_LOADING } from "../actions/types";
const initialState = {
  isLoading: false,
};
export default function (state = initialState, action) {
  const { type } = action;
  switch (type) {
    case SET_IS_LOADING:
      return { ...state, isLoading: true };
    case REMOVE_IS_LOADING:
      return { ...state, isLoading: false };
    default:
      return state;
  }
}
