import { SELECT_PROPRTY, DESELECT_PROPRTY } from '../actions/types';
const initialState = {};
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SELECT_PROPRTY:
      return { ...state, selectedId: payload };
    case DESELECT_PROPRTY:
      return { ...state, selectedId: null };
    default:
      return state;
  }
}
