export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REGISTER_SUCCES = 'REGISTER_SUCCES';
export const REGISTER_FAEL = 'REGISTER_FAEL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCES = 'LOGIN_SUCCES';
export const LOGIN_FAEL = 'LOGIN_FAEL';
export const LOGOUT = 'LOGOUT';

export const GET_PROFILE = 'GET_PROFILE';
export const GET_PROFILES = 'GET_PROFILES';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const PROFILE_ERROR = 'PROFILE_ERROR';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';
export const ACCOUNT_DELETE = 'ACCOUNT_DELETE';
export const SELECT_PROPRTY = 'SELECT_PROPRTY';
export const DESELECT_PROPRTY = 'DESELECT_PROPRTY';

// AQARAT
export const SEARCH_AQARAT = 'SEARCH_AQARAT';
export const FETCH_AQARAT = 'FETCH_AQARAT';
export const ERROR_FETCH_AQARAT = 'ERROR_FETCH_AQARAT';
export const FETCH_AQAR = 'FETCH_AQAR';
export const ERROR_FETCH_AQAR = 'ERROR_FETCH_AQAR';
export const ERROR_FETCH_AQAR_PRICES = 'ERROR_FETCH_AQAR_PRICES';
export const FETCH_AQAR_PRICES = 'FETCH_AQAR_PRICES';
export const FETCH_AQAR_ANNOUNCMENTS = 'FETCH_AQAR_ANNOUNCMENTS';
export const ERROR_FETCH_AQAR_ANNOUNCMENTS = 'ERROR_FETCH_AQAR_ANNOUNCMENTS';
export const FETCH_AQAR_RECOARDS = 'FETCH_AQAR_RECOARDS';
export const ERROR_FETCH_AQAR_RECOARDS = 'ERROR_FETCH_AQAR_RECOARDS';

export const FETCH_AQAR_FILES = 'FETCH_AQAR_FILES';
export const ERROR_FETCH_AQAR_FILES = 'ERROR_FETCH_AQAR_FILES';

export const FETCH_AQAR_DETAILS = 'FETCH_AQAR_DETAILS';
export const ERROR_FETCH_AQAR_DETAILS = 'ERROR_FETCH_AQAR_DETAILS';

export const FETCH_AQAR_HASER = 'FETCH_AQAR_HASER';
export const ERROR_FETCH_AQAR_HASER = 'ERROR_FETCH_AQAR_HASER';
export const FETCH_AQAR_COOR = 'FETCH_AQAR_COOR';
export const ERROR_FETCH_AQAR_COOR = 'ERROR_FETCH_AQAR_COOR';
export const FETCH_PRICE_REVISION = 'FETCH_PRICE_REVISION';
export const ERROR_FETCH_PRICE_REVISION = 'ERROR_FETCH_PRICE_REVISION';
// IN COMING
export const FETCH_INCOMING_RECOARDS = 'FETCH_INCOMING_RECOARDS';
export const ERROR_FETCH_INCOMING_RECOARDS = 'ERROR_FETCH_INCOMING_RECOARDS';
export const SEARCH_INCOMING_RECOARDS = 'SEARCH_INCOMING_RECOARDS';
export const FETCH_INCOMING = 'FETCH_INCOMING';
export const ERROR_FETCH_INCOMING = 'ERROR_FETCH_INCOMING';
export const FETCH_NEW_INCOMING_NUMBER = 'FETCH_NEW_INCOMING_NUMBER';
export const ERROR_FETCH_NEW_INCOMING_NUMBER =
  'ERROR_FETCH_NEW_INCOMING_NUMBER';
export const FETCH_INCOMING_REFFS = 'FETCH_INCOMING_REFFS';
export const ERROR_FETCH_INCOMING_REFFS = 'ERROR_FETCH_INCOMING_REFFS';
export const FETCH_IN_COMING_FIELS = 'FETCH_IN_COMING_FIELS';
export const ERROR_FETCH_IN_COMING_FIELS = 'ERROR_FETCH_IN_COMING_FIELS';
// RECOARDS == Out going
export const FETCH_RECOARS = 'FETCH_RECOARS';
export const ERROR_FETCH_RECOARDS = 'ERROR_FETCH_RECOARDS';
export const SEARCH_RECOAR = 'SEARCH_RECOAR';
export const FETCH_RECOARD = 'FETCH_RECOARD';
export const ERROR_FETCH_RECOARD = 'ERROR_FETCH_RECOARD';
export const FETCH_RECOARD_NUMBER = 'FETCH_RECOARD_NUMBER';
export const ERROR_FETCH_RECOARD_NUMBER = 'ERROR_FETCH_RECOARD_NUMBER';
export const FETCH_OUT_GOING_FIELS = 'FETCH_OUT_GOING_FIELS';
export const ERROR_FETCH_OUT_GOING_FIELS = 'ERROR_FETCH_OUT_GOING_FIELS';

export const FETCH_LINKED_AQARAT = 'FETCH_LINKED_AQARAT';
export const ERROR_FETCH_LINKED_AQARAT = 'ERROR_FETCH_LINKED_AQARAT';

export const FETCH_LINKED_RECOARDS = 'FETCH_LINKED_RECOARDS';
export const ERROR_FETCH_LINKED_RECOARDS = 'ERROR_FETCH_LINKED_RECOARDS';
// USERS
export const SEARCH_USERS = 'SEARCH_USERS';
export const FETCH_USER_BY_ID = 'FETCH_USER_BY_ID';
export const ERROR_FETCH_USER_BY_ID = 'ERROR_FETCH_USER_BY_ID';
export const FETCH_USERS = 'FETCH_USERS';
export const ERROR_FETCH_USERS = 'ERROR_FETCH_USERS';
// ACCESS RIGHT
export const FETCH_ACCESS_RIGHT = 'FETCH_ACCESS_RIGHT';
export const ERROR_FETCH_ACCESS_RIGHT = 'ERROR_FETCH_ACCESS_RIGHT';

// DEPARTMENT
export const FETCH_DEPARTMENTS = 'FETCH_DEPARTMENTS';
export const ERROR_FETCH_DEPARTMENTS = 'ERROR_FETCH_DEPARTMENTS';
export const SEARCH_DEPARTMENTS = 'SEARCH_DEPARTMENTS';
export const FETCH_DEPARTMENT_BY_ID = 'FETCH_DEPARTMENT_BY_ID';
export const ERROR_FETCH_DEPARTMENT_BY_ID = 'ERROR_FETCH_DEPARTMENT_BY_ID';

// LOG
export const FETCH_LOGS = 'FETCH_LOGS';
export const ERROR_FETCH_LOGS = 'ERROR_FETCH_LOGS';

// STATUS
export const FETCH_AQARAT_COUNT = 'FETCH_AQARAT_COUNT';
export const ERROR_FETCH_AQARAT_COUNT = 'ERROR_FETCH_AQARAT_COUNT';
export const FETCH_NAKASAH_NOA_ALMILKIA = 'FETCH_NAKASAH_NOA_ALMILKIA';
export const ERROR_FETCH_NAKASAH_NOA_ALMILKIA =
  'ERROR_FETCH_NAKASAH_NOA_ALMILKIA';
export const FETCH_HASER_STATUS = 'FETCH_HASER_STATUS';
export const ERROR_FETCH_HASER_STATUS = 'ERROR_FETCH_HASER_STATUS';

export const FETCH_KROKI_STATUS = 'FETCH_KROKI_STATUS';
export const ERROR_FETCH_KROKI_STATUS = 'ERROR_FETCH_KROKI_STATUS';

// AUTO_FILL
export const AUTO_FILL = 'AUTO_FILL';
export const EMPTY_AUTO_FILL = 'EMPTY_AUTO_FILL';
// LAYERS
export const FETCH_LAYERS = 'FETCH_LAYERS';
export const ERROR_FETCH_LAYERS = 'ERROR_FETCH_LAYERS';
// LOADING
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const REMOVE_IS_LOADING = 'REMOVE_IS_LOADING';
// CATEGORIES
export const FETCH_ALL_CATEORIES = 'FETCH_ALL_CATEORIES';
export const ERROR_FETCH_ALL_CATEORIES = 'ERROR_FETCH_ALL_CATEORIES';

export const FETCH_CATEORY = 'FETCH_CATEORY';
export const ERROR_FETCH_CATEORY = 'ERROR_FETCH_CATEORY';
export const FETCH_SUB_CATEORIES = 'FETCH_SUB_CATEORIES';
export const ERROR_FETCH_SUB_CATEORIES = 'ERROR_FETCH_SUB_CATEORIES';

export const FETECH_CATEGORY_SUBS = 'FETECH_CATEGORY_SUBS';
export const ERROR_FETECH_CATEGORY_SUBS = 'ERROR_FETECH_CATEGORY_SUBS';
//  Arcive Request
export const FETCH_ARCHIVE_REQUESTS = 'FETCH_ARCHIVE_REQUESTS';
export const ERROR_FETCH_ARCHIVE_REQUESTS = 'ERROR_FETCH_ARCHIVE_REQUESTS';
